import React, { useCallback, useMemo, useRef, useState } from 'react';
import './waterLog.scss';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { waterApi } from '../api';
import { WaterResponse } from '../api/generated/models';
import { useStore } from '../context';
import { Trans } from '../i18n';
import WaterCup from '../theme/icons/water.svg';

export const useWater = (opts: {
  date: DateTime,
  patient_id: number,
}) => {
  const { date, patient_id } = opts;
  const queryClient = useQueryClient();
  const dateMonth = date?.startOf('month');
  // eslint-disable-next-line i18next/no-literal-string
  const queryKey = ['water', patient_id, dateMonth?.toISODate()];

  const query = useQuery(queryKey, async () => {
    if (!patient_id || !dateMonth) {
      return null;
    }

    const res = await waterApi.appApiWaterGetWaters({
      patient_id: patient_id,
      date_since: dateMonth.toISODate(),
      date_until: dateMonth.plus({ months: 1 }).toISODate(),
    }).catch(err => {
      console.error('WaterService: error fetching water data:', err);
      if (err instanceof AxiosError && err.response?.status == 404) {
        return { data: [] };
      }
      throw err;
    });
    return res.data;
  });

  return {
    query,
    cups: query.data?.find(w => w.water_date == date.toISODate())?.cups || 0,
    setCups: (cups: number) => {
      waterApi.appApiWaterPutWater({
        patient_id: patient_id,
        date: date.toISODate(),
        cups: cups,
      });
      queryClient.setQueriesData(queryKey, (oldWaters: WaterResponse[]) => {
        const oldWater = oldWaters?.find(w => w.water_date == date.toISODate());
        if (oldWater) {
          return oldWaters.map(w => w.water_date == date.toISODate() ? { ...w, cups: cups } : w);
        }
        const newWater = [...oldWaters, { water_date: date.toISODate(), cups: cups }];
        return _.sortBy(newWater, w => w.water_date);
      });
    },
  };
};

export const WaterLog = (props: { date: DateTime, patientId: number }) => {
  const { date, patientId } = props;
  const water = useWater({ date: date, patient_id: patientId });
  const AddCups = () => {
    water.setCups(water.cups + 1);
  };

  const minusCups = () => {
    if (water.cups != 0) {
      water.setCups(water.cups - 1);
    }
  };

  return (
    <div className="d-flex justify-content-between align-items-center waterLogCard">
      <img src={WaterCup} alt="water-cup" />
      <span>
        <Trans count={water?.cups}>{{ count: water?.cups }} Cups</Trans>
      </span>
      <span className="water-plus waterbtn">
        <i className="fas fa-minus" onClick={minusCups} />
      </span>
      <span className="water-minus waterbtn" onClick={AddCups}>
        <i className="fas fa-plus" />
      </span>
    </div>
  );
};
