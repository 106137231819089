/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateGoalRequest } from '../models';
// @ts-ignore
import { CreateGoalsRequest } from '../models';
// @ts-ignore
import { GoalLogProgressRequest } from '../models';
// @ts-ignore
import { GoalProgressResponse } from '../models';
// @ts-ignore
import { GoalRecentFrequencyBreakdownResponse } from '../models';
// @ts-ignore
import { GoalTargetResponse } from '../models';
// @ts-ignore
import { UpdateGoalRequest } from '../models';
/**
 * GoalApi - axios parameter creator
 * @export
 */
export const GoalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete the goal of the patient
         * @param {number} patient_id 
         * @param {number} goal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalDeleteGoal: async (patient_id: number, goal_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiGoalDeleteGoal', 'patient_id', patient_id)
            // verify required parameter 'goal_id' is not null or undefined
            assertParamExists('appApiGoalDeleteGoal', 'goal_id', goal_id)
            const localVarPath = `/patients/{patient_id}/goals/{goal_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"goal_id"}}`, encodeURIComponent(String(goal_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the goal of the patient
         * @param {number} patient_id 
         * @param {number} goal_id 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetGoal: async (patient_id: number, goal_id: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiGoalGetGoal', 'patient_id', patient_id)
            // verify required parameter 'goal_id' is not null or undefined
            assertParamExists('appApiGoalGetGoal', 'goal_id', goal_id)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('appApiGoalGetGoal', 'date', date)
            const localVarPath = `/patients/{patient_id}/goals/{goal_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"goal_id"}}`, encodeURIComponent(String(goal_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the possible goal targets for nutrients or food types (the cc columns) supported in the system. Use this as a catalogue for goal targets when creating a new goal.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetGoalTargets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/goal_targets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s goals and progress for specific calendar date
         * @param {number} patient_id 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetGoals: async (patient_id: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiGoalGetGoals', 'patient_id', patient_id)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('appApiGoalGetGoals', 'date', date)
            const localVarPath = `/patients/{patient_id}/goals`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the recent frequency breakdown for the patient, for use by the mobile app to suggest goals. Note: currently only returns cc_* nutrients.
         * @param {number} patient_id 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetRecentFrequencyBreakdown: async (patient_id: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiGoalGetRecentFrequencyBreakdown', 'patient_id', patient_id)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('appApiGoalGetRecentFrequencyBreakdown', 'date', date)
            const localVarPath = `/patients/{patient_id}/goals/recent-frequency-breakdown`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log progress towards the goal of the patient
         * @param {number} patient_id 
         * @param {number} goal_id 
         * @param {GoalLogProgressRequest} GoalLogProgressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalPostGoalLogProgress: async (patient_id: number, goal_id: number, GoalLogProgressRequest: GoalLogProgressRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiGoalPostGoalLogProgress', 'patient_id', patient_id)
            // verify required parameter 'goal_id' is not null or undefined
            assertParamExists('appApiGoalPostGoalLogProgress', 'goal_id', goal_id)
            // verify required parameter 'GoalLogProgressRequest' is not null or undefined
            assertParamExists('appApiGoalPostGoalLogProgress', 'GoalLogProgressRequest', GoalLogProgressRequest)
            const localVarPath = `/patients/{patient_id}/goals/{goal_id}/log-progress`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"goal_id"}}`, encodeURIComponent(String(goal_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(GoalLogProgressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the progress log entry
         * @param {number} patient_id 
         * @param {number} goal_id 
         * @param {number} progress_log_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalPostGoalLogProgressDelete: async (patient_id: number, goal_id: number, progress_log_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiGoalPostGoalLogProgressDelete', 'patient_id', patient_id)
            // verify required parameter 'goal_id' is not null or undefined
            assertParamExists('appApiGoalPostGoalLogProgressDelete', 'goal_id', goal_id)
            // verify required parameter 'progress_log_id' is not null or undefined
            assertParamExists('appApiGoalPostGoalLogProgressDelete', 'progress_log_id', progress_log_id)
            const localVarPath = `/patients/{patient_id}/goals/{goal_id}/log-progress/{progress_log_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"goal_id"}}`, encodeURIComponent(String(goal_id)))
                .replace(`{${"progress_log_id"}}`, encodeURIComponent(String(progress_log_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new goal of the patient
         * @param {number} patient_id 
         * @param {CreateGoalRequest} CreateGoalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalPostGoals: async (patient_id: number, CreateGoalRequest: CreateGoalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiGoalPostGoals', 'patient_id', patient_id)
            // verify required parameter 'CreateGoalRequest' is not null or undefined
            assertParamExists('appApiGoalPostGoals', 'CreateGoalRequest', CreateGoalRequest)
            const localVarPath = `/patients/{patient_id}/goals`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateGoalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the goal of the patient
         * @param {number} patient_id 
         * @param {number} goal_id 
         * @param {UpdateGoalRequest} UpdateGoalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalPutGoal: async (patient_id: number, goal_id: number, UpdateGoalRequest: UpdateGoalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiGoalPutGoal', 'patient_id', patient_id)
            // verify required parameter 'goal_id' is not null or undefined
            assertParamExists('appApiGoalPutGoal', 'goal_id', goal_id)
            // verify required parameter 'UpdateGoalRequest' is not null or undefined
            assertParamExists('appApiGoalPutGoal', 'UpdateGoalRequest', UpdateGoalRequest)
            const localVarPath = `/patients/{patient_id}/goals/{goal_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"goal_id"}}`, encodeURIComponent(String(goal_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateGoalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the goals of the patient
         * @param {number} patient_id 
         * @param {CreateGoalsRequest} CreateGoalsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalPutGoals: async (patient_id: number, CreateGoalsRequest: CreateGoalsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiGoalPutGoals', 'patient_id', patient_id)
            // verify required parameter 'CreateGoalsRequest' is not null or undefined
            assertParamExists('appApiGoalPutGoals', 'CreateGoalsRequest', CreateGoalsRequest)
            const localVarPath = `/patients/{patient_id}/goals`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateGoalsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GoalApi - functional programming interface
 * @export
 */
export const GoalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GoalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete the goal of the patient
         * @param {number} patient_id 
         * @param {number} goal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiGoalDeleteGoal(patient_id: number, goal_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiGoalDeleteGoal(patient_id, goal_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the goal of the patient
         * @param {number} patient_id 
         * @param {number} goal_id 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiGoalGetGoal(patient_id: number, goal_id: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoalProgressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiGoalGetGoal(patient_id, goal_id, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return the possible goal targets for nutrients or food types (the cc columns) supported in the system. Use this as a catalogue for goal targets when creating a new goal.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiGoalGetGoalTargets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GoalTargetResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiGoalGetGoalTargets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s goals and progress for specific calendar date
         * @param {number} patient_id 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiGoalGetGoals(patient_id: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GoalProgressResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiGoalGetGoals(patient_id, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the recent frequency breakdown for the patient, for use by the mobile app to suggest goals. Note: currently only returns cc_* nutrients.
         * @param {number} patient_id 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiGoalGetRecentFrequencyBreakdown(patient_id: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoalRecentFrequencyBreakdownResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiGoalGetRecentFrequencyBreakdown(patient_id, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Log progress towards the goal of the patient
         * @param {number} patient_id 
         * @param {number} goal_id 
         * @param {GoalLogProgressRequest} GoalLogProgressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiGoalPostGoalLogProgress(patient_id: number, goal_id: number, GoalLogProgressRequest: GoalLogProgressRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoalProgressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiGoalPostGoalLogProgress(patient_id, goal_id, GoalLogProgressRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes the progress log entry
         * @param {number} patient_id 
         * @param {number} goal_id 
         * @param {number} progress_log_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiGoalPostGoalLogProgressDelete(patient_id: number, goal_id: number, progress_log_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoalProgressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiGoalPostGoalLogProgressDelete(patient_id, goal_id, progress_log_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new goal of the patient
         * @param {number} patient_id 
         * @param {CreateGoalRequest} CreateGoalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiGoalPostGoals(patient_id: number, CreateGoalRequest: CreateGoalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoalProgressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiGoalPostGoals(patient_id, CreateGoalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the goal of the patient
         * @param {number} patient_id 
         * @param {number} goal_id 
         * @param {UpdateGoalRequest} UpdateGoalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiGoalPutGoal(patient_id: number, goal_id: number, UpdateGoalRequest: UpdateGoalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoalProgressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiGoalPutGoal(patient_id, goal_id, UpdateGoalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the goals of the patient
         * @param {number} patient_id 
         * @param {CreateGoalsRequest} CreateGoalsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiGoalPutGoals(patient_id: number, CreateGoalsRequest: CreateGoalsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GoalProgressResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiGoalPutGoals(patient_id, CreateGoalsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GoalApi - factory interface
 * @export
 */
export const GoalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GoalApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete the goal of the patient
         * @param {GoalApiAppApiGoalDeleteGoalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalDeleteGoal(requestParameters: GoalApiAppApiGoalDeleteGoalRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiGoalDeleteGoal(requestParameters.patient_id, requestParameters.goal_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the goal of the patient
         * @param {GoalApiAppApiGoalGetGoalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetGoal(requestParameters: GoalApiAppApiGoalGetGoalRequest, options?: AxiosRequestConfig): AxiosPromise<GoalProgressResponse> {
            return localVarFp.appApiGoalGetGoal(requestParameters.patient_id, requestParameters.goal_id, requestParameters.date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the possible goal targets for nutrients or food types (the cc columns) supported in the system. Use this as a catalogue for goal targets when creating a new goal.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetGoalTargets(options?: AxiosRequestConfig): AxiosPromise<Array<GoalTargetResponse>> {
            return localVarFp.appApiGoalGetGoalTargets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s goals and progress for specific calendar date
         * @param {GoalApiAppApiGoalGetGoalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetGoals(requestParameters: GoalApiAppApiGoalGetGoalsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<GoalProgressResponse>> {
            return localVarFp.appApiGoalGetGoals(requestParameters.patient_id, requestParameters.date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the recent frequency breakdown for the patient, for use by the mobile app to suggest goals. Note: currently only returns cc_* nutrients.
         * @param {GoalApiAppApiGoalGetRecentFrequencyBreakdownRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetRecentFrequencyBreakdown(requestParameters: GoalApiAppApiGoalGetRecentFrequencyBreakdownRequest, options?: AxiosRequestConfig): AxiosPromise<GoalRecentFrequencyBreakdownResponse> {
            return localVarFp.appApiGoalGetRecentFrequencyBreakdown(requestParameters.patient_id, requestParameters.date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log progress towards the goal of the patient
         * @param {GoalApiAppApiGoalPostGoalLogProgressRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalPostGoalLogProgress(requestParameters: GoalApiAppApiGoalPostGoalLogProgressRequest, options?: AxiosRequestConfig): AxiosPromise<GoalProgressResponse> {
            return localVarFp.appApiGoalPostGoalLogProgress(requestParameters.patient_id, requestParameters.goal_id, requestParameters.GoalLogProgressRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the progress log entry
         * @param {GoalApiAppApiGoalPostGoalLogProgressDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalPostGoalLogProgressDelete(requestParameters: GoalApiAppApiGoalPostGoalLogProgressDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<GoalProgressResponse> {
            return localVarFp.appApiGoalPostGoalLogProgressDelete(requestParameters.patient_id, requestParameters.goal_id, requestParameters.progress_log_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new goal of the patient
         * @param {GoalApiAppApiGoalPostGoalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalPostGoals(requestParameters: GoalApiAppApiGoalPostGoalsRequest, options?: AxiosRequestConfig): AxiosPromise<GoalProgressResponse> {
            return localVarFp.appApiGoalPostGoals(requestParameters.patient_id, requestParameters.CreateGoalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the goal of the patient
         * @param {GoalApiAppApiGoalPutGoalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalPutGoal(requestParameters: GoalApiAppApiGoalPutGoalRequest, options?: AxiosRequestConfig): AxiosPromise<GoalProgressResponse> {
            return localVarFp.appApiGoalPutGoal(requestParameters.patient_id, requestParameters.goal_id, requestParameters.UpdateGoalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the goals of the patient
         * @param {GoalApiAppApiGoalPutGoalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalPutGoals(requestParameters: GoalApiAppApiGoalPutGoalsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<GoalProgressResponse>> {
            return localVarFp.appApiGoalPutGoals(requestParameters.patient_id, requestParameters.CreateGoalsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiGoalDeleteGoal operation in GoalApi.
 * @export
 * @interface GoalApiAppApiGoalDeleteGoalRequest
 */
export interface GoalApiAppApiGoalDeleteGoalRequest {
    /**
     * 
     * @type {number}
     * @memberof GoalApiAppApiGoalDeleteGoal
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof GoalApiAppApiGoalDeleteGoal
     */
    readonly goal_id: number
}

/**
 * Request parameters for appApiGoalGetGoal operation in GoalApi.
 * @export
 * @interface GoalApiAppApiGoalGetGoalRequest
 */
export interface GoalApiAppApiGoalGetGoalRequest {
    /**
     * 
     * @type {number}
     * @memberof GoalApiAppApiGoalGetGoal
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof GoalApiAppApiGoalGetGoal
     */
    readonly goal_id: number

    /**
     * 
     * @type {string}
     * @memberof GoalApiAppApiGoalGetGoal
     */
    readonly date: string
}

/**
 * Request parameters for appApiGoalGetGoals operation in GoalApi.
 * @export
 * @interface GoalApiAppApiGoalGetGoalsRequest
 */
export interface GoalApiAppApiGoalGetGoalsRequest {
    /**
     * 
     * @type {number}
     * @memberof GoalApiAppApiGoalGetGoals
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof GoalApiAppApiGoalGetGoals
     */
    readonly date: string
}

/**
 * Request parameters for appApiGoalGetRecentFrequencyBreakdown operation in GoalApi.
 * @export
 * @interface GoalApiAppApiGoalGetRecentFrequencyBreakdownRequest
 */
export interface GoalApiAppApiGoalGetRecentFrequencyBreakdownRequest {
    /**
     * 
     * @type {number}
     * @memberof GoalApiAppApiGoalGetRecentFrequencyBreakdown
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof GoalApiAppApiGoalGetRecentFrequencyBreakdown
     */
    readonly date: string
}

/**
 * Request parameters for appApiGoalPostGoalLogProgress operation in GoalApi.
 * @export
 * @interface GoalApiAppApiGoalPostGoalLogProgressRequest
 */
export interface GoalApiAppApiGoalPostGoalLogProgressRequest {
    /**
     * 
     * @type {number}
     * @memberof GoalApiAppApiGoalPostGoalLogProgress
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof GoalApiAppApiGoalPostGoalLogProgress
     */
    readonly goal_id: number

    /**
     * 
     * @type {GoalLogProgressRequest}
     * @memberof GoalApiAppApiGoalPostGoalLogProgress
     */
    readonly GoalLogProgressRequest: GoalLogProgressRequest
}

/**
 * Request parameters for appApiGoalPostGoalLogProgressDelete operation in GoalApi.
 * @export
 * @interface GoalApiAppApiGoalPostGoalLogProgressDeleteRequest
 */
export interface GoalApiAppApiGoalPostGoalLogProgressDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof GoalApiAppApiGoalPostGoalLogProgressDelete
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof GoalApiAppApiGoalPostGoalLogProgressDelete
     */
    readonly goal_id: number

    /**
     * 
     * @type {number}
     * @memberof GoalApiAppApiGoalPostGoalLogProgressDelete
     */
    readonly progress_log_id: number
}

/**
 * Request parameters for appApiGoalPostGoals operation in GoalApi.
 * @export
 * @interface GoalApiAppApiGoalPostGoalsRequest
 */
export interface GoalApiAppApiGoalPostGoalsRequest {
    /**
     * 
     * @type {number}
     * @memberof GoalApiAppApiGoalPostGoals
     */
    readonly patient_id: number

    /**
     * 
     * @type {CreateGoalRequest}
     * @memberof GoalApiAppApiGoalPostGoals
     */
    readonly CreateGoalRequest: CreateGoalRequest
}

/**
 * Request parameters for appApiGoalPutGoal operation in GoalApi.
 * @export
 * @interface GoalApiAppApiGoalPutGoalRequest
 */
export interface GoalApiAppApiGoalPutGoalRequest {
    /**
     * 
     * @type {number}
     * @memberof GoalApiAppApiGoalPutGoal
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof GoalApiAppApiGoalPutGoal
     */
    readonly goal_id: number

    /**
     * 
     * @type {UpdateGoalRequest}
     * @memberof GoalApiAppApiGoalPutGoal
     */
    readonly UpdateGoalRequest: UpdateGoalRequest
}

/**
 * Request parameters for appApiGoalPutGoals operation in GoalApi.
 * @export
 * @interface GoalApiAppApiGoalPutGoalsRequest
 */
export interface GoalApiAppApiGoalPutGoalsRequest {
    /**
     * 
     * @type {number}
     * @memberof GoalApiAppApiGoalPutGoals
     */
    readonly patient_id: number

    /**
     * 
     * @type {CreateGoalsRequest}
     * @memberof GoalApiAppApiGoalPutGoals
     */
    readonly CreateGoalsRequest: CreateGoalsRequest
}

/**
 * GoalApi - object-oriented interface
 * @export
 * @class GoalApi
 * @extends {BaseAPI}
 */
export class GoalApi extends BaseAPI {
    /**
     * 
     * @summary Delete the goal of the patient
     * @param {GoalApiAppApiGoalDeleteGoalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public appApiGoalDeleteGoal(requestParameters: GoalApiAppApiGoalDeleteGoalRequest, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).appApiGoalDeleteGoal(requestParameters.patient_id, requestParameters.goal_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the goal of the patient
     * @param {GoalApiAppApiGoalGetGoalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public appApiGoalGetGoal(requestParameters: GoalApiAppApiGoalGetGoalRequest, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).appApiGoalGetGoal(requestParameters.patient_id, requestParameters.goal_id, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the possible goal targets for nutrients or food types (the cc columns) supported in the system. Use this as a catalogue for goal targets when creating a new goal.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public appApiGoalGetGoalTargets(options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).appApiGoalGetGoalTargets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s goals and progress for specific calendar date
     * @param {GoalApiAppApiGoalGetGoalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public appApiGoalGetGoals(requestParameters: GoalApiAppApiGoalGetGoalsRequest, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).appApiGoalGetGoals(requestParameters.patient_id, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the recent frequency breakdown for the patient, for use by the mobile app to suggest goals. Note: currently only returns cc_* nutrients.
     * @param {GoalApiAppApiGoalGetRecentFrequencyBreakdownRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public appApiGoalGetRecentFrequencyBreakdown(requestParameters: GoalApiAppApiGoalGetRecentFrequencyBreakdownRequest, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).appApiGoalGetRecentFrequencyBreakdown(requestParameters.patient_id, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log progress towards the goal of the patient
     * @param {GoalApiAppApiGoalPostGoalLogProgressRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public appApiGoalPostGoalLogProgress(requestParameters: GoalApiAppApiGoalPostGoalLogProgressRequest, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).appApiGoalPostGoalLogProgress(requestParameters.patient_id, requestParameters.goal_id, requestParameters.GoalLogProgressRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the progress log entry
     * @param {GoalApiAppApiGoalPostGoalLogProgressDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public appApiGoalPostGoalLogProgressDelete(requestParameters: GoalApiAppApiGoalPostGoalLogProgressDeleteRequest, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).appApiGoalPostGoalLogProgressDelete(requestParameters.patient_id, requestParameters.goal_id, requestParameters.progress_log_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new goal of the patient
     * @param {GoalApiAppApiGoalPostGoalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public appApiGoalPostGoals(requestParameters: GoalApiAppApiGoalPostGoalsRequest, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).appApiGoalPostGoals(requestParameters.patient_id, requestParameters.CreateGoalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the goal of the patient
     * @param {GoalApiAppApiGoalPutGoalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public appApiGoalPutGoal(requestParameters: GoalApiAppApiGoalPutGoalRequest, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).appApiGoalPutGoal(requestParameters.patient_id, requestParameters.goal_id, requestParameters.UpdateGoalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the goals of the patient
     * @param {GoalApiAppApiGoalPutGoalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public appApiGoalPutGoals(requestParameters: GoalApiAppApiGoalPutGoalsRequest, options?: AxiosRequestConfig) {
        return GoalApiFp(this.configuration).appApiGoalPutGoals(requestParameters.patient_id, requestParameters.CreateGoalsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

