/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateUserSurveyFormRequest } from '../models';
// @ts-ignore
import { CreateUserSurveyResponseRequest } from '../models';
// @ts-ignore
import { UserSurveyFormResponse } from '../models';
// @ts-ignore
import { UserSurveyResponseResponse } from '../models';
/**
 * UserSurveysApi - axios parameter creator
 * @export
 */
export const UserSurveysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a user survey form
         * @param {number} user_id 
         * @param {string} survey_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserSurveyGetUserSurveyForm: async (user_id: number, survey_type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiUserSurveyGetUserSurveyForm', 'user_id', user_id)
            // verify required parameter 'survey_type' is not null or undefined
            assertParamExists('appApiUserSurveyGetUserSurveyForm', 'survey_type', survey_type)
            const localVarPath = `/user-surveys/user/{user_id}/forms/{survey_type}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)))
                .replace(`{${"survey_type"}}`, encodeURIComponent(String(survey_type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user survey response
         * @param {number} user_id 
         * @param {string} [survey_type] 
         * @param {string} [before_time] 
         * @param {string} [related_activity_type] 
         * @param {number} [related_activity_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserSurveyGetUserSurveyResponses: async (user_id: number, survey_type?: string, before_time?: string, related_activity_type?: string, related_activity_id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiUserSurveyGetUserSurveyResponses', 'user_id', user_id)
            const localVarPath = `/user-surveys/user/{user_id}/responses`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (survey_type !== undefined) {
                localVarQueryParameter['survey_type'] = survey_type;
            }

            if (before_time !== undefined) {
                localVarQueryParameter['before_time'] = before_time;
            }

            if (related_activity_type !== undefined) {
                localVarQueryParameter['related_activity_type'] = related_activity_type;
            }

            if (related_activity_id !== undefined) {
                localVarQueryParameter['related_activity_id'] = related_activity_id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post a user survey form
         * @param {CreateUserSurveyFormRequest} CreateUserSurveyFormRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserSurveyPostUserSurveyForm: async (CreateUserSurveyFormRequest: CreateUserSurveyFormRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateUserSurveyFormRequest' is not null or undefined
            assertParamExists('appApiUserSurveyPostUserSurveyForm', 'CreateUserSurveyFormRequest', CreateUserSurveyFormRequest)
            const localVarPath = `/user-surveys/form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateUserSurveyFormRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post a user survey response
         * @param {number} user_id 
         * @param {CreateUserSurveyResponseRequest} CreateUserSurveyResponseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserSurveyPostUserSurveyResponse: async (user_id: number, CreateUserSurveyResponseRequest: CreateUserSurveyResponseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiUserSurveyPostUserSurveyResponse', 'user_id', user_id)
            // verify required parameter 'CreateUserSurveyResponseRequest' is not null or undefined
            assertParamExists('appApiUserSurveyPostUserSurveyResponse', 'CreateUserSurveyResponseRequest', CreateUserSurveyResponseRequest)
            const localVarPath = `/user-surveys/user/{user_id}/responses`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateUserSurveyResponseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSurveysApi - functional programming interface
 * @export
 */
export const UserSurveysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserSurveysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a user survey form
         * @param {number} user_id 
         * @param {string} survey_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiUserSurveyGetUserSurveyForm(user_id: number, survey_type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSurveyFormResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserSurveyGetUserSurveyForm(user_id, survey_type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a user survey response
         * @param {number} user_id 
         * @param {string} [survey_type] 
         * @param {string} [before_time] 
         * @param {string} [related_activity_type] 
         * @param {number} [related_activity_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiUserSurveyGetUserSurveyResponses(user_id: number, survey_type?: string, before_time?: string, related_activity_type?: string, related_activity_id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserSurveyResponseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserSurveyGetUserSurveyResponses(user_id, survey_type, before_time, related_activity_type, related_activity_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post a user survey form
         * @param {CreateUserSurveyFormRequest} CreateUserSurveyFormRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiUserSurveyPostUserSurveyForm(CreateUserSurveyFormRequest: CreateUserSurveyFormRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserSurveyPostUserSurveyForm(CreateUserSurveyFormRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post a user survey response
         * @param {number} user_id 
         * @param {CreateUserSurveyResponseRequest} CreateUserSurveyResponseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiUserSurveyPostUserSurveyResponse(user_id: number, CreateUserSurveyResponseRequest: CreateUserSurveyResponseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserSurveyPostUserSurveyResponse(user_id, CreateUserSurveyResponseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserSurveysApi - factory interface
 * @export
 */
export const UserSurveysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserSurveysApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a user survey form
         * @param {UserSurveysApiAppApiUserSurveyGetUserSurveyFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserSurveyGetUserSurveyForm(requestParameters: UserSurveysApiAppApiUserSurveyGetUserSurveyFormRequest, options?: AxiosRequestConfig): AxiosPromise<UserSurveyFormResponse> {
            return localVarFp.appApiUserSurveyGetUserSurveyForm(requestParameters.user_id, requestParameters.survey_type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user survey response
         * @param {UserSurveysApiAppApiUserSurveyGetUserSurveyResponsesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserSurveyGetUserSurveyResponses(requestParameters: UserSurveysApiAppApiUserSurveyGetUserSurveyResponsesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<UserSurveyResponseResponse>> {
            return localVarFp.appApiUserSurveyGetUserSurveyResponses(requestParameters.user_id, requestParameters.survey_type, requestParameters.before_time, requestParameters.related_activity_type, requestParameters.related_activity_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post a user survey form
         * @param {UserSurveysApiAppApiUserSurveyPostUserSurveyFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserSurveyPostUserSurveyForm(requestParameters: UserSurveysApiAppApiUserSurveyPostUserSurveyFormRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiUserSurveyPostUserSurveyForm(requestParameters.CreateUserSurveyFormRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post a user survey response
         * @param {UserSurveysApiAppApiUserSurveyPostUserSurveyResponseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserSurveyPostUserSurveyResponse(requestParameters: UserSurveysApiAppApiUserSurveyPostUserSurveyResponseRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiUserSurveyPostUserSurveyResponse(requestParameters.user_id, requestParameters.CreateUserSurveyResponseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiUserSurveyGetUserSurveyForm operation in UserSurveysApi.
 * @export
 * @interface UserSurveysApiAppApiUserSurveyGetUserSurveyFormRequest
 */
export interface UserSurveysApiAppApiUserSurveyGetUserSurveyFormRequest {
    /**
     * 
     * @type {number}
     * @memberof UserSurveysApiAppApiUserSurveyGetUserSurveyForm
     */
    readonly user_id: number

    /**
     * 
     * @type {string}
     * @memberof UserSurveysApiAppApiUserSurveyGetUserSurveyForm
     */
    readonly survey_type: string
}

/**
 * Request parameters for appApiUserSurveyGetUserSurveyResponses operation in UserSurveysApi.
 * @export
 * @interface UserSurveysApiAppApiUserSurveyGetUserSurveyResponsesRequest
 */
export interface UserSurveysApiAppApiUserSurveyGetUserSurveyResponsesRequest {
    /**
     * 
     * @type {number}
     * @memberof UserSurveysApiAppApiUserSurveyGetUserSurveyResponses
     */
    readonly user_id: number

    /**
     * 
     * @type {string}
     * @memberof UserSurveysApiAppApiUserSurveyGetUserSurveyResponses
     */
    readonly survey_type?: string

    /**
     * 
     * @type {string}
     * @memberof UserSurveysApiAppApiUserSurveyGetUserSurveyResponses
     */
    readonly before_time?: string

    /**
     * 
     * @type {string}
     * @memberof UserSurveysApiAppApiUserSurveyGetUserSurveyResponses
     */
    readonly related_activity_type?: string

    /**
     * 
     * @type {number}
     * @memberof UserSurveysApiAppApiUserSurveyGetUserSurveyResponses
     */
    readonly related_activity_id?: number
}

/**
 * Request parameters for appApiUserSurveyPostUserSurveyForm operation in UserSurveysApi.
 * @export
 * @interface UserSurveysApiAppApiUserSurveyPostUserSurveyFormRequest
 */
export interface UserSurveysApiAppApiUserSurveyPostUserSurveyFormRequest {
    /**
     * 
     * @type {CreateUserSurveyFormRequest}
     * @memberof UserSurveysApiAppApiUserSurveyPostUserSurveyForm
     */
    readonly CreateUserSurveyFormRequest: CreateUserSurveyFormRequest
}

/**
 * Request parameters for appApiUserSurveyPostUserSurveyResponse operation in UserSurveysApi.
 * @export
 * @interface UserSurveysApiAppApiUserSurveyPostUserSurveyResponseRequest
 */
export interface UserSurveysApiAppApiUserSurveyPostUserSurveyResponseRequest {
    /**
     * 
     * @type {number}
     * @memberof UserSurveysApiAppApiUserSurveyPostUserSurveyResponse
     */
    readonly user_id: number

    /**
     * 
     * @type {CreateUserSurveyResponseRequest}
     * @memberof UserSurveysApiAppApiUserSurveyPostUserSurveyResponse
     */
    readonly CreateUserSurveyResponseRequest: CreateUserSurveyResponseRequest
}

/**
 * UserSurveysApi - object-oriented interface
 * @export
 * @class UserSurveysApi
 * @extends {BaseAPI}
 */
export class UserSurveysApi extends BaseAPI {
    /**
     * 
     * @summary Get a user survey form
     * @param {UserSurveysApiAppApiUserSurveyGetUserSurveyFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSurveysApi
     */
    public appApiUserSurveyGetUserSurveyForm(requestParameters: UserSurveysApiAppApiUserSurveyGetUserSurveyFormRequest, options?: AxiosRequestConfig) {
        return UserSurveysApiFp(this.configuration).appApiUserSurveyGetUserSurveyForm(requestParameters.user_id, requestParameters.survey_type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user survey response
     * @param {UserSurveysApiAppApiUserSurveyGetUserSurveyResponsesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSurveysApi
     */
    public appApiUserSurveyGetUserSurveyResponses(requestParameters: UserSurveysApiAppApiUserSurveyGetUserSurveyResponsesRequest, options?: AxiosRequestConfig) {
        return UserSurveysApiFp(this.configuration).appApiUserSurveyGetUserSurveyResponses(requestParameters.user_id, requestParameters.survey_type, requestParameters.before_time, requestParameters.related_activity_type, requestParameters.related_activity_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post a user survey form
     * @param {UserSurveysApiAppApiUserSurveyPostUserSurveyFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSurveysApi
     */
    public appApiUserSurveyPostUserSurveyForm(requestParameters: UserSurveysApiAppApiUserSurveyPostUserSurveyFormRequest, options?: AxiosRequestConfig) {
        return UserSurveysApiFp(this.configuration).appApiUserSurveyPostUserSurveyForm(requestParameters.CreateUserSurveyFormRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post a user survey response
     * @param {UserSurveysApiAppApiUserSurveyPostUserSurveyResponseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSurveysApi
     */
    public appApiUserSurveyPostUserSurveyResponse(requestParameters: UserSurveysApiAppApiUserSurveyPostUserSurveyResponseRequest, options?: AxiosRequestConfig) {
        return UserSurveysApiFp(this.configuration).appApiUserSurveyPostUserSurveyResponse(requestParameters.user_id, requestParameters.CreateUserSurveyResponseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

