/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClinicianTipResponse } from '../models';
// @ts-ignore
import { ClinicianTipResponses } from '../models';
// @ts-ignore
import { CreateClinicianTipBatchRequest } from '../models';
// @ts-ignore
import { CreateClinicianTipForTestingRequest } from '../models';
/**
 * ClinicianTipApi - axios parameter creator
 * @export
 */
export const ClinicianTipApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add clinician tip for patient for testing
         * @param {CreateClinicianTipForTestingRequest} CreateClinicianTipForTestingRequest 
         * @param {boolean} [include_sms] whether the tip should be delivered by SMS to SMS enabled users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicianTipClinicianTipForTesting: async (CreateClinicianTipForTestingRequest: CreateClinicianTipForTestingRequest, include_sms?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateClinicianTipForTestingRequest' is not null or undefined
            assertParamExists('appApiClinicianTipClinicianTipForTesting', 'CreateClinicianTipForTestingRequest', CreateClinicianTipForTestingRequest)
            const localVarPath = `/clinician_tips/for_testing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (include_sms !== undefined) {
                localVarQueryParameter['include_sms'] = include_sms;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateClinicianTipForTestingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tip from clinician
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicianTipGetClinicianTip: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiClinicianTipGetClinicianTip', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/clinician_tip`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tips from clinician between a range of dates
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicianTipGetClinicianTips: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiClinicianTipGetClinicianTips', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiClinicianTipGetClinicianTips', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiClinicianTipGetClinicianTips', 'date_until', date_until)
            const localVarPath = `/patients/{patient_id}/clinician_tips`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Batch request for creating clinician links for patients.
         * @param {CreateClinicianTipBatchRequest} CreateClinicianTipBatchRequest 
         * @param {boolean} [include_sms] whether the tip should be delivered by SMS to SMS enabled users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicianTipPostBatchClinicianTip: async (CreateClinicianTipBatchRequest: CreateClinicianTipBatchRequest, include_sms?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateClinicianTipBatchRequest' is not null or undefined
            assertParamExists('appApiClinicianTipPostBatchClinicianTip', 'CreateClinicianTipBatchRequest', CreateClinicianTipBatchRequest)
            const localVarPath = `/clinician_tips/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include_sms !== undefined) {
                localVarQueryParameter['include_sms'] = include_sms;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateClinicianTipBatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClinicianTipApi - functional programming interface
 * @export
 */
export const ClinicianTipApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClinicianTipApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add clinician tip for patient for testing
         * @param {CreateClinicianTipForTestingRequest} CreateClinicianTipForTestingRequest 
         * @param {boolean} [include_sms] whether the tip should be delivered by SMS to SMS enabled users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiClinicianTipClinicianTipForTesting(CreateClinicianTipForTestingRequest: CreateClinicianTipForTestingRequest, include_sms?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiClinicianTipClinicianTipForTesting(CreateClinicianTipForTestingRequest, include_sms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tip from clinician
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiClinicianTipGetClinicianTip(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicianTipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiClinicianTipGetClinicianTip(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tips from clinician between a range of dates
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiClinicianTipGetClinicianTips(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicianTipResponses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiClinicianTipGetClinicianTips(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Batch request for creating clinician links for patients.
         * @param {CreateClinicianTipBatchRequest} CreateClinicianTipBatchRequest 
         * @param {boolean} [include_sms] whether the tip should be delivered by SMS to SMS enabled users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiClinicianTipPostBatchClinicianTip(CreateClinicianTipBatchRequest: CreateClinicianTipBatchRequest, include_sms?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiClinicianTipPostBatchClinicianTip(CreateClinicianTipBatchRequest, include_sms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClinicianTipApi - factory interface
 * @export
 */
export const ClinicianTipApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClinicianTipApiFp(configuration)
    return {
        /**
         * 
         * @summary Add clinician tip for patient for testing
         * @param {ClinicianTipApiAppApiClinicianTipClinicianTipForTestingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicianTipClinicianTipForTesting(requestParameters: ClinicianTipApiAppApiClinicianTipClinicianTipForTestingRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiClinicianTipClinicianTipForTesting(requestParameters.CreateClinicianTipForTestingRequest, requestParameters.include_sms, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tip from clinician
         * @param {ClinicianTipApiAppApiClinicianTipGetClinicianTipRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicianTipGetClinicianTip(requestParameters: ClinicianTipApiAppApiClinicianTipGetClinicianTipRequest, options?: AxiosRequestConfig): AxiosPromise<ClinicianTipResponse> {
            return localVarFp.appApiClinicianTipGetClinicianTip(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tips from clinician between a range of dates
         * @param {ClinicianTipApiAppApiClinicianTipGetClinicianTipsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicianTipGetClinicianTips(requestParameters: ClinicianTipApiAppApiClinicianTipGetClinicianTipsRequest, options?: AxiosRequestConfig): AxiosPromise<ClinicianTipResponses> {
            return localVarFp.appApiClinicianTipGetClinicianTips(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Batch request for creating clinician links for patients.
         * @param {ClinicianTipApiAppApiClinicianTipPostBatchClinicianTipRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicianTipPostBatchClinicianTip(requestParameters: ClinicianTipApiAppApiClinicianTipPostBatchClinicianTipRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiClinicianTipPostBatchClinicianTip(requestParameters.CreateClinicianTipBatchRequest, requestParameters.include_sms, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiClinicianTipClinicianTipForTesting operation in ClinicianTipApi.
 * @export
 * @interface ClinicianTipApiAppApiClinicianTipClinicianTipForTestingRequest
 */
export interface ClinicianTipApiAppApiClinicianTipClinicianTipForTestingRequest {
    /**
     * 
     * @type {CreateClinicianTipForTestingRequest}
     * @memberof ClinicianTipApiAppApiClinicianTipClinicianTipForTesting
     */
    readonly CreateClinicianTipForTestingRequest: CreateClinicianTipForTestingRequest

    /**
     * whether the tip should be delivered by SMS to SMS enabled users
     * @type {boolean}
     * @memberof ClinicianTipApiAppApiClinicianTipClinicianTipForTesting
     */
    readonly include_sms?: boolean
}

/**
 * Request parameters for appApiClinicianTipGetClinicianTip operation in ClinicianTipApi.
 * @export
 * @interface ClinicianTipApiAppApiClinicianTipGetClinicianTipRequest
 */
export interface ClinicianTipApiAppApiClinicianTipGetClinicianTipRequest {
    /**
     * 
     * @type {number}
     * @memberof ClinicianTipApiAppApiClinicianTipGetClinicianTip
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiClinicianTipGetClinicianTips operation in ClinicianTipApi.
 * @export
 * @interface ClinicianTipApiAppApiClinicianTipGetClinicianTipsRequest
 */
export interface ClinicianTipApiAppApiClinicianTipGetClinicianTipsRequest {
    /**
     * 
     * @type {number}
     * @memberof ClinicianTipApiAppApiClinicianTipGetClinicianTips
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof ClinicianTipApiAppApiClinicianTipGetClinicianTips
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof ClinicianTipApiAppApiClinicianTipGetClinicianTips
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiClinicianTipPostBatchClinicianTip operation in ClinicianTipApi.
 * @export
 * @interface ClinicianTipApiAppApiClinicianTipPostBatchClinicianTipRequest
 */
export interface ClinicianTipApiAppApiClinicianTipPostBatchClinicianTipRequest {
    /**
     * 
     * @type {CreateClinicianTipBatchRequest}
     * @memberof ClinicianTipApiAppApiClinicianTipPostBatchClinicianTip
     */
    readonly CreateClinicianTipBatchRequest: CreateClinicianTipBatchRequest

    /**
     * whether the tip should be delivered by SMS to SMS enabled users
     * @type {boolean}
     * @memberof ClinicianTipApiAppApiClinicianTipPostBatchClinicianTip
     */
    readonly include_sms?: boolean
}

/**
 * ClinicianTipApi - object-oriented interface
 * @export
 * @class ClinicianTipApi
 * @extends {BaseAPI}
 */
export class ClinicianTipApi extends BaseAPI {
    /**
     * 
     * @summary Add clinician tip for patient for testing
     * @param {ClinicianTipApiAppApiClinicianTipClinicianTipForTestingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicianTipApi
     */
    public appApiClinicianTipClinicianTipForTesting(requestParameters: ClinicianTipApiAppApiClinicianTipClinicianTipForTestingRequest, options?: AxiosRequestConfig) {
        return ClinicianTipApiFp(this.configuration).appApiClinicianTipClinicianTipForTesting(requestParameters.CreateClinicianTipForTestingRequest, requestParameters.include_sms, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tip from clinician
     * @param {ClinicianTipApiAppApiClinicianTipGetClinicianTipRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicianTipApi
     */
    public appApiClinicianTipGetClinicianTip(requestParameters: ClinicianTipApiAppApiClinicianTipGetClinicianTipRequest, options?: AxiosRequestConfig) {
        return ClinicianTipApiFp(this.configuration).appApiClinicianTipGetClinicianTip(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tips from clinician between a range of dates
     * @param {ClinicianTipApiAppApiClinicianTipGetClinicianTipsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicianTipApi
     */
    public appApiClinicianTipGetClinicianTips(requestParameters: ClinicianTipApiAppApiClinicianTipGetClinicianTipsRequest, options?: AxiosRequestConfig) {
        return ClinicianTipApiFp(this.configuration).appApiClinicianTipGetClinicianTips(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Batch request for creating clinician links for patients.
     * @param {ClinicianTipApiAppApiClinicianTipPostBatchClinicianTipRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicianTipApi
     */
    public appApiClinicianTipPostBatchClinicianTip(requestParameters: ClinicianTipApiAppApiClinicianTipPostBatchClinicianTipRequest, options?: AxiosRequestConfig) {
        return ClinicianTipApiFp(this.configuration).appApiClinicianTipPostBatchClinicianTip(requestParameters.CreateClinicianTipBatchRequest, requestParameters.include_sms, options).then((request) => request(this.axios, this.basePath));
    }
}

