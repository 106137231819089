import classnames from 'classnames';
import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from 'mdbreact';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';

import moment from 'moment';
import { Chart } from 'react-charts';
import { Input } from '../components/input';
import { Label, LabelG } from '../components/label';
import { useStore, useStoreDispatch } from '../context';
import { Trans, useTranslation } from '../i18n';
import { PatientService } from '../services/patient';
import './patient-dash.scss';

const Dash = (props) => {
  const { dash, patient } = useStore();
  const patientService = PatientService();
  const [loadState, setLoadState] = useState(0);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const patient_id = patient.patient_id;
    const getDataDash = async () => {
      const dashData = await patientService.getDash(patient_id);
    };
    getDataDash();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function outputStatement() {
    console.log(dash);
    if (dash.length == 0) {
      return t('Loading');
    } else if (!dash.daily_dash_scores) {
      return '';
    }

    return (
      <div>
        {PlotData(dash)}
        <br />
        <p>
          <b>
            <Trans>
              Note: Dash score ranges between 0 and 9, with 9 being the highest value.{' '}
              <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5061059/" target="_blank">
                Kim et al.
              </a>
            </Trans>
          </b>
        </p>
        <Table className="patientDataTable">
          <thead>
            <tr>
              <td>
                <Trans>Log Day</Trans>
              </td>
              <td>
                <Trans>Dash Index</Trans>
              </td>
            </tr>
          </thead>
          <tbody>
            {dash.daily_dash_scores.map((item, idx) => {
              console.log(item.daily_dash_score);
              return (
                <tr key={idx}>
                  <td>{item.log_date}</td>
                  <td>{item.daily_dash_score}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  function PlotData(data1) {
    const plotPoints = [];
    if (data1) {
      if (data1.daily_dash_scores) {
        let countData = 0;
        data1.daily_dash_scores.map(item => {
          /*const formattedDate = Intl.DateTimeFormat('en-US',{
          month: 'short',
          day: 'numeric',
          year: '2-digit',
          hour: 'numeric',
          minute: 'numeric' }).format(new Date(item.log_date));*/
          plotPoints.push([new Date(item.log_date), item.daily_dash_score]); // new Date(item.log_date)
          countData++;
        });
        console.log(plotPoints);
      }
    }
    const data = // React.useMemo(
      // () =>
      [
        {
          label: t('Dash Index'),
          data: plotPoints,
        },
      ]; // ,
    // []
    // )

    const series = // React.useMemo(
      // () =>
      {
        type: t('chart'),
      }; // ,
    // []
    // );

    const axes = // React.useMemo(
      // () =>
      [
        {
          primary: true,
          type: 'time',
          position: 'bottom',
          hardMin: moment().subtract(50, 'days').format('YYYY-MM-DD'),
          hardMax: moment().add(1, 'days').format('YYYY-MM-DD'),
        },
        { type: 'linear', position: 'left', hardMin: 0, hardMax: 9 },
      ]; // ,
    // []
    // )

    const lineChart = (
      <div
        style={{
          padding: '20px',
          width: '100%',
          height: '300px',
        }}
      >
        <Chart id="dashplot" data={data} axes={axes} tooltip />
      </div>
    );

    return (
      <div>
        {lineChart}
      </div>
    );
  }

  return (
    <div>
      <div>
        <p />
        <p className="goalDetail">
          {t('Daily Dash Index')}{'  '}(
          {moment().subtract(60, 'days').format('MMM DD/YY')} {t('to')} {moment().format('MMM DD/YY')})
        </p>
        <br />
        {outputStatement()}
      </div>
    </div>
  );
};

export default Dash;
