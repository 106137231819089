export const EDAMAM_DIET_LABELS = [
  'high-protein',
  'high-fiber',
  'balanced',
  'low-carb',
  'low-fat',
  'low-sodium',
];

export const EDAMAM_MEAL_TYPE_LABELS = [
  'Breakfast',
  'Lunch',
  'Dinner',
  'Snack',
  'Teatime',
];

export const EDAMAM_HEALTH_LABELS = [
  'alcohol-cocktail',
  'alcohol-free',
  'celery-free',
  'crustacean-free',
  'dairy-free',
  'DASH',
  'egg-free',
  'fish-free',
  'fodmap-free',
  'gluten-free',
  'immuno-supportive',
  'keto-friendly',
  'kidney-friendly',
  'kosher',
  'low-potassium',
  'low-sugar',
  'lupine-free',
  'Mediterranean',
  'mollusk-free',
  'mustard-free',
  'No-oil-added',
  'paleo',
  'peanut-free',
  'pescatarian',
  'pork-free',
  'red-meat-free',
  'sesame-free',
  'shellfish-free',
  'soy-free',
  'sugar-conscious',
  'sulfite-free',
  'tree-nut-free',
  'vegan',
  'vegetarian',
  'wheat-free',
];

export const EDAMAM_FILTERS = {
  'balanced diet': 'balanced',
  'increasing fibre': 'high-fiber',
  'increasing protein': 'high-protein',
  'low carb': 'low-carb',
  'low fat': 'low-fat',
  'reducing sodium': 'low-sodium',
  'no dairy': 'dairy-free',
  'DASH diet': 'DASH',
  'no egg': 'egg-free',
  'no fish': 'fish-free',
  'gluten free': 'gluten-free',
  'immuno-supportive': 'immuno-supportive',
  'keto': 'keto-friendly',
  'kidney friendly': 'kidney-friendly',
  'kosher': 'kosher',
  'reducing potassium': 'low-potassium',
  'reducing sugar': 'low-sugar',
  'Mediterranean diet': 'Mediterranean',
  'no added oil': 'No-oil-added',
  'paleo': 'paleo',
  'peanut free': 'peanut-free',
  'pescatarian': 'pescatarian',
  'no pork': 'pork-free',
  'no red meat': 'red-meat-free',
  'no shellfish': 'shellfish-free',
  'no soy': 'soy-free',
  'no sulfites': 'sulfite-free',
  'no tree nuts': 'tree-nut-free',
  'vegan': 'vegan',
  'vegetarian': 'vegetarian',
  'no wheat': 'wheat-free',
  'breakfast': 'Breakfast',
  'lunch': 'Lunch',
  'dinner': 'Dinner',
  'snack': 'Snack',
  'teatime': 'Teatime',
};
