import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { MDBIcon } from 'mdbreact';
import React from 'react';
import { Table } from 'react-bootstrap';
import { StringParam, useQueryParams } from 'use-query-params';
import { patientReportApi } from '../api';
import { PatientReportApiAppApiClinicReportClinicianMealLogsTableRequest } from '../api/generated/apis/patient-report-api';
import {
  AppApiClinicReportClinicianMealLogsTable200Response,
  ClinicianMealLogsTableResponseRow,
} from '../api/generated/models';
import { useCurrentPatientData, useStore } from '../context';
import { Trans, useTranslation } from '../i18n';
import placeholder from '../placeholder.png';
import { ClinicService } from '../services/clinic';
import { CGMMealGlucoseChart } from './cgm/CGMGlucoseChart';
import { CGMMealTimeInRange } from './cgm/CGMTimeInRange';
import { Image } from './image';
import { LoadingSpinner } from './loadingSpinner';

type TableColumn<T> = {
  field?: keyof T,
  header: string,
  sort?: boolean,
  render?: (row: T) => React.ReactNode,
  onClick?: null | ((row: T) => void),
  hidden?: boolean,
};

type GraphRowData = {
  patient_id: number,
  meal_date: string,
  meal_time: string,
};

function useDebounce<K, T extends Promise<K>>(delay: number, callback: () => T): () => T {
  const timeout = React.useRef<NodeJS.Timeout | null>(null);
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return React.useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    return new Promise((resolve, reject) => {
      timeout.current = setTimeout(async () => {
        try {
          resolve(await callbackRef.current());
        } catch (err) {
          reject(err);
        }
      }, delay);
    }) as unknown as T;
  }, [delay]);
}

const Div = (props: React.ComponentProps<'div'>) => <div {...props} />;

export const MealTable = (props: {
  filterObj: any,
  extraColumns?: Array<TableColumn<ClinicianMealLogsTableResponseRow>>,
  onClickMeal: (mealId: number) => void,
}) => {
  const { t } = useTranslation();
  const { patient } = useStore();
  const { flags } = useCurrentPatientData();

  const COLUMNS: Array<TableColumn<ClinicianMealLogsTableResponseRow>> = [
    {
      field: 'meal_photo_id',
      header: t('Photo'),
      render: (row) => {
        let photoUrl = row.meal_photo_url || row.food_image_url;
        const photoImgType = row.meal_photo_url ? 'mealImageContainerBorder' : 'mealImageContainer';
        if (!photoUrl) {
          photoUrl = placeholder;
        }
        return (
          <>
            <div className={photoImgType}>
              <Image className="mealImage" src={photoUrl} />
            </div>
            {flags?.patient_show_dexcom_oauth && (
              <CGMMealTimeInRange
                patient_id={patient.patient_id}
                meal_date={row.meal_date}
                meal_time={row.meal_time}
              />
            )}
          </>
        );
      },
    },
    {
      header: t('Glucose Profile (4hr after meal)'),
      hidden: !flags?.patient_show_dexcom_oauth,
      render: (row) => {
        return (
          <CGMMealGlucoseChart
            patient_id={patient.patient_id}
            meal_date={row.meal_date}
            meal_time={row.meal_time}
          />
        );
      },
    },
    {
      field: 'meal_date',
      header: t('Date'),
      sort: true,
    },

    {
      field: 'meal_time',
      header: t('Time'),
      sort: true,
    },

    {
      field: 'meal_name',
      header: t('Meal'),
      sort: true,
    },

    {
      field: 'day_of_week',
      header: t('Day of Week'),
      sort: true,
    },
    {
      field: 'meal_items',
      header: t('Items'),
      sort: true,
      render: (row) => <div>{row.meal_items.map((item, i) => <div key={i}>{item}</div>)}</div>,
    },
  ];

  const columns = COLUMNS.concat(...(props.extraColumns || [])).filter((c) => !c.hidden);
  const clinicService = ClinicService();
  const clinicianReq = useQuery(['clinician'], async () => {
    return clinicService.getClinician();
  });

  const [q, setQ] = useQueryParams({
    order: StringParam,
  });
  const queryOrder = !q.order ? null : {
    column: q.order.substring(1),
    dir: q.order.substring(0, 1) as '+' | '-',
  };
  const [sortColumn, _setSortColumn] = React.useState(queryOrder);
  const handleColumnHeaderClick = (column: TableColumn<ClinicianMealLogsTableResponseRow>) => {
    if (!column.field || !column.sort) {
      return;
    }

    if (!sortColumn || sortColumn.column != column.field) {
      _setSortColumn({
        column: column.field,
        dir: '+',
      });
      return;
    }

    if (sortColumn.dir == '+') {
      _setSortColumn({
        column: column.field,
        dir: '-',
      });
    } else {
      _setSortColumn(null);
    }
  };

  const tableParams: Partial<PatientReportApiAppApiClinicReportClinicianMealLogsTableRequest> = {
    order: sortColumn ? `${sortColumn.dir}${sortColumn.column}` : '',
    filter: JSON.stringify(props.filterObj),
  };
  Object.keys(tableParams).forEach(key => {
    const queryVal = q[key];
    const curVal = tableParams[key];
    if (queryVal != curVal) {
      setQ(cur => ({ ...cur, [key]: curVal }));
    }
  });

  const params: PatientReportApiAppApiClinicReportClinicianMealLogsTableRequest = {
    clinician_id: clinicianReq.data?.id || 0,
    hospital_id: clinicianReq.data?.hospital_id || 0,
    patient_id: patient?.patient_id || 0,
    ...tableParams,
  };

  const getTableData = useDebounce(250, async () => {
    if (clinicianReq.isLoading) {
      return new Promise(() => {}) as Promise<AppApiClinicReportClinicianMealLogsTable200Response>;
    }
    if (clinicianReq.isError) {
      throw clinicianReq.error;
    }
    const res = await patientReportApi.appApiClinicReportClinicianMealLogsTable(params);
    if (res.data?.rows) {
      const seen = {};
      res.data.rows = res.data.rows.filter((row) => {
        if (seen[row.meal_id]) {
          return false;
        }
        seen[row.meal_id] = true;
        return true;
      });
    }
    return res.data;
  });
  const tableQuery = useQuery(['meal-table', params], getTableData);
  console.log('TableQuery', tableQuery);

  const columnSortIcon = (column: TableColumn<ClinicianMealLogsTableResponseRow>) => {
    if (!sortColumn || sortColumn.column != column.field) {
      /* eslint-disable-next-line i18next/no-literal-string */
      return 'sort';
    }
    /* eslint-disable-next-line i18next/no-literal-string */
    return sortColumn.dir == '+' ? 'sort-up' : 'sort-down';
  };

  if (tableQuery.isError) {
    return (
      <div>
        <Trans>Unexpected error loading meal list:</Trans> {'' + tableQuery.error}
      </div>
    );
  }

  return (
    <div>
      <Table hover style={{ width: '100%' }}>
        <thead>
          {columns.map((column, index) => (
            <th
              key={index}
              onClick={() => handleColumnHeaderClick(column)}
              style={{ cursor: column.field ? 'pointer' : 'default' }}
            >
              {column.header}
              {(column.field && column.sort) && (
                <>
                  {' '}
                  <MDBIcon icon={columnSortIcon(column)} />
                </>
              )}
            </th>
          ))}
        </thead>
        {tableQuery.isLoading && (
          <tbody>
            <tr>
              <td colSpan={99}>
                <LoadingSpinner />
              </td>
            </tr>
          </tbody>
        )}
        {!tableQuery.isLoading && tableQuery.data?.rows?.length == 0 && (
          <tbody>
            <tr>
              <td colSpan={99}>
                <i>
                  <Trans>No meals found</Trans>
                </i>
              </td>
            </tr>
          </tbody>
        )}
        {!tableQuery.isLoading && (
          <tbody>
            {tableQuery.data?.rows?.map((row, index) => {
              const defaultOnClick = () => props.onClickMeal(row.meal_id);
              return (
                <tr key={index}>
                  {columns.map((column, index) => (
                    <td
                      key={index}
                      style={{ cursor: column.onClick !== null ? 'pointer' : 'default' }}
                      onClick={column.onClick === null
                        ? null
                        : column.onClick === undefined
                        ? defaultOnClick
                        : () => column.onClick(row)}
                    >
                      {column.render ? column.render(row) : row[column.field]}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
    </div>
  );
};
