import classnames from 'classnames';
import * as React from 'react';

import './label.scss';

const LabelAAA = (props) => {
  return (
    <h6 className={classnames('labelUpper', 'font-wide', props.className)}>
      {props.children}
    </h6>
  );
};

const LabelAA = (props) => {
  return (
    <h6 className={classnames('labelUpper', 'font-xs', props.className)}>
      {props.children}
    </h6>
  );
};

const LabelA = (props) => {
  return (
    <h6 className={classnames('labelUpper', props.className)}>
      {props.children}
    </h6>
  );
};

const LabelB = (props) => {
  return (
    <h5 className={classnames('labelUpper', props.className)}>
      {props.children}
    </h5>
  );
};

const LabelC = (props) => {
  return (
    <h4 className={classnames('labelUpper', props.className)}>
      {props.children}
    </h4>
  );
};

const LabelS = (props) => {
  return <div className={classnames('labelValue', 'labelS', props.className)}>{props.children}</div>;
};

const LabelM = (props) => {
  const { children, className, ...divProps } = props;
  return <div className={classnames('labelValue', 'lableM', props.className)} {...divProps}>{props.children}</div>;
};

const LabelL = (props) => {
  return <div className={classnames('labelValue', 'labelL', props.className)}>{props.children}</div>;
};

const Label = (props) => {
  return <div className={classnames('labelValue', props.className)}>{props.children}</div>;
};
const LabelG = (props) => {
  const { children, hidden, className, ...others } = props;
  if (hidden) {
    return <div {...others} className="d-none" />;
  }
  return <div {...others} className={classnames('labelG', className)}>{children}</div>;
};

export { Label, LabelA, LabelAA, LabelAAA, LabelB, LabelC, LabelG, LabelL, LabelM, LabelS };
