import * as d3 from 'd3';
import moment from 'moment';
import React from 'react';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryContainer, VictoryLine } from 'victory';
import { PatientGlucoseDataResponse } from '../../api/generated';
import {
  cgmReadingMaxIntervalMinutes,
  glucoseChartLowerBound,
  glucoseChartUpperBound,
  glucoseTargets,
  mmMolTargetRange,
  timestampToTimestampMS,
  useId,
  victoryTheme,
} from './common';

export class GlucoseClipPaths extends React.Component<{
  prefix: string | number,
}> {
  render() {
    const props = this.props as any;
    const mkRectProps = (yMin: number, yMax: number) => {
      const pos = {
        x1: props.scale.x(props.domain.x[0]),
        x2: props.scale.x(props.domain.x[1]),
        y1: props.scale.y(yMax),
        y2: props.scale.y(yMin),
      };

      return {
        x: pos.x1,
        width: pos.x2 - pos.x1,
        y: pos.y1,
        height: pos.y2 - pos.y1,
      };
    };

    return (
      <defs>
        {Object.entries(glucoseTargets).map(([targetName, target]) => (
          <clipPath key={targetName} id={`${props.prefix}-glucose-target-${targetName}-clip`}>
            <rect {...mkRectProps(target.mmMolLower, target.mmMolUpper)} />
          </clipPath>
        ))}
      </defs>
    );
  }
}

const roundTimestamp = (timestamp: number, toMinutes: number) => {
  const ms = toMinutes * 60 * 1000;
  return Math.floor(timestamp / ms) * ms;
};

export const CGMSmallGlucoseTimeseries = (props: {
  data: PatientGlucoseDataResponse[],
  mealTimesMS: number[],
}) => {
  const prefix = useId();
  const data = props.data.map(d => ({
    xTimestampMS: timestampToTimestampMS(d.timestamp),
    value: d.value,
  }));
  const [dataMinX, dataMaxX] = d3.extent(data, d => d.xTimestampMS);
  const [dataMinY, dataMaxY] = d3.extent(data, d => d.value);

  const firstMealTime = props.mealTimesMS[0];
  let xTickValues = d3.range(dataMinX, dataMaxX, 1000 * 60 * 60)
    .map(x => roundTimestamp(x, 15))
    .filter(x => x >= dataMinX && x <= (dataMaxX - 1000 * 60 * 15));
  if (xTickValues.length >= 5) {
    xTickValues = xTickValues.filter((_, i) => i % 2 === 0);
  }
  const xTickNearestToMealTime = xTickValues.reduce((acc, x) => {
    const delta = Math.abs(x - firstMealTime);
    const isCloser = delta < Math.abs(acc - firstMealTime);
    if (isCloser && delta < 1000 * 60 * 45) {
      return x;
    }
    return acc;
  }, Infinity);
  xTickValues = xTickValues.map(x => {
    if (x === xTickNearestToMealTime) {
      return firstMealTime;
    }
    return x;
  });

  const yDomain = [
    Math.min(glucoseChartLowerBound, dataMinY - 1),
    Math.max(glucoseChartUpperBound, dataMaxY + 1),
  ] as [number, number];

  const dataWithNulls: typeof data = [];
  data.sort((a, b) => a.xTimestampMS - b.xTimestampMS);
  data.forEach((d, i) => {
    dataWithNulls.push(d);
    const nextD = data[i + 1];
    if (!nextD) {
      return;
    }
    const minuteDelta = (nextD.xTimestampMS - d.xTimestampMS) / 1000 / 60;
    if (minuteDelta > cgmReadingMaxIntervalMinutes) {
      dataWithNulls.push({
        xTimestampMS: d.xTimestampMS,
        value: null,
      });
    }
  });

  let _prevMealTime = 0;
  const mealTimesToShow = props.mealTimesMS.filter(x => {
    if (x - _prevMealTime < 1000 * 60 * 15) {
      return false;
    }
    _prevMealTime = x;
    return true;
  });

  return (
    <VictoryChart
      theme={victoryTheme}
      domain={{
        y: yDomain,
      }}
      padding={{
        top: 0,
        left: 36,
        right: 0,
        bottom: 20,
      }}
      height={77}
      width={325}
      containerComponent={<VictoryContainer responsive={false} style={{ height: 'auto' }} />}
    >
      <GlucoseClipPaths prefix={prefix} />

      <VictoryArea
        style={{
          data: {
            fill: '#F3F3F3',
          },
        }}
        data={[
          { x: dataMinX, y0: mmMolTargetRange.lower, y: mmMolTargetRange.upperWarn },
          { x: dataMaxX, y0: mmMolTargetRange.lower, y: mmMolTargetRange.upperWarn },
        ]}
      />

      <VictoryAxis
        tickFormat={x => moment(x).format('h:mma')}
        tickValues={xTickValues}
        style={{
          grid: {
            stroke: '#F3F3F3',
            strokeWidth: 1,
          },
          tickLabels: {
            fontSize: 10,
          },
        }}
      />

      <VictoryAxis
        tickFormat={x => moment(x).format('h:mma')}
        tickValues={mealTimesToShow}
        style={{
          grid: {
            stroke: '#CCC',
            strokeWidth: 1,
          },
          axis: {
            stroke: 'transparent',
          },
          ticks: {
            stroke: 'transparent',
          },
          tickLabels: {
            fill: 'transparent',
          },
        }}
      />

      <VictoryAxis
        dependentAxis
        tickValues={[mmMolTargetRange.lower, mmMolTargetRange.upperWarn]}
        label="mmol/L"
        style={{
          tickLabels: {
            fontSize: 10,
          },
        }}
      />

      {Object.entries(glucoseTargets).map(([targetName, target]) => (
        <VictoryLine
          key={targetName}
          data={dataWithNulls}
          x="xTimestampMS"
          y="value"
          groupComponent={<g clipPath={`url(#${prefix}-glucose-target-${targetName}-clip)`} />}
          style={{
            data: {
              stroke: target.color,
              strokeWidth: 1,
            },
          }}
        />
      ))}
    </VictoryChart>
  );
};
