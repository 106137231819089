import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { Link, NavLink, Redirect, Route, useHistory, useParams, withRouter } from 'react-router-dom';
import { useStore, useStoreDispatch } from '../context';
import { Trans, useTranslation } from '../i18n';
import { formatDateShortMonth } from '../utils/formatDate';

import { AddUser } from '../components/addUser';
import { SendAppointment } from '../components/sendAppointment';
import { SendLink } from '../components/sendLink';
import { SendLoggingTrial } from '../components/sendLoggingTrial';
import { SendTip } from '../components/sendTip';
import { PatientService } from '../services/patient';
import Dash from './patient-dash';
import Emotions from './patient-emotions';
import { Goals } from './patient-goals';
import Log from './patient-log';
import PatientLogV2 from './patient-logV2';
import Nutrient from './patient-nutrient-new';
// import Nutrients from './patient-nutrient';
import { Recipes } from './patient-recipes';
import Reports from './patient-reports';
import Resources from './patient-resources';
import { Summary } from './patient-summary';

import { useQuery } from '@tanstack/react-query';
import { clinicianApi, patientApi, patientReportApi } from '../api';
import { LabelA, LabelL, LabelM } from '../components/label';
import { LoadingSpinner } from '../components/loadingSpinner';
import { strToId } from '../utils/idStr';
import Subscriptions from './patient-subscriptions';
import './patient.scss';

const Header = () => {
  const { patient } = useStore();
  const name = patient ? `${patient.first_name} ${patient.last_name}` : '...';
  const { i18n } = useTranslation();

  const patientClinicsQuery = useQuery(['patient-clinics', patient?.patient_id], async () => {
    const res = await patientApi.appApiPatientGetClinics({ patient_id: patient.patient_id });
    return res.data || [];
  }, { enabled: !!patient?.patient_id });
  const patientClinics = patientClinicsQuery.data;
  const patientClinicsStr = patientClinics?.map(c => c.name)?.join(', ') || '–';

  const clinicianReq = useQuery(['clinician'], async () => {
    const res = await clinicianApi.appApiClinicianGetCurrentClinician();
    return res.data;
  });
  const patientStartDateQuery = useQuery(['patient-start-date', clinicianReq.data, patient?.patient_id], async () => {
    const res = await patientReportApi.appApiClinicReportClinicianPatientsTable({
      clinician_id: clinicianReq.data?.id,
      hospital_id: clinicianReq.data?.hospital_id,
      filter: JSON.stringify({ 'patient_id': patient?.patient_id }),
    });
    return res.data;
  }, { enabled: !!clinicianReq.data && !!patient?.patient_id });
  const patientData = patientStartDateQuery.data?.rows[0];

  return (
    <Row className="header">
      <Col className="panelCol name">
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Link className="btn-link backToList" to="/patients">
          &lt; <Trans>Back to patient list</Trans>
        </Link>
        <Row style={{ display: 'flex', alignItems: 'baseline' }}>
          <LabelL>{name}</LabelL>
          <Col style={{ color: '#9F9F9F', marginLeft: '-4px' }}>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            <LabelM>{'#' + patient?.patient_id}</LabelM>
          </Col>
        </Row>
      </Col>
      <Col className="panelCol clinic">
        <LabelA>
          <Trans>Clinic</Trans>
        </LabelA>
        <LabelM style={{ overflowY: 'scroll', maxHeight: 150 }}>{patientClinicsStr}</LabelM>
      </Col>
      <Col className="panelCol date last">
        <LabelA>
          <Trans>Start date</Trans>
        </LabelA>
        <LabelM>
          {formatDateShortMonth(i18n.language, patientData?.most_recent_subscription_start_date) || '–'}
        </LabelM>
      </Col>
    </Row>
  );
};

const Menu = (props) => {
  const { patient, clinician } = useStore();
  const [showEditUser, toggleEditUser] = useState(false);
  const [showSendLink, toggleSendLink] = useState(false);
  const [showSendTip, toggleSendTip] = useState(false);
  const [showAppointment, toggleAppointment] = useState(false);
  const [showLoggingTrial, toggleLoggingTrial] = useState(false);
  const patientService = PatientService();

  const showLoggingTrialWindow = async () => {
    toggleLoggingTrial(!showLoggingTrial);
    await patientService.getPatientSubscriptions(patient.patient_id);
  };
  const { t } = useTranslation();
  const items = [
    { title: t('summary'), url: 'summary' },
    { title: t('food log'), url: clinician.flags.clinician_show_timeline ? 'logV2' : 'log' },
    { title: t('nutrients'), url: 'nutrients' },
    { title: t('prescriptions'), url: 'prescriptions' },
    { title: t('emotion'), url: 'emotions' },
    { title: t('logging trials'), url: 'subscriptions' },
    { title: t('reports'), url: 'reports' },
    { title: t('resources'), url: 'resources' },
  ];

  if (clinician.flags?.clinician_autopilot_enabled) {
    items.splice(4, 0, { title: t('recipes'), url: 'recipes' });
  }

  return (
    <Row>
      <Col className="menuCol">
        {items.map(item => {
          return (
            <NavLink key={item.title} className="menuitem" to={`${props.match.url}/${item.url}`}>{item.title}</NavLink>
          );
        })}
      </Col>
      <Col className="menuCol last">
        <i
          className="fas fa-plus patientMenuIcon"
          onClick={() => showLoggingTrialWindow()}
          title={t('Schedule Logging Trial')}
        />
        {/* <i className="fas fa-calendar patientMenuIcon" onClick={() => toggleAppointment(true)} title="Book or edit appointment"></i> */}
        <i
          className="fas fa-user-edit patientMenuIcon userEditMenuIcon"
          onClick={() => toggleEditUser(true)}
          title={t('Edit User')}
        />
        {clinician.direct_patient_email_permitted && (
          <a href={`mailto:${patient.email}`}>
            <i className="fas fa-envelope patientMenuIcon" title={t('Send Email')} />
          </a>
        )}
        {/* <i className="fas fa-link patientMenuIcon" onClick={() => toggleSendLink(true)} title="Send Link"></i> */}
        <i className="fas fa-share-square patientMenuIcon" onClick={() => toggleSendTip(true)} title={t('Send Tip')} />
        <i className="fas fa-print patientMenuIcon" onClick={() => window.print()} title={t('Print Page')} />
      </Col>
      <AddUser
        mode="edit"
        show={showEditUser}
        toggle={() => {
          toggleEditUser(!showEditUser);
        }}
      />
      <SendLink
        show={showSendLink}
        toggle={() => {
          toggleSendLink(!showSendLink);
        }}
      />
      <SendTip
        show={showSendTip}
        toggle={() => {
          toggleSendTip(!showSendTip);
        }}
      />
      <SendAppointment
        show={showAppointment}
        toggle={() => {
          toggleAppointment(!showAppointment);
        }}
      />
      <SendLoggingTrial
        show={showLoggingTrial}
        toggle={() => {
          toggleLoggingTrial(!showLoggingTrial);
        }}
      />
    </Row>
  );
};

const Patient = (props) => {
  const { clinician, patient } = useStore();
  const { setPatient } = useStoreDispatch();
  const history = useHistory();
  const routeParams = useParams<{ patientIdStr: string }>();

  const patientId = strToId(routeParams.patientIdStr);

  const query = useQuery(['patient', patientId], async () => {
    const res = await patientApi.appApiPatientGetPatient({ patient_id: patientId });
    return res.data;
  }, {
    enabled: !!patientId,
  });

  useEffect(() => {
    const patient = query.data;
    setPatient(
      patient && {
        ...patient,
        patient_id: patientId,
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data]);

  if (!patientId) {
    history.push('/patients');
    return null;
  }

  if (query.isLoading || (query.isSuccess && !patient)) {
    return (
      <Container fluid className="patient">
        <Header />
        <LoadingSpinner />
      </Container>
    );
  }

  if (query.isError) {
    return (
      <Container fluid className="patient">
        <Header />
        <Alert>
          <Trans>Unexpected error loading patient</Trans>: {'' + query.error}
        </Alert>
      </Container>
    );
  }

  return (
    <Container fluid className="patient">
      <Header />
      <Menu {...props} />
      <Route path={`${props.match.path}/summary`} component={Summary} />
      <Route path={`${props.match.path}/log`} component={Log} />
      <Route path={`${props.match.path}/logV2`} component={PatientLogV2} />
      <Route path={`${props.match.path}/nutrients`} component={Nutrient} />
      <Route path={`${props.match.path}/prescriptions`} component={Goals} />
      {clinician.flags?.clinician_autopilot_enabled && (
        <Route path={`${props.match.path}/recipes`} component={Recipes} />
      )}
      <Route path={`${props.match.path}/emotions`} component={Emotions} />
      <Route path={`${props.match.path}/dash`} component={Dash} />
      <Route path={`${props.match.path}/reports`} component={Reports} />
      <Route path={`${props.match.path}/resources`} component={Resources} />
      <Route path={`${props.match.path}/subscriptions`} component={Subscriptions} />
      <Route path={`${props.match.path}/`} exact>
        <Redirect to={`${props.match.url}/summary`} />
      </Route>
    </Container>
  );
};

export default withRouter(Patient);
