import emailValidator from 'email-validator';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Input } from '../components/input';
import { Trans, useTranslation } from '../i18n';
import { AuthService } from '../services/auth';

const ForgotPassword: React.FunctionComponent = (props: any) => {
  const [sent, setSentStatus] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { setInvertTheme } = props;
  const authService = AuthService();
  const { t } = useTranslation();

  const validate = () => {
    if (!email) {
      setError(t('Please enter your email address'));
      return false;
    }

    if (email && !emailValidator.validate(email)) {
      setError(t("oops! that's not a valid email"));
      return false;
    }

    setError('');
    return true;
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSendEmail = (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    setProcessing(true);

    authService.resetPassword(email)
      .then((response) => {
        if (!response) {
          console.log('reset password failed');
        } else {
          setInvertTheme(true);
          setSentStatus(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setError(t('email not found'));
          setProcessing(false);
        }
        console.log(err);
      });
  };

  useEffect(() => {
    return () => setInvertTheme(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!sent) {
    return (
      <Container>
        <Row>
          <Col />
          <Col>
            <h3>
              <Trans>Forgot password</Trans>
            </h3>
            <Form>
              <Input
                controlId="formBasicEmail"
                type="email"
                label={t('EMAIL')}
                background
                size="lg"
                error={error}
                value={email}
                onChange={handleEmailChange}
                onBlur={validate}
              />
              <p className="margin-bot-m">
                <Button
                  variant="primary"
                  block
                  type="submit"
                  className="margin-bot-l"
                  disabled={processing}
                  onClick={handleSendEmail}
                >
                  <Trans>send email</Trans>
                </Button>
              </p>
            </Form>
            <p>
              <Link className="btn-link" to="/login">
                <Trans>remembered password? login</Trans>
              </Link>
            </p>
          </Col>
          <Col />
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col />
        <Col xs={6} className="text-center">
          <h1 className="margin-bot-l">
            <Trans>Please check your email to reset your password</Trans>
          </h1>
          <p>
            <Link className="btn-link" to="/login">
              <Trans>remembered password? login</Trans>
            </Link>
          </p>
        </Col>
        <Col />
      </Row>
    </Container>
  );
};

export default ForgotPassword;
