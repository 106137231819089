import React from 'react';
import { useTranslation } from '../../i18n';
import styles from '../index.module.scss';
import { formatPct } from './common';

export const NutrientBreakdownBar = (props: {
  food: {
    carbohydrate_pct_of_total_kcal?: number,
    fibre_pct_of_total_kcal?: number,
    protein_pct_of_total_kcal?: number,
    fat_pct_of_total_kcal?: number,
  },
}) => {
  const { food } = props;
  const { t } = useTranslation();
  const macros = [
    {
      value: food.carbohydrate_pct_of_total_kcal,
      color: '#FCDC00',
      label: t('Carbs'),
    },
    /*{
      value: food.fibre_pct_of_total_kcal,
      color: '#36C2B4',
      label: 'Fibre',
    },*/
    {
      value: food.protein_pct_of_total_kcal,
      color: '#F99026',
      label: t('Protein'),
    },
    {
      value: food.fat_pct_of_total_kcal,
      color: '#528EC3',
      label: t('Fat'),
    },
  ].filter(m => m.value && m.value > 1.0);

  // Ensure all the values add up to exactly 100% by distributing any rounding
  // error between each term evenly.
  const sum = macros.reduce((acc, m) => acc + m.value, 0);
  const delta = 100 - sum;
  macros.forEach(m => {
    m.value += Math.floor(delta / macros.length * 100) / 100;
  });

  return (
    <div className={styles.nutrientBreakdownBar} style={{ position: 'relative', overflow: 'hidden' }}>
      <div className={styles.labels} style={{ display: 'flex' }}>
        {macros.map(macro => (
          <div
            key={macro.label}
            style={{
              flex: macro.value,
              color: macro.color,
              display: 'inline-block',
            }}
          >
            {macro.label}
          </div>
        ))}
      </div>
      <div
        className={styles.bars}
        style={{
          width: '100%',
          display: 'flex',
          position: 'absolute',
        }}
      >
        {macros.map(macro => (
          <div
            key={macro.label}
            style={{
              flex: macro.value,
              display: 'inline-block',
            }}
          >
            {macro.value > 15
              ? <>&nbsp;{formatPct(macro.value)}</>
              : macro.value > 7
              ? <>&nbsp;{formatPct(macro.value, 0)}</>
              : null}
          </div>
        ))}
      </div>
      <div className={styles.bars} style={{ width: '100%', display: 'flex' }}>
        {macros.map(macro => (
          <div
            key={macro.label}
            style={{
              width: macro.value + '%',
              backgroundColor: macro.color,
              display: 'inline-block',
              textAlign: 'center',
            }}
          />
        ))}
      </div>
    </div>
  );
};
