import * as d3 from 'd3';
import _ from 'lodash';
import React from 'react';

import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
} from 'victory';
import {
  floorToInt,
  glucoseChartLowerBound,
  glucoseChartUpperBound,
  glucoseTargets,
  mmMolTargetRange,
  useId,
  victoryTheme,
} from './common';

import { GlucoseClipPaths } from './CGMSmallGlucoseTimeseries';
import { ExerciseDotSVG } from './images/ExerciseDotSVG';
import { GlucoseMaxSVG } from './images/GlucoseMaxSvg';
import { GlucoseMinSVG } from './images/GlucoseMinSvg';
import { InsulinDotSVG } from './images/InsulinDotSVG';

type PointMarkerMarker =
  | { type: 'meal_dot', alpha_index: string }
  | { type: 'glucose_min', value: number }
  | { type: 'glucose_max', value: number }
  | { type: 'exercise_dot', value: number }
  | { type: 'insulin_dot', value: number };

export type CGMReportMarkerPoint = {
  value: number,
  xTimestampMS: number,
  marker: PointMarkerMarker,
};

const MealDotSVG = (props: {
  letter: string,
  lineHeight: number,
}) => {
  return (
    <g transform="translate(0, -30)">
      <g transform="scale(0.6)">
        <circle
          style={{
            fill: '#36C2B415',
            stroke: 'none',
          }}
          cx="0"
          cy="0"
          r="10.5"
        />
        <text
          x="1"
          y="4.7"
          textAnchor="middle"
          stroke="#36C2B4"
          fill="#36C2B4"
          fontSize={14}
        >
          {props.letter}
        </text>
      </g>
      {false && (
        <line
          x1={0}
          x2={0}
          y1={10.5}
          y2={props.lineHeight}
          strokeDasharray="5,5"
          style={{
            stroke: 'grey',
            strokeWidth: 1,
          }}
        />
      )}
    </g>
  );
};

class PointMarker extends React.Component {
  render() {
    const { x, y, datum } = this.props as any; // VictoryScatter supplies x, y and datum
    if (isNaN(x) || isNaN(y)) {
      console.error('NaN while rendering PointMarker:', x, y, datum);
      return <g />;
    }

    const marker: PointMarkerMarker = datum.marker;

    const icon = marker.type === 'meal_dot'
      ? <MealDotSVG letter={marker.alpha_index} lineHeight={y} />
      : marker.type === 'glucose_min'
      ? <GlucoseMinSVG value={marker.value.toFixed(1)} />
      : marker.type === 'glucose_max'
      ? <GlucoseMaxSVG value={marker.value.toFixed(1)} />
      : marker.type === 'exercise_dot'
      ? <ExerciseDotSVG value={marker.value.toFixed(0)} />
      : marker.type === 'insulin_dot'
      ? <InsulinDotSVG value={marker.value.toFixed(1)} />
      /* eslint-disable-next-line i18next/no-literal-string */
      : '?';
    return (
      <g transform={`translate(${x}, ${y})`}>
        {icon}
      </g>
    );
  }
}

export const CGMGlucoseChart = (p: {
  width?: number,
  height?: number,
  unit: string,
  data: Array<{
    value: number,
    p5?: number,
    p25?: number,
    p75?: number,
    p95?: number,
    xTimestampMS: number,
  }>,
  points?: CGMReportMarkerPoint[],
  xTickFrequencyHours: number,
  xTickFormat: (x: number) => string,
  yAxisLabel?: string,
  xAxisLabel?: string,
  tickFontSize?: number,
  hideHorizontalGridLines?: boolean,
  responsive?: boolean,
}) => {
  const id = useId();
  // const xx = (x: number) => x && (x - 6) * 6 + 10
  const data = p.data.filter(x => x.value > 0);
  /* eslint-disable i18next/no-literal-string */

  /*
  Show what a larger glucose range looks like
  .map(x => ({
    ...x,
    value: xx(x.value),
    p5: xx(x.p5),
    p25: xx(x.p25),
    p75: xx(x.p75),
    p95: xx(x.p95),
  }))*/

  const dataYDomain = [
    d3.min(data, d => d.p5 || d.p25 || d.value) as number,
    d3.max(data, d => d.p95 || d.p75 || d.value) as number,
  ];
  const dataXDomain = d3.extent(data, d => d.xTimestampMS) as [number, number];

  const yDomain = [
    Math.min(glucoseChartLowerBound, dataYDomain[0] - 1),
    Math.max(glucoseChartUpperBound, dataYDomain[1] + 1),
  ] as [number, number];

  // const xTicks = _.range(
  //   floorToInt(1000 * 60 * 60, data[0].xTimestampMS),
  //   floorToInt(1000 * 60 * 60, data[data.length - 1].xTimestampMS),
  //   1000 * 60 * 60 * p.xTickFrequencyHours,
  // )
  const [xMinMS, xMaxMS] = d3.extent(data, d => d.xTimestampMS) as [number, number];
  const xTicks = _.range(
    floorToInt(1000 * 60 * 60, xMinMS),
    floorToInt(1000 * 60 * 60, xMaxMS),
    1000 * 60 * 60 * p.xTickFrequencyHours,
  );

  const xMinorTicks = xTicks.map(x => x - 1000 * 60 * 60 * p.xTickFrequencyHours / 2);

  return (
    <div
      style={{
        marginRight: -20,
      }}
    >
      <VictoryChart
        domain={{
          y: yDomain,
          x: dataXDomain,
        }}
        theme={victoryTheme}
        width={p.width}
        height={p.height}
        padding={{
          top: 5,
          bottom: 28 + (p.yAxisLabel ? 10 : 0),
          left: 36,
          right: 20,
        }}
        containerComponent={<VictoryContainer responsive={p.responsive} />}
      >
        <GlucoseClipPaths prefix={id} />

        <VictoryAxis
          fixLabelOverlap
          tickFormat={p.xTickFormat}
          tickValues={xTicks}
          label={p.xAxisLabel}
          style={{
            grid: {
              stroke: '#fafafa',
              strokeWidth: 1,
            },
            tickLabels: {
              fontSize: p.tickFontSize,
            },
          }}
        />

        <VictoryAxis
          fixLabelOverlap
          tickFormat={(x) => ''}
          tickValues={xMinorTicks}
          style={{
            ticks: {
              stroke: 'black',
              size: 5,
            },
          }}
        />

        <VictoryArea
          style={{
            data: {
              fill: '#F3F3F3',
            },
          }}
          data={[
            { x: dataXDomain[0], y0: mmMolTargetRange.lower, y: mmMolTargetRange.upperWarn },
            { x: dataXDomain[1], y0: mmMolTargetRange.lower, y: mmMolTargetRange.upperWarn },
          ]}
        />

        <VictoryAxis
          dependentAxis
          style={{
            grid: {
              stroke: 'grey',
              strokeWidth: p.hideHorizontalGridLines ? 0 : 0.5,
            },
            tickLabels: {
              fontSize: p.tickFontSize,
            },
          }}
          label={p.yAxisLabel}
        />

        {Object.entries(glucoseTargets).map(([targetName, target]) => (
          <VictoryGroup key={targetName}>
            {data[0]?.p5 && data[0]?.p95 && (
              <VictoryArea
                data={data}
                x="xTimestampMS"
                y="p95"
                y0="p5"
                style={{
                  data: {
                    fill: target.color,
                    fillOpacity: 0.2,
                    stroke: 'none',
                  },
                }}
                groupComponent={<g clipPath={`url(#${id}-glucose-target-${targetName}-clip)`} />}
              />
            )}

            {data[0]?.p25 && data[0]?.p75 && (
              <VictoryArea
                data={data}
                x="xTimestampMS"
                y="p75"
                y0="p25"
                style={{
                  data: {
                    fill: target.color,
                    fillOpacity: 0.4,
                    stroke: 'none',
                  },
                }}
                groupComponent={<g clipPath={`url(#${id}-glucose-target-${targetName}-clip)`} />}
              />
            )}

            <VictoryLine
              data={data}
              x="xTimestampMS"
              y="value"
              style={{
                data: {
                  stroke: target.color,
                  strokeWidth: 1,
                },
              }}
              groupComponent={<g clipPath={`url(#${id}-glucose-target-${targetName}-clip)`} />}
            />
          </VictoryGroup>
        ))}

        {p.points && (
          <VictoryScatter
            dataComponent={<PointMarker />}
            data={p.points}
            x="xTimestampMS"
            y="value"
          />
        )}
      </VictoryChart>
    </div>
  );
};
