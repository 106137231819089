/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClinicInvitationCodeResponse } from '../models';
// @ts-ignore
import { ClinicResponse } from '../models';
// @ts-ignore
import { ClinicianResponse } from '../models';
// @ts-ignore
import { CreateClinicRequest } from '../models';
// @ts-ignore
import { CreateClinicianRequest } from '../models';
// @ts-ignore
import { DeregisterPatientsBatchRequest } from '../models';
// @ts-ignore
import { HospitalResponse } from '../models';
// @ts-ignore
import { PatchClinicianRequest } from '../models';
// @ts-ignore
import { PatientResponse } from '../models';
// @ts-ignore
import { UpdateClinicRequest } from '../models';
// @ts-ignore
import { UpdateHospitalRequest } from '../models';
/**
 * HospitalApi - axios parameter creator
 * @export
 */
export const HospitalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get clinicians registered in the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalClinicGetClinicians: async (hospital_id: number, clinic_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalClinicGetClinicians', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiHospitalClinicGetClinicians', 'clinic_id', clinic_id)
            const localVarPath = `/hospitals/{hospital_id}/clinics/{clinic_id}/clinicians`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patients registered in the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalClinicGetPatients: async (hospital_id: number, clinic_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalClinicGetPatients', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiHospitalClinicGetPatients', 'clinic_id', clinic_id)
            const localVarPath = `/hospitals/{hospital_id}/clinics/{clinic_id}/patients`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get clinics this clinician is registered
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalClinicianGetClinics: async (hospital_id: number, clinician_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalClinicianGetClinics', 'hospital_id', hospital_id)
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiHospitalClinicianGetClinics', 'clinician_id', clinician_id)
            const localVarPath = `/hospitals/{hospital_id}/clinicians/{clinician_id}/clinics`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinician_id"}}`, encodeURIComponent(String(clinician_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary (NO IMPL) Delete the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalDeleteClinic: async (hospital_id: number, clinic_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalDeleteClinic', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiHospitalDeleteClinic', 'clinic_id', clinic_id)
            const localVarPath = `/hospitals/{hospital_id}/clinics/{clinic_id}`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary (NO IMPL) Delete a clinician
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalDeleteClinician: async (hospital_id: number, clinician_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalDeleteClinician', 'hospital_id', hospital_id)
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiHospitalDeleteClinician', 'clinician_id', clinician_id)
            const localVarPath = `/hospitals/{hospital_id}/clinicians/{clinician_id}`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinician_id"}}`, encodeURIComponent(String(clinician_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary (NO IMPL) Delete a hospital
         * @param {number} hospital_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalDeleteHospital: async (hospital_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalDeleteHospital', 'hospital_id', hospital_id)
            const localVarPath = `/hospitals/{hospital_id}`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deregister the clinician to the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalDeregisterClinician: async (hospital_id: number, clinic_id: number, clinician_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalDeregisterClinician', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiHospitalDeregisterClinician', 'clinic_id', clinic_id)
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiHospitalDeregisterClinician', 'clinician_id', clinician_id)
            const localVarPath = `/hospitals/{hospital_id}/clinics/{clinic_id}/deregister_clinician`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clinician_id !== undefined) {
                localVarQueryParameter['clinician_id'] = clinician_id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deregister the patient to the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalDeregisterPatient: async (hospital_id: number, clinic_id: number, patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalDeregisterPatient', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiHospitalDeregisterPatient', 'clinic_id', clinic_id)
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiHospitalDeregisterPatient', 'patient_id', patient_id)
            const localVarPath = `/hospitals/{hospital_id}/clinics/{clinic_id}/deregister_patient`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (patient_id !== undefined) {
                localVarQueryParameter['patient_id'] = patient_id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [NO IMPL] Deregister the patients from the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {DeregisterPatientsBatchRequest} DeregisterPatientsBatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalDeregisterPatients: async (hospital_id: number, clinic_id: number, DeregisterPatientsBatchRequest: DeregisterPatientsBatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalDeregisterPatients', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiHospitalDeregisterPatients', 'clinic_id', clinic_id)
            // verify required parameter 'DeregisterPatientsBatchRequest' is not null or undefined
            assertParamExists('appApiHospitalDeregisterPatients', 'DeregisterPatientsBatchRequest', DeregisterPatientsBatchRequest)
            const localVarPath = `/hospitals/{hospital_id}/clinics/{clinic_id}/deregister_patients`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(DeregisterPatientsBatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the clinic\'s info
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetClinic: async (hospital_id: number, clinic_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalGetClinic', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiHospitalGetClinic', 'clinic_id', clinic_id)
            const localVarPath = `/hospitals/{hospital_id}/clinics/{clinic_id}`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the clinic\'s default invitation code
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetClinicInvitationCodesDefault: async (hospital_id: number, clinic_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalGetClinicInvitationCodesDefault', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiHospitalGetClinicInvitationCodesDefault', 'clinic_id', clinic_id)
            const localVarPath = `/hospitals/{hospital_id}/clinics/{clinic_id}/invitation-codes/default`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a clinician info
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetClinician: async (hospital_id: number, clinician_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalGetClinician', 'hospital_id', hospital_id)
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiHospitalGetClinician', 'clinician_id', clinician_id)
            const localVarPath = `/hospitals/{hospital_id}/clinicians/{clinician_id}`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinician_id"}}`, encodeURIComponent(String(clinician_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [MOCK] Get the photo of the clinician
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetClinicianPhoto: async (hospital_id: number, clinician_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalGetClinicianPhoto', 'hospital_id', hospital_id)
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiHospitalGetClinicianPhoto', 'clinician_id', clinician_id)
            const localVarPath = `/hospitals/{hospital_id}/clinicians/{clinician_id}/photo`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinician_id"}}`, encodeURIComponent(String(clinician_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all clinicians in the hospital
         * @param {number} hospital_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetClinicians: async (hospital_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalGetClinicians', 'hospital_id', hospital_id)
            const localVarPath = `/hospitals/{hospital_id}/clinicians`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all clinics in the hospital
         * @param {number} hospital_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetClinics: async (hospital_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalGetClinics', 'hospital_id', hospital_id)
            const localVarPath = `/hospitals/{hospital_id}/clinics`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get hospital information
         * @param {number} hospital_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetHospital: async (hospital_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalGetHospital', 'hospital_id', hospital_id)
            const localVarPath = `/hospitals/{hospital_id}`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of all hospitals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetHospitals: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hospitals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit information of the clinician. This endpoint edits the provided field in the body. That is, if the first_name is null in the request body, the first_name in the system remains the same (as opposed to write to null).
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {PatchClinicianRequest} PatchClinicianRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPatchClinician: async (hospital_id: number, clinician_id: number, PatchClinicianRequest: PatchClinicianRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalPatchClinician', 'hospital_id', hospital_id)
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiHospitalPatchClinician', 'clinician_id', clinician_id)
            // verify required parameter 'PatchClinicianRequest' is not null or undefined
            assertParamExists('appApiHospitalPatchClinician', 'PatchClinicianRequest', PatchClinicianRequest)
            const localVarPath = `/hospitals/{hospital_id}/clinicians/{clinician_id}`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinician_id"}}`, encodeURIComponent(String(clinician_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(PatchClinicianRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [NO IMPL] Upload a photo of the clinician
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {File} [photo] photo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPostClinicianPhoto: async (hospital_id: number, clinician_id: number, photo?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalPostClinicianPhoto', 'hospital_id', hospital_id)
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiHospitalPostClinicianPhoto', 'clinician_id', clinician_id)
            const localVarPath = `/hospitals/{hospital_id}/clinicians/{clinician_id}/photo`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinician_id"}}`, encodeURIComponent(String(clinician_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new clinician in the hospital
         * @param {number} hospital_id 
         * @param {CreateClinicianRequest} CreateClinicianRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPostClinicians: async (hospital_id: number, CreateClinicianRequest: CreateClinicianRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalPostClinicians', 'hospital_id', hospital_id)
            // verify required parameter 'CreateClinicianRequest' is not null or undefined
            assertParamExists('appApiHospitalPostClinicians', 'CreateClinicianRequest', CreateClinicianRequest)
            const localVarPath = `/hospitals/{hospital_id}/clinicians`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateClinicianRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new clinic in the hospital
         * @param {number} hospital_id 
         * @param {CreateClinicRequest} CreateClinicRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPostClinics: async (hospital_id: number, CreateClinicRequest: CreateClinicRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalPostClinics', 'hospital_id', hospital_id)
            // verify required parameter 'CreateClinicRequest' is not null or undefined
            assertParamExists('appApiHospitalPostClinics', 'CreateClinicRequest', CreateClinicRequest)
            const localVarPath = `/hospitals/{hospital_id}/clinics`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateClinicRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate all users in the clinic (clinicians and patients)
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPostDeregisterUsers: async (hospital_id: number, clinic_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalPostDeregisterUsers', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiHospitalPostDeregisterUsers', 'clinic_id', clinic_id)
            const localVarPath = `/hospitals/{hospital_id}/clinics/{clinic_id}/deregister_users`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new hospital
         * @param {string} [invitation_email_logo_url] The link to the invitation email logo.
         * @param {string} [invitation_email_logo_link] The link to the invitation email logo.
         * @param {string} [name] The name of the university.
         * @param {string} [country] The country where the university is located.
         * @param {string} [full_name] The full name of the university.
         * @param {string} [address] The address of the university.
         * @param {string} [city] The city where the university is located.
         * @param {string} [state] The state or province where the university is located.
         * @param {string} [zip_code] The postal code for the university.
         * @param {string} [description] A brief description of the university.
         * @param {File} [photo] A photo of the university.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPostHospitals: async (invitation_email_logo_url?: string, invitation_email_logo_link?: string, name?: string, country?: string, full_name?: string, address?: string, city?: string, state?: string, zip_code?: string, description?: string, photo?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hospitals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (invitation_email_logo_url !== undefined) { 
                localVarFormParams.append('invitation_email_logo_url', invitation_email_logo_url as any);
            }
    
            if (invitation_email_logo_link !== undefined) { 
                localVarFormParams.append('invitation_email_logo_link', invitation_email_logo_link as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (country !== undefined) { 
                localVarFormParams.append('country', country as any);
            }
    
            if (full_name !== undefined) { 
                localVarFormParams.append('full_name', full_name as any);
            }
    
            if (address !== undefined) { 
                localVarFormParams.append('address', address as any);
            }
    
            if (city !== undefined) { 
                localVarFormParams.append('city', city as any);
            }
    
            if (state !== undefined) { 
                localVarFormParams.append('state', state as any);
            }
    
            if (zip_code !== undefined) { 
                localVarFormParams.append('zip_code', zip_code as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the clinic info
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {UpdateClinicRequest} UpdateClinicRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPutClinic: async (hospital_id: number, clinic_id: number, UpdateClinicRequest: UpdateClinicRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalPutClinic', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiHospitalPutClinic', 'clinic_id', clinic_id)
            // verify required parameter 'UpdateClinicRequest' is not null or undefined
            assertParamExists('appApiHospitalPutClinic', 'UpdateClinicRequest', UpdateClinicRequest)
            const localVarPath = `/hospitals/{hospital_id}/clinics/{clinic_id}`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateClinicRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update hospital information
         * @param {number} hospital_id 
         * @param {UpdateHospitalRequest} UpdateHospitalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPutHospital: async (hospital_id: number, UpdateHospitalRequest: UpdateHospitalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalPutHospital', 'hospital_id', hospital_id)
            // verify required parameter 'UpdateHospitalRequest' is not null or undefined
            assertParamExists('appApiHospitalPutHospital', 'UpdateHospitalRequest', UpdateHospitalRequest)
            const localVarPath = `/hospitals/{hospital_id}`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateHospitalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update hospital information
         * @param {number} hospital_id 
         * @param {File} photo image file for the hospital logo. jpg or png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPutHospitalPhoto: async (hospital_id: number, photo: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalPutHospitalPhoto', 'hospital_id', hospital_id)
            // verify required parameter 'photo' is not null or undefined
            assertParamExists('appApiHospitalPutHospitalPhoto', 'photo', photo)
            const localVarPath = `/hospitals/{hospital_id}/photo`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register the clinician to the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} clinician_id 
         * @param {boolean | null} [preferred_contact] 
         * @param {boolean | null} [is_notified_of_report] 
         * @param {boolean | null} [is_clinic_admin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalRegisterClinician: async (hospital_id: number, clinic_id: number, clinician_id: number, preferred_contact?: boolean | null, is_notified_of_report?: boolean | null, is_clinic_admin?: boolean | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalRegisterClinician', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiHospitalRegisterClinician', 'clinic_id', clinic_id)
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiHospitalRegisterClinician', 'clinician_id', clinician_id)
            const localVarPath = `/hospitals/{hospital_id}/clinics/{clinic_id}/register_clinician`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clinician_id !== undefined) {
                localVarQueryParameter['clinician_id'] = clinician_id;
            }

            if (preferred_contact !== undefined) {
                localVarQueryParameter['preferred_contact'] = preferred_contact;
            }

            if (is_notified_of_report !== undefined) {
                localVarQueryParameter['is_notified_of_report'] = is_notified_of_report;
            }

            if (is_clinic_admin !== undefined) {
                localVarQueryParameter['is_clinic_admin'] = is_clinic_admin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register the patient to the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalRegisterPatient: async (hospital_id: number, clinic_id: number, patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalRegisterPatient', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiHospitalRegisterPatient', 'clinic_id', clinic_id)
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiHospitalRegisterPatient', 'patient_id', patient_id)
            const localVarPath = `/hospitals/{hospital_id}/clinics/{clinic_id}/register_patient`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (patient_id !== undefined) {
                localVarQueryParameter['patient_id'] = patient_id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send hospital invitation email to the patient\'s email address
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalSendHospitalClinicInvitationEmail: async (patient_id: number, hospital_id: number, clinic_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiHospitalSendHospitalClinicInvitationEmail', 'patient_id', patient_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalSendHospitalClinicInvitationEmail', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiHospitalSendHospitalClinicInvitationEmail', 'clinic_id', clinic_id)
            const localVarPath = `/hospitals/{hospital_id}/clinics/{clinic_id}/send_hospital_invitation_email`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (patient_id !== undefined) {
                localVarQueryParameter['patient_id'] = patient_id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send hospital invitation email to the patient\'s email address
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalSendHospitalInvitationEmail: async (patient_id: number, hospital_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiHospitalSendHospitalInvitationEmail', 'patient_id', patient_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiHospitalSendHospitalInvitationEmail', 'hospital_id', hospital_id)
            const localVarPath = `/hospitals/{hospital_id}/send_hospital_invitation_email`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (patient_id !== undefined) {
                localVarQueryParameter['patient_id'] = patient_id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HospitalApi - functional programming interface
 * @export
 */
export const HospitalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HospitalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get clinicians registered in the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalClinicGetClinicians(hospital_id: number, clinic_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClinicianResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalClinicGetClinicians(hospital_id, clinic_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patients registered in the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalClinicGetPatients(hospital_id: number, clinic_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalClinicGetPatients(hospital_id, clinic_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get clinics this clinician is registered
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalClinicianGetClinics(hospital_id: number, clinician_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClinicResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalClinicianGetClinics(hospital_id, clinician_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary (NO IMPL) Delete the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalDeleteClinic(hospital_id: number, clinic_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalDeleteClinic(hospital_id, clinic_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary (NO IMPL) Delete a clinician
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalDeleteClinician(hospital_id: number, clinician_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalDeleteClinician(hospital_id, clinician_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary (NO IMPL) Delete a hospital
         * @param {number} hospital_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalDeleteHospital(hospital_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalDeleteHospital(hospital_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deregister the clinician to the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalDeregisterClinician(hospital_id: number, clinic_id: number, clinician_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalDeregisterClinician(hospital_id, clinic_id, clinician_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deregister the patient to the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalDeregisterPatient(hospital_id: number, clinic_id: number, patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalDeregisterPatient(hospital_id, clinic_id, patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [NO IMPL] Deregister the patients from the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {DeregisterPatientsBatchRequest} DeregisterPatientsBatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalDeregisterPatients(hospital_id: number, clinic_id: number, DeregisterPatientsBatchRequest: DeregisterPatientsBatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalDeregisterPatients(hospital_id, clinic_id, DeregisterPatientsBatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the clinic\'s info
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalGetClinic(hospital_id: number, clinic_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalGetClinic(hospital_id, clinic_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the clinic\'s default invitation code
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalGetClinicInvitationCodesDefault(hospital_id: number, clinic_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicInvitationCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalGetClinicInvitationCodesDefault(hospital_id, clinic_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a clinician info
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalGetClinician(hospital_id: number, clinician_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicianResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalGetClinician(hospital_id, clinician_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [MOCK] Get the photo of the clinician
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalGetClinicianPhoto(hospital_id: number, clinician_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalGetClinicianPhoto(hospital_id, clinician_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all clinicians in the hospital
         * @param {number} hospital_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalGetClinicians(hospital_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClinicianResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalGetClinicians(hospital_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all clinics in the hospital
         * @param {number} hospital_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalGetClinics(hospital_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClinicResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalGetClinics(hospital_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get hospital information
         * @param {number} hospital_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalGetHospital(hospital_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HospitalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalGetHospital(hospital_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of all hospitals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalGetHospitals(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HospitalResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalGetHospitals(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit information of the clinician. This endpoint edits the provided field in the body. That is, if the first_name is null in the request body, the first_name in the system remains the same (as opposed to write to null).
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {PatchClinicianRequest} PatchClinicianRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalPatchClinician(hospital_id: number, clinician_id: number, PatchClinicianRequest: PatchClinicianRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicianResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalPatchClinician(hospital_id, clinician_id, PatchClinicianRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [NO IMPL] Upload a photo of the clinician
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {File} [photo] photo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalPostClinicianPhoto(hospital_id: number, clinician_id: number, photo?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalPostClinicianPhoto(hospital_id, clinician_id, photo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new clinician in the hospital
         * @param {number} hospital_id 
         * @param {CreateClinicianRequest} CreateClinicianRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalPostClinicians(hospital_id: number, CreateClinicianRequest: CreateClinicianRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicianResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalPostClinicians(hospital_id, CreateClinicianRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new clinic in the hospital
         * @param {number} hospital_id 
         * @param {CreateClinicRequest} CreateClinicRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalPostClinics(hospital_id: number, CreateClinicRequest: CreateClinicRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalPostClinics(hospital_id, CreateClinicRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate all users in the clinic (clinicians and patients)
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalPostDeregisterUsers(hospital_id: number, clinic_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalPostDeregisterUsers(hospital_id, clinic_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new hospital
         * @param {string} [invitation_email_logo_url] The link to the invitation email logo.
         * @param {string} [invitation_email_logo_link] The link to the invitation email logo.
         * @param {string} [name] The name of the university.
         * @param {string} [country] The country where the university is located.
         * @param {string} [full_name] The full name of the university.
         * @param {string} [address] The address of the university.
         * @param {string} [city] The city where the university is located.
         * @param {string} [state] The state or province where the university is located.
         * @param {string} [zip_code] The postal code for the university.
         * @param {string} [description] A brief description of the university.
         * @param {File} [photo] A photo of the university.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalPostHospitals(invitation_email_logo_url?: string, invitation_email_logo_link?: string, name?: string, country?: string, full_name?: string, address?: string, city?: string, state?: string, zip_code?: string, description?: string, photo?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HospitalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalPostHospitals(invitation_email_logo_url, invitation_email_logo_link, name, country, full_name, address, city, state, zip_code, description, photo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the clinic info
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {UpdateClinicRequest} UpdateClinicRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalPutClinic(hospital_id: number, clinic_id: number, UpdateClinicRequest: UpdateClinicRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalPutClinic(hospital_id, clinic_id, UpdateClinicRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update hospital information
         * @param {number} hospital_id 
         * @param {UpdateHospitalRequest} UpdateHospitalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalPutHospital(hospital_id: number, UpdateHospitalRequest: UpdateHospitalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HospitalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalPutHospital(hospital_id, UpdateHospitalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update hospital information
         * @param {number} hospital_id 
         * @param {File} photo image file for the hospital logo. jpg or png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalPutHospitalPhoto(hospital_id: number, photo: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HospitalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalPutHospitalPhoto(hospital_id, photo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register the clinician to the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} clinician_id 
         * @param {boolean | null} [preferred_contact] 
         * @param {boolean | null} [is_notified_of_report] 
         * @param {boolean | null} [is_clinic_admin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalRegisterClinician(hospital_id: number, clinic_id: number, clinician_id: number, preferred_contact?: boolean | null, is_notified_of_report?: boolean | null, is_clinic_admin?: boolean | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalRegisterClinician(hospital_id, clinic_id, clinician_id, preferred_contact, is_notified_of_report, is_clinic_admin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register the patient to the clinic
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalRegisterPatient(hospital_id: number, clinic_id: number, patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalRegisterPatient(hospital_id, clinic_id, patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send hospital invitation email to the patient\'s email address
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalSendHospitalClinicInvitationEmail(patient_id: number, hospital_id: number, clinic_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalSendHospitalClinicInvitationEmail(patient_id, hospital_id, clinic_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send hospital invitation email to the patient\'s email address
         * @param {number} patient_id 
         * @param {number} hospital_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiHospitalSendHospitalInvitationEmail(patient_id: number, hospital_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiHospitalSendHospitalInvitationEmail(patient_id, hospital_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HospitalApi - factory interface
 * @export
 */
export const HospitalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HospitalApiFp(configuration)
    return {
        /**
         * 
         * @summary Get clinicians registered in the clinic
         * @param {HospitalApiAppApiHospitalClinicGetCliniciansRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalClinicGetClinicians(requestParameters: HospitalApiAppApiHospitalClinicGetCliniciansRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ClinicianResponse>> {
            return localVarFp.appApiHospitalClinicGetClinicians(requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patients registered in the clinic
         * @param {HospitalApiAppApiHospitalClinicGetPatientsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalClinicGetPatients(requestParameters: HospitalApiAppApiHospitalClinicGetPatientsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PatientResponse>> {
            return localVarFp.appApiHospitalClinicGetPatients(requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get clinics this clinician is registered
         * @param {HospitalApiAppApiHospitalClinicianGetClinicsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalClinicianGetClinics(requestParameters: HospitalApiAppApiHospitalClinicianGetClinicsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ClinicResponse>> {
            return localVarFp.appApiHospitalClinicianGetClinics(requestParameters.hospital_id, requestParameters.clinician_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary (NO IMPL) Delete the clinic
         * @param {HospitalApiAppApiHospitalDeleteClinicRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalDeleteClinic(requestParameters: HospitalApiAppApiHospitalDeleteClinicRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiHospitalDeleteClinic(requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary (NO IMPL) Delete a clinician
         * @param {HospitalApiAppApiHospitalDeleteClinicianRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalDeleteClinician(requestParameters: HospitalApiAppApiHospitalDeleteClinicianRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiHospitalDeleteClinician(requestParameters.hospital_id, requestParameters.clinician_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary (NO IMPL) Delete a hospital
         * @param {HospitalApiAppApiHospitalDeleteHospitalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalDeleteHospital(requestParameters: HospitalApiAppApiHospitalDeleteHospitalRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiHospitalDeleteHospital(requestParameters.hospital_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deregister the clinician to the clinic
         * @param {HospitalApiAppApiHospitalDeregisterClinicianRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalDeregisterClinician(requestParameters: HospitalApiAppApiHospitalDeregisterClinicianRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiHospitalDeregisterClinician(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.clinician_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deregister the patient to the clinic
         * @param {HospitalApiAppApiHospitalDeregisterPatientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalDeregisterPatient(requestParameters: HospitalApiAppApiHospitalDeregisterPatientRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiHospitalDeregisterPatient(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [NO IMPL] Deregister the patients from the clinic
         * @param {HospitalApiAppApiHospitalDeregisterPatientsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalDeregisterPatients(requestParameters: HospitalApiAppApiHospitalDeregisterPatientsRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiHospitalDeregisterPatients(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.DeregisterPatientsBatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the clinic\'s info
         * @param {HospitalApiAppApiHospitalGetClinicRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetClinic(requestParameters: HospitalApiAppApiHospitalGetClinicRequest, options?: AxiosRequestConfig): AxiosPromise<ClinicResponse> {
            return localVarFp.appApiHospitalGetClinic(requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the clinic\'s default invitation code
         * @param {HospitalApiAppApiHospitalGetClinicInvitationCodesDefaultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetClinicInvitationCodesDefault(requestParameters: HospitalApiAppApiHospitalGetClinicInvitationCodesDefaultRequest, options?: AxiosRequestConfig): AxiosPromise<ClinicInvitationCodeResponse> {
            return localVarFp.appApiHospitalGetClinicInvitationCodesDefault(requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a clinician info
         * @param {HospitalApiAppApiHospitalGetClinicianRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetClinician(requestParameters: HospitalApiAppApiHospitalGetClinicianRequest, options?: AxiosRequestConfig): AxiosPromise<ClinicianResponse> {
            return localVarFp.appApiHospitalGetClinician(requestParameters.hospital_id, requestParameters.clinician_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [MOCK] Get the photo of the clinician
         * @param {HospitalApiAppApiHospitalGetClinicianPhotoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetClinicianPhoto(requestParameters: HospitalApiAppApiHospitalGetClinicianPhotoRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiHospitalGetClinicianPhoto(requestParameters.hospital_id, requestParameters.clinician_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all clinicians in the hospital
         * @param {HospitalApiAppApiHospitalGetCliniciansRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetClinicians(requestParameters: HospitalApiAppApiHospitalGetCliniciansRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ClinicianResponse>> {
            return localVarFp.appApiHospitalGetClinicians(requestParameters.hospital_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all clinics in the hospital
         * @param {HospitalApiAppApiHospitalGetClinicsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetClinics(requestParameters: HospitalApiAppApiHospitalGetClinicsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ClinicResponse>> {
            return localVarFp.appApiHospitalGetClinics(requestParameters.hospital_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get hospital information
         * @param {HospitalApiAppApiHospitalGetHospitalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetHospital(requestParameters: HospitalApiAppApiHospitalGetHospitalRequest, options?: AxiosRequestConfig): AxiosPromise<HospitalResponse> {
            return localVarFp.appApiHospitalGetHospital(requestParameters.hospital_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of all hospitals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalGetHospitals(options?: AxiosRequestConfig): AxiosPromise<Array<HospitalResponse>> {
            return localVarFp.appApiHospitalGetHospitals(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit information of the clinician. This endpoint edits the provided field in the body. That is, if the first_name is null in the request body, the first_name in the system remains the same (as opposed to write to null).
         * @param {HospitalApiAppApiHospitalPatchClinicianRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPatchClinician(requestParameters: HospitalApiAppApiHospitalPatchClinicianRequest, options?: AxiosRequestConfig): AxiosPromise<ClinicianResponse> {
            return localVarFp.appApiHospitalPatchClinician(requestParameters.hospital_id, requestParameters.clinician_id, requestParameters.PatchClinicianRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [NO IMPL] Upload a photo of the clinician
         * @param {HospitalApiAppApiHospitalPostClinicianPhotoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPostClinicianPhoto(requestParameters: HospitalApiAppApiHospitalPostClinicianPhotoRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiHospitalPostClinicianPhoto(requestParameters.hospital_id, requestParameters.clinician_id, requestParameters.photo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new clinician in the hospital
         * @param {HospitalApiAppApiHospitalPostCliniciansRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPostClinicians(requestParameters: HospitalApiAppApiHospitalPostCliniciansRequest, options?: AxiosRequestConfig): AxiosPromise<ClinicianResponse> {
            return localVarFp.appApiHospitalPostClinicians(requestParameters.hospital_id, requestParameters.CreateClinicianRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new clinic in the hospital
         * @param {HospitalApiAppApiHospitalPostClinicsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPostClinics(requestParameters: HospitalApiAppApiHospitalPostClinicsRequest, options?: AxiosRequestConfig): AxiosPromise<ClinicResponse> {
            return localVarFp.appApiHospitalPostClinics(requestParameters.hospital_id, requestParameters.CreateClinicRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate all users in the clinic (clinicians and patients)
         * @param {HospitalApiAppApiHospitalPostDeregisterUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPostDeregisterUsers(requestParameters: HospitalApiAppApiHospitalPostDeregisterUsersRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiHospitalPostDeregisterUsers(requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new hospital
         * @param {HospitalApiAppApiHospitalPostHospitalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPostHospitals(requestParameters: HospitalApiAppApiHospitalPostHospitalsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<HospitalResponse> {
            return localVarFp.appApiHospitalPostHospitals(requestParameters.invitation_email_logo_url, requestParameters.invitation_email_logo_link, requestParameters.name, requestParameters.country, requestParameters.full_name, requestParameters.address, requestParameters.city, requestParameters.state, requestParameters.zip_code, requestParameters.description, requestParameters.photo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the clinic info
         * @param {HospitalApiAppApiHospitalPutClinicRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPutClinic(requestParameters: HospitalApiAppApiHospitalPutClinicRequest, options?: AxiosRequestConfig): AxiosPromise<ClinicResponse> {
            return localVarFp.appApiHospitalPutClinic(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.UpdateClinicRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update hospital information
         * @param {HospitalApiAppApiHospitalPutHospitalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPutHospital(requestParameters: HospitalApiAppApiHospitalPutHospitalRequest, options?: AxiosRequestConfig): AxiosPromise<HospitalResponse> {
            return localVarFp.appApiHospitalPutHospital(requestParameters.hospital_id, requestParameters.UpdateHospitalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update hospital information
         * @param {HospitalApiAppApiHospitalPutHospitalPhotoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalPutHospitalPhoto(requestParameters: HospitalApiAppApiHospitalPutHospitalPhotoRequest, options?: AxiosRequestConfig): AxiosPromise<HospitalResponse> {
            return localVarFp.appApiHospitalPutHospitalPhoto(requestParameters.hospital_id, requestParameters.photo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register the clinician to the clinic
         * @param {HospitalApiAppApiHospitalRegisterClinicianRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalRegisterClinician(requestParameters: HospitalApiAppApiHospitalRegisterClinicianRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiHospitalRegisterClinician(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.clinician_id, requestParameters.preferred_contact, requestParameters.is_notified_of_report, requestParameters.is_clinic_admin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register the patient to the clinic
         * @param {HospitalApiAppApiHospitalRegisterPatientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalRegisterPatient(requestParameters: HospitalApiAppApiHospitalRegisterPatientRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiHospitalRegisterPatient(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send hospital invitation email to the patient\'s email address
         * @param {HospitalApiAppApiHospitalSendHospitalClinicInvitationEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalSendHospitalClinicInvitationEmail(requestParameters: HospitalApiAppApiHospitalSendHospitalClinicInvitationEmailRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiHospitalSendHospitalClinicInvitationEmail(requestParameters.patient_id, requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send hospital invitation email to the patient\'s email address
         * @param {HospitalApiAppApiHospitalSendHospitalInvitationEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiHospitalSendHospitalInvitationEmail(requestParameters: HospitalApiAppApiHospitalSendHospitalInvitationEmailRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiHospitalSendHospitalInvitationEmail(requestParameters.patient_id, requestParameters.hospital_id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiHospitalClinicGetClinicians operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalClinicGetCliniciansRequest
 */
export interface HospitalApiAppApiHospitalClinicGetCliniciansRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalClinicGetClinicians
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalClinicGetClinicians
     */
    readonly clinic_id: number
}

/**
 * Request parameters for appApiHospitalClinicGetPatients operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalClinicGetPatientsRequest
 */
export interface HospitalApiAppApiHospitalClinicGetPatientsRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalClinicGetPatients
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalClinicGetPatients
     */
    readonly clinic_id: number
}

/**
 * Request parameters for appApiHospitalClinicianGetClinics operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalClinicianGetClinicsRequest
 */
export interface HospitalApiAppApiHospitalClinicianGetClinicsRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalClinicianGetClinics
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalClinicianGetClinics
     */
    readonly clinician_id: number
}

/**
 * Request parameters for appApiHospitalDeleteClinic operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalDeleteClinicRequest
 */
export interface HospitalApiAppApiHospitalDeleteClinicRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalDeleteClinic
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalDeleteClinic
     */
    readonly clinic_id: number
}

/**
 * Request parameters for appApiHospitalDeleteClinician operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalDeleteClinicianRequest
 */
export interface HospitalApiAppApiHospitalDeleteClinicianRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalDeleteClinician
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalDeleteClinician
     */
    readonly clinician_id: number
}

/**
 * Request parameters for appApiHospitalDeleteHospital operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalDeleteHospitalRequest
 */
export interface HospitalApiAppApiHospitalDeleteHospitalRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalDeleteHospital
     */
    readonly hospital_id: number
}

/**
 * Request parameters for appApiHospitalDeregisterClinician operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalDeregisterClinicianRequest
 */
export interface HospitalApiAppApiHospitalDeregisterClinicianRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalDeregisterClinician
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalDeregisterClinician
     */
    readonly clinic_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalDeregisterClinician
     */
    readonly clinician_id: number
}

/**
 * Request parameters for appApiHospitalDeregisterPatient operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalDeregisterPatientRequest
 */
export interface HospitalApiAppApiHospitalDeregisterPatientRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalDeregisterPatient
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalDeregisterPatient
     */
    readonly clinic_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalDeregisterPatient
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiHospitalDeregisterPatients operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalDeregisterPatientsRequest
 */
export interface HospitalApiAppApiHospitalDeregisterPatientsRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalDeregisterPatients
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalDeregisterPatients
     */
    readonly clinic_id: number

    /**
     * 
     * @type {DeregisterPatientsBatchRequest}
     * @memberof HospitalApiAppApiHospitalDeregisterPatients
     */
    readonly DeregisterPatientsBatchRequest: DeregisterPatientsBatchRequest
}

/**
 * Request parameters for appApiHospitalGetClinic operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalGetClinicRequest
 */
export interface HospitalApiAppApiHospitalGetClinicRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalGetClinic
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalGetClinic
     */
    readonly clinic_id: number
}

/**
 * Request parameters for appApiHospitalGetClinicInvitationCodesDefault operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalGetClinicInvitationCodesDefaultRequest
 */
export interface HospitalApiAppApiHospitalGetClinicInvitationCodesDefaultRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalGetClinicInvitationCodesDefault
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalGetClinicInvitationCodesDefault
     */
    readonly clinic_id: number
}

/**
 * Request parameters for appApiHospitalGetClinician operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalGetClinicianRequest
 */
export interface HospitalApiAppApiHospitalGetClinicianRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalGetClinician
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalGetClinician
     */
    readonly clinician_id: number
}

/**
 * Request parameters for appApiHospitalGetClinicianPhoto operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalGetClinicianPhotoRequest
 */
export interface HospitalApiAppApiHospitalGetClinicianPhotoRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalGetClinicianPhoto
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalGetClinicianPhoto
     */
    readonly clinician_id: number
}

/**
 * Request parameters for appApiHospitalGetClinicians operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalGetCliniciansRequest
 */
export interface HospitalApiAppApiHospitalGetCliniciansRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalGetClinicians
     */
    readonly hospital_id: number
}

/**
 * Request parameters for appApiHospitalGetClinics operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalGetClinicsRequest
 */
export interface HospitalApiAppApiHospitalGetClinicsRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalGetClinics
     */
    readonly hospital_id: number
}

/**
 * Request parameters for appApiHospitalGetHospital operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalGetHospitalRequest
 */
export interface HospitalApiAppApiHospitalGetHospitalRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalGetHospital
     */
    readonly hospital_id: number
}

/**
 * Request parameters for appApiHospitalPatchClinician operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalPatchClinicianRequest
 */
export interface HospitalApiAppApiHospitalPatchClinicianRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalPatchClinician
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalPatchClinician
     */
    readonly clinician_id: number

    /**
     * 
     * @type {PatchClinicianRequest}
     * @memberof HospitalApiAppApiHospitalPatchClinician
     */
    readonly PatchClinicianRequest: PatchClinicianRequest
}

/**
 * Request parameters for appApiHospitalPostClinicianPhoto operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalPostClinicianPhotoRequest
 */
export interface HospitalApiAppApiHospitalPostClinicianPhotoRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalPostClinicianPhoto
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalPostClinicianPhoto
     */
    readonly clinician_id: number

    /**
     * photo
     * @type {File}
     * @memberof HospitalApiAppApiHospitalPostClinicianPhoto
     */
    readonly photo?: File
}

/**
 * Request parameters for appApiHospitalPostClinicians operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalPostCliniciansRequest
 */
export interface HospitalApiAppApiHospitalPostCliniciansRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalPostClinicians
     */
    readonly hospital_id: number

    /**
     * 
     * @type {CreateClinicianRequest}
     * @memberof HospitalApiAppApiHospitalPostClinicians
     */
    readonly CreateClinicianRequest: CreateClinicianRequest
}

/**
 * Request parameters for appApiHospitalPostClinics operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalPostClinicsRequest
 */
export interface HospitalApiAppApiHospitalPostClinicsRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalPostClinics
     */
    readonly hospital_id: number

    /**
     * 
     * @type {CreateClinicRequest}
     * @memberof HospitalApiAppApiHospitalPostClinics
     */
    readonly CreateClinicRequest: CreateClinicRequest
}

/**
 * Request parameters for appApiHospitalPostDeregisterUsers operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalPostDeregisterUsersRequest
 */
export interface HospitalApiAppApiHospitalPostDeregisterUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalPostDeregisterUsers
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalPostDeregisterUsers
     */
    readonly clinic_id: number
}

/**
 * Request parameters for appApiHospitalPostHospitals operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalPostHospitalsRequest
 */
export interface HospitalApiAppApiHospitalPostHospitalsRequest {
    /**
     * The link to the invitation email logo.
     * @type {string}
     * @memberof HospitalApiAppApiHospitalPostHospitals
     */
    readonly invitation_email_logo_url?: string

    /**
     * The link to the invitation email logo.
     * @type {string}
     * @memberof HospitalApiAppApiHospitalPostHospitals
     */
    readonly invitation_email_logo_link?: string

    /**
     * The name of the university.
     * @type {string}
     * @memberof HospitalApiAppApiHospitalPostHospitals
     */
    readonly name?: string

    /**
     * The country where the university is located.
     * @type {string}
     * @memberof HospitalApiAppApiHospitalPostHospitals
     */
    readonly country?: string

    /**
     * The full name of the university.
     * @type {string}
     * @memberof HospitalApiAppApiHospitalPostHospitals
     */
    readonly full_name?: string

    /**
     * The address of the university.
     * @type {string}
     * @memberof HospitalApiAppApiHospitalPostHospitals
     */
    readonly address?: string

    /**
     * The city where the university is located.
     * @type {string}
     * @memberof HospitalApiAppApiHospitalPostHospitals
     */
    readonly city?: string

    /**
     * The state or province where the university is located.
     * @type {string}
     * @memberof HospitalApiAppApiHospitalPostHospitals
     */
    readonly state?: string

    /**
     * The postal code for the university.
     * @type {string}
     * @memberof HospitalApiAppApiHospitalPostHospitals
     */
    readonly zip_code?: string

    /**
     * A brief description of the university.
     * @type {string}
     * @memberof HospitalApiAppApiHospitalPostHospitals
     */
    readonly description?: string

    /**
     * A photo of the university.
     * @type {File}
     * @memberof HospitalApiAppApiHospitalPostHospitals
     */
    readonly photo?: File
}

/**
 * Request parameters for appApiHospitalPutClinic operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalPutClinicRequest
 */
export interface HospitalApiAppApiHospitalPutClinicRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalPutClinic
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalPutClinic
     */
    readonly clinic_id: number

    /**
     * 
     * @type {UpdateClinicRequest}
     * @memberof HospitalApiAppApiHospitalPutClinic
     */
    readonly UpdateClinicRequest: UpdateClinicRequest
}

/**
 * Request parameters for appApiHospitalPutHospital operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalPutHospitalRequest
 */
export interface HospitalApiAppApiHospitalPutHospitalRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalPutHospital
     */
    readonly hospital_id: number

    /**
     * 
     * @type {UpdateHospitalRequest}
     * @memberof HospitalApiAppApiHospitalPutHospital
     */
    readonly UpdateHospitalRequest: UpdateHospitalRequest
}

/**
 * Request parameters for appApiHospitalPutHospitalPhoto operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalPutHospitalPhotoRequest
 */
export interface HospitalApiAppApiHospitalPutHospitalPhotoRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalPutHospitalPhoto
     */
    readonly hospital_id: number

    /**
     * image file for the hospital logo. jpg or png
     * @type {File}
     * @memberof HospitalApiAppApiHospitalPutHospitalPhoto
     */
    readonly photo: File
}

/**
 * Request parameters for appApiHospitalRegisterClinician operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalRegisterClinicianRequest
 */
export interface HospitalApiAppApiHospitalRegisterClinicianRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalRegisterClinician
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalRegisterClinician
     */
    readonly clinic_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalRegisterClinician
     */
    readonly clinician_id: number

    /**
     * 
     * @type {boolean}
     * @memberof HospitalApiAppApiHospitalRegisterClinician
     */
    readonly preferred_contact?: boolean | null

    /**
     * 
     * @type {boolean}
     * @memberof HospitalApiAppApiHospitalRegisterClinician
     */
    readonly is_notified_of_report?: boolean | null

    /**
     * 
     * @type {boolean}
     * @memberof HospitalApiAppApiHospitalRegisterClinician
     */
    readonly is_clinic_admin?: boolean | null
}

/**
 * Request parameters for appApiHospitalRegisterPatient operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalRegisterPatientRequest
 */
export interface HospitalApiAppApiHospitalRegisterPatientRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalRegisterPatient
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalRegisterPatient
     */
    readonly clinic_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalRegisterPatient
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiHospitalSendHospitalClinicInvitationEmail operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalSendHospitalClinicInvitationEmailRequest
 */
export interface HospitalApiAppApiHospitalSendHospitalClinicInvitationEmailRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalSendHospitalClinicInvitationEmail
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalSendHospitalClinicInvitationEmail
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalSendHospitalClinicInvitationEmail
     */
    readonly clinic_id: number
}

/**
 * Request parameters for appApiHospitalSendHospitalInvitationEmail operation in HospitalApi.
 * @export
 * @interface HospitalApiAppApiHospitalSendHospitalInvitationEmailRequest
 */
export interface HospitalApiAppApiHospitalSendHospitalInvitationEmailRequest {
    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalSendHospitalInvitationEmail
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof HospitalApiAppApiHospitalSendHospitalInvitationEmail
     */
    readonly hospital_id: number
}

/**
 * HospitalApi - object-oriented interface
 * @export
 * @class HospitalApi
 * @extends {BaseAPI}
 */
export class HospitalApi extends BaseAPI {
    /**
     * 
     * @summary Get clinicians registered in the clinic
     * @param {HospitalApiAppApiHospitalClinicGetCliniciansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalClinicGetClinicians(requestParameters: HospitalApiAppApiHospitalClinicGetCliniciansRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalClinicGetClinicians(requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patients registered in the clinic
     * @param {HospitalApiAppApiHospitalClinicGetPatientsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalClinicGetPatients(requestParameters: HospitalApiAppApiHospitalClinicGetPatientsRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalClinicGetPatients(requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get clinics this clinician is registered
     * @param {HospitalApiAppApiHospitalClinicianGetClinicsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalClinicianGetClinics(requestParameters: HospitalApiAppApiHospitalClinicianGetClinicsRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalClinicianGetClinics(requestParameters.hospital_id, requestParameters.clinician_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary (NO IMPL) Delete the clinic
     * @param {HospitalApiAppApiHospitalDeleteClinicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalDeleteClinic(requestParameters: HospitalApiAppApiHospitalDeleteClinicRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalDeleteClinic(requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary (NO IMPL) Delete a clinician
     * @param {HospitalApiAppApiHospitalDeleteClinicianRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalDeleteClinician(requestParameters: HospitalApiAppApiHospitalDeleteClinicianRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalDeleteClinician(requestParameters.hospital_id, requestParameters.clinician_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary (NO IMPL) Delete a hospital
     * @param {HospitalApiAppApiHospitalDeleteHospitalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalDeleteHospital(requestParameters: HospitalApiAppApiHospitalDeleteHospitalRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalDeleteHospital(requestParameters.hospital_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deregister the clinician to the clinic
     * @param {HospitalApiAppApiHospitalDeregisterClinicianRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalDeregisterClinician(requestParameters: HospitalApiAppApiHospitalDeregisterClinicianRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalDeregisterClinician(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.clinician_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deregister the patient to the clinic
     * @param {HospitalApiAppApiHospitalDeregisterPatientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalDeregisterPatient(requestParameters: HospitalApiAppApiHospitalDeregisterPatientRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalDeregisterPatient(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [NO IMPL] Deregister the patients from the clinic
     * @param {HospitalApiAppApiHospitalDeregisterPatientsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalDeregisterPatients(requestParameters: HospitalApiAppApiHospitalDeregisterPatientsRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalDeregisterPatients(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.DeregisterPatientsBatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the clinic\'s info
     * @param {HospitalApiAppApiHospitalGetClinicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalGetClinic(requestParameters: HospitalApiAppApiHospitalGetClinicRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalGetClinic(requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the clinic\'s default invitation code
     * @param {HospitalApiAppApiHospitalGetClinicInvitationCodesDefaultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalGetClinicInvitationCodesDefault(requestParameters: HospitalApiAppApiHospitalGetClinicInvitationCodesDefaultRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalGetClinicInvitationCodesDefault(requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a clinician info
     * @param {HospitalApiAppApiHospitalGetClinicianRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalGetClinician(requestParameters: HospitalApiAppApiHospitalGetClinicianRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalGetClinician(requestParameters.hospital_id, requestParameters.clinician_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [MOCK] Get the photo of the clinician
     * @param {HospitalApiAppApiHospitalGetClinicianPhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalGetClinicianPhoto(requestParameters: HospitalApiAppApiHospitalGetClinicianPhotoRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalGetClinicianPhoto(requestParameters.hospital_id, requestParameters.clinician_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all clinicians in the hospital
     * @param {HospitalApiAppApiHospitalGetCliniciansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalGetClinicians(requestParameters: HospitalApiAppApiHospitalGetCliniciansRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalGetClinicians(requestParameters.hospital_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all clinics in the hospital
     * @param {HospitalApiAppApiHospitalGetClinicsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalGetClinics(requestParameters: HospitalApiAppApiHospitalGetClinicsRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalGetClinics(requestParameters.hospital_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get hospital information
     * @param {HospitalApiAppApiHospitalGetHospitalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalGetHospital(requestParameters: HospitalApiAppApiHospitalGetHospitalRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalGetHospital(requestParameters.hospital_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of all hospitals
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalGetHospitals(options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalGetHospitals(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit information of the clinician. This endpoint edits the provided field in the body. That is, if the first_name is null in the request body, the first_name in the system remains the same (as opposed to write to null).
     * @param {HospitalApiAppApiHospitalPatchClinicianRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalPatchClinician(requestParameters: HospitalApiAppApiHospitalPatchClinicianRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalPatchClinician(requestParameters.hospital_id, requestParameters.clinician_id, requestParameters.PatchClinicianRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [NO IMPL] Upload a photo of the clinician
     * @param {HospitalApiAppApiHospitalPostClinicianPhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalPostClinicianPhoto(requestParameters: HospitalApiAppApiHospitalPostClinicianPhotoRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalPostClinicianPhoto(requestParameters.hospital_id, requestParameters.clinician_id, requestParameters.photo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new clinician in the hospital
     * @param {HospitalApiAppApiHospitalPostCliniciansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalPostClinicians(requestParameters: HospitalApiAppApiHospitalPostCliniciansRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalPostClinicians(requestParameters.hospital_id, requestParameters.CreateClinicianRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new clinic in the hospital
     * @param {HospitalApiAppApiHospitalPostClinicsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalPostClinics(requestParameters: HospitalApiAppApiHospitalPostClinicsRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalPostClinics(requestParameters.hospital_id, requestParameters.CreateClinicRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate all users in the clinic (clinicians and patients)
     * @param {HospitalApiAppApiHospitalPostDeregisterUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalPostDeregisterUsers(requestParameters: HospitalApiAppApiHospitalPostDeregisterUsersRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalPostDeregisterUsers(requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new hospital
     * @param {HospitalApiAppApiHospitalPostHospitalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalPostHospitals(requestParameters: HospitalApiAppApiHospitalPostHospitalsRequest = {}, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalPostHospitals(requestParameters.invitation_email_logo_url, requestParameters.invitation_email_logo_link, requestParameters.name, requestParameters.country, requestParameters.full_name, requestParameters.address, requestParameters.city, requestParameters.state, requestParameters.zip_code, requestParameters.description, requestParameters.photo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the clinic info
     * @param {HospitalApiAppApiHospitalPutClinicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalPutClinic(requestParameters: HospitalApiAppApiHospitalPutClinicRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalPutClinic(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.UpdateClinicRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update hospital information
     * @param {HospitalApiAppApiHospitalPutHospitalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalPutHospital(requestParameters: HospitalApiAppApiHospitalPutHospitalRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalPutHospital(requestParameters.hospital_id, requestParameters.UpdateHospitalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update hospital information
     * @param {HospitalApiAppApiHospitalPutHospitalPhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalPutHospitalPhoto(requestParameters: HospitalApiAppApiHospitalPutHospitalPhotoRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalPutHospitalPhoto(requestParameters.hospital_id, requestParameters.photo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register the clinician to the clinic
     * @param {HospitalApiAppApiHospitalRegisterClinicianRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalRegisterClinician(requestParameters: HospitalApiAppApiHospitalRegisterClinicianRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalRegisterClinician(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.clinician_id, requestParameters.preferred_contact, requestParameters.is_notified_of_report, requestParameters.is_clinic_admin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register the patient to the clinic
     * @param {HospitalApiAppApiHospitalRegisterPatientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalRegisterPatient(requestParameters: HospitalApiAppApiHospitalRegisterPatientRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalRegisterPatient(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send hospital invitation email to the patient\'s email address
     * @param {HospitalApiAppApiHospitalSendHospitalClinicInvitationEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalSendHospitalClinicInvitationEmail(requestParameters: HospitalApiAppApiHospitalSendHospitalClinicInvitationEmailRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalSendHospitalClinicInvitationEmail(requestParameters.patient_id, requestParameters.hospital_id, requestParameters.clinic_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send hospital invitation email to the patient\'s email address
     * @param {HospitalApiAppApiHospitalSendHospitalInvitationEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HospitalApi
     */
    public appApiHospitalSendHospitalInvitationEmail(requestParameters: HospitalApiAppApiHospitalSendHospitalInvitationEmailRequest, options?: AxiosRequestConfig) {
        return HospitalApiFp(this.configuration).appApiHospitalSendHospitalInvitationEmail(requestParameters.patient_id, requestParameters.hospital_id, options).then((request) => request(this.axios, this.basePath));
    }
}

