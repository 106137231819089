import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { PatientCgmReportStatisticsResponse } from '../../api/generated';
import { Trans, useTranslation } from '../../i18n';
import { ReportDot, ReportSection } from '..';
import styles from '../index.module.scss';
import { AuthenticatedImage } from './AuthenticatedImage';
import { CGMGlucoseChart } from './CGMGlucoseChart';
import { dateTimeToTimestampMS, resultMealDotValue, timestampToTimestampMS, timeStrToAmPm } from './common';

export const CGMPostFoodGlucoseRise = (props: {
  summary: any,
  statistics: PatientCgmReportStatisticsResponse,
}) => {
  const s = props.summary;
  const { t } = useTranslation();
  const glucoseIncreaseColor = (value: number) => {
    if (!props.statistics?.glucose_two_hour_delta) {
      return null;
    }
    const glucose2hDeltaStats = props.statistics.glucose_two_hour_delta;
    /* eslint-disable-next-line i18next/no-literal-string */
    return Math.abs(value) > glucose2hDeltaStats.std * 2 ? 'red' : null;
  };

  return (
    <ReportSection>
      <header>
        <h1 style={{ maxWidth: 900, marginLeft: 'auto', marginRight: 'auto' }}>
          {s.heading}
        </h1>
      </header>
      {s.results.map((r, idx) => (
        <ReportSection key={idx}>
          <Row className="align-baseline">
            <Col style={{ maxWidth: 300, minWidth: 300, paddingTop: 60 }}>
              <header>
                <h2 style={{ maxWidth: 700, marginLeft: 'auto', marginRight: 'auto' }}>
                  {t('Meal Date')}: {r.meal_date}
                </h2>
              </header>
              <table>
                <thead>
                  <tr>
                    <th colSpan={3}>
                      <h2>
                        <Trans>Key Details</Trans>
                      </h2>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: 40, textAlign: 'center' }}>
                      <ReportDot bgColor="green">{r.food_alpha_index}</ReportDot>
                    </td>
                    <td style={{ width: 120 }}>
                      <AuthenticatedImage src={encodeURI(r.food_photo_url)} style={{ maxWidth: 100, maxHeight: 100 }} />
                    </td>
                    <td>
                      <strong>{timeStrToAmPm(r.meal_time)}</strong>
                      <div>{r.food_string}</div>
                    </td>
                  </tr>
                  {[
                    [
                      t('Glucose delta'),
                      [t('after 2 hrs'), t('mmol/L')],
                      r.glucose_two_hour_delta?.toFixed(2),
                      glucoseIncreaseColor(r.glucose_two_hour_delta),
                    ],
                    // ['Time outside range', 'm', f.after_meal_time_out_of_range_mins],
                    // ['Net serving size', 'g', r.net_serving_size_g.toFixed(1)], // removed per conversation with Jeff and Katherine
                    [t('Net carbs'), t('g'), r.netcarb_g.toFixed(1)],
                    [t('Protein'), t('g'), r.protein_g.toFixed(1)],
                    [t('Fat'), t('g'), r.fat_g.toFixed(1)],
                  ].map(([label, units, value, color], idx) => (
                    <tr key={idx} style={{ verticalAlign: 'top' }}>
                      <td />
                      <td>
                        <div>{label}</div>
                        {(Array.isArray(units) ? units : [units]).map((u, idx) => (
                          <div key={idx} className={styles.lightGreyText}>({u})</div>
                        ))}
                      </td>
                      <td>
                        <div style={{ color }}>{value}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
            <Col className="ml-2">
              <div style={{ height: 70 }}>
                <h2 className="text-center" style={{ marginBottom: 0 }}>{s.meal_date}</h2>
              </div>
              <CGMGlucoseChart
                width={400}
                height={275}
                unit="mmol/L"
                data={r.glucose
                  .map(g => ({
                    value: g.value,
                    xTimestampMS: timestampToTimestampMS(g.timestamp),
                  }))
                  .filter(pt => {
                    return (
                      pt.xTimestampMS >= new Date(r.time_lower_bound).getTime()
                      && pt.xTimestampMS <= new Date(r.time_upper_bound).getTime()
                    );
                  })}
                xTickFormat={ms => moment(ms).format('ha')}
                xTickFrequencyHours={1}
                yAxisLabel={t('Glucose (mmol/L)')}
                xAxisLabel=""
                points={[
                  {
                    xTimestampMS: dateTimeToTimestampMS(r.meal_date, r.meal_time),
                    value: resultMealDotValue(r.glucose, 'meal', r, idx, r.time_lower_bound, r.time_upper_bound),
                    marker: {
                      type: 'meal_dot',
                      alpha_index: r.food_alpha_index,
                    },
                  },
                  {
                    xTimestampMS: dateTimeToTimestampMS(r.meal_date, r.glucose_min_time),
                    value: r.glucose_min_value,
                    marker: {
                      type: 'glucose_min',
                      value: r.glucose_min_value,
                    },
                  },
                  {
                    xTimestampMS: dateTimeToTimestampMS(r.meal_date, r.glucose_max_time),
                    value: r.glucose_max_value,
                    marker: {
                      type: 'glucose_max',
                      value: r.glucose_max_value,
                    },
                  },
                ]}
              />
            </Col>
          </Row>
          {
            /* Note: summary statements are currently hidden because they typically need
            some hand-tweaking. This can be uncommented when we are reliably generating
            good statements.
        r.summary_statements && <div>
          <h2>
            Statements
          </h2>
          <ul>
            {Object.values(r.summary_statements || {}).map((statementObj: any, idx) => (
              <li key={idx}><div style={{fontWeight: 'bold'}}>{statementObj.heading}</div>{statementObj.statement}</li>
            ))}
          </ul>
          </div>*/
          }
        </ReportSection>
      ))}

      {
        /*
          add section summary statements here.
          */
      }

      {s.heading == 'Times When Glucose Readings Went Under 4mmol/L Within 2 Hours of Logged Food' && (
        <ReportSection>
          <header>
            <h2 style={{ maxWidth: 900, marginLeft: 'auto', marginRight: 'auto' }}>
              <Trans>Summary Statements</Trans>
            </h2>
          </header>
          <div style={{ paddingTop: 10 }}>
            <ul>
              <li>
                <div style={{ fontWeight: 'bold' }}>
                  <Trans>Low Glucose</Trans>
                </div>{' '}
                <Trans>
                  Glucose readings below 4mmol/L (hypoglycemia). Symptoms of low glucose levels include feeling dizzy,
                  shaky, anxious, sweaty and/or hungry. Visit this Diabetes Canada resource to learn more about how best
                  to treat a low glucose :
                  https://guidelines.diabetes.ca/docs/patient-resources/lows-and-highs-blood-sugar-levels.pdf
                </Trans>
              </li>

              <li>
                <div style={{ fontWeight: 'bold' }}>
                  <Trans>Any exercise?</Trans>
                </div>

                <Trans>
                  Did you exercise longer, more vigorously, or differently than usual? Exercise can lower our glucose
                  levels, so if we do not time meals and snacks appropriately glucose levels can go low. Speak to your
                  clinician to determine how to best manage your exercise and glucose levels. Visit here for more
                  information
                </Trans>:
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <a href="https://www.diabetes.ca/nutrition---fitness/exercise---activity">
                  https://www.diabetes.ca/nutrition---fitness/exercise---activity
                </a>.
              </li>
              <li>
                <div style={{ fontWeight: 'bold' }}>
                  <Trans>Stress, change in medications, feeling unwell, other changes?</Trans>
                </div>
                <Trans>
                  All these factors can impact glucose levels in addition to the carbohydrate containing foods that we
                  eat.
                </Trans>
              </li>
            </ul>
          </div>
        </ReportSection>
      )}
    </ReportSection>
  );
};
