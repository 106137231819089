import classnames from 'classnames';
import * as React from 'react';
import { Form } from 'react-bootstrap';

import { MDBInput } from 'mdbreact';

const Input = (props) => {
  const { controlId, ...rest } = props;
  const classes = classnames(props.className, { error: props.error });
  return (
    <Form.Group controlId={controlId}>
      <MDBInput {...rest} className={classes}>
        <p className="errorMessage">{props.error}</p>
      </MDBInput>
    </Form.Group>
  );
};

export { Input };
