import classnames from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { TFunc, Trans, useTranslation } from '../i18n';
import 'react-day-picker/lib/style.css';

import './foodFilter.scss';

import DateFnsUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Button } from 'react-bootstrap';
import { useStore } from '../context';
import { PatientService } from '../services/patient';

const today = moment().endOf('day');
const dayAgo = moment().subtract(2, 'd').startOf('day');
const weekAgo = moment().subtract(7, 'd').startOf('day');
const monthAgo = moment().subtract(1, 'M').startOf('day');
const threemonthAgo = moment().subtract(3, 'M').startOf('day');

const GetDurationFilters = (t: TFunc) =>
  [
    [[weekAgo, today], t('last 7 days')],
    [[today, today], t('today')],
    [[dayAgo, today], t('yesterday')],
    [[monthAgo, today], t('last month')],
    [[threemonthAgo, today], t('last 3 months')],
    [[weekAgo, today], t('custom')],
  ] as Array<[[moment.Moment, moment.Moment], string]>;

const view_types = [
  /* eslint-disable-next-line i18next/no-literal-string */
  ['meal_name', 'meal types'],
  /* eslint-disable-next-line i18next/no-literal-string */
  ['weekday_weekend', 'weekday vs weekend'],
  /* eslint-disable-next-line i18next/no-literal-string */
  ['date', 'date'],
] as const;

const GetCCNutrients = (t: TFunc) => [
  {
    'value': 'cc_healthy_fat',
    'label': t('Healthy fat'),
  },
  {
    'value': 'cc_unhealthy_fat',
    'label': t('Unhealthy fat'),
  },
  {
    'value': 'cc_plant_based',
    'label': t('Plant-based protein'),
  },
  {
    'value': 'cc_ultraprocessed',
    'label': t('Ultraprocessed foods'),
  },
  {
    'value': 'cc_omega_three',
    'label': t('Omega-3'),
  },
  {
    'value': 'cc_whole',
    'label': t('Whole grains'),
  },
  {
    'value': 'cc_high_fiber',
    'label': t('Fibre'),
  },
  {
    'value': 'cc_sugary_beverage',
    'label': t('Sugary drinks'),
  },
  {
    'value': 'cc_redmeat',
    'label': t('Red and processed meat'),
  },
  {
    'value': 'cc_nuts_seeds',
    'label': t('Nuts and seeds'),
  },
  {
    'value': 'cc_legume',
    'label': t('Legumes'),
  },
  {
    'value': 'cc_vegetable',
    'label': t('Vegetables'),
  },
  {
    'value': 'cc_fruit',
    'label': t('Fruits'),
  },
];

const FilterDropdown = (props) => {
  const handleClick = (e) => {
    e.preventDefault();
    props.onClick(e);
  };

  return (
    <a href="" onClick={handleClick}>
      {props.children}
    </a>
  );
};

const withFilterPanel = (WrappedComponent) => {
  return function() {
    const { t, i18n } = useTranslation();
    const duration_filters = GetDurationFilters(t);
    const ccNutrients = GetCCNutrients(t);
    const [filter, setFilter] = useState(duration_filters[0]);
    const [selectedNutrientFilters, setSelectedNutrientFilters] = useState({} as Record<string, boolean>);
    const [view, setView] = useState(view_types[0]);
    const target = useRef(null);
    const [since, until] = filter[0];
    const [errorText, setErrorText] = useState('');
    const { clinician, patient } = useStore();
    const patientService = PatientService();
    const handleFilterClick = (f) => {
      setFilter(f);
    };

    const handleNewSince = (date: moment.Moment) => {
      const filter = [[date, until], t('custom')] as any;
      setFilter(filter);
      duration_filters[duration_filters.length - 1] = filter;
    };
    const handleNewUntil = (date) => {
      const filter = [[since, date], t('custom')] as any;
      setFilter(filter);
      duration_filters[duration_filters.length - 1] = filter;
    };
    /* eslint-disable-next-line i18next/no-literal-string */
    const [selectedMealTypes, setSelectedMealTypes] = useState(['Breakfast', 'Lunch', 'Dinner', 'Snack']);
    const handleMealTypesChange = (meal) => {
      const newSelectedMealTypes = [...selectedMealTypes];
      const idx = newSelectedMealTypes.indexOf(meal);
      if (idx > -1) {
        newSelectedMealTypes.splice(idx, 1);
      } else {
        newSelectedMealTypes.push(meal);
      }
      setSelectedMealTypes(newSelectedMealTypes);
    };
    /* eslint-disable-next-line i18next/no-literal-string */
    const mealTypes = ['Breakfast', 'Lunch', 'Dinner', 'Snack'];

    const generateMealReport = async () => {
      if (since == null || until == null) {
        return;
      }
      try {
        await patientService.downloadMealReport(
          clinician.id,
          patient.patient_id,
          moment(since).format('YYYY-MM-DD'),
          moment(until).format('YYYY-MM-DD'),
        );
      } catch (e) {
        setErrorText(t('Insufficient data in range to generate report.'));
        return;
      }
      setErrorText('');
    };

    const reloadMeals = () => {
      patientService.getMeals({
        patient_id: patient.patient_id,
        include_deleted: clinician.is_meal_admin,
      });
    };
    return (
      <>
        <Row className="logFilterPanel">
          <Col className="logFilterCol">
            <span className="mr-5">
              <Trans>filter by</Trans>
            </span>
            <Dropdown className="logFilter" ref={target}>
              <Dropdown.Toggle id="log-filter-date" as={FilterDropdown as any}>
                {filter[1]} <i className="fas fa-chevron-down" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {duration_filters.map((f, idx) => {
                  return (
                    <Dropdown.Item
                      key={idx}
                      className={classnames('filterMenuItem', { active: filter == f })}
                      onClick={() => handleFilterClick(f)}
                    >
                      {f[1]}
                      <i className="fas fa-check-circle" />
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col className="logFilterCol">
            <Dropdown className="logFilter">
              <Dropdown.Toggle id="log-filter-meal-type" as={FilterDropdown as any}>
                <Trans>Meal Types</Trans> <i className="fas fa-chevron-down" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {mealTypes.map((meal, idx) => {
                  return (
                    <Dropdown.Item
                      key={idx}
                      className={classnames('filterMenuItem', { active: selectedMealTypes.indexOf(meal) > -1 })}
                      onClick={() => handleMealTypesChange(meal)}
                    >
                      {meal}
                      <i className="fas fa-check-circle" />
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col className="logFilterCol">
            <Dropdown className="logFilter">
              <Dropdown.Toggle id="log-filter-nutrients" as={FilterDropdown as any}>
                <Trans>Food Types</Trans> <i className="fas fa-chevron-down" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {ccNutrients.map(nutrient => {
                  return (
                    <Dropdown.Item
                      key={nutrient.value}
                      className={classnames('filterMenuItem', { active: selectedNutrientFilters[nutrient.value] })}
                      onClick={() => {
                        setSelectedNutrientFilters({
                          ...selectedNutrientFilters,
                          [nutrient.value]: selectedNutrientFilters[nutrient.value] ? undefined : true,
                        });
                      }}
                    >
                      {nutrient.label}
                      <i className="fas fa-check-circle" />
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col style={{ marginTop: -19 }}>
            <div style={{ width: '400px' }}>
              <label style={{ fontSize: '14px', paddingTop: '25px' }}>
                <Trans>Date Range</Trans>:&nbsp;
              </label>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={i18n.language}
              >
                <DatePicker
                  style={{
                    width: '120px',
                    fontSize: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingBottom: '15px',
                  }}
                  variant="inline"
                  format="YYYY-MM-DD"
                  value={since}
                  onChange={handleNewSince}
                />
              </MuiPickersUtilsProvider>
              <label style={{ fontSize: '14px', paddingTop: '25px' }}>-&nbsp;</label>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={i18n.language}
              >
                <DatePicker
                  style={{
                    width: '100px',
                    fontSize: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingBottom: '15px',
                  }}
                  variant="inline"
                  format="YYYY-MM-DD"
                  value={until}
                  onChange={handleNewUntil}
                />
              </MuiPickersUtilsProvider>
              {clinician.is_meal_admin && (
                <i
                  className="fas fa-redo patientMenuIcon"
                  title={t('Reload Meals')}
                  style={{
                    color: '#0000008f',
                    borderColor: 'black',
                    transform: 'scale(0.7)',
                    marginLeft: 0,
                    marginTop: 18,
                  }}
                  onClick={reloadMeals}
                />
              )}
            </div>
            {errorText != '' && (
              <div style={{ color: 'red', fontSize: '10px', paddingBottom: '10px' }}>
                {errorText}
              </div>
            )}
          </Col>

          {clinician.is_meal_admin && (
            <Col>
              <div style={{ fontSize: '10px', paddingTop: 10, paddingBottom: 10 }}>
                <Button style={{ fontSize: '10px', width: '100%' }} onClick={() => generateMealReport()}>
                  <Trans>Download Meal History Report</Trans>
                </Button>
              </div>
            </Col>
          )}
        </Row>

        <WrappedComponent
          view={view}
          filter={filter}
          since={since}
          until={until}
          mealTypes={selectedMealTypes}
          nutrientFilters={selectedNutrientFilters}
        />
      </>
    );
  };
};

export { GetDurationFilters, view_types, withFilterPanel };
