import classnames from 'classnames';
import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from 'mdbreact';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';

import { Input } from '../components/input';
import { Label, LabelG } from '../components/label';
import { useStore, useStoreDispatch } from '../context';

import './patient-resources.scss';
import { Trans, useTranslation } from '../i18n';

const Resources = (props) => {
  const { t } = useTranslation;
  return (
    <div>
      <div>
        <p />
        <h3>
          <Trans>Videos</Trans>
        </h3>
        <p>
          <a
            href="https://us-east-1-inneranalytics-mnt-public.s3.amazonaws.com/media/onboarding/Patient+Videos/onboarding.mp4"
            target="_blank"
          >
            <Trans>Onboarding (15 seconds)</Trans>
            <i className="fas fa-external-link" />
          </a>
        </p>
        <h5>
          <Trans>Using the app features to log a meal (1 min 30 sec)</Trans>
        </h5>
        <p>
          <a
            href="https://us-east-1-inneranalytics-mnt-public.s3.amazonaws.com/media/onboarding/Patient+Videos/iOS/add_meal_update_iOS_02242020.mp4"
            target="_blank"
          >
            <Trans>iOS users</Trans>
          </a>
        </p>
        <p>
          <a
            href="https://us-east-1-inneranalytics-mnt-public.s3.amazonaws.com/media/onboarding/Patient+Videos/onboarding/onboarding_v03.mp4"
            target="_blank"
          >
            <Trans>Full Onboarding iOS users</Trans>
          </a>
        </p>
        <p>
          <a
            href="https://us-east-1-inneranalytics-mnt-public.s3.amazonaws.com/media/onboarding/Patient+Videos/Android/add_meal_update_02242020.mp4"
            target="_blank"
          >
            <Trans>Android users</Trans>
          </a>
        </p>
        <p>
          <a
            href="http://us-east-1-inneranalytics-mnt-public.s3.amazonaws.com/media/onboarding/Patient+Videos/onboarding/onboarding_v03_android.mp4"
            target="_blank"
          >
            <Trans>Full Onboarding Android users</Trans>
          </a>
        </p>

        <h3>
          <Trans>Clinician Help Videos</Trans>
        </h3>
        <p>
          <a
            href="https://vimeo.com/924643315/d70fc2a2ca?share=copy"
            target="_blank"
          >
            <Trans>Adding a Patient</Trans>
          </a>
        </p>
        <p>
          <a
            href="https://us-east-1-inneranalytics-mnt-public.s3.amazonaws.com/media/onboarding/Clinician+Videos/07_patientList.mp4"
            target="_blank"
          >
            <Trans>Features within the Patient List Page</Trans>
          </a>
        </p>
        <p>
          <a
            href="https://us-east-1-inneranalytics-mnt-public.s3.amazonaws.com/media/onboarding/Clinician+Videos/02_add_goal.mp4"
            target="_blank"
          >
            <Trans>Adding a Goal</Trans>
          </a>
        </p>
        <p>
          <a
            href="https://us-east-1-inneranalytics-mnt-public.s3.amazonaws.com/media/onboarding/Clinician+Videos/03_new_tip.mp4"
            target="_blank"
          >
            <Trans>Adding a new tip</Trans>
          </a>
        </p>
        <p>
          <a
            href="https://us-east-1-inneranalytics-mnt-public.s3.amazonaws.com/media/onboarding/Clinician+Videos/04_summary.mp4"
            target="_blank"
          >
            <Trans>Features within the summary page</Trans>
          </a>
        </p>
        <p>
          <a
            href="https://us-east-1-inneranalytics-mnt-public.s3.amazonaws.com/media/onboarding/Clinician+Videos/05_foodLog.mp4"
            target="_blank"
          >
            <Trans>Features within the food log page</Trans>
          </a>
        </p>
        <p>
          <a
            href="https://us-east-1-inneranalytics-mnt-public.s3.amazonaws.com/media/onboarding/Clinician+Videos/06_nutrients.mp4"
            target="_blank"
          >
            <Trans>Features within the nutrients page</Trans>
          </a>
        </p>
      </div>
    </div>
  );
};

export default Resources;
