import classnames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Pluralize from 'react-pluralize';
import { Link } from 'react-router-dom';
import { useStore, useStoreDispatch } from '../context';

import { PatientService } from '../services/patient';

import { MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import { Button, Form } from 'react-bootstrap';
import ScrollArea from 'react-scrollbar';
import { Image, OriginalImage } from './image';
import { Input } from './input';
import { LabelG, LabelL, LabelM } from './label';

import './showMeal.scss';
import { useQuery } from '@tanstack/react-query';
import { mealApi, patientApi } from '../api';
import { useTranslation } from '../i18n';

export const getAddonsStr = (i /* MealItemResponse */) => {
  const customAddons = i.custom_addons
    && i.custom_addons.map(a => `${a.food_name} (${a.servings} ${a.serving_unit_label})`);
  return [
    ...(i.addons || []),
    ...(customAddons || []),
  ].join(', ');
};

const ShowMeal = (props: {
  mealId: number,
  onClose: () => void,
  onDelete: (mealId: number) => void,
}) => {
  const { mealId, onClose } = props;
  const { patient, clinician } = useStore();
  const { t, i18n } = useTranslation();
  const patientService = PatientService();
  const mealQuery = useQuery(['meal', mealId], async () => {
    const res = await mealApi.appApiMealGetMeal({
      meal_id: mealId,
      patient_id: patient.patient_id,
    });
    return res.data;
  });

  const meal = mealQuery.data;

  const handleClose = () => {
    onClose();
  };

  async function editMeal() {
    const res = await patientService.getMealAuditorUrl(patient.patient_id, mealId);
    if (!res) {
      console.error('Error fetching meal auditor url');
      return;
    }
    // eslint-disable-next-line i18next/no-literal-string
    const q = res.url.indexOf('?') >= 0 ? '&' : '?';
    window.open(res.url + q + 'back=' + encodeURIComponent(window.location.href), '_blank');
  }

  async function refreshMealItem(meal_id) {
    console.log(meal_id, mealId, patient.patient_id);
    const r = await patientService.refreshMealItem(patient.patient_id, mealId, meal_id);
  }

  return (
    <MDBContainer>
      <MDBModal className="fullModal" fullHeight position="top" isOpen={true} onClose={handleClose} size="fluid">
        <MDBModalBody>
          <Container fluid>
            <Row>
              <Col className="mealModalImageCol">
                <div style={{ height: (window.innerHeight).toString().concat('px') }}>
                  {meal && <OriginalImage className="mealModalImage" mealPhotoId={meal.meal_photo_id} />}
                </div>
              </Col>

              <Col className="mealModalInfoCol">
                <ScrollArea style={{ height: (window.innerHeight).toString().concat('px'), width: '100%' }}>
                  <div className="mealModalCloseBtn">
                    <i className="fas fa-times modalCloseButton" onClick={handleClose} />
                  </div>
                  {mealQuery.isLoading
                    ? (
                      <Container fluid>
                        <Row>
                          <Col>
                            <div style={{ height: 30 }} />
                            <i className="fas fa-spinner fa-spin" />
                          </Col>
                        </Row>
                      </Container>
                    )
                    : mealQuery.isError
                    ? (
                      <Container fluid>
                        <Row>
                          <Col>
                            <div style={{ height: 30 }} />
                            <i className="fas fa-exclamation-triangle" />
                            <span>{t('Error loading meal')}: {'' + mealQuery.error}</span>
                          </Col>
                        </Row>
                      </Container>
                    )
                    : (
                      <Container fluid>
                        <Row>
                          <Col>
                            <br />
                            <LabelL className="mealModalTitle">{meal.meal_name}</LabelL>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <LabelG className="mealModalSummary">{`${meal.meal_time} / ${meal.meal_date}`}</LabelG>
                          </Col>
                        </Row>
                        {(!!clinician.is_meal_admin || !!clinician?.flags?.clinician_can_delete_meals) && (
                          <Row className="mealModalEditBtns">
                            {!!clinician.is_meal_admin && (
                              <i
                                className="fas fa-edit patientMenuIcon"
                                title={t('Edit Meal')}
                                onClick={() => editMeal()}
                              />
                            )}
                            <i
                              className="fas fa-trash patientMenuIcon"
                              title={t('Remove Meal')}
                              onClick={() => props.onDelete(mealId)}
                            />
                          </Row>
                        )}
                        <Row className="mealModalNumItemsRow">
                          <Col>
                            <LabelG className="mealModalNumItems">
                              <Pluralize singular={t('item')} count={meal.meal_items.length} /> {t('logged')}
                            </LabelG>
                          </Col>
                        </Row>
                        {meal.meal_items.map((i, idx) => {
                          return (
                            <Row key={i.id} className="mealModalItem">
                              <Col className="flex-grow-0">
                                {i.food_photo_url && (
                                  <Image width={75} className="mealModalThumb" src={i.food_photo_url} />
                                )}
                                {!i.food_photo_url && <div style={{ display: 'inline-block', width: 75 }} />}
                              </Col>
                              <Col>
                                <LabelM className="mealModalItemName">
                                  {i.food_replacement_name ? i.food_replacement_name : i.food_name}
                                </LabelM>
                                <LabelG className="mealModalItemQuantity">
                                  {[`${i.servings} ${i.serving_unit_label}`]}
                                </LabelG>
                                <LabelG hidden={!getAddonsStr(i)}>{t('Additions')}: {getAddonsStr(i)}</LabelG>
                                <LabelG hidden={!i.note}>{t('Notes')}: {`${i.note}`}</LabelG>
                                <div style={{ marginLeft: -10, marginTop: 5 }}>
                                  {!!clinician.is_meal_admin && (
                                    <i
                                      className="fas fa-redo patientMenuIcon"
                                      title={t('Refresh Item')}
                                      onClick={() => refreshMealItem(i.id)}
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col>
                                <LabelG>{t('Carbs')}: {`${(i.carbohydrate_g).toFixed()}`} {t('grams')}</LabelG>
                              </Col>
                            </Row>
                          );
                        })}
                      </Container>
                    )}
                </ScrollArea>
              </Col>
            </Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export { ShowMeal };
