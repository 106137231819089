import React from 'react';

import 'react-svg-radar-chart/build/css/index.css';
import { useStore } from '../context';
import './patient-summary.scss';

import { Grid } from '@material-ui/core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { recipeApi } from '../api';
import { PatientAutopilot } from '../components/PatientAutopilot';
import { PatientRecipeList } from '../components/PatientRecipeList';

export const usePatientRecipeList = () => {
  const { patient } = useStore();
  const query = useQuery(['patient-recipe-list', patient?.patient_id], async () => {
    const recipeListRes = await recipeApi.appApiRecipeGetPatientRecipeList({
      patient_id: patient?.patient_id,
      recipe_list_id_or_type: 'patient_favourites',
    });

    return recipeListRes.data;
  }, { enabled: !!patient.patient_id });

  return {
    list: query.data || null,
    query: query,
  };
};

export const Recipes = () => {
  const { patient } = useStore();

  const recipeListQuery = usePatientRecipeList();

  return (
    <ErrorBoundary fallbackRender={() => <div>Error loading patient recipes</div>}>
      <Grid container style={{ marginTop: 30, marginBottom: 30 }} spacing={2}>
        <Grid item md={12}>
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <PatientAutopilot patientId={patient.patient_id} />
          </div>
        </Grid>
        <div style={{ height: 50 }} />
        <Grid item md={12}>
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            {recipeListQuery.query.isSuccess && (
              <PatientRecipeList recipeList={recipeListQuery.list} patientId={patient.patient_id} />
            )}
          </div>
        </Grid>
      </Grid>
      <div style={{ height: 50 }} />
    </ErrorBoundary>
  );
};
