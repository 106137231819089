import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { goalApi, patientReportApi } from '../api';
import {
  CreateGoalRequest,
  GoalExtendedWeeklyReportResponse,
  GoalTargetResponse,
  UpdateGoalRequest,
} from '../api/generated';
import { useStore } from '../context';

export const useGoals = () => {
  const patient = useStore().patient;
  const goalsQuery = useQuery(['patient-goals', patient.patient_id], async () => {
    if (!patient) {
      return null;
    }
    const res = await patientReportApi.appApiGoalGetGoalsProgressReport({
      patient_id: patient.patient_id,
      date_since: DateTime.local().minus({ days: 6 }).toISODate(),
      date_until: DateTime.local().toISODate(),
    });
    return res.data;
  });

  const goalTargetsQuery = useQuery(['goal-targets'], async () => {
    const res = await goalApi.appApiGoalGetGoalTargets();
    return res.data;
  });

  const addGoal = async (opts: { goal: CreateGoalRequest }) => {
    try {
      const res = await goalApi.appApiGoalPostGoals({
        patient_id: patient.patient_id,
        CreateGoalRequest: opts.goal,
      });
      return res.data;
    } catch (err) {
      console.log('Error adding goal: ', err);
      return false;
    }
  };

  const updateGoal = async (opts: { goal_id: number, goal: UpdateGoalRequest }) => {
    try {
      const res = await goalApi.appApiGoalPutGoal({
        patient_id: patient.patient_id,
        goal_id: opts.goal_id,
        UpdateGoalRequest: opts.goal,
      });
      return res.data;
    } catch (err) {
      console.log('Error updating goal: ', err);
      return false;
    }
  };

  const deleteGoal = async (opts: { goal_id: number }) => {
    try {
      const res = await goalApi.appApiGoalDeleteGoal({
        patient_id: patient.patient_id,
        goal_id: opts.goal_id,
      });
      return res.data;
    } catch (err) {
      console.log('Error deleting goal: ', err);
      return false;
    }
  };

  return {
    query: goalsQuery as UseQueryResult<unknown, unknown>,
    goals: (goalsQuery.data || []) as GoalExtendedWeeklyReportResponse[],
    goalTargets: (goalTargetsQuery.data || []) as GoalTargetResponse[],
    addGoal,
    updateGoal,
    deleteGoal,
  };
};
