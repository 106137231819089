/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreatePatientRecipeListItemRequest } from '../models';
// @ts-ignore
import { CreatePatientRecipeListRequest } from '../models';
// @ts-ignore
import { CreateRecipeRequest } from '../models';
// @ts-ignore
import { PatientRecipeListItemResponse } from '../models';
// @ts-ignore
import { PatientRecipeListResponse } from '../models';
// @ts-ignore
import { PutPatientRecipeListItemRequest } from '../models';
// @ts-ignore
import { RecipeInstructionResponse } from '../models';
// @ts-ignore
import { RecipeResponse } from '../models';
// @ts-ignore
import { RecipeSuggestionResponse } from '../models';
/**
 * RecipesApi - axios parameter creator
 * @export
 */
export const RecipesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a recipe list
         * @param {number} patient_id 
         * @param {string} recipe_list_id_or_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeDeletePatientRecipeList: async (patient_id: number, recipe_list_id_or_type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiRecipeDeletePatientRecipeList', 'patient_id', patient_id)
            // verify required parameter 'recipe_list_id_or_type' is not null or undefined
            assertParamExists('appApiRecipeDeletePatientRecipeList', 'recipe_list_id_or_type', recipe_list_id_or_type)
            const localVarPath = `/patients/{patient_id}/recipes/lists/{recipe_list_id_or_type}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"recipe_list_id_or_type"}}`, encodeURIComponent(String(recipe_list_id_or_type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a recipe from a recipe list
         * @param {number} patient_id 
         * @param {string} recipe_list_id_or_type 
         * @param {number} recipe_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeDeletePatientRecipeListItem: async (patient_id: number, recipe_list_id_or_type: string, recipe_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiRecipeDeletePatientRecipeListItem', 'patient_id', patient_id)
            // verify required parameter 'recipe_list_id_or_type' is not null or undefined
            assertParamExists('appApiRecipeDeletePatientRecipeListItem', 'recipe_list_id_or_type', recipe_list_id_or_type)
            // verify required parameter 'recipe_id' is not null or undefined
            assertParamExists('appApiRecipeDeletePatientRecipeListItem', 'recipe_id', recipe_id)
            const localVarPath = `/patients/{patient_id}/recipes/lists/{recipe_list_id_or_type}/items/{recipe_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"recipe_list_id_or_type"}}`, encodeURIComponent(String(recipe_list_id_or_type)))
                .replace(`{${"recipe_id"}}`, encodeURIComponent(String(recipe_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all recipes from a recipe list
         * @param {number} patient_id 
         * @param {string} recipe_list_id_or_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeGetAllPatientRecipeListItems: async (patient_id: number, recipe_list_id_or_type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiRecipeGetAllPatientRecipeListItems', 'patient_id', patient_id)
            // verify required parameter 'recipe_list_id_or_type' is not null or undefined
            assertParamExists('appApiRecipeGetAllPatientRecipeListItems', 'recipe_list_id_or_type', recipe_list_id_or_type)
            const localVarPath = `/patients/{patient_id}/recipes/lists/{recipe_list_id_or_type}/items`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"recipe_list_id_or_type"}}`, encodeURIComponent(String(recipe_list_id_or_type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all recipe lists saved by a patient
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeGetAllPatientRecipeLists: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiRecipeGetAllPatientRecipeLists', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/recipes/lists`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a recipe list saved by a patient
         * @param {number} patient_id 
         * @param {string} recipe_list_id_or_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeGetPatientRecipeList: async (patient_id: number, recipe_list_id_or_type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiRecipeGetPatientRecipeList', 'patient_id', patient_id)
            // verify required parameter 'recipe_list_id_or_type' is not null or undefined
            assertParamExists('appApiRecipeGetPatientRecipeList', 'recipe_list_id_or_type', recipe_list_id_or_type)
            const localVarPath = `/patients/{patient_id}/recipes/lists/{recipe_list_id_or_type}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"recipe_list_id_or_type"}}`, encodeURIComponent(String(recipe_list_id_or_type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of recipes suggestions for a patient
         * @param {number} patient_id 
         * @param {boolean} [force_update] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeGetPatientRecipeSuggestions: async (patient_id: number, force_update?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiRecipeGetPatientRecipeSuggestions', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/recipes/suggestions`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (force_update !== undefined) {
                localVarQueryParameter['force_update'] = force_update;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the Edamam results for a patient\'s recipe suggestions
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeGetPatientRecipeSuggestionsDebugEdamamResults: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiRecipeGetPatientRecipeSuggestionsDebugEdamamResults', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/recipes/suggestions/debug/edamam-results`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the raw OpenAI results for a patient\'s recipe suggestions
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResults: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResults', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/recipes/suggestions/debug/openai-results`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recipes instructions
         * @param {number} recipe_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeGetRecipeInstruction: async (recipe_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recipe_id' is not null or undefined
            assertParamExists('appApiRecipeGetRecipeInstruction', 'recipe_id', recipe_id)
            const localVarPath = `/recipes/instructions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (recipe_id !== undefined) {
                localVarQueryParameter['recipe_id'] = recipe_id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a recipe list
         * @param {number} patient_id 
         * @param {CreatePatientRecipeListRequest} CreatePatientRecipeListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipePostPatientRecipeList: async (patient_id: number, CreatePatientRecipeListRequest: CreatePatientRecipeListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiRecipePostPatientRecipeList', 'patient_id', patient_id)
            // verify required parameter 'CreatePatientRecipeListRequest' is not null or undefined
            assertParamExists('appApiRecipePostPatientRecipeList', 'CreatePatientRecipeListRequest', CreatePatientRecipeListRequest)
            const localVarPath = `/patients/{patient_id}/recipes/lists`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreatePatientRecipeListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Email a recipe list to patient
         * @param {number} patient_id 
         * @param {number} clinician_id 
         * @param {string} recipe_list_id_or_type 
         * @param {Array<number>} [recipe_item_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipePostPatientRecipeListEmail: async (patient_id: number, clinician_id: number, recipe_list_id_or_type: string, recipe_item_ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiRecipePostPatientRecipeListEmail', 'patient_id', patient_id)
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiRecipePostPatientRecipeListEmail', 'clinician_id', clinician_id)
            // verify required parameter 'recipe_list_id_or_type' is not null or undefined
            assertParamExists('appApiRecipePostPatientRecipeListEmail', 'recipe_list_id_or_type', recipe_list_id_or_type)
            const localVarPath = `/patients/{patient_id}/recipes/lists/{recipe_list_id_or_type}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"recipe_list_id_or_type"}}`, encodeURIComponent(String(recipe_list_id_or_type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clinician_id !== undefined) {
                localVarQueryParameter['clinician_id'] = clinician_id;
            }

            if (recipe_item_ids) {
                localVarQueryParameter['recipe_item_ids'] = recipe_item_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a recipe to a recipe list
         * @param {number} patient_id 
         * @param {string} recipe_list_id_or_type 
         * @param {CreatePatientRecipeListItemRequest} CreatePatientRecipeListItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipePostPatientRecipeListItem: async (patient_id: number, recipe_list_id_or_type: string, CreatePatientRecipeListItemRequest: CreatePatientRecipeListItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiRecipePostPatientRecipeListItem', 'patient_id', patient_id)
            // verify required parameter 'recipe_list_id_or_type' is not null or undefined
            assertParamExists('appApiRecipePostPatientRecipeListItem', 'recipe_list_id_or_type', recipe_list_id_or_type)
            // verify required parameter 'CreatePatientRecipeListItemRequest' is not null or undefined
            assertParamExists('appApiRecipePostPatientRecipeListItem', 'CreatePatientRecipeListItemRequest', CreatePatientRecipeListItemRequest)
            const localVarPath = `/patients/{patient_id}/recipes/lists/{recipe_list_id_or_type}/items`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"recipe_list_id_or_type"}}`, encodeURIComponent(String(recipe_list_id_or_type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreatePatientRecipeListItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create recipes
         * @param {CreateRecipeRequest} CreateRecipeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipePostRecipe: async (CreateRecipeRequest: CreateRecipeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateRecipeRequest' is not null or undefined
            assertParamExists('appApiRecipePostRecipe', 'CreateRecipeRequest', CreateRecipeRequest)
            const localVarPath = `/recipes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateRecipeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a recipe list item from a recipe list
         * @param {number} patient_id 
         * @param {string} recipe_list_id_or_type 
         * @param {number} recipe_id 
         * @param {PutPatientRecipeListItemRequest} PutPatientRecipeListItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipePutPatientRecipeListItem: async (patient_id: number, recipe_list_id_or_type: string, recipe_id: number, PutPatientRecipeListItemRequest: PutPatientRecipeListItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiRecipePutPatientRecipeListItem', 'patient_id', patient_id)
            // verify required parameter 'recipe_list_id_or_type' is not null or undefined
            assertParamExists('appApiRecipePutPatientRecipeListItem', 'recipe_list_id_or_type', recipe_list_id_or_type)
            // verify required parameter 'recipe_id' is not null or undefined
            assertParamExists('appApiRecipePutPatientRecipeListItem', 'recipe_id', recipe_id)
            // verify required parameter 'PutPatientRecipeListItemRequest' is not null or undefined
            assertParamExists('appApiRecipePutPatientRecipeListItem', 'PutPatientRecipeListItemRequest', PutPatientRecipeListItemRequest)
            const localVarPath = `/patients/{patient_id}/recipes/lists/{recipe_list_id_or_type}/items/{recipe_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"recipe_list_id_or_type"}}`, encodeURIComponent(String(recipe_list_id_or_type)))
                .replace(`{${"recipe_id"}}`, encodeURIComponent(String(recipe_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(PutPatientRecipeListItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of recipes related to the search
         * @param {string} name 
         * @param {object | null} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeSearchRecipes: async (name: string, filters?: object | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('appApiRecipeSearchRecipes', 'name', name)
            const localVarPath = `/recipes/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecipesApi - functional programming interface
 * @export
 */
export const RecipesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecipesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a recipe list
         * @param {number} patient_id 
         * @param {string} recipe_list_id_or_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiRecipeDeletePatientRecipeList(patient_id: number, recipe_list_id_or_type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiRecipeDeletePatientRecipeList(patient_id, recipe_list_id_or_type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a recipe from a recipe list
         * @param {number} patient_id 
         * @param {string} recipe_list_id_or_type 
         * @param {number} recipe_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiRecipeDeletePatientRecipeListItem(patient_id: number, recipe_list_id_or_type: string, recipe_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiRecipeDeletePatientRecipeListItem(patient_id, recipe_list_id_or_type, recipe_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all recipes from a recipe list
         * @param {number} patient_id 
         * @param {string} recipe_list_id_or_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiRecipeGetAllPatientRecipeListItems(patient_id: number, recipe_list_id_or_type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientRecipeListItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiRecipeGetAllPatientRecipeListItems(patient_id, recipe_list_id_or_type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all recipe lists saved by a patient
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiRecipeGetAllPatientRecipeLists(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientRecipeListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiRecipeGetAllPatientRecipeLists(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a recipe list saved by a patient
         * @param {number} patient_id 
         * @param {string} recipe_list_id_or_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiRecipeGetPatientRecipeList(patient_id: number, recipe_list_id_or_type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientRecipeListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiRecipeGetPatientRecipeList(patient_id, recipe_list_id_or_type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of recipes suggestions for a patient
         * @param {number} patient_id 
         * @param {boolean} [force_update] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiRecipeGetPatientRecipeSuggestions(patient_id: number, force_update?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecipeSuggestionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiRecipeGetPatientRecipeSuggestions(patient_id, force_update, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the Edamam results for a patient\'s recipe suggestions
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiRecipeGetPatientRecipeSuggestionsDebugEdamamResults(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiRecipeGetPatientRecipeSuggestionsDebugEdamamResults(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the raw OpenAI results for a patient\'s recipe suggestions
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResults(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResults(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recipes instructions
         * @param {number} recipe_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiRecipeGetRecipeInstruction(recipe_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeInstructionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiRecipeGetRecipeInstruction(recipe_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a recipe list
         * @param {number} patient_id 
         * @param {CreatePatientRecipeListRequest} CreatePatientRecipeListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiRecipePostPatientRecipeList(patient_id: number, CreatePatientRecipeListRequest: CreatePatientRecipeListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientRecipeListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiRecipePostPatientRecipeList(patient_id, CreatePatientRecipeListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Email a recipe list to patient
         * @param {number} patient_id 
         * @param {number} clinician_id 
         * @param {string} recipe_list_id_or_type 
         * @param {Array<number>} [recipe_item_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiRecipePostPatientRecipeListEmail(patient_id: number, clinician_id: number, recipe_list_id_or_type: string, recipe_item_ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiRecipePostPatientRecipeListEmail(patient_id, clinician_id, recipe_list_id_or_type, recipe_item_ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a recipe to a recipe list
         * @param {number} patient_id 
         * @param {string} recipe_list_id_or_type 
         * @param {CreatePatientRecipeListItemRequest} CreatePatientRecipeListItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiRecipePostPatientRecipeListItem(patient_id: number, recipe_list_id_or_type: string, CreatePatientRecipeListItemRequest: CreatePatientRecipeListItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientRecipeListItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiRecipePostPatientRecipeListItem(patient_id, recipe_list_id_or_type, CreatePatientRecipeListItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create recipes
         * @param {CreateRecipeRequest} CreateRecipeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiRecipePostRecipe(CreateRecipeRequest: CreateRecipeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecipeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiRecipePostRecipe(CreateRecipeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a recipe list item from a recipe list
         * @param {number} patient_id 
         * @param {string} recipe_list_id_or_type 
         * @param {number} recipe_id 
         * @param {PutPatientRecipeListItemRequest} PutPatientRecipeListItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiRecipePutPatientRecipeListItem(patient_id: number, recipe_list_id_or_type: string, recipe_id: number, PutPatientRecipeListItemRequest: PutPatientRecipeListItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientRecipeListItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiRecipePutPatientRecipeListItem(patient_id, recipe_list_id_or_type, recipe_id, PutPatientRecipeListItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of recipes related to the search
         * @param {string} name 
         * @param {object | null} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiRecipeSearchRecipes(name: string, filters?: object | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecipeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiRecipeSearchRecipes(name, filters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecipesApi - factory interface
 * @export
 */
export const RecipesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecipesApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a recipe list
         * @param {RecipesApiAppApiRecipeDeletePatientRecipeListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeDeletePatientRecipeList(requestParameters: RecipesApiAppApiRecipeDeletePatientRecipeListRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiRecipeDeletePatientRecipeList(requestParameters.patient_id, requestParameters.recipe_list_id_or_type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a recipe from a recipe list
         * @param {RecipesApiAppApiRecipeDeletePatientRecipeListItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeDeletePatientRecipeListItem(requestParameters: RecipesApiAppApiRecipeDeletePatientRecipeListItemRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiRecipeDeletePatientRecipeListItem(requestParameters.patient_id, requestParameters.recipe_list_id_or_type, requestParameters.recipe_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all recipes from a recipe list
         * @param {RecipesApiAppApiRecipeGetAllPatientRecipeListItemsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeGetAllPatientRecipeListItems(requestParameters: RecipesApiAppApiRecipeGetAllPatientRecipeListItemsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PatientRecipeListItemResponse>> {
            return localVarFp.appApiRecipeGetAllPatientRecipeListItems(requestParameters.patient_id, requestParameters.recipe_list_id_or_type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all recipe lists saved by a patient
         * @param {RecipesApiAppApiRecipeGetAllPatientRecipeListsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeGetAllPatientRecipeLists(requestParameters: RecipesApiAppApiRecipeGetAllPatientRecipeListsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PatientRecipeListResponse>> {
            return localVarFp.appApiRecipeGetAllPatientRecipeLists(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a recipe list saved by a patient
         * @param {RecipesApiAppApiRecipeGetPatientRecipeListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeGetPatientRecipeList(requestParameters: RecipesApiAppApiRecipeGetPatientRecipeListRequest, options?: AxiosRequestConfig): AxiosPromise<PatientRecipeListResponse> {
            return localVarFp.appApiRecipeGetPatientRecipeList(requestParameters.patient_id, requestParameters.recipe_list_id_or_type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of recipes suggestions for a patient
         * @param {RecipesApiAppApiRecipeGetPatientRecipeSuggestionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeGetPatientRecipeSuggestions(requestParameters: RecipesApiAppApiRecipeGetPatientRecipeSuggestionsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<RecipeSuggestionResponse>> {
            return localVarFp.appApiRecipeGetPatientRecipeSuggestions(requestParameters.patient_id, requestParameters.force_update, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the Edamam results for a patient\'s recipe suggestions
         * @param {RecipesApiAppApiRecipeGetPatientRecipeSuggestionsDebugEdamamResultsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeGetPatientRecipeSuggestionsDebugEdamamResults(requestParameters: RecipesApiAppApiRecipeGetPatientRecipeSuggestionsDebugEdamamResultsRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiRecipeGetPatientRecipeSuggestionsDebugEdamamResults(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the raw OpenAI results for a patient\'s recipe suggestions
         * @param {RecipesApiAppApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResultsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResults(requestParameters: RecipesApiAppApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResultsRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResults(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recipes instructions
         * @param {RecipesApiAppApiRecipeGetRecipeInstructionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeGetRecipeInstruction(requestParameters: RecipesApiAppApiRecipeGetRecipeInstructionRequest, options?: AxiosRequestConfig): AxiosPromise<RecipeInstructionResponse> {
            return localVarFp.appApiRecipeGetRecipeInstruction(requestParameters.recipe_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a recipe list
         * @param {RecipesApiAppApiRecipePostPatientRecipeListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipePostPatientRecipeList(requestParameters: RecipesApiAppApiRecipePostPatientRecipeListRequest, options?: AxiosRequestConfig): AxiosPromise<PatientRecipeListResponse> {
            return localVarFp.appApiRecipePostPatientRecipeList(requestParameters.patient_id, requestParameters.CreatePatientRecipeListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Email a recipe list to patient
         * @param {RecipesApiAppApiRecipePostPatientRecipeListEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipePostPatientRecipeListEmail(requestParameters: RecipesApiAppApiRecipePostPatientRecipeListEmailRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiRecipePostPatientRecipeListEmail(requestParameters.patient_id, requestParameters.clinician_id, requestParameters.recipe_list_id_or_type, requestParameters.recipe_item_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a recipe to a recipe list
         * @param {RecipesApiAppApiRecipePostPatientRecipeListItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipePostPatientRecipeListItem(requestParameters: RecipesApiAppApiRecipePostPatientRecipeListItemRequest, options?: AxiosRequestConfig): AxiosPromise<PatientRecipeListItemResponse> {
            return localVarFp.appApiRecipePostPatientRecipeListItem(requestParameters.patient_id, requestParameters.recipe_list_id_or_type, requestParameters.CreatePatientRecipeListItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create recipes
         * @param {RecipesApiAppApiRecipePostRecipeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipePostRecipe(requestParameters: RecipesApiAppApiRecipePostRecipeRequest, options?: AxiosRequestConfig): AxiosPromise<Array<RecipeResponse>> {
            return localVarFp.appApiRecipePostRecipe(requestParameters.CreateRecipeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a recipe list item from a recipe list
         * @param {RecipesApiAppApiRecipePutPatientRecipeListItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipePutPatientRecipeListItem(requestParameters: RecipesApiAppApiRecipePutPatientRecipeListItemRequest, options?: AxiosRequestConfig): AxiosPromise<PatientRecipeListItemResponse> {
            return localVarFp.appApiRecipePutPatientRecipeListItem(requestParameters.patient_id, requestParameters.recipe_list_id_or_type, requestParameters.recipe_id, requestParameters.PutPatientRecipeListItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of recipes related to the search
         * @param {RecipesApiAppApiRecipeSearchRecipesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiRecipeSearchRecipes(requestParameters: RecipesApiAppApiRecipeSearchRecipesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<RecipeResponse>> {
            return localVarFp.appApiRecipeSearchRecipes(requestParameters.name, requestParameters.filters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiRecipeDeletePatientRecipeList operation in RecipesApi.
 * @export
 * @interface RecipesApiAppApiRecipeDeletePatientRecipeListRequest
 */
export interface RecipesApiAppApiRecipeDeletePatientRecipeListRequest {
    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipeDeletePatientRecipeList
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof RecipesApiAppApiRecipeDeletePatientRecipeList
     */
    readonly recipe_list_id_or_type: string
}

/**
 * Request parameters for appApiRecipeDeletePatientRecipeListItem operation in RecipesApi.
 * @export
 * @interface RecipesApiAppApiRecipeDeletePatientRecipeListItemRequest
 */
export interface RecipesApiAppApiRecipeDeletePatientRecipeListItemRequest {
    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipeDeletePatientRecipeListItem
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof RecipesApiAppApiRecipeDeletePatientRecipeListItem
     */
    readonly recipe_list_id_or_type: string

    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipeDeletePatientRecipeListItem
     */
    readonly recipe_id: number
}

/**
 * Request parameters for appApiRecipeGetAllPatientRecipeListItems operation in RecipesApi.
 * @export
 * @interface RecipesApiAppApiRecipeGetAllPatientRecipeListItemsRequest
 */
export interface RecipesApiAppApiRecipeGetAllPatientRecipeListItemsRequest {
    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipeGetAllPatientRecipeListItems
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof RecipesApiAppApiRecipeGetAllPatientRecipeListItems
     */
    readonly recipe_list_id_or_type: string
}

/**
 * Request parameters for appApiRecipeGetAllPatientRecipeLists operation in RecipesApi.
 * @export
 * @interface RecipesApiAppApiRecipeGetAllPatientRecipeListsRequest
 */
export interface RecipesApiAppApiRecipeGetAllPatientRecipeListsRequest {
    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipeGetAllPatientRecipeLists
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiRecipeGetPatientRecipeList operation in RecipesApi.
 * @export
 * @interface RecipesApiAppApiRecipeGetPatientRecipeListRequest
 */
export interface RecipesApiAppApiRecipeGetPatientRecipeListRequest {
    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipeGetPatientRecipeList
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof RecipesApiAppApiRecipeGetPatientRecipeList
     */
    readonly recipe_list_id_or_type: string
}

/**
 * Request parameters for appApiRecipeGetPatientRecipeSuggestions operation in RecipesApi.
 * @export
 * @interface RecipesApiAppApiRecipeGetPatientRecipeSuggestionsRequest
 */
export interface RecipesApiAppApiRecipeGetPatientRecipeSuggestionsRequest {
    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipeGetPatientRecipeSuggestions
     */
    readonly patient_id: number

    /**
     * 
     * @type {boolean}
     * @memberof RecipesApiAppApiRecipeGetPatientRecipeSuggestions
     */
    readonly force_update?: boolean
}

/**
 * Request parameters for appApiRecipeGetPatientRecipeSuggestionsDebugEdamamResults operation in RecipesApi.
 * @export
 * @interface RecipesApiAppApiRecipeGetPatientRecipeSuggestionsDebugEdamamResultsRequest
 */
export interface RecipesApiAppApiRecipeGetPatientRecipeSuggestionsDebugEdamamResultsRequest {
    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipeGetPatientRecipeSuggestionsDebugEdamamResults
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResults operation in RecipesApi.
 * @export
 * @interface RecipesApiAppApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResultsRequest
 */
export interface RecipesApiAppApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResultsRequest {
    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResults
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiRecipeGetRecipeInstruction operation in RecipesApi.
 * @export
 * @interface RecipesApiAppApiRecipeGetRecipeInstructionRequest
 */
export interface RecipesApiAppApiRecipeGetRecipeInstructionRequest {
    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipeGetRecipeInstruction
     */
    readonly recipe_id: number
}

/**
 * Request parameters for appApiRecipePostPatientRecipeList operation in RecipesApi.
 * @export
 * @interface RecipesApiAppApiRecipePostPatientRecipeListRequest
 */
export interface RecipesApiAppApiRecipePostPatientRecipeListRequest {
    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipePostPatientRecipeList
     */
    readonly patient_id: number

    /**
     * 
     * @type {CreatePatientRecipeListRequest}
     * @memberof RecipesApiAppApiRecipePostPatientRecipeList
     */
    readonly CreatePatientRecipeListRequest: CreatePatientRecipeListRequest
}

/**
 * Request parameters for appApiRecipePostPatientRecipeListEmail operation in RecipesApi.
 * @export
 * @interface RecipesApiAppApiRecipePostPatientRecipeListEmailRequest
 */
export interface RecipesApiAppApiRecipePostPatientRecipeListEmailRequest {
    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipePostPatientRecipeListEmail
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipePostPatientRecipeListEmail
     */
    readonly clinician_id: number

    /**
     * 
     * @type {string}
     * @memberof RecipesApiAppApiRecipePostPatientRecipeListEmail
     */
    readonly recipe_list_id_or_type: string

    /**
     * 
     * @type {Array<number>}
     * @memberof RecipesApiAppApiRecipePostPatientRecipeListEmail
     */
    readonly recipe_item_ids?: Array<number>
}

/**
 * Request parameters for appApiRecipePostPatientRecipeListItem operation in RecipesApi.
 * @export
 * @interface RecipesApiAppApiRecipePostPatientRecipeListItemRequest
 */
export interface RecipesApiAppApiRecipePostPatientRecipeListItemRequest {
    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipePostPatientRecipeListItem
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof RecipesApiAppApiRecipePostPatientRecipeListItem
     */
    readonly recipe_list_id_or_type: string

    /**
     * 
     * @type {CreatePatientRecipeListItemRequest}
     * @memberof RecipesApiAppApiRecipePostPatientRecipeListItem
     */
    readonly CreatePatientRecipeListItemRequest: CreatePatientRecipeListItemRequest
}

/**
 * Request parameters for appApiRecipePostRecipe operation in RecipesApi.
 * @export
 * @interface RecipesApiAppApiRecipePostRecipeRequest
 */
export interface RecipesApiAppApiRecipePostRecipeRequest {
    /**
     * 
     * @type {CreateRecipeRequest}
     * @memberof RecipesApiAppApiRecipePostRecipe
     */
    readonly CreateRecipeRequest: CreateRecipeRequest
}

/**
 * Request parameters for appApiRecipePutPatientRecipeListItem operation in RecipesApi.
 * @export
 * @interface RecipesApiAppApiRecipePutPatientRecipeListItemRequest
 */
export interface RecipesApiAppApiRecipePutPatientRecipeListItemRequest {
    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipePutPatientRecipeListItem
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof RecipesApiAppApiRecipePutPatientRecipeListItem
     */
    readonly recipe_list_id_or_type: string

    /**
     * 
     * @type {number}
     * @memberof RecipesApiAppApiRecipePutPatientRecipeListItem
     */
    readonly recipe_id: number

    /**
     * 
     * @type {PutPatientRecipeListItemRequest}
     * @memberof RecipesApiAppApiRecipePutPatientRecipeListItem
     */
    readonly PutPatientRecipeListItemRequest: PutPatientRecipeListItemRequest
}

/**
 * Request parameters for appApiRecipeSearchRecipes operation in RecipesApi.
 * @export
 * @interface RecipesApiAppApiRecipeSearchRecipesRequest
 */
export interface RecipesApiAppApiRecipeSearchRecipesRequest {
    /**
     * 
     * @type {string}
     * @memberof RecipesApiAppApiRecipeSearchRecipes
     */
    readonly name: string

    /**
     * 
     * @type {object}
     * @memberof RecipesApiAppApiRecipeSearchRecipes
     */
    readonly filters?: object | null
}

/**
 * RecipesApi - object-oriented interface
 * @export
 * @class RecipesApi
 * @extends {BaseAPI}
 */
export class RecipesApi extends BaseAPI {
    /**
     * 
     * @summary Delete a recipe list
     * @param {RecipesApiAppApiRecipeDeletePatientRecipeListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipesApi
     */
    public appApiRecipeDeletePatientRecipeList(requestParameters: RecipesApiAppApiRecipeDeletePatientRecipeListRequest, options?: AxiosRequestConfig) {
        return RecipesApiFp(this.configuration).appApiRecipeDeletePatientRecipeList(requestParameters.patient_id, requestParameters.recipe_list_id_or_type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a recipe from a recipe list
     * @param {RecipesApiAppApiRecipeDeletePatientRecipeListItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipesApi
     */
    public appApiRecipeDeletePatientRecipeListItem(requestParameters: RecipesApiAppApiRecipeDeletePatientRecipeListItemRequest, options?: AxiosRequestConfig) {
        return RecipesApiFp(this.configuration).appApiRecipeDeletePatientRecipeListItem(requestParameters.patient_id, requestParameters.recipe_list_id_or_type, requestParameters.recipe_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all recipes from a recipe list
     * @param {RecipesApiAppApiRecipeGetAllPatientRecipeListItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipesApi
     */
    public appApiRecipeGetAllPatientRecipeListItems(requestParameters: RecipesApiAppApiRecipeGetAllPatientRecipeListItemsRequest, options?: AxiosRequestConfig) {
        return RecipesApiFp(this.configuration).appApiRecipeGetAllPatientRecipeListItems(requestParameters.patient_id, requestParameters.recipe_list_id_or_type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all recipe lists saved by a patient
     * @param {RecipesApiAppApiRecipeGetAllPatientRecipeListsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipesApi
     */
    public appApiRecipeGetAllPatientRecipeLists(requestParameters: RecipesApiAppApiRecipeGetAllPatientRecipeListsRequest, options?: AxiosRequestConfig) {
        return RecipesApiFp(this.configuration).appApiRecipeGetAllPatientRecipeLists(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a recipe list saved by a patient
     * @param {RecipesApiAppApiRecipeGetPatientRecipeListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipesApi
     */
    public appApiRecipeGetPatientRecipeList(requestParameters: RecipesApiAppApiRecipeGetPatientRecipeListRequest, options?: AxiosRequestConfig) {
        return RecipesApiFp(this.configuration).appApiRecipeGetPatientRecipeList(requestParameters.patient_id, requestParameters.recipe_list_id_or_type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of recipes suggestions for a patient
     * @param {RecipesApiAppApiRecipeGetPatientRecipeSuggestionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipesApi
     */
    public appApiRecipeGetPatientRecipeSuggestions(requestParameters: RecipesApiAppApiRecipeGetPatientRecipeSuggestionsRequest, options?: AxiosRequestConfig) {
        return RecipesApiFp(this.configuration).appApiRecipeGetPatientRecipeSuggestions(requestParameters.patient_id, requestParameters.force_update, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the Edamam results for a patient\'s recipe suggestions
     * @param {RecipesApiAppApiRecipeGetPatientRecipeSuggestionsDebugEdamamResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipesApi
     */
    public appApiRecipeGetPatientRecipeSuggestionsDebugEdamamResults(requestParameters: RecipesApiAppApiRecipeGetPatientRecipeSuggestionsDebugEdamamResultsRequest, options?: AxiosRequestConfig) {
        return RecipesApiFp(this.configuration).appApiRecipeGetPatientRecipeSuggestionsDebugEdamamResults(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the raw OpenAI results for a patient\'s recipe suggestions
     * @param {RecipesApiAppApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipesApi
     */
    public appApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResults(requestParameters: RecipesApiAppApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResultsRequest, options?: AxiosRequestConfig) {
        return RecipesApiFp(this.configuration).appApiRecipeGetPatientRecipeSuggestionsDebugOpenaiResults(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recipes instructions
     * @param {RecipesApiAppApiRecipeGetRecipeInstructionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipesApi
     */
    public appApiRecipeGetRecipeInstruction(requestParameters: RecipesApiAppApiRecipeGetRecipeInstructionRequest, options?: AxiosRequestConfig) {
        return RecipesApiFp(this.configuration).appApiRecipeGetRecipeInstruction(requestParameters.recipe_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a recipe list
     * @param {RecipesApiAppApiRecipePostPatientRecipeListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipesApi
     */
    public appApiRecipePostPatientRecipeList(requestParameters: RecipesApiAppApiRecipePostPatientRecipeListRequest, options?: AxiosRequestConfig) {
        return RecipesApiFp(this.configuration).appApiRecipePostPatientRecipeList(requestParameters.patient_id, requestParameters.CreatePatientRecipeListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Email a recipe list to patient
     * @param {RecipesApiAppApiRecipePostPatientRecipeListEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipesApi
     */
    public appApiRecipePostPatientRecipeListEmail(requestParameters: RecipesApiAppApiRecipePostPatientRecipeListEmailRequest, options?: AxiosRequestConfig) {
        return RecipesApiFp(this.configuration).appApiRecipePostPatientRecipeListEmail(requestParameters.patient_id, requestParameters.clinician_id, requestParameters.recipe_list_id_or_type, requestParameters.recipe_item_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a recipe to a recipe list
     * @param {RecipesApiAppApiRecipePostPatientRecipeListItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipesApi
     */
    public appApiRecipePostPatientRecipeListItem(requestParameters: RecipesApiAppApiRecipePostPatientRecipeListItemRequest, options?: AxiosRequestConfig) {
        return RecipesApiFp(this.configuration).appApiRecipePostPatientRecipeListItem(requestParameters.patient_id, requestParameters.recipe_list_id_or_type, requestParameters.CreatePatientRecipeListItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create recipes
     * @param {RecipesApiAppApiRecipePostRecipeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipesApi
     */
    public appApiRecipePostRecipe(requestParameters: RecipesApiAppApiRecipePostRecipeRequest, options?: AxiosRequestConfig) {
        return RecipesApiFp(this.configuration).appApiRecipePostRecipe(requestParameters.CreateRecipeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a recipe list item from a recipe list
     * @param {RecipesApiAppApiRecipePutPatientRecipeListItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipesApi
     */
    public appApiRecipePutPatientRecipeListItem(requestParameters: RecipesApiAppApiRecipePutPatientRecipeListItemRequest, options?: AxiosRequestConfig) {
        return RecipesApiFp(this.configuration).appApiRecipePutPatientRecipeListItem(requestParameters.patient_id, requestParameters.recipe_list_id_or_type, requestParameters.recipe_id, requestParameters.PutPatientRecipeListItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of recipes related to the search
     * @param {RecipesApiAppApiRecipeSearchRecipesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipesApi
     */
    public appApiRecipeSearchRecipes(requestParameters: RecipesApiAppApiRecipeSearchRecipesRequest, options?: AxiosRequestConfig) {
        return RecipesApiFp(this.configuration).appApiRecipeSearchRecipes(requestParameters.name, requestParameters.filters, options).then((request) => request(this.axios, this.basePath));
    }
}

