/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppApiPatientActivityFeedGetPatientActivityFeed200Response } from '../models';
// @ts-ignore
import { ClinicResponse } from '../models';
// @ts-ignore
import { ClinicianResponse } from '../models';
// @ts-ignore
import { CreatePatientFromClinicianRequest } from '../models';
// @ts-ignore
import { CreatePatientNoteRequest } from '../models';
// @ts-ignore
import { DailyReportResponse } from '../models';
// @ts-ignore
import { EmailHistoryResponse } from '../models';
// @ts-ignore
import { MealDateRangeResponse } from '../models';
// @ts-ignore
import { PatchPatientRequest } from '../models';
// @ts-ignore
import { PatientCioAttributesResponse } from '../models';
// @ts-ignore
import { PatientCioSubscriptionPreferencesResponse } from '../models';
// @ts-ignore
import { PatientJoinClinicByInvitationCodeResponse } from '../models';
// @ts-ignore
import { PatientNoteQueueResponse } from '../models';
// @ts-ignore
import { PatientNoteResponse } from '../models';
// @ts-ignore
import { PatientRdiResponse } from '../models';
// @ts-ignore
import { PatientResponse } from '../models';
// @ts-ignore
import { RdiResponse } from '../models';
/**
 * PatientApi - axios parameter creator
 * @export
 */
export const PatientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets an ApiTable response of a patient\'s activity feed
         * @param {number} patient_id 
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [before] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientActivityFeedGetPatientActivityFeed: async (patient_id: number, filter?: string, limit?: number, before?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientActivityFeedGetPatientActivityFeed', 'patient_id', patient_id)
            const localVarPath = `/patient/{patient_id}/activity_feed`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = (before as any instanceof Date) ?
                    (before as any).toISOString().substring(0,10) :
                    before;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of clinicians this patient belongs to
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetClinicians: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientGetClinicians', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/clinicians`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of clinics this patient belongs to
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetClinics: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientGetClinics', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/clinics`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get currently logged in patient info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetCurrentPatient: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Mixpanel tags for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetCurrentPatientMixpanelTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/current/mixpanel-tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patient info
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetPatient: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientGetPatient', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customerio attributes patient. See also: https://customer.io/docs/api/app/#operation/getPersonAttributes
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetPatientCioAttributes: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientGetPatientCioAttributes', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/cio/attributes`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customerio subscription preferences for the patient. See also: https://customer.io/docs/api/app/#operation/getPersonSubscriptionPreferences
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetPatientCioSubscriptionPreferences: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientGetPatientCioSubscriptionPreferences', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/cio/subscription_preferences`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get currently logged in patient info
         * @param {number} patient_id patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetPatientInvitationHistory: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientGetPatientInvitationHistory', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/invitation_history`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get latest patient note
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNoteGetPatientNote: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientNoteGetPatientNote', 'patient_id', patient_id)
            const localVarPath = `/patient/{patient_id}/patient_note`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patient note by id
         * @param {number} patient_id 
         * @param {number} patient_note_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNoteGetPatientNoteById: async (patient_id: number, patient_note_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientNoteGetPatientNoteById', 'patient_id', patient_id)
            // verify required parameter 'patient_note_id' is not null or undefined
            assertParamExists('appApiPatientNoteGetPatientNoteById', 'patient_note_id', patient_note_id)
            const localVarPath = `/patient/{patient_id}/patient_note/{patient_note_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"patient_note_id"}}`, encodeURIComponent(String(patient_note_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get latest patient note for clinician
         * @param {number} patient_id 
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNoteGetPatientNoteForClinician: async (patient_id: number, clinician_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientNoteGetPatientNoteForClinician', 'patient_id', patient_id)
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiPatientNoteGetPatientNoteForClinician', 'clinician_id', clinician_id)
            const localVarPath = `/patient/{patient_id}/clinician/{clinician_id}/patient_note`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"clinician_id"}}`, encodeURIComponent(String(clinician_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get most recent patient note
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNoteGetPatientNoteQueue: async (data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiPatientNoteGetPatientNoteQueue', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiPatientNoteGetPatientNoteQueue', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiPatientNoteGetPatientNoteQueue', 'meal_photo_queue_id', meal_photo_queue_id)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/patient_note`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Summary of All Notes
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNoteGetPatientNotes: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientNoteGetPatientNotes', 'patient_id', patient_id)
            const localVarPath = `/patient/{patient_id}/patient_notes`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post patient note
         * @param {number} patient_id 
         * @param {CreatePatientNoteRequest} CreatePatientNoteRequest Updated version of the patient note history.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNotePostPatientNote: async (patient_id: number, CreatePatientNoteRequest: CreatePatientNoteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientNotePostPatientNote', 'patient_id', patient_id)
            // verify required parameter 'CreatePatientNoteRequest' is not null or undefined
            assertParamExists('appApiPatientNotePostPatientNote', 'CreatePatientNoteRequest', CreatePatientNoteRequest)
            const localVarPath = `/patient/{patient_id}/patient_note`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreatePatientNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post updated patient note
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {CreatePatientNoteRequest} CreatePatientNoteRequest Updated version of the patient note history.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNotePostPatientNoteQueue: async (data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, CreatePatientNoteRequest: CreatePatientNoteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data_reviewer_group_id' is not null or undefined
            assertParamExists('appApiPatientNotePostPatientNoteQueue', 'data_reviewer_group_id', data_reviewer_group_id)
            // verify required parameter 'data_reviewer_id' is not null or undefined
            assertParamExists('appApiPatientNotePostPatientNoteQueue', 'data_reviewer_id', data_reviewer_id)
            // verify required parameter 'meal_photo_queue_id' is not null or undefined
            assertParamExists('appApiPatientNotePostPatientNoteQueue', 'meal_photo_queue_id', meal_photo_queue_id)
            // verify required parameter 'CreatePatientNoteRequest' is not null or undefined
            assertParamExists('appApiPatientNotePostPatientNoteQueue', 'CreatePatientNoteRequest', CreatePatientNoteRequest)
            const localVarPath = `/data_reviewer_group/{data_reviewer_group_id}/data_reviewer/{data_reviewer_id}/meal_photo_queue/{meal_photo_queue_id}/patient_note`
                .replace(`{${"data_reviewer_group_id"}}`, encodeURIComponent(String(data_reviewer_group_id)))
                .replace(`{${"data_reviewer_id"}}`, encodeURIComponent(String(data_reviewer_id)))
                .replace(`{${"meal_photo_queue_id"}}`, encodeURIComponent(String(meal_photo_queue_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreatePatientNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update patient note for clinician
         * @param {number} patient_id 
         * @param {number} clinician_id 
         * @param {CreatePatientNoteRequest} CreatePatientNoteRequest Updated version of the patient note history.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNotePutPatientNoteForClinician: async (patient_id: number, clinician_id: number, CreatePatientNoteRequest: CreatePatientNoteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientNotePutPatientNoteForClinician', 'patient_id', patient_id)
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiPatientNotePutPatientNoteForClinician', 'clinician_id', clinician_id)
            // verify required parameter 'CreatePatientNoteRequest' is not null or undefined
            assertParamExists('appApiPatientNotePutPatientNoteForClinician', 'CreatePatientNoteRequest', CreatePatientNoteRequest)
            const localVarPath = `/patient/{patient_id}/clinician/{clinician_id}/patient_note`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"clinician_id"}}`, encodeURIComponent(String(clinician_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreatePatientNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit information of the patient. This endpoint edits the provided field in the body. That is, if the first_name is null in the request body, the first_name in the system remains the same (as opposed to write to null).
         * @param {number} patient_id 
         * @param {PatchPatientRequest} PatchPatientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientPatchPatient: async (patient_id: number, PatchPatientRequest: PatchPatientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientPatchPatient', 'patient_id', patient_id)
            // verify required parameter 'PatchPatientRequest' is not null or undefined
            assertParamExists('appApiPatientPatchPatient', 'PatchPatientRequest', PatchPatientRequest)
            const localVarPath = `/patients/{patient_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(PatchPatientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new patient, supposed to be used from a clinician
         * @param {CreatePatientFromClinicianRequest} CreatePatientFromClinicianRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientPostFromClinician: async (CreatePatientFromClinicianRequest: CreatePatientFromClinicianRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreatePatientFromClinicianRequest' is not null or undefined
            assertParamExists('appApiPatientPostFromClinician', 'CreatePatientFromClinicianRequest', CreatePatientFromClinicianRequest)
            const localVarPath = `/patients/from_clinician`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreatePatientFromClinicianRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Join clinic by invitation code
         * @param {number} patient_id 
         * @param {string} invitation_code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientPostPatientJoinClinicByInvitationCode: async (patient_id: number, invitation_code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientPostPatientJoinClinicByInvitationCode', 'patient_id', patient_id)
            // verify required parameter 'invitation_code' is not null or undefined
            assertParamExists('appApiPatientPostPatientJoinClinicByInvitationCode', 'invitation_code', invitation_code)
            const localVarPath = `/patients/{patient_id}/join-clinic/by-invitation-code`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (invitation_code !== undefined) {
                localVarQueryParameter['invitation_code'] = invitation_code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate daily usage report data for a patient
         * @param {number} patient_id 
         * @param {string} [report_date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReport: async (patient_id: number, report_date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReport', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/daily_usage_report`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (report_date !== undefined) {
                localVarQueryParameter['report_date'] = report_date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all logged patients
         * @param {string} tracking_reference_date filter results such that only those tracking_starts greater than / equal to this date are included
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientUsageSummaryGetAllPatientsMetrics: async (tracking_reference_date: string, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tracking_reference_date' is not null or undefined
            assertParamExists('appApiPatientReportsPatientUsageSummaryGetAllPatientsMetrics', 'tracking_reference_date', tracking_reference_date)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsPatientUsageSummaryGetAllPatientsMetrics', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsPatientUsageSummaryGetAllPatientsMetrics', 'date_until', date_until)
            const localVarPath = `/patients/all/usage_summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tracking_reference_date !== undefined) {
                localVarQueryParameter['tracking_reference_date'] = tracking_reference_date;
            }

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all logged patients
         * @param {number} days_since_tracking_started 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuick: async (days_since_tracking_started: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'days_since_tracking_started' is not null or undefined
            assertParamExists('appApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuick', 'days_since_tracking_started', days_since_tracking_started)
            const localVarPath = `/patients/all/usage_summary_quick`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (days_since_tracking_started !== undefined) {
                localVarQueryParameter['days_since_tracking_started'] = days_since_tracking_started;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of rdis for the patient
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} [use_insufficient_meal_filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReferenceDailyIntakeGetPatientRdi: async (patient_id: number, date_since: string, date_until: string, use_insufficient_meal_filter?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiReferenceDailyIntakeGetPatientRdi', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiReferenceDailyIntakeGetPatientRdi', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiReferenceDailyIntakeGetPatientRdi', 'date_until', date_until)
            const localVarPath = `/patients/{patient_id}/patient_rdi`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (use_insufficient_meal_filter !== undefined) {
                localVarQueryParameter['use_insufficient_meal_filter'] = use_insufficient_meal_filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of rdis for the patient
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReferenceDailyIntakeGetRdi: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiReferenceDailyIntakeGetRdi', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/rdi`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of rdis for the patient
         * @param {number} patient_id 
         * @param {string} [reference_date] 
         * @param {string} [period_name] 
         * @param {number} [target_n_dates] 
         * @param {number} [max_window_size] maximum number of days since the reference date from which to draw the distinct dates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiGetPatientMealDatePeriodFilters: async (patient_id: number, reference_date?: string, period_name?: string, target_n_dates?: number, max_window_size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiGetPatientMealDatePeriodFilters', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/meal_date_period_filters`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reference_date !== undefined) {
                localVarQueryParameter['reference_date'] = reference_date;
            }

            if (period_name !== undefined) {
                localVarQueryParameter['period_name'] = period_name;
            }

            if (target_n_dates !== undefined) {
                localVarQueryParameter['target_n_dates'] = target_n_dates;
            }

            if (max_window_size !== undefined) {
                localVarQueryParameter['max_window_size'] = max_window_size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientApi - functional programming interface
 * @export
 */
export const PatientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets an ApiTable response of a patient\'s activity feed
         * @param {number} patient_id 
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [before] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientActivityFeedGetPatientActivityFeed(patient_id: number, filter?: string, limit?: number, before?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppApiPatientActivityFeedGetPatientActivityFeed200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientActivityFeedGetPatientActivityFeed(patient_id, filter, limit, before, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of clinicians this patient belongs to
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientGetClinicians(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClinicianResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientGetClinicians(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of clinics this patient belongs to
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientGetClinics(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClinicResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientGetClinics(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get currently logged in patient info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientGetCurrentPatient(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientGetCurrentPatient(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Mixpanel tags for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientGetCurrentPatientMixpanelTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientGetCurrentPatientMixpanelTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patient info
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientGetPatient(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientGetPatient(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customerio attributes patient. See also: https://customer.io/docs/api/app/#operation/getPersonAttributes
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientGetPatientCioAttributes(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientCioAttributesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientGetPatientCioAttributes(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customerio subscription preferences for the patient. See also: https://customer.io/docs/api/app/#operation/getPersonSubscriptionPreferences
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientGetPatientCioSubscriptionPreferences(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientCioSubscriptionPreferencesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientGetPatientCioSubscriptionPreferences(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get currently logged in patient info
         * @param {number} patient_id patient id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientGetPatientInvitationHistory(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmailHistoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientGetPatientInvitationHistory(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get latest patient note
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientNoteGetPatientNote(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientNoteGetPatientNote(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patient note by id
         * @param {number} patient_id 
         * @param {number} patient_note_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientNoteGetPatientNoteById(patient_id: number, patient_note_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientNoteGetPatientNoteById(patient_id, patient_note_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get latest patient note for clinician
         * @param {number} patient_id 
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientNoteGetPatientNoteForClinician(patient_id: number, clinician_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientNoteGetPatientNoteForClinician(patient_id, clinician_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get most recent patient note
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientNoteGetPatientNoteQueue(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientNoteQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientNoteGetPatientNoteQueue(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Summary of All Notes
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientNoteGetPatientNotes(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientNoteResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientNoteGetPatientNotes(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post patient note
         * @param {number} patient_id 
         * @param {CreatePatientNoteRequest} CreatePatientNoteRequest Updated version of the patient note history.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientNotePostPatientNote(patient_id: number, CreatePatientNoteRequest: CreatePatientNoteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientNotePostPatientNote(patient_id, CreatePatientNoteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post updated patient note
         * @param {number} data_reviewer_group_id 
         * @param {number} data_reviewer_id 
         * @param {number} meal_photo_queue_id 
         * @param {CreatePatientNoteRequest} CreatePatientNoteRequest Updated version of the patient note history.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientNotePostPatientNoteQueue(data_reviewer_group_id: number, data_reviewer_id: number, meal_photo_queue_id: number, CreatePatientNoteRequest: CreatePatientNoteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientNoteQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientNotePostPatientNoteQueue(data_reviewer_group_id, data_reviewer_id, meal_photo_queue_id, CreatePatientNoteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update patient note for clinician
         * @param {number} patient_id 
         * @param {number} clinician_id 
         * @param {CreatePatientNoteRequest} CreatePatientNoteRequest Updated version of the patient note history.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientNotePutPatientNoteForClinician(patient_id: number, clinician_id: number, CreatePatientNoteRequest: CreatePatientNoteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientNotePutPatientNoteForClinician(patient_id, clinician_id, CreatePatientNoteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit information of the patient. This endpoint edits the provided field in the body. That is, if the first_name is null in the request body, the first_name in the system remains the same (as opposed to write to null).
         * @param {number} patient_id 
         * @param {PatchPatientRequest} PatchPatientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientPatchPatient(patient_id: number, PatchPatientRequest: PatchPatientRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientPatchPatient(patient_id, PatchPatientRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new patient, supposed to be used from a clinician
         * @param {CreatePatientFromClinicianRequest} CreatePatientFromClinicianRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientPostFromClinician(CreatePatientFromClinicianRequest: CreatePatientFromClinicianRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientPostFromClinician(CreatePatientFromClinicianRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Join clinic by invitation code
         * @param {number} patient_id 
         * @param {string} invitation_code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientPostPatientJoinClinicByInvitationCode(patient_id: number, invitation_code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientJoinClinicByInvitationCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientPostPatientJoinClinicByInvitationCode(patient_id, invitation_code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate daily usage report data for a patient
         * @param {number} patient_id 
         * @param {string} [report_date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReport(patient_id: number, report_date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DailyReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReport(patient_id, report_date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all logged patients
         * @param {string} tracking_reference_date filter results such that only those tracking_starts greater than / equal to this date are included
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientUsageSummaryGetAllPatientsMetrics(tracking_reference_date: string, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientUsageSummaryGetAllPatientsMetrics(tracking_reference_date, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all logged patients
         * @param {number} days_since_tracking_started 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuick(days_since_tracking_started: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuick(days_since_tracking_started, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of rdis for the patient
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} [use_insufficient_meal_filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReferenceDailyIntakeGetPatientRdi(patient_id: number, date_since: string, date_until: string, use_insufficient_meal_filter?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientRdiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReferenceDailyIntakeGetPatientRdi(patient_id, date_since, date_until, use_insufficient_meal_filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of rdis for the patient
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiReferenceDailyIntakeGetRdi(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RdiResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiReferenceDailyIntakeGetRdi(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of rdis for the patient
         * @param {number} patient_id 
         * @param {string} [reference_date] 
         * @param {string} [period_name] 
         * @param {number} [target_n_dates] 
         * @param {number} [max_window_size] maximum number of days since the reference date from which to draw the distinct dates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionApiGetPatientMealDatePeriodFilters(patient_id: number, reference_date?: string, period_name?: string, target_n_dates?: number, max_window_size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealDateRangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionApiGetPatientMealDatePeriodFilters(patient_id, reference_date, period_name, target_n_dates, max_window_size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientApi - factory interface
 * @export
 */
export const PatientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets an ApiTable response of a patient\'s activity feed
         * @param {PatientApiAppApiPatientActivityFeedGetPatientActivityFeedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientActivityFeedGetPatientActivityFeed(requestParameters: PatientApiAppApiPatientActivityFeedGetPatientActivityFeedRequest, options?: AxiosRequestConfig): AxiosPromise<AppApiPatientActivityFeedGetPatientActivityFeed200Response> {
            return localVarFp.appApiPatientActivityFeedGetPatientActivityFeed(requestParameters.patient_id, requestParameters.filter, requestParameters.limit, requestParameters.before, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of clinicians this patient belongs to
         * @param {PatientApiAppApiPatientGetCliniciansRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetClinicians(requestParameters: PatientApiAppApiPatientGetCliniciansRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ClinicianResponse>> {
            return localVarFp.appApiPatientGetClinicians(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of clinics this patient belongs to
         * @param {PatientApiAppApiPatientGetClinicsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetClinics(requestParameters: PatientApiAppApiPatientGetClinicsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ClinicResponse>> {
            return localVarFp.appApiPatientGetClinics(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get currently logged in patient info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetCurrentPatient(options?: AxiosRequestConfig): AxiosPromise<PatientResponse> {
            return localVarFp.appApiPatientGetCurrentPatient(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Mixpanel tags for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetCurrentPatientMixpanelTags(options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.appApiPatientGetCurrentPatientMixpanelTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patient info
         * @param {PatientApiAppApiPatientGetPatientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetPatient(requestParameters: PatientApiAppApiPatientGetPatientRequest, options?: AxiosRequestConfig): AxiosPromise<PatientResponse> {
            return localVarFp.appApiPatientGetPatient(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customerio attributes patient. See also: https://customer.io/docs/api/app/#operation/getPersonAttributes
         * @param {PatientApiAppApiPatientGetPatientCioAttributesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetPatientCioAttributes(requestParameters: PatientApiAppApiPatientGetPatientCioAttributesRequest, options?: AxiosRequestConfig): AxiosPromise<PatientCioAttributesResponse> {
            return localVarFp.appApiPatientGetPatientCioAttributes(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customerio subscription preferences for the patient. See also: https://customer.io/docs/api/app/#operation/getPersonSubscriptionPreferences
         * @param {PatientApiAppApiPatientGetPatientCioSubscriptionPreferencesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetPatientCioSubscriptionPreferences(requestParameters: PatientApiAppApiPatientGetPatientCioSubscriptionPreferencesRequest, options?: AxiosRequestConfig): AxiosPromise<PatientCioSubscriptionPreferencesResponse> {
            return localVarFp.appApiPatientGetPatientCioSubscriptionPreferences(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get currently logged in patient info
         * @param {PatientApiAppApiPatientGetPatientInvitationHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientGetPatientInvitationHistory(requestParameters: PatientApiAppApiPatientGetPatientInvitationHistoryRequest, options?: AxiosRequestConfig): AxiosPromise<Array<EmailHistoryResponse>> {
            return localVarFp.appApiPatientGetPatientInvitationHistory(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get latest patient note
         * @param {PatientApiAppApiPatientNoteGetPatientNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNoteGetPatientNote(requestParameters: PatientApiAppApiPatientNoteGetPatientNoteRequest, options?: AxiosRequestConfig): AxiosPromise<PatientNoteResponse> {
            return localVarFp.appApiPatientNoteGetPatientNote(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patient note by id
         * @param {PatientApiAppApiPatientNoteGetPatientNoteByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNoteGetPatientNoteById(requestParameters: PatientApiAppApiPatientNoteGetPatientNoteByIdRequest, options?: AxiosRequestConfig): AxiosPromise<PatientNoteResponse> {
            return localVarFp.appApiPatientNoteGetPatientNoteById(requestParameters.patient_id, requestParameters.patient_note_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get latest patient note for clinician
         * @param {PatientApiAppApiPatientNoteGetPatientNoteForClinicianRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNoteGetPatientNoteForClinician(requestParameters: PatientApiAppApiPatientNoteGetPatientNoteForClinicianRequest, options?: AxiosRequestConfig): AxiosPromise<PatientNoteResponse> {
            return localVarFp.appApiPatientNoteGetPatientNoteForClinician(requestParameters.patient_id, requestParameters.clinician_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get most recent patient note
         * @param {PatientApiAppApiPatientNoteGetPatientNoteQueueRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNoteGetPatientNoteQueue(requestParameters: PatientApiAppApiPatientNoteGetPatientNoteQueueRequest, options?: AxiosRequestConfig): AxiosPromise<PatientNoteQueueResponse> {
            return localVarFp.appApiPatientNoteGetPatientNoteQueue(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Summary of All Notes
         * @param {PatientApiAppApiPatientNoteGetPatientNotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNoteGetPatientNotes(requestParameters: PatientApiAppApiPatientNoteGetPatientNotesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PatientNoteResponse>> {
            return localVarFp.appApiPatientNoteGetPatientNotes(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post patient note
         * @param {PatientApiAppApiPatientNotePostPatientNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNotePostPatientNote(requestParameters: PatientApiAppApiPatientNotePostPatientNoteRequest, options?: AxiosRequestConfig): AxiosPromise<PatientNoteResponse> {
            return localVarFp.appApiPatientNotePostPatientNote(requestParameters.patient_id, requestParameters.CreatePatientNoteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post updated patient note
         * @param {PatientApiAppApiPatientNotePostPatientNoteQueueRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNotePostPatientNoteQueue(requestParameters: PatientApiAppApiPatientNotePostPatientNoteQueueRequest, options?: AxiosRequestConfig): AxiosPromise<PatientNoteQueueResponse> {
            return localVarFp.appApiPatientNotePostPatientNoteQueue(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, requestParameters.CreatePatientNoteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update patient note for clinician
         * @param {PatientApiAppApiPatientNotePutPatientNoteForClinicianRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNotePutPatientNoteForClinician(requestParameters: PatientApiAppApiPatientNotePutPatientNoteForClinicianRequest, options?: AxiosRequestConfig): AxiosPromise<PatientNoteResponse> {
            return localVarFp.appApiPatientNotePutPatientNoteForClinician(requestParameters.patient_id, requestParameters.clinician_id, requestParameters.CreatePatientNoteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit information of the patient. This endpoint edits the provided field in the body. That is, if the first_name is null in the request body, the first_name in the system remains the same (as opposed to write to null).
         * @param {PatientApiAppApiPatientPatchPatientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientPatchPatient(requestParameters: PatientApiAppApiPatientPatchPatientRequest, options?: AxiosRequestConfig): AxiosPromise<PatientResponse> {
            return localVarFp.appApiPatientPatchPatient(requestParameters.patient_id, requestParameters.PatchPatientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new patient, supposed to be used from a clinician
         * @param {PatientApiAppApiPatientPostFromClinicianRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientPostFromClinician(requestParameters: PatientApiAppApiPatientPostFromClinicianRequest, options?: AxiosRequestConfig): AxiosPromise<PatientResponse> {
            return localVarFp.appApiPatientPostFromClinician(requestParameters.CreatePatientFromClinicianRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Join clinic by invitation code
         * @param {PatientApiAppApiPatientPostPatientJoinClinicByInvitationCodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientPostPatientJoinClinicByInvitationCode(requestParameters: PatientApiAppApiPatientPostPatientJoinClinicByInvitationCodeRequest, options?: AxiosRequestConfig): AxiosPromise<PatientJoinClinicByInvitationCodeResponse> {
            return localVarFp.appApiPatientPostPatientJoinClinicByInvitationCode(requestParameters.patient_id, requestParameters.invitation_code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate daily usage report data for a patient
         * @param {PatientApiAppApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReport(requestParameters: PatientApiAppApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReportRequest, options?: AxiosRequestConfig): AxiosPromise<DailyReportResponse> {
            return localVarFp.appApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReport(requestParameters.patient_id, requestParameters.report_date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all logged patients
         * @param {PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientUsageSummaryGetAllPatientsMetrics(requestParameters: PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PatientResponse>> {
            return localVarFp.appApiPatientReportsPatientUsageSummaryGetAllPatientsMetrics(requestParameters.tracking_reference_date, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all logged patients
         * @param {PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuickRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuick(requestParameters: PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuickRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PatientResponse>> {
            return localVarFp.appApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuick(requestParameters.days_since_tracking_started, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of rdis for the patient
         * @param {PatientApiAppApiReferenceDailyIntakeGetPatientRdiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReferenceDailyIntakeGetPatientRdi(requestParameters: PatientApiAppApiReferenceDailyIntakeGetPatientRdiRequest, options?: AxiosRequestConfig): AxiosPromise<PatientRdiResponse> {
            return localVarFp.appApiReferenceDailyIntakeGetPatientRdi(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.use_insufficient_meal_filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of rdis for the patient
         * @param {PatientApiAppApiReferenceDailyIntakeGetRdiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiReferenceDailyIntakeGetRdi(requestParameters: PatientApiAppApiReferenceDailyIntakeGetRdiRequest, options?: AxiosRequestConfig): AxiosPromise<Array<RdiResponse>> {
            return localVarFp.appApiReferenceDailyIntakeGetRdi(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of rdis for the patient
         * @param {PatientApiAppApiSubscriptionApiGetPatientMealDatePeriodFiltersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiGetPatientMealDatePeriodFilters(requestParameters: PatientApiAppApiSubscriptionApiGetPatientMealDatePeriodFiltersRequest, options?: AxiosRequestConfig): AxiosPromise<Array<MealDateRangeResponse>> {
            return localVarFp.appApiSubscriptionApiGetPatientMealDatePeriodFilters(requestParameters.patient_id, requestParameters.reference_date, requestParameters.period_name, requestParameters.target_n_dates, requestParameters.max_window_size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiPatientActivityFeedGetPatientActivityFeed operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientActivityFeedGetPatientActivityFeedRequest
 */
export interface PatientApiAppApiPatientActivityFeedGetPatientActivityFeedRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientActivityFeedGetPatientActivityFeed
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientApiAppApiPatientActivityFeedGetPatientActivityFeed
     */
    readonly filter?: string

    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientActivityFeedGetPatientActivityFeed
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof PatientApiAppApiPatientActivityFeedGetPatientActivityFeed
     */
    readonly before?: string
}

/**
 * Request parameters for appApiPatientGetClinicians operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientGetCliniciansRequest
 */
export interface PatientApiAppApiPatientGetCliniciansRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientGetClinicians
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiPatientGetClinics operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientGetClinicsRequest
 */
export interface PatientApiAppApiPatientGetClinicsRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientGetClinics
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiPatientGetPatient operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientGetPatientRequest
 */
export interface PatientApiAppApiPatientGetPatientRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientGetPatient
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiPatientGetPatientCioAttributes operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientGetPatientCioAttributesRequest
 */
export interface PatientApiAppApiPatientGetPatientCioAttributesRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientGetPatientCioAttributes
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiPatientGetPatientCioSubscriptionPreferences operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientGetPatientCioSubscriptionPreferencesRequest
 */
export interface PatientApiAppApiPatientGetPatientCioSubscriptionPreferencesRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientGetPatientCioSubscriptionPreferences
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiPatientGetPatientInvitationHistory operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientGetPatientInvitationHistoryRequest
 */
export interface PatientApiAppApiPatientGetPatientInvitationHistoryRequest {
    /**
     * patient id
     * @type {number}
     * @memberof PatientApiAppApiPatientGetPatientInvitationHistory
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiPatientNoteGetPatientNote operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientNoteGetPatientNoteRequest
 */
export interface PatientApiAppApiPatientNoteGetPatientNoteRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientNoteGetPatientNote
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiPatientNoteGetPatientNoteById operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientNoteGetPatientNoteByIdRequest
 */
export interface PatientApiAppApiPatientNoteGetPatientNoteByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientNoteGetPatientNoteById
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientNoteGetPatientNoteById
     */
    readonly patient_note_id: number
}

/**
 * Request parameters for appApiPatientNoteGetPatientNoteForClinician operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientNoteGetPatientNoteForClinicianRequest
 */
export interface PatientApiAppApiPatientNoteGetPatientNoteForClinicianRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientNoteGetPatientNoteForClinician
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientNoteGetPatientNoteForClinician
     */
    readonly clinician_id: number
}

/**
 * Request parameters for appApiPatientNoteGetPatientNoteQueue operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientNoteGetPatientNoteQueueRequest
 */
export interface PatientApiAppApiPatientNoteGetPatientNoteQueueRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientNoteGetPatientNoteQueue
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientNoteGetPatientNoteQueue
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientNoteGetPatientNoteQueue
     */
    readonly meal_photo_queue_id: number
}

/**
 * Request parameters for appApiPatientNoteGetPatientNotes operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientNoteGetPatientNotesRequest
 */
export interface PatientApiAppApiPatientNoteGetPatientNotesRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientNoteGetPatientNotes
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiPatientNotePostPatientNote operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientNotePostPatientNoteRequest
 */
export interface PatientApiAppApiPatientNotePostPatientNoteRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientNotePostPatientNote
     */
    readonly patient_id: number

    /**
     * Updated version of the patient note history.
     * @type {CreatePatientNoteRequest}
     * @memberof PatientApiAppApiPatientNotePostPatientNote
     */
    readonly CreatePatientNoteRequest: CreatePatientNoteRequest
}

/**
 * Request parameters for appApiPatientNotePostPatientNoteQueue operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientNotePostPatientNoteQueueRequest
 */
export interface PatientApiAppApiPatientNotePostPatientNoteQueueRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientNotePostPatientNoteQueue
     */
    readonly data_reviewer_group_id: number

    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientNotePostPatientNoteQueue
     */
    readonly data_reviewer_id: number

    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientNotePostPatientNoteQueue
     */
    readonly meal_photo_queue_id: number

    /**
     * Updated version of the patient note history.
     * @type {CreatePatientNoteRequest}
     * @memberof PatientApiAppApiPatientNotePostPatientNoteQueue
     */
    readonly CreatePatientNoteRequest: CreatePatientNoteRequest
}

/**
 * Request parameters for appApiPatientNotePutPatientNoteForClinician operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientNotePutPatientNoteForClinicianRequest
 */
export interface PatientApiAppApiPatientNotePutPatientNoteForClinicianRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientNotePutPatientNoteForClinician
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientNotePutPatientNoteForClinician
     */
    readonly clinician_id: number

    /**
     * Updated version of the patient note history.
     * @type {CreatePatientNoteRequest}
     * @memberof PatientApiAppApiPatientNotePutPatientNoteForClinician
     */
    readonly CreatePatientNoteRequest: CreatePatientNoteRequest
}

/**
 * Request parameters for appApiPatientPatchPatient operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientPatchPatientRequest
 */
export interface PatientApiAppApiPatientPatchPatientRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientPatchPatient
     */
    readonly patient_id: number

    /**
     * 
     * @type {PatchPatientRequest}
     * @memberof PatientApiAppApiPatientPatchPatient
     */
    readonly PatchPatientRequest: PatchPatientRequest
}

/**
 * Request parameters for appApiPatientPostFromClinician operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientPostFromClinicianRequest
 */
export interface PatientApiAppApiPatientPostFromClinicianRequest {
    /**
     * 
     * @type {CreatePatientFromClinicianRequest}
     * @memberof PatientApiAppApiPatientPostFromClinician
     */
    readonly CreatePatientFromClinicianRequest: CreatePatientFromClinicianRequest
}

/**
 * Request parameters for appApiPatientPostPatientJoinClinicByInvitationCode operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientPostPatientJoinClinicByInvitationCodeRequest
 */
export interface PatientApiAppApiPatientPostPatientJoinClinicByInvitationCodeRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientPostPatientJoinClinicByInvitationCode
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientApiAppApiPatientPostPatientJoinClinicByInvitationCode
     */
    readonly invitation_code: string
}

/**
 * Request parameters for appApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReport operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReportRequest
 */
export interface PatientApiAppApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReportRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientApiAppApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReport
     */
    readonly report_date?: string
}

/**
 * Request parameters for appApiPatientReportsPatientUsageSummaryGetAllPatientsMetrics operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsRequest
 */
export interface PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsRequest {
    /**
     * filter results such that only those tracking_starts greater than / equal to this date are included
     * @type {string}
     * @memberof PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetrics
     */
    readonly tracking_reference_date: string

    /**
     * 
     * @type {string}
     * @memberof PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetrics
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetrics
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuick operation in PatientApi.
 * @export
 * @interface PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuickRequest
 */
export interface PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuickRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuick
     */
    readonly days_since_tracking_started: number
}

/**
 * Request parameters for appApiReferenceDailyIntakeGetPatientRdi operation in PatientApi.
 * @export
 * @interface PatientApiAppApiReferenceDailyIntakeGetPatientRdiRequest
 */
export interface PatientApiAppApiReferenceDailyIntakeGetPatientRdiRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiReferenceDailyIntakeGetPatientRdi
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientApiAppApiReferenceDailyIntakeGetPatientRdi
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientApiAppApiReferenceDailyIntakeGetPatientRdi
     */
    readonly date_until: string

    /**
     * 
     * @type {boolean}
     * @memberof PatientApiAppApiReferenceDailyIntakeGetPatientRdi
     */
    readonly use_insufficient_meal_filter?: boolean
}

/**
 * Request parameters for appApiReferenceDailyIntakeGetRdi operation in PatientApi.
 * @export
 * @interface PatientApiAppApiReferenceDailyIntakeGetRdiRequest
 */
export interface PatientApiAppApiReferenceDailyIntakeGetRdiRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiReferenceDailyIntakeGetRdi
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiSubscriptionApiGetPatientMealDatePeriodFilters operation in PatientApi.
 * @export
 * @interface PatientApiAppApiSubscriptionApiGetPatientMealDatePeriodFiltersRequest
 */
export interface PatientApiAppApiSubscriptionApiGetPatientMealDatePeriodFiltersRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiSubscriptionApiGetPatientMealDatePeriodFilters
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientApiAppApiSubscriptionApiGetPatientMealDatePeriodFilters
     */
    readonly reference_date?: string

    /**
     * 
     * @type {string}
     * @memberof PatientApiAppApiSubscriptionApiGetPatientMealDatePeriodFilters
     */
    readonly period_name?: string

    /**
     * 
     * @type {number}
     * @memberof PatientApiAppApiSubscriptionApiGetPatientMealDatePeriodFilters
     */
    readonly target_n_dates?: number

    /**
     * maximum number of days since the reference date from which to draw the distinct dates
     * @type {number}
     * @memberof PatientApiAppApiSubscriptionApiGetPatientMealDatePeriodFilters
     */
    readonly max_window_size?: number
}

/**
 * PatientApi - object-oriented interface
 * @export
 * @class PatientApi
 * @extends {BaseAPI}
 */
export class PatientApi extends BaseAPI {
    /**
     * 
     * @summary Gets an ApiTable response of a patient\'s activity feed
     * @param {PatientApiAppApiPatientActivityFeedGetPatientActivityFeedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientActivityFeedGetPatientActivityFeed(requestParameters: PatientApiAppApiPatientActivityFeedGetPatientActivityFeedRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientActivityFeedGetPatientActivityFeed(requestParameters.patient_id, requestParameters.filter, requestParameters.limit, requestParameters.before, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of clinicians this patient belongs to
     * @param {PatientApiAppApiPatientGetCliniciansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientGetClinicians(requestParameters: PatientApiAppApiPatientGetCliniciansRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientGetClinicians(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of clinics this patient belongs to
     * @param {PatientApiAppApiPatientGetClinicsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientGetClinics(requestParameters: PatientApiAppApiPatientGetClinicsRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientGetClinics(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get currently logged in patient info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientGetCurrentPatient(options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientGetCurrentPatient(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Mixpanel tags for the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientGetCurrentPatientMixpanelTags(options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientGetCurrentPatientMixpanelTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patient info
     * @param {PatientApiAppApiPatientGetPatientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientGetPatient(requestParameters: PatientApiAppApiPatientGetPatientRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientGetPatient(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customerio attributes patient. See also: https://customer.io/docs/api/app/#operation/getPersonAttributes
     * @param {PatientApiAppApiPatientGetPatientCioAttributesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientGetPatientCioAttributes(requestParameters: PatientApiAppApiPatientGetPatientCioAttributesRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientGetPatientCioAttributes(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customerio subscription preferences for the patient. See also: https://customer.io/docs/api/app/#operation/getPersonSubscriptionPreferences
     * @param {PatientApiAppApiPatientGetPatientCioSubscriptionPreferencesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientGetPatientCioSubscriptionPreferences(requestParameters: PatientApiAppApiPatientGetPatientCioSubscriptionPreferencesRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientGetPatientCioSubscriptionPreferences(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get currently logged in patient info
     * @param {PatientApiAppApiPatientGetPatientInvitationHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientGetPatientInvitationHistory(requestParameters: PatientApiAppApiPatientGetPatientInvitationHistoryRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientGetPatientInvitationHistory(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get latest patient note
     * @param {PatientApiAppApiPatientNoteGetPatientNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientNoteGetPatientNote(requestParameters: PatientApiAppApiPatientNoteGetPatientNoteRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientNoteGetPatientNote(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patient note by id
     * @param {PatientApiAppApiPatientNoteGetPatientNoteByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientNoteGetPatientNoteById(requestParameters: PatientApiAppApiPatientNoteGetPatientNoteByIdRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientNoteGetPatientNoteById(requestParameters.patient_id, requestParameters.patient_note_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get latest patient note for clinician
     * @param {PatientApiAppApiPatientNoteGetPatientNoteForClinicianRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientNoteGetPatientNoteForClinician(requestParameters: PatientApiAppApiPatientNoteGetPatientNoteForClinicianRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientNoteGetPatientNoteForClinician(requestParameters.patient_id, requestParameters.clinician_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get most recent patient note
     * @param {PatientApiAppApiPatientNoteGetPatientNoteQueueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientNoteGetPatientNoteQueue(requestParameters: PatientApiAppApiPatientNoteGetPatientNoteQueueRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientNoteGetPatientNoteQueue(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Summary of All Notes
     * @param {PatientApiAppApiPatientNoteGetPatientNotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientNoteGetPatientNotes(requestParameters: PatientApiAppApiPatientNoteGetPatientNotesRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientNoteGetPatientNotes(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post patient note
     * @param {PatientApiAppApiPatientNotePostPatientNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientNotePostPatientNote(requestParameters: PatientApiAppApiPatientNotePostPatientNoteRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientNotePostPatientNote(requestParameters.patient_id, requestParameters.CreatePatientNoteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post updated patient note
     * @param {PatientApiAppApiPatientNotePostPatientNoteQueueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientNotePostPatientNoteQueue(requestParameters: PatientApiAppApiPatientNotePostPatientNoteQueueRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientNotePostPatientNoteQueue(requestParameters.data_reviewer_group_id, requestParameters.data_reviewer_id, requestParameters.meal_photo_queue_id, requestParameters.CreatePatientNoteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update patient note for clinician
     * @param {PatientApiAppApiPatientNotePutPatientNoteForClinicianRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientNotePutPatientNoteForClinician(requestParameters: PatientApiAppApiPatientNotePutPatientNoteForClinicianRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientNotePutPatientNoteForClinician(requestParameters.patient_id, requestParameters.clinician_id, requestParameters.CreatePatientNoteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit information of the patient. This endpoint edits the provided field in the body. That is, if the first_name is null in the request body, the first_name in the system remains the same (as opposed to write to null).
     * @param {PatientApiAppApiPatientPatchPatientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientPatchPatient(requestParameters: PatientApiAppApiPatientPatchPatientRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientPatchPatient(requestParameters.patient_id, requestParameters.PatchPatientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new patient, supposed to be used from a clinician
     * @param {PatientApiAppApiPatientPostFromClinicianRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientPostFromClinician(requestParameters: PatientApiAppApiPatientPostFromClinicianRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientPostFromClinician(requestParameters.CreatePatientFromClinicianRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Join clinic by invitation code
     * @param {PatientApiAppApiPatientPostPatientJoinClinicByInvitationCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientPostPatientJoinClinicByInvitationCode(requestParameters: PatientApiAppApiPatientPostPatientJoinClinicByInvitationCodeRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientPostPatientJoinClinicByInvitationCode(requestParameters.patient_id, requestParameters.invitation_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate daily usage report data for a patient
     * @param {PatientApiAppApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReport(requestParameters: PatientApiAppApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReportRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientReportsPatientUsageSummaryGeneratePatientDailyUsageReport(requestParameters.patient_id, requestParameters.report_date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all logged patients
     * @param {PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientReportsPatientUsageSummaryGetAllPatientsMetrics(requestParameters: PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientReportsPatientUsageSummaryGetAllPatientsMetrics(requestParameters.tracking_reference_date, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all logged patients
     * @param {PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuickRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuick(requestParameters: PatientApiAppApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuickRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiPatientReportsPatientUsageSummaryGetAllPatientsMetricsQuick(requestParameters.days_since_tracking_started, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of rdis for the patient
     * @param {PatientApiAppApiReferenceDailyIntakeGetPatientRdiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiReferenceDailyIntakeGetPatientRdi(requestParameters: PatientApiAppApiReferenceDailyIntakeGetPatientRdiRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiReferenceDailyIntakeGetPatientRdi(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.use_insufficient_meal_filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of rdis for the patient
     * @param {PatientApiAppApiReferenceDailyIntakeGetRdiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiReferenceDailyIntakeGetRdi(requestParameters: PatientApiAppApiReferenceDailyIntakeGetRdiRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiReferenceDailyIntakeGetRdi(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of rdis for the patient
     * @param {PatientApiAppApiSubscriptionApiGetPatientMealDatePeriodFiltersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public appApiSubscriptionApiGetPatientMealDatePeriodFilters(requestParameters: PatientApiAppApiSubscriptionApiGetPatientMealDatePeriodFiltersRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).appApiSubscriptionApiGetPatientMealDatePeriodFilters(requestParameters.patient_id, requestParameters.reference_date, requestParameters.period_name, requestParameters.target_n_dates, requestParameters.max_window_size, options).then((request) => request(this.axios, this.basePath));
    }
}

