/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PatientCGMConnectionStatusResult } from '../models';
// @ts-ignore
import { PatientCGMDataRefetchResult } from '../models';
// @ts-ignore
import { PatientCgmDailySummaryResponse } from '../models';
// @ts-ignore
import { PatientCgmDataResponse } from '../models';
// @ts-ignore
import { PatientCgmDatesWithDataResponse } from '../models';
// @ts-ignore
import { PatientCgmEntryResponse } from '../models';
// @ts-ignore
import { PatientCgmReportDataResponse } from '../models';
// @ts-ignore
import { PatientCgmSectionSummaryResponse } from '../models';
// @ts-ignore
import { PatientCgmWholeSummaryResponse } from '../models';
// @ts-ignore
import { PatientReportResponse } from '../models';
/**
 * CgmApi - axios parameter creator
 * @export
 */
export const CgmApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the list of patients\' CGM entries available for the specified clinician.
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiGetCliniciansPatients: async (clinician_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiCgmApiGetCliniciansPatients', 'clinician_id', clinician_id)
            const localVarPath = `/cgm/clinicians/{clinician_id}/patients`
                .replace(`{${"clinician_id"}}`, encodeURIComponent(String(clinician_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the status of all CGM connections for a given patient
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiGetPatientCgmConnections: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiCgmApiGetPatientCgmConnections', 'patient_id', patient_id)
            const localVarPath = `/cgm/{patient_id}/connections`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate, upload, and log CGM report PDF for the given patient and date range
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiGetPatientCgmReport: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiCgmApiGetPatientCgmReport', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiCgmApiGetPatientCgmReport', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiCgmApiGetPatientCgmReport', 'date_until', date_until)
            const localVarPath = `/cgm/patients/{patient_id}/report`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate, and return draft CGM report PDF for the given patient and date range
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiGetPatientCgmReportPreview: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiCgmApiGetPatientCgmReportPreview', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiCgmApiGetPatientCgmReportPreview', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiCgmApiGetPatientCgmReportPreview', 'date_until', date_until)
            const localVarPath = `/cgm/patients/{patient_id}/report/preview`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload patient\'s raw CGM data, compatible for both Abbott and Dexcom
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {File} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiPostCgmData: async (patient_id: number, date_since: string, date_until: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiCgmApiPostCgmData', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiCgmApiPostCgmData', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiCgmApiPostCgmData', 'date_until', date_until)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiCgmApiPostCgmData', 'file', file)
            const localVarPath = `/cgm/patients/{patient_id}/upload`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refetches CGM data for a given patient
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiPostCgmDataRefetch: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiCgmApiPostCgmDataRefetch', 'patient_id', patient_id)
            const localVarPath = `/cgm/{patient_id}/data/refetch`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the CGM report PDF for a given CGM entry ID
         * @param {number} cgm_entry_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiRetrievePatientCgmReport: async (cgm_entry_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cgm_entry_id' is not null or undefined
            assertParamExists('appApiCgmApiRetrievePatientCgmReport', 'cgm_entry_id', cgm_entry_id)
            const localVarPath = `/cgm/reports/{cgm_entry_id}/pdf`
                .replace(`{${"cgm_entry_id"}}`, encodeURIComponent(String(cgm_entry_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload patient\'s raw CGM data, compatible for both Abbott and Dexcom
         * @param {number} patient_id 
         * @param {File} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiUploadToDb: async (patient_id: number, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiCgmApiUploadToDb', 'patient_id', patient_id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiCgmApiUploadToDb', 'file', file)
            const localVarPath = `/cgm/patients/{patient_id}/upload_to_db`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] load CGM data
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {File} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmCgmConvertCgmData: async (patient_id: number, date_since: string, date_until: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiCgmCgmConvertCgmData', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiCgmCgmConvertCgmData', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiCgmCgmConvertCgmData', 'date_until', date_until)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiCgmCgmConvertCgmData', 'file', file)
            const localVarPath = `/cgm/{patient_id}/convert`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] load CGM data and return a summarized zip file of the data
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {File} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmCgmConvertCgmDataToZip: async (patient_id: number, date_since: string, date_until: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiCgmCgmConvertCgmDataToZip', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiCgmCgmConvertCgmDataToZip', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiCgmCgmConvertCgmDataToZip', 'date_until', date_until)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('appApiCgmCgmConvertCgmDataToZip', 'file', file)
            const localVarPath = `/cgm/{patient_id}/convert/zip`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the list of patients\' CGM entries available for the specified clinician.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmV2ApiV2GetCgmDailySummary: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetCgmDailySummary', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetCgmDailySummary', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetCgmDailySummary', 'date_until', date_until)
            const localVarPath = `/cgm/patients/{patient_id}/daily_summary`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a patient\'s raw CGM-related data (note: a maximum of 5,000 datapoints will be returned; limit queries to 24h)
         * @param {number} patient_id 
         * @param {string} time_since_local 
         * @param {string} time_until_local 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmV2ApiV2GetCgmData: async (patient_id: number, time_since_local: string, time_until_local: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetCgmData', 'patient_id', patient_id)
            // verify required parameter 'time_since_local' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetCgmData', 'time_since_local', time_since_local)
            // verify required parameter 'time_until_local' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetCgmData', 'time_until_local', time_until_local)
            const localVarPath = `/cgm/patients/{patient_id}/cgm_data`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (time_since_local !== undefined) {
                localVarQueryParameter['time_since_local'] = time_since_local;
            }

            if (time_until_local !== undefined) {
                localVarQueryParameter['time_until_local'] = time_until_local;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the list of patients\' CGM entries available for the specified clinician.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmV2ApiV2GetCgmSectionSummaries: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetCgmSectionSummaries', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetCgmSectionSummaries', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetCgmSectionSummaries', 'date_until', date_until)
            const localVarPath = `/cgm/patients/{patient_id}/section_summaries`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the list of patients\' CGM entries available for the specified clinician.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {string} [meal_name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmV2ApiV2GetCgmWholeSummary: async (patient_id: number, date_since: string, date_until: string, meal_name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetCgmWholeSummary', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetCgmWholeSummary', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetCgmWholeSummary', 'date_until', date_until)
            const localVarPath = `/cgm/patients/{patient_id}/whole_summary`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (meal_name !== undefined) {
                localVarQueryParameter['meal_name'] = meal_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of dates with CGM data for a patient
         * @param {number} patient_id 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmV2ApiV2GetPatientCgmDatesWithData: async (patient_id: number, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetPatientCgmDatesWithData', 'patient_id', patient_id)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetPatientCgmDatesWithData', 'date_until', date_until)
            const localVarPath = `/cgm/patients/{patient_id}/dates-with-data`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the list of patients\' CGM entries available for the specified clinician.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmV2ApiV2GetPatientCgmReportData: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetPatientCgmReportData', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetPatientCgmReportData', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiCgmV2ApiV2GetPatientCgmReportData', 'date_until', date_until)
            const localVarPath = `/cgm/patients/{patient_id}/report_data`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CgmApi - functional programming interface
 * @export
 */
export const CgmApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CgmApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the list of patients\' CGM entries available for the specified clinician.
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmApiGetCliniciansPatients(clinician_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientCgmEntryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmApiGetCliniciansPatients(clinician_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the status of all CGM connections for a given patient
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmApiGetPatientCgmConnections(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientCGMConnectionStatusResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmApiGetPatientCgmConnections(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate, upload, and log CGM report PDF for the given patient and date range
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmApiGetPatientCgmReport(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmApiGetPatientCgmReport(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate, and return draft CGM report PDF for the given patient and date range
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmApiGetPatientCgmReportPreview(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmApiGetPatientCgmReportPreview(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload patient\'s raw CGM data, compatible for both Abbott and Dexcom
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {File} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmApiPostCgmData(patient_id: number, date_since: string, date_until: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmApiPostCgmData(patient_id, date_since, date_until, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refetches CGM data for a given patient
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmApiPostCgmDataRefetch(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientCGMDataRefetchResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmApiPostCgmDataRefetch(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the CGM report PDF for a given CGM entry ID
         * @param {number} cgm_entry_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmApiRetrievePatientCgmReport(cgm_entry_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmApiRetrievePatientCgmReport(cgm_entry_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload patient\'s raw CGM data, compatible for both Abbott and Dexcom
         * @param {number} patient_id 
         * @param {File} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmApiUploadToDb(patient_id: number, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmApiUploadToDb(patient_id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] load CGM data
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {File} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmCgmConvertCgmData(patient_id: number, date_since: string, date_until: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmCgmConvertCgmData(patient_id, date_since, date_until, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] load CGM data and return a summarized zip file of the data
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {File} file csv format - utf-8-sig
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmCgmConvertCgmDataToZip(patient_id: number, date_since: string, date_until: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmCgmConvertCgmDataToZip(patient_id, date_since, date_until, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the list of patients\' CGM entries available for the specified clinician.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmV2ApiV2GetCgmDailySummary(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientCgmDailySummaryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmV2ApiV2GetCgmDailySummary(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a patient\'s raw CGM-related data (note: a maximum of 5,000 datapoints will be returned; limit queries to 24h)
         * @param {number} patient_id 
         * @param {string} time_since_local 
         * @param {string} time_until_local 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmV2ApiV2GetCgmData(patient_id: number, time_since_local: string, time_until_local: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientCgmDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmV2ApiV2GetCgmData(patient_id, time_since_local, time_until_local, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the list of patients\' CGM entries available for the specified clinician.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmV2ApiV2GetCgmSectionSummaries(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientCgmSectionSummaryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmV2ApiV2GetCgmSectionSummaries(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the list of patients\' CGM entries available for the specified clinician.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {string} [meal_name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmV2ApiV2GetCgmWholeSummary(patient_id: number, date_since: string, date_until: string, meal_name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientCgmWholeSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmV2ApiV2GetCgmWholeSummary(patient_id, date_since, date_until, meal_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of dates with CGM data for a patient
         * @param {number} patient_id 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmV2ApiV2GetPatientCgmDatesWithData(patient_id: number, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientCgmDatesWithDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmV2ApiV2GetPatientCgmDatesWithData(patient_id, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the list of patients\' CGM entries available for the specified clinician.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiCgmV2ApiV2GetPatientCgmReportData(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientCgmReportDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiCgmV2ApiV2GetPatientCgmReportData(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CgmApi - factory interface
 * @export
 */
export const CgmApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CgmApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the list of patients\' CGM entries available for the specified clinician.
         * @param {CgmApiAppApiCgmApiGetCliniciansPatientsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiGetCliniciansPatients(requestParameters: CgmApiAppApiCgmApiGetCliniciansPatientsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PatientCgmEntryResponse>> {
            return localVarFp.appApiCgmApiGetCliniciansPatients(requestParameters.clinician_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the status of all CGM connections for a given patient
         * @param {CgmApiAppApiCgmApiGetPatientCgmConnectionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiGetPatientCgmConnections(requestParameters: CgmApiAppApiCgmApiGetPatientCgmConnectionsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PatientCGMConnectionStatusResult>> {
            return localVarFp.appApiCgmApiGetPatientCgmConnections(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate, upload, and log CGM report PDF for the given patient and date range
         * @param {CgmApiAppApiCgmApiGetPatientCgmReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiGetPatientCgmReport(requestParameters: CgmApiAppApiCgmApiGetPatientCgmReportRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PatientReportResponse>> {
            return localVarFp.appApiCgmApiGetPatientCgmReport(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate, and return draft CGM report PDF for the given patient and date range
         * @param {CgmApiAppApiCgmApiGetPatientCgmReportPreviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiGetPatientCgmReportPreview(requestParameters: CgmApiAppApiCgmApiGetPatientCgmReportPreviewRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.appApiCgmApiGetPatientCgmReportPreview(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload patient\'s raw CGM data, compatible for both Abbott and Dexcom
         * @param {CgmApiAppApiCgmApiPostCgmDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiPostCgmData(requestParameters: CgmApiAppApiCgmApiPostCgmDataRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiCgmApiPostCgmData(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refetches CGM data for a given patient
         * @param {CgmApiAppApiCgmApiPostCgmDataRefetchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiPostCgmDataRefetch(requestParameters: CgmApiAppApiCgmApiPostCgmDataRefetchRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PatientCGMDataRefetchResult>> {
            return localVarFp.appApiCgmApiPostCgmDataRefetch(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the CGM report PDF for a given CGM entry ID
         * @param {CgmApiAppApiCgmApiRetrievePatientCgmReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiRetrievePatientCgmReport(requestParameters: CgmApiAppApiCgmApiRetrievePatientCgmReportRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.appApiCgmApiRetrievePatientCgmReport(requestParameters.cgm_entry_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload patient\'s raw CGM data, compatible for both Abbott and Dexcom
         * @param {CgmApiAppApiCgmApiUploadToDbRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmApiUploadToDb(requestParameters: CgmApiAppApiCgmApiUploadToDbRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiCgmApiUploadToDb(requestParameters.patient_id, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] load CGM data
         * @param {CgmApiAppApiCgmCgmConvertCgmDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmCgmConvertCgmData(requestParameters: CgmApiAppApiCgmCgmConvertCgmDataRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.appApiCgmCgmConvertCgmData(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] load CGM data and return a summarized zip file of the data
         * @param {CgmApiAppApiCgmCgmConvertCgmDataToZipRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmCgmConvertCgmDataToZip(requestParameters: CgmApiAppApiCgmCgmConvertCgmDataToZipRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.appApiCgmCgmConvertCgmDataToZip(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the list of patients\' CGM entries available for the specified clinician.
         * @param {CgmApiAppApiCgmV2ApiV2GetCgmDailySummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmV2ApiV2GetCgmDailySummary(requestParameters: CgmApiAppApiCgmV2ApiV2GetCgmDailySummaryRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PatientCgmDailySummaryResponse>> {
            return localVarFp.appApiCgmV2ApiV2GetCgmDailySummary(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a patient\'s raw CGM-related data (note: a maximum of 5,000 datapoints will be returned; limit queries to 24h)
         * @param {CgmApiAppApiCgmV2ApiV2GetCgmDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmV2ApiV2GetCgmData(requestParameters: CgmApiAppApiCgmV2ApiV2GetCgmDataRequest, options?: AxiosRequestConfig): AxiosPromise<PatientCgmDataResponse> {
            return localVarFp.appApiCgmV2ApiV2GetCgmData(requestParameters.patient_id, requestParameters.time_since_local, requestParameters.time_until_local, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the list of patients\' CGM entries available for the specified clinician.
         * @param {CgmApiAppApiCgmV2ApiV2GetCgmSectionSummariesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmV2ApiV2GetCgmSectionSummaries(requestParameters: CgmApiAppApiCgmV2ApiV2GetCgmSectionSummariesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PatientCgmSectionSummaryResponse>> {
            return localVarFp.appApiCgmV2ApiV2GetCgmSectionSummaries(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the list of patients\' CGM entries available for the specified clinician.
         * @param {CgmApiAppApiCgmV2ApiV2GetCgmWholeSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmV2ApiV2GetCgmWholeSummary(requestParameters: CgmApiAppApiCgmV2ApiV2GetCgmWholeSummaryRequest, options?: AxiosRequestConfig): AxiosPromise<PatientCgmWholeSummaryResponse> {
            return localVarFp.appApiCgmV2ApiV2GetCgmWholeSummary(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.meal_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of dates with CGM data for a patient
         * @param {CgmApiAppApiCgmV2ApiV2GetPatientCgmDatesWithDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmV2ApiV2GetPatientCgmDatesWithData(requestParameters: CgmApiAppApiCgmV2ApiV2GetPatientCgmDatesWithDataRequest, options?: AxiosRequestConfig): AxiosPromise<PatientCgmDatesWithDataResponse> {
            return localVarFp.appApiCgmV2ApiV2GetPatientCgmDatesWithData(requestParameters.patient_id, requestParameters.date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the list of patients\' CGM entries available for the specified clinician.
         * @param {CgmApiAppApiCgmV2ApiV2GetPatientCgmReportDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiCgmV2ApiV2GetPatientCgmReportData(requestParameters: CgmApiAppApiCgmV2ApiV2GetPatientCgmReportDataRequest, options?: AxiosRequestConfig): AxiosPromise<PatientCgmReportDataResponse> {
            return localVarFp.appApiCgmV2ApiV2GetPatientCgmReportData(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiCgmApiGetCliniciansPatients operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmApiGetCliniciansPatientsRequest
 */
export interface CgmApiAppApiCgmApiGetCliniciansPatientsRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmApiGetCliniciansPatients
     */
    readonly clinician_id: number
}

/**
 * Request parameters for appApiCgmApiGetPatientCgmConnections operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmApiGetPatientCgmConnectionsRequest
 */
export interface CgmApiAppApiCgmApiGetPatientCgmConnectionsRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmApiGetPatientCgmConnections
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiCgmApiGetPatientCgmReport operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmApiGetPatientCgmReportRequest
 */
export interface CgmApiAppApiCgmApiGetPatientCgmReportRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmApiGetPatientCgmReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmApiGetPatientCgmReport
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmApiGetPatientCgmReport
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiCgmApiGetPatientCgmReportPreview operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmApiGetPatientCgmReportPreviewRequest
 */
export interface CgmApiAppApiCgmApiGetPatientCgmReportPreviewRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmApiGetPatientCgmReportPreview
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmApiGetPatientCgmReportPreview
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmApiGetPatientCgmReportPreview
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiCgmApiPostCgmData operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmApiPostCgmDataRequest
 */
export interface CgmApiAppApiCgmApiPostCgmDataRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmApiPostCgmData
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmApiPostCgmData
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmApiPostCgmData
     */
    readonly date_until: string

    /**
     * csv format - utf-8-sig
     * @type {File}
     * @memberof CgmApiAppApiCgmApiPostCgmData
     */
    readonly file: File
}

/**
 * Request parameters for appApiCgmApiPostCgmDataRefetch operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmApiPostCgmDataRefetchRequest
 */
export interface CgmApiAppApiCgmApiPostCgmDataRefetchRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmApiPostCgmDataRefetch
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiCgmApiRetrievePatientCgmReport operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmApiRetrievePatientCgmReportRequest
 */
export interface CgmApiAppApiCgmApiRetrievePatientCgmReportRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmApiRetrievePatientCgmReport
     */
    readonly cgm_entry_id: number
}

/**
 * Request parameters for appApiCgmApiUploadToDb operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmApiUploadToDbRequest
 */
export interface CgmApiAppApiCgmApiUploadToDbRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmApiUploadToDb
     */
    readonly patient_id: number

    /**
     * csv format - utf-8-sig
     * @type {File}
     * @memberof CgmApiAppApiCgmApiUploadToDb
     */
    readonly file: File
}

/**
 * Request parameters for appApiCgmCgmConvertCgmData operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmCgmConvertCgmDataRequest
 */
export interface CgmApiAppApiCgmCgmConvertCgmDataRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmCgmConvertCgmData
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmCgmConvertCgmData
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmCgmConvertCgmData
     */
    readonly date_until: string

    /**
     * csv format - utf-8-sig
     * @type {File}
     * @memberof CgmApiAppApiCgmCgmConvertCgmData
     */
    readonly file: File
}

/**
 * Request parameters for appApiCgmCgmConvertCgmDataToZip operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmCgmConvertCgmDataToZipRequest
 */
export interface CgmApiAppApiCgmCgmConvertCgmDataToZipRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmCgmConvertCgmDataToZip
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmCgmConvertCgmDataToZip
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmCgmConvertCgmDataToZip
     */
    readonly date_until: string

    /**
     * csv format - utf-8-sig
     * @type {File}
     * @memberof CgmApiAppApiCgmCgmConvertCgmDataToZip
     */
    readonly file: File
}

/**
 * Request parameters for appApiCgmV2ApiV2GetCgmDailySummary operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmV2ApiV2GetCgmDailySummaryRequest
 */
export interface CgmApiAppApiCgmV2ApiV2GetCgmDailySummaryRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmV2ApiV2GetCgmDailySummary
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmV2ApiV2GetCgmDailySummary
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmV2ApiV2GetCgmDailySummary
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiCgmV2ApiV2GetCgmData operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmV2ApiV2GetCgmDataRequest
 */
export interface CgmApiAppApiCgmV2ApiV2GetCgmDataRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmV2ApiV2GetCgmData
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmV2ApiV2GetCgmData
     */
    readonly time_since_local: string

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmV2ApiV2GetCgmData
     */
    readonly time_until_local: string
}

/**
 * Request parameters for appApiCgmV2ApiV2GetCgmSectionSummaries operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmV2ApiV2GetCgmSectionSummariesRequest
 */
export interface CgmApiAppApiCgmV2ApiV2GetCgmSectionSummariesRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmV2ApiV2GetCgmSectionSummaries
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmV2ApiV2GetCgmSectionSummaries
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmV2ApiV2GetCgmSectionSummaries
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiCgmV2ApiV2GetCgmWholeSummary operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmV2ApiV2GetCgmWholeSummaryRequest
 */
export interface CgmApiAppApiCgmV2ApiV2GetCgmWholeSummaryRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmV2ApiV2GetCgmWholeSummary
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmV2ApiV2GetCgmWholeSummary
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmV2ApiV2GetCgmWholeSummary
     */
    readonly date_until: string

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmV2ApiV2GetCgmWholeSummary
     */
    readonly meal_name?: string
}

/**
 * Request parameters for appApiCgmV2ApiV2GetPatientCgmDatesWithData operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmV2ApiV2GetPatientCgmDatesWithDataRequest
 */
export interface CgmApiAppApiCgmV2ApiV2GetPatientCgmDatesWithDataRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmV2ApiV2GetPatientCgmDatesWithData
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmV2ApiV2GetPatientCgmDatesWithData
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiCgmV2ApiV2GetPatientCgmReportData operation in CgmApi.
 * @export
 * @interface CgmApiAppApiCgmV2ApiV2GetPatientCgmReportDataRequest
 */
export interface CgmApiAppApiCgmV2ApiV2GetPatientCgmReportDataRequest {
    /**
     * 
     * @type {number}
     * @memberof CgmApiAppApiCgmV2ApiV2GetPatientCgmReportData
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmV2ApiV2GetPatientCgmReportData
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof CgmApiAppApiCgmV2ApiV2GetPatientCgmReportData
     */
    readonly date_until: string
}

/**
 * CgmApi - object-oriented interface
 * @export
 * @class CgmApi
 * @extends {BaseAPI}
 */
export class CgmApi extends BaseAPI {
    /**
     * 
     * @summary Get the list of patients\' CGM entries available for the specified clinician.
     * @param {CgmApiAppApiCgmApiGetCliniciansPatientsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmApiGetCliniciansPatients(requestParameters: CgmApiAppApiCgmApiGetCliniciansPatientsRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmApiGetCliniciansPatients(requestParameters.clinician_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the status of all CGM connections for a given patient
     * @param {CgmApiAppApiCgmApiGetPatientCgmConnectionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmApiGetPatientCgmConnections(requestParameters: CgmApiAppApiCgmApiGetPatientCgmConnectionsRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmApiGetPatientCgmConnections(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate, upload, and log CGM report PDF for the given patient and date range
     * @param {CgmApiAppApiCgmApiGetPatientCgmReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmApiGetPatientCgmReport(requestParameters: CgmApiAppApiCgmApiGetPatientCgmReportRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmApiGetPatientCgmReport(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate, and return draft CGM report PDF for the given patient and date range
     * @param {CgmApiAppApiCgmApiGetPatientCgmReportPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmApiGetPatientCgmReportPreview(requestParameters: CgmApiAppApiCgmApiGetPatientCgmReportPreviewRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmApiGetPatientCgmReportPreview(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload patient\'s raw CGM data, compatible for both Abbott and Dexcom
     * @param {CgmApiAppApiCgmApiPostCgmDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmApiPostCgmData(requestParameters: CgmApiAppApiCgmApiPostCgmDataRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmApiPostCgmData(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refetches CGM data for a given patient
     * @param {CgmApiAppApiCgmApiPostCgmDataRefetchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmApiPostCgmDataRefetch(requestParameters: CgmApiAppApiCgmApiPostCgmDataRefetchRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmApiPostCgmDataRefetch(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the CGM report PDF for a given CGM entry ID
     * @param {CgmApiAppApiCgmApiRetrievePatientCgmReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmApiRetrievePatientCgmReport(requestParameters: CgmApiAppApiCgmApiRetrievePatientCgmReportRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmApiRetrievePatientCgmReport(requestParameters.cgm_entry_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload patient\'s raw CGM data, compatible for both Abbott and Dexcom
     * @param {CgmApiAppApiCgmApiUploadToDbRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmApiUploadToDb(requestParameters: CgmApiAppApiCgmApiUploadToDbRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmApiUploadToDb(requestParameters.patient_id, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] load CGM data
     * @param {CgmApiAppApiCgmCgmConvertCgmDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmCgmConvertCgmData(requestParameters: CgmApiAppApiCgmCgmConvertCgmDataRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmCgmConvertCgmData(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] load CGM data and return a summarized zip file of the data
     * @param {CgmApiAppApiCgmCgmConvertCgmDataToZipRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmCgmConvertCgmDataToZip(requestParameters: CgmApiAppApiCgmCgmConvertCgmDataToZipRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmCgmConvertCgmDataToZip(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the list of patients\' CGM entries available for the specified clinician.
     * @param {CgmApiAppApiCgmV2ApiV2GetCgmDailySummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmV2ApiV2GetCgmDailySummary(requestParameters: CgmApiAppApiCgmV2ApiV2GetCgmDailySummaryRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmV2ApiV2GetCgmDailySummary(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a patient\'s raw CGM-related data (note: a maximum of 5,000 datapoints will be returned; limit queries to 24h)
     * @param {CgmApiAppApiCgmV2ApiV2GetCgmDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmV2ApiV2GetCgmData(requestParameters: CgmApiAppApiCgmV2ApiV2GetCgmDataRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmV2ApiV2GetCgmData(requestParameters.patient_id, requestParameters.time_since_local, requestParameters.time_until_local, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the list of patients\' CGM entries available for the specified clinician.
     * @param {CgmApiAppApiCgmV2ApiV2GetCgmSectionSummariesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmV2ApiV2GetCgmSectionSummaries(requestParameters: CgmApiAppApiCgmV2ApiV2GetCgmSectionSummariesRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmV2ApiV2GetCgmSectionSummaries(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the list of patients\' CGM entries available for the specified clinician.
     * @param {CgmApiAppApiCgmV2ApiV2GetCgmWholeSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmV2ApiV2GetCgmWholeSummary(requestParameters: CgmApiAppApiCgmV2ApiV2GetCgmWholeSummaryRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmV2ApiV2GetCgmWholeSummary(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.meal_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of dates with CGM data for a patient
     * @param {CgmApiAppApiCgmV2ApiV2GetPatientCgmDatesWithDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmV2ApiV2GetPatientCgmDatesWithData(requestParameters: CgmApiAppApiCgmV2ApiV2GetPatientCgmDatesWithDataRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmV2ApiV2GetPatientCgmDatesWithData(requestParameters.patient_id, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the list of patients\' CGM entries available for the specified clinician.
     * @param {CgmApiAppApiCgmV2ApiV2GetPatientCgmReportDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CgmApi
     */
    public appApiCgmV2ApiV2GetPatientCgmReportData(requestParameters: CgmApiAppApiCgmV2ApiV2GetPatientCgmReportDataRequest, options?: AxiosRequestConfig) {
        return CgmApiFp(this.configuration).appApiCgmV2ApiV2GetPatientCgmReportData(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }
}

