import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAsyncResult } from 'react-use-async-result';
import {
  MealResponse,
  PatientCgmDailySummaryResponse,
  PatientCgmReportDataResponse,
  PatientCgmReportMealResponse,
  PatientCgmWholeSummaryResponse,
  UsdaNutritionResponse,
} from '../api/generated';

import _ from 'lodash';
import moment from 'moment';
import { useLocation } from 'react-router';
import { cgmApi } from '../api';
import { config } from '../config';
import { Trans, useTranslation } from '../i18n';
import { ReportDot, ReportPage, ReportSection, ReportWrapper } from '.';
import { AuthenticatedImage } from './cgm/AuthenticatedImage';
import { CGMGlucoseChart, CGMReportMarkerPoint } from './cgm/CGMGlucoseChart';
import { CGMMealSummary, MealSummarySection } from './cgm/CGMMealSummary';
import { CGMPostFoodGlucoseRise } from './cgm/CGMPostFoodGlucoseRise';
import { dateTimeToTimestampMS, formatMinutes, resultMealDotValue } from './cgm/common';
import { NutrientBreakdownBar } from './cgm/NutrientBreakdownBar';
import styles from './index.module.scss';

export const CGMSummary = (props: {
  summary: PatientCgmWholeSummaryResponse,
}) => {
  const { summary } = props;
  const { t } = useTranslation();
  return (
    <>
      <ReportSection greyBg>
        <header style={{ marginBottom: 60 }}>
          <div className={styles.reportSubheading}>
            {summary.date_since} - {summary.date_until}
          </div>
          <h1>
            <Trans>Average Glucose Readings</Trans>
          </h1>
        </header>
        <CGMGlucoseChart
          width={900}
          height={300}
          unit="mmol/L"
          data={summary.glucose_period_stats
            .map(g => ({
              ...g,
              value: g.avg,
              xTimestampMS: new Date('2000-01-01T' + g.time_of_day).getTime(),
            }))}
          xTickFormat={ms => moment(ms).format('ha')}
          xTickFrequencyHours={2}
          yAxisLabel={t('Average Glucose (mmol/L)')}
          xAxisLabel={t('Hour of Day')}
        />
      </ReportSection>

      <ReportSection>
        <h1>
          <Trans>Summary</Trans>
        </h1>
        <ul>
          {summary.glucose_rise_foods?.length > 0 && (
            <li>
              <Trans>Highest reported increase in glucose was after the following foods:</Trans>
              <ul>
                {summary.glucose_rise_foods.map((f, idx) => (
                  <li key={idx}>
                    {f.food_string} ({f.increase_in_mmol_l}
                    <Trans>mmol/L</Trans>)
                  </li>
                ))}
              </ul>
            </li>
          )}

          {
            /* {summary.summary_statements?.map((s, idx) => (
          UNDO THIS.
          <li key={idx}>{s}</li>
        ))} */
          }
        </ul>

        <div className={styles.disclaimer}>
          <Trans>Disclaimer: This table is based upon the meal time indicated when the food is logged.</Trans>
        </div>
      </ReportSection>
    </>
  );
};

// const XXX_REPORT = require('./DO_NOT_COMMIT-Patient 1368_V2.json')
// const XXX_REPORT = require('./DO_NOT_COMMIT-1325_Report_Data_v5.json')
// const XXX_REPORT = require('./DO_NOT_COMMIT-Patiggent 1366.json')
// const XXX_REPORT = require('./DO_NOT_COMMIT-Patient 1362v2.json')
// const XXX_REPORT = require('./DO_NOT_COMMIT-Patient 6.json')
// const XXX_REPORT = require('./DO_NOT_COMMIT-995_Report_Data.json')
const jeffSampleReport = require('./sample-data/2022-08-jeff-cgm-data.json');
// const testReport = require('./sample-data/DO_NOT_COMMIT-1849.json')
const testReport = null;

export const ReportCGM = (props: {
  patientId: number | string,
}) => {
  const { t } = useTranslation();
  const reportReq = useAsyncResult<PatientCgmReportDataResponse>();
  const location = useLocation();
  const q = new URLSearchParams(location.search);
  const [dateSince, dateUntil, manufacturer] = [
    q.get('dateSince') || moment().subtract(1, 'week').format('YYYY-MM-DD'),
    q.get('dateUntil') || moment().format('YYYY-MM-DD'),
    q.get('manufacturer') || t('dexcom'),
  ];

  useEffect(() => {
    if (props.patientId == 'demo-jeff') {
      reportReq.bind(Promise.resolve(jeffSampleReport));
      return;
    }

    if (config.IS_DEV && props.patientId == 'test') {
      if (!testReport) {
        reportReq.bind(Promise.reject(new Error('testReport is null; be sure to set `const testReport = ...`')));
        return;
      }
      reportReq.bind(Promise.resolve(testReport));
      return;
    }

    reportReq.clear();

    if (!props.patientId) {
      return;
    }

    const dataResp = cgmApi.appApiCgmV2ApiV2GetPatientCgmReportData({
      patient_id: +props.patientId,
      date_since: dateSince,
      date_until: dateUntil,
      // manufacturer: manufacturer,
    });
    reportReq.bind(dataResp.then(r => r.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.patientId, dateSince, dateUntil, manufacturer]);

  if (reportReq.isError) {
    return (
      <div>
        <Trans>Error loading report:</Trans> {'' + reportReq.error}
      </div>
    );
  }

  if (!reportReq.isDone) {
    return (
      <div>
        <Trans>Loading CGM report...</Trans>
      </div>
    );
  }

  return <CGMReportView data={reportReq.result} section={q.get('section')} />;
};

const CGMReportView = (props: {
  data: PatientCgmReportDataResponse,
  section?: string,
}) => {
  const { data } = props;
  const { t } = useTranslation();
  const showSection = (section: string) => {
    return !props.section || props.section == section;
  };

  return (
    <ReportWrapper>
      {
        /* eslint-disable-next-line i18next/no-literal-string */
        showSection('summary') && (
          <ReportPage>
            <CGMSummary summary={data.whole_summary} />
          </ReportPage>
        )
      }

      {
        /* eslint-disable-next-line i18next/no-literal-string */
        showSection('meals') && data.meals && (
          <ReportPage>
            <MealSummarySection
              allMeals={data.meals}
              glucosePeriodStats={data.whole_summary?.glucose_period_stats}
            />
          </ReportPage>
        )
      }

      {
        /* eslint-disable-next-line i18next/no-literal-string */
        showSection('meals') && data.meals && (
          <ReportPage>
            {
              /* eslint-disable-next-line i18next/no-literal-string */
              (['breakfast', 'lunch', 'dinner', 'snack'] as const).map(mealName => (
                <CGMMealSummary
                  key={mealName}
                  mealName={mealName}
                  allMeals={data.meals}
                  glucosePeriodStats={data.whole_summary?.glucose_period_stats}
                />
              ))
            }
          </ReportPage>
        )
      }

      {
        /* eslint-disable-next-line i18next/no-literal-string */
        showSection('glucose-rise') && (
          <ReportPage>
            {Object.values(data.section_summaries || {}).map((summary: any) => (
              summary.results.length > 0 && (
                <CGMPostFoodGlucoseRise
                  key={summary.heading}
                  summary={summary}
                  statistics={data.statistics}
                />
              )
            ))}
          </ReportPage>
        )
      }

      {
        /* eslint-disable-next-line i18next/no-literal-string */
        showSection('daily-summaies') && data.daily_summaries && (
          <ReportPage>
            {data.daily_summaries.filter(dailySummary => dailySummary.foods.length > 0).map((dailySummary, idx) => (
              <CGMDailySummary key={dailySummary.meal_date + idx} dailySummary={dailySummary} />
            ))}
          </ReportPage>
        )
      }
    </ReportWrapper>
  );
};

const CGMDailySummary = (p: {
  dailySummary: PatientCgmDailySummaryResponse,
}) => {
  const { t } = useTranslation();
  const foodToMarkerPoint = (food: PatientCgmDailySummaryResponse['foods'][0]): CGMReportMarkerPoint => ({
    xTimestampMS: dateTimeToTimestampMS(food.meal_date, food.meal_time),
    value: resultMealDotValue(p.dailySummary.glucose, 'meal', food),
    marker: {
      type: 'meal_dot',
      alpha_index: food.food_alpha_index,
    },
  });

  const exerciseToMarkerPoint = (exercise: PatientCgmDailySummaryResponse['exercise'][0]): CGMReportMarkerPoint => ({
    xTimestampMS: dateTimeToTimestampMS(exercise.exercise_date, exercise.exercise_time),
    value: resultMealDotValue(p.dailySummary.glucose, 'exercise', exercise),
    marker: {
      type: 'exercise_dot',
      value: exercise.duration_min,
    },
  });

  const insulinToMarkerPoint = (insulin: PatientCgmDailySummaryResponse['insulin'][0]): CGMReportMarkerPoint => ({
    xTimestampMS: dateTimeToTimestampMS(insulin.medication_date, insulin.medication_time),
    value: resultMealDotValue(p.dailySummary.glucose, 'medication', insulin),
    marker: {
      type: 'insulin_dot',
      value: insulin.quantity,
    },
  });

  const chartPoints: CGMReportMarkerPoint[] = [
    ...p.dailySummary.foods.map(foodToMarkerPoint),
    ...p.dailySummary.exercise.map(exerciseToMarkerPoint),
    ...p.dailySummary.insulin.map(insulinToMarkerPoint),
  ];

  return (
    <ReportSection>
      <header>
        <h1 style={{ marginBottom: 40 }}>
          <Trans>Daily Report:</Trans> {p.dailySummary.meal_date}
        </h1>
      </header>

      <Row className="align-baseline">
        <Col style={{ maxWidth: 500, minWidth: 500 }}>
          <table>
            <thead>
              <tr>
                <th colSpan={3}>
                  <h2>
                    <Trans>Key Details</Trans>
                  </h2>
                  {p.dailySummary.foods.length < 1 ? t('Note{{colon}} no meals found', { colon: ':' }) : ''}
                </th>
              </tr>
            </thead>
            <tbody>
              {p.dailySummary.foods.map((food, idx) => (
                <React.Fragment key={idx}>
                  <tr style={{ borderBottom: 'none' }}>
                    <td style={{ width: 40, textAlign: 'center' }}>
                      <ReportDot bgColor="green">{food.food_alpha_index}</ReportDot>
                    </td>
                    <td style={{ width: 120 }}>
                      <AuthenticatedImage
                        src={encodeURI(food.food_photo_url)}
                        style={{ maxWidth: 100, maxHeight: 100 }}
                      />
                    </td>
                    <td style={{ display: 'flex', flexDirection: 'column' }}>
                      <div>
                        <strong>{food.meal_time}</strong>
                      </div>
                      <div>{food.food_name}</div>
                      <div style={{ flex: 1 }} />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <table style={{ borderWidth: 0 }}>
                        <tbody>
                          <tr style={{ verticalAlign: 'top', borderWidth: 0 }} key={'food_summary' + Math.random()}>
                            {[
                              [
                                t('Glucose delta'),
                                [t('after 2 hrs'), t('mmol/L')],
                                food.glucose_two_hour_delta?.toFixed(2) || t('n/a'),
                              ],
                              [t('Fibre'), t('g'), food.fiber_g?.toFixed(2) || t('n/a')],
                            ].map(([label, units, value], idx) => (
                              <>
                                <td>
                                  <div>{label}</div>
                                  {(Array.isArray(units) ? units : [units]).map((u, idx) => (
                                    <div key={idx} className={styles.lightGreyText}>({u})</div>
                                  ))}
                                </td>
                                <td />
                                <td>
                                  <div>{value}</div>
                                </td>
                              </>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  {(food.energy_kcal == null || food.energy_kcal > 20) && (
                    <tr style={{ borderTop: 'none' }}>
                      <td colSpan={3} style={{ paddingTop: 0 }}>
                        <NutrientBreakdownBar food={food} />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </Col>
        <Col>
          {
            /* If there are more than 4 food items the table will be split across
            multiple printed pages. Repeat the chart so there will be one copy for each
            page. */
          }
          {new Array(Math.ceil(p.dailySummary.foods.length / 4)).fill(1).map((_, idx) => (
            <CGMGlucoseChart
              key={idx}
              width={400}
              height={340}
              unit="mmol/L"
              data={p.dailySummary.glucose.map(g => ({
                value: g.value,
                xTimestampMS: new Date(g.timestamp.replace('Z', '')).getTime(),
              }))}
              xTickFormat={ms => moment(ms).format('ha')}
              xTickFrequencyHours={2}
              yAxisLabel={t('Glucose (mmol/L)')}
              xAxisLabel=""
              points={chartPoints}
            />
          ))}
        </Col>
      </Row>

      <div>
        <h2>
          <Trans>Daily Summary</Trans>
        </h2>
        <ul>
          <li>
            <Trans>Average glucose for the day:</Trans>
            &nbsp;{p.dailySummary.glucose_value_avg?.toFixed(2)} <Trans>mmol/L</Trans>
          </li>
          <li>
            <Trans>Average glucose peak per meal:</Trans>
            &nbsp;{p.dailySummary.glucose_peak_per_meal_avg?.toFixed(2)} <Trans>mmol/L</Trans>
          </li>
          {p.dailySummary.glucose_peak_per_meal_max > 0 && (
            <li>
              <Trans>Largest glucose peak after a meal:</Trans>
              {p.dailySummary.glucose_peak_per_meal_max?.toFixed(2)} <Trans>mmol/L after</Trans>{' '}
              {(p.dailySummary as any).max_meals_food_item_glucose_increases /* XXX */}
            </li>
          )}
          {p.dailySummary.glucose_peak_per_meal_min > 0 && (
            <li>
              <Trans>Largest glucose drop after a meal:</Trans>&nbsp;
              {p.dailySummary.glucose_peak_per_meal_min?.toFixed(2)} <Trans>mmol/L after</Trans>{' '}
              {(p.dailySummary as any).min_meals_food_item_glucose_increases /* XXX */}
            </li>
          )}
          <li>
            <Trans>Time out of range:</Trans>&nbsp;
            {formatMinutes(p.dailySummary.out_of_range_min)}
          </li>
        </ul>
      </div>
    </ReportSection>
  );
};
