/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { TaskResultResponse } from '../models';
/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the status of a task
         * @param {string} task_id 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiTaskGetTaskResult: async (task_id: string, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'task_id' is not null or undefined
            assertParamExists('appApiTaskGetTaskResult', 'task_id', task_id)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('appApiTaskGetTaskResult', 'token', token)
            const localVarPath = `/tasks/{task_id}/result`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(task_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the status of a task
         * @param {string} task_id 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiTaskGetTaskResult(task_id: string, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiTaskGetTaskResult(task_id, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TasksApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the status of a task
         * @param {TasksApiAppApiTaskGetTaskResultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiTaskGetTaskResult(requestParameters: TasksApiAppApiTaskGetTaskResultRequest, options?: AxiosRequestConfig): AxiosPromise<TaskResultResponse> {
            return localVarFp.appApiTaskGetTaskResult(requestParameters.task_id, requestParameters.token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiTaskGetTaskResult operation in TasksApi.
 * @export
 * @interface TasksApiAppApiTaskGetTaskResultRequest
 */
export interface TasksApiAppApiTaskGetTaskResultRequest {
    /**
     * 
     * @type {string}
     * @memberof TasksApiAppApiTaskGetTaskResult
     */
    readonly task_id: string

    /**
     * 
     * @type {string}
     * @memberof TasksApiAppApiTaskGetTaskResult
     */
    readonly token: string
}

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
    /**
     * 
     * @summary Get the status of a task
     * @param {TasksApiAppApiTaskGetTaskResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public appApiTaskGetTaskResult(requestParameters: TasksApiAppApiTaskGetTaskResultRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).appApiTaskGetTaskResult(requestParameters.task_id, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }
}

