import axios from 'axios';
import { hostUrl, userApi } from '../api';
import { useStore, useStoreDispatch } from '../context';

export const AuthService = () => {
  const store = useStore();
  const { setToken, setIsInternalUser } = useStoreDispatch();

  function strEndsWith(str, suffix) {
    return str.match(suffix + '$') == suffix;
  }
  const authenticate = async (email: string, password: string, otp?: string) => {
    const formData = new FormData();
    /* eslint-disable i18next/no-literal-string */
    formData.append('username', email);
    /* eslint-disable i18next/no-literal-string */
    formData.append('password', password);
    /* eslint-disable i18next/no-literal-string */
    formData.append('token', otp);
    /* eslint-disable i18next/no-literal-string */
    formData.append('client_id', '2');
    /* eslint-disable i18next/no-literal-string */
    formData.append('redirect_uri', '/');
    /* eslint-disable i18next/no-literal-string */
    formData.append('response_type', 'token');

    const response = await axios.post(hostUrl + '/api/oauth/authorize', formData);

    const jsonResponse = await response.data;

    if (!jsonResponse.clinician) {
      jsonResponse.success = false;
      jsonResponse.reason = 'not_clinician';
    }

    if (response.status !== 200) {
      return jsonResponse;
    }

    // this should be used for cosmetic purposes only, and not hiding data
    if (strEndsWith(email, 'rxfood.co') || strEndsWith(email, 'inneranalytics.com')) {
      setIsInternalUser(true);
    } else {
      setIsInternalUser(false);
    }

    const { access_token } = jsonResponse.oauth_token;
    setToken(access_token);

    return jsonResponse;
  };

  const authenticateWithSecureToken = async (secureToken) => {
    const formData = new FormData();
    formData.append('secure_token', secureToken);
    formData.append('client_id', '2');
    formData.append('redirect_uri', '/');
    formData.append('response_type', 'token');

    const response = await axios.post(hostUrl + '/api/oauth/authorize_magic_link', formData);

    const jsonResponse = await response.data;

    if (response.status !== 200) {
      return jsonResponse;
    }

    const email = jsonResponse.clinician.email;
    // this should be used for cosmetic purposes only, and not hiding data
    if (strEndsWith(email, 'rxfood.co') || strEndsWith(email, 'inneranalytics.com')) {
      setIsInternalUser(true);
    } else {
      setIsInternalUser(false);
    }

    const { access_token } = jsonResponse.oauth_token;
    setToken(access_token);

    return jsonResponse;
  };

  const resetPassword = async (email: string) => {
    return await userApi.appApiUserRequestPasswordResetEmail({ email });
  };

  return { authenticate, authenticateWithSecureToken, resetPassword };
};
