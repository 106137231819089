import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useAsyncResult } from 'react-use-async-result';
import { useStore, useStoreDispatch } from '../context';
import { PatientService } from '../services/patient';
import './patient-reports.scss';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { ErrorFlagsModal, GenerateReport } from '../components/generateReport';
import { TFunc, Trans, useTranslation } from '../i18n';
import { ClinicService } from '../services/clinic';
import '../layout/layout.scss';
import { useQueries, useQuery } from '@tanstack/react-query';
import { reportingApi } from '../api';
import { PatientReportResponse, SummaryStatementResponse, SummaryStatementRowResponse } from '../api/generated/models';

const PdfViewer = (props: { reportId: number }) => {
  const pdfRes = useAsyncResult<any>();
  const patientService = PatientService();

  useEffect(() => {
    pdfRes.clear();
    if (!props.reportId) {
      return;
    }

    pdfRes.bind(patientService.getPatientReportPdf(props.reportId));

    return () => {
      console.log('revoking object url');
      pdfRes.isDone && URL.revokeObjectURL(pdfRes.result);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reportId]);

  if (pdfRes.isError) {
    return (
      <div style={{ color: 'red' }}>
        <Trans>Unable to load PDF file</Trans>: {'' + pdfRes.error}
      </div>
    );
  }

  if (!pdfRes.isDone) {
    return (
      <div>
        <Trans>Loading...</Trans>
      </div>
    );
  }

  return <object data={pdfRes.result} className="pdfViewer" type="application/pdf" />;
};

const Reports = (props) => {
  const { patient, clinician, allReports } = useStore();
  const { setAllPatientReports } = useStoreDispatch();
  const patientService = PatientService();
  const [expandedReports, setExpandedReports] = React.useState({});
  const clinicianGenReportsAvailable = React.useRef(false);
  const rxfoodGenReportsAvailable = React.useRef(false);
  const sentReportsAvailable = React.useRef(false);
  const [showReportGen, setReportGen] = useState(false);
  const [showErrorFlags, setShowErrorFlags] = useState(false);
  const [errorFlagReport, setErrorFlagReport] = useState<PatientReportResponse>(null);
  const [clinicId, setClinicId] = useState(0);
  const [hospitalId, setHospitalId] = useState(0);
  const [draftPending, setDraftPending] = useState(false);
  const [showClinicalSummaries, setShowClinicalSummaries] = useState({});
  const { t } = useTranslation();

  const clinicReq = useQuery(['patient-clinics-req', patient.patient_id], async () => {
    return patientService.getPatientClinics(patient.patient_id);
  });
  const p_clinics: [patientId: number, hospitalId: number][] = clinicReq.data?.map((pid) => {
    if (clinician.clinics.filter(id => id == pid.id)) {
      return [pid.id, pid.hospital_id];
    }
    return [null, null];
  });

  useEffect(() => {
    if (!p_clinics) {
      return;
    }
    setClinicId(p_clinics[0][0]);
    setHospitalId(p_clinics[0][1]);
  }, [p_clinics]);

  const fetchAllPatientReports = async () => {
    // setAllPatientReports([]);
    const fetchClinics = async () => {
      if (!p_clinics) {
        return;
      }
      const allReports: PatientReportResponse[] = [];
      await Promise.all(p_clinics.map(async (p) => {
        if (!p[0]) {
          return;
        }

        const reports = await patientService.getAllPatientReports(patient.patient_id, p[1], p[0]);
        if (!reports) {
          console.log('no reports');
          return;
        }
        allReports.push(...reports);
        allReports.sort((a, b) => {
          return moment(b.created_time).diff(moment(a.created_time));
        });
        setAllPatientReports(allReports.concat());
      }));
      setAllPatientReports(allReports.concat());
    };
    fetchClinics();
  };

  useEffect(() => {
    fetchAllPatientReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.patient_id, clinicReq.data, clinician.clinics]);

  useEffect(() => {
    const defaultExpandedReports = {};
    for (const r of allReports) {
      defaultExpandedReports[r.patient_report_id] = false;
    }
    // console.log(defaultExpandedReports);
    setExpandedReports(defaultExpandedReports);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allReports.length]);

  async function getImageForReport(image_blob) {
    const blob = new Blob([image_blob], { type: 'image/jpeg' });
    const blobUrl = URL.createObjectURL(image_blob);
    console.log(blobUrl);
    return blobUrl;
  }
  t;
  function setPdfViewer(id, open) {
    const newDict = {};
    for (const x of allReports) {
      if (x.patient_report_id == id) {
        newDict[id] = open;
      } else {
        newDict[x.patient_report_id] = false;
      }
    }
    setExpandedReports(newDict);
  }

  const reportTypeDisplayText = {
    'default': t('Report'),
    'comparison_report': t('Comparison Report'),
    'cgm_report': t('CGM Report'),
    'rxfood': t('RxFood Report'),
  };

  function checkReportsAvailable() {
    for (const r of allReports) {
      if (r.report_type in reportTypeDisplayText && r.status == 'draft' && r.created_by_user_id == clinician.id) {
        clinicianGenReportsAvailable.current = true;
      } else if (
        r.report_type in reportTypeDisplayText && r.status == 'draft' && r.created_by_user_id !== clinician.id
      ) {
        rxfoodGenReportsAvailable.current = true;
      } else if (r.report_type in reportTypeDisplayText && r.status == 'sent') {
        sentReportsAvailable.current = true;
      }
    }
  }

  checkReportsAvailable();

  const reportQueries = useQueries({
    queries: allReports.map((item, idx) => {
      return {
        queryKey: ['report-summary', item.patient_report_id],
        queryFn: async () => {
          const reportData = await reportingApi.appApiPatientReportsApiGetPatientReportData({
            patient_id: item.patient_id,
            hospital_id: item.hospital_id,
            clinic_id: item.clinic_id,
            patient_report_id: item.patient_report_id,
          });
          return reportData.data;
        },
      };
    }),
  });
  const reportClinicalSummary = reportQueries.map(q => q.data?.clinical_summary_statement);

  const errorMessageRegex = /custom|awaiting verification|with more than/;

  const processSendReport = (report: PatientReportResponse) => {
    const isErrorFlag = report.error_flags.find(e => errorMessageRegex.test(e.message));
    if (isErrorFlag) {
      setShowErrorFlags(true);
      setErrorFlagReport(report);
    } else {
      patientService.setReportSent(
        hospitalId,
        clinicId,
        report.patient_id,
        report.patient_report_id,
      );
      fetchAllPatientReports();
    }
  };

  return (
    <div>
      <div style={{ marginBottom: '100px' }}>
        <br />
        <div className="sectionHeader">
          <div>
            <p className="goalDetail">
              <Trans>Patient Reports</Trans>
            </p>
            <div>
              <Trans>Latest reports first, click to view PDF</Trans>
            </div>
          </div>
          {(clinician.is_report_admin || clinician.is_report_previewer
            || clinician?.flags?.clinician_can_generate_reports) && (
            <Button onClick={() => setReportGen(true)}>
              <Trans>Generate New Report</Trans>
            </Button>
          )}
        </div>

        <div className="mt-4 mb-1" style={{ fontSize: '14px' }}>
          <Trans>
            If a report is missing you can contact support (
            {/**/}
            <a
              href="mailto:support@rxfood.co?bcc=dlichti@rxfood.co&subject=Clinician Portal Report Missing"
              target="_blank"
              className="emailLink"
            >
              support@rxfood.co<i className="fas fa-external-link" />
            </a>
            {/**/}
            ). If it is urgent (i.e. needed during the current day) you can also call 1-888-998-6181.'
          </Trans>
        </div>

        {clinicReq.isError
          ? (
            <div>
              <h6>
                <Trans>Error loading patient reports</Trans>
              </h6>
            </div>
          )
          : (
            (clinician.is_report_admin || clinician?.flags?.clinician_can_generate_reports) && (
              <div>
                <br />
                <h6>
                  <Trans>Clinician Generated Reports</Trans>
                </h6>
                {draftPending && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                      width: 400,
                      color: 'grey.500',
                      margin: 10,
                      gap: 10,
                    }}
                  >
                    <CircularProgress
                      size={20}
                      thickness={5}
                    />
                    <div>
                      <Trans>Generating Report...</Trans>
                    </div>
                  </div>
                )}
                {!clinicianGenReportsAvailable.current && (
                  <div>
                    <Trans>No clinician generated reports available.</Trans>
                  </div>
                )}
                {[...allReports].map((item, idx) => {
                  const pdfOpened = expandedReports[item.patient_report_id];
                  const hoverText = t('Report Created {{createdTime}}; Updated {{updatedTime}}', {
                    createdTime: moment(item.created_time).format('YYYY-MM-DD HH:MM'),
                    updatedTime: moment(item.updated_time).format('YYYY-MM-DD HH:MM'),
                  });
                  if (
                    item.report_type in reportTypeDisplayText && item.status == 'draft'
                    && item.created_by_user_id == clinician.id
                  ) {
                    return (
                      <div
                        key={idx}
                        id={'' + item.patient_report_id}
                        style={{ paddingBottom: '0.5rem', cursor: 'pointer' }}
                      >
                        <label
                          onClick={() => {
                            setPdfViewer(item.patient_report_id, !pdfOpened);
                          }}
                          title={hoverText}
                          // eslint-disable-next-line i18next/no-literal-string
                        >
                          [{pdfOpened
                            ? '-'
                            // eslint-disable-next-line i18next/no-literal-string
                            : '+'}]{' '}
                          <span className="reportBtnLink">
                            {reportTypeDisplayText[item.report_type]} - {moment(item.date_since).format('YYYY-MM-DD')}
                            {' '}
                            <Trans>to</Trans> {moment(item.date_until).format('YYYY-MM-DD')}
                          </span>
                        </label>
                        {(clinician.is_report_admin || clinician?.flags?.clinician_can_generate_reports) && (
                          <i
                            className="fas fa-envelope"
                            style={{ width: '36px', paddingLeft: '12px', color: '#909090' }}
                            onClick={() => processSendReport(item)}
                            title={t('Send report')}
                          />
                        )}
                        {(clinician.is_report_admin || clinician?.flags?.clinician_can_generate_reports) && (
                          <i
                            className="fas fa-redo"
                            style={{ width: '36px', paddingLeft: '6px', color: '#909090' }}
                            onClick={() => patientService.regenerateReport(item.patient_report_id)}
                            title={t('Regenerate report')}
                          />
                        )}
                        {(clinician.is_report_admin || clinician?.flags?.clinician_can_generate_reports) && (
                          <i
                            className="fas fa-trash-alt"
                            style={{ width: '36px', paddingLeft: '3px', color: '#909090' }}
                            onClick={() => {
                              patientService.deleteReport(
                                hospitalId,
                                clinicId,
                                patient.patient_id,
                                item.patient_report_id,
                              );
                              setAllPatientReports(
                                allReports.filter((r) => r.patient_report_id !== item.patient_report_id),
                              );
                            }}
                            title={t('Delete report')}
                          />
                        )}
                        <button onClick={() => setShowClinicalSummaries((prev) => ({ ...prev, [idx]: !prev[idx] }))}>
                          <Trans>Clinical Summary</Trans>
                        </button>

                        {!!item.dates_exclude?.length && (
                          <div style={{ marginTop: '-5px', marginLeft: '25px', fontSize: '12px' }}>
                            {' '}
                            <Trans>excluding</Trans>{'  '}{item.dates_exclude.map((date, idx) => (
                              <span key={idx}>
                                {moment(date).format('YYYY-MM-DD')}
                                {idx < item.dates_exclude.length - 1 ? ', ' : ''}
                              </span>
                            ))}
                          </div>
                        )}
                        {showClinicalSummaries[idx] && (
                          <ClinicalSummaryText
                            reportClinicalSummary={reportClinicalSummary}
                            t={t}
                            idx={idx}
                          />
                        )}
                        {pdfOpened && <PdfViewer reportId={item.patient_report_id} />}
                      </div>
                    );
                  }
                })}
              </div>
            )
          )}
        <div>
          <br />
          <h6>
            <Trans>RxFood Generated Reports</Trans>
          </h6>
          {!rxfoodGenReportsAvailable.current
            && (
              <div>
                <Trans>No RxFood generated reports available.</Trans>
              </div>
            )}
          {[...allReports].map((item, idx) => {
            const pdfOpened = expandedReports[item.patient_report_id];
            const hoverText = t('Report Created {{createdTime}}; Updated {{updatedTime}}', {
              createdTime: moment(item.created_time).format('YYYY-MM-DD HH:MM'),
              updatedTime: moment(item.updated_time).format('YYYY-MM-DD HH:MM'),
            });
            if (
              item.report_type in reportTypeDisplayText && item.status == 'draft'
              && item.created_by_user_id !== clinician.id
            ) {
              return (
                <div key={idx} id={'' + item.patient_report_id} style={{ paddingBottom: '0.5rem', cursor: 'pointer' }}>
                  <label
                    onClick={() => {
                      setPdfViewer(item.patient_report_id, !pdfOpened);
                    }}
                    title={hoverText}
                    // eslint-disable-next-line i18next/no-literal-string
                  >
                    [{pdfOpened
                      ? '-'
                      // eslint-disable-next-line i18next/no-literal-string
                      : '+'}]{' '}
                    <span className="reportBtnLink">
                      {reportTypeDisplayText[item.report_type]} - {moment(item.date_since).format('YYYY-MM-DD')}{' '}
                      <Trans>to</Trans> {moment(item.date_until).format('YYYY-MM-DD')}
                    </span>
                  </label>
                  {(clinician.is_report_admin || clinician?.flags?.clinician_can_generate_reports) && (
                    <i
                      className="fas fa-envelope"
                      style={{ width: '36px', paddingLeft: '12px', color: '#909090' }}
                      onClick={() => {
                        patientService.setReportSent(
                          hospitalId,
                          clinicId,
                          patient.patient_id,
                          item.patient_report_id,
                        );
                        fetchAllPatientReports();
                      }}
                      title={t('Send report')}
                    />
                  )}
                  {(clinician.is_report_admin || clinician?.flags?.clinician_can_generate_reports) && (
                    <i
                      className="fas fa-redo"
                      style={{ width: '36px', paddingLeft: '6px', color: '#909090' }}
                      onClick={() => patientService.regenerateReport(item.patient_report_id)}
                      title={t('Regenerate report')}
                    />
                  )}
                  {(clinician.is_report_admin || clinician?.flags?.clinician_can_generate_reports) && (
                    <i
                      className="fas fa-trash-alt"
                      style={{ width: '36px', paddingLeft: '3px', color: '#909090' }}
                      onClick={() => {
                        patientService.deleteReport(hospitalId, clinicId, patient.patient_id, item.patient_report_id);
                        setAllPatientReports(
                          allReports.filter((r) => r.patient_report_id !== item.patient_report_id),
                        );
                      }}
                      title={t('Delete report')}
                    />
                  )}
                  <button
                    onClick={() =>
                      setShowClinicalSummaries((prev) => ({ ...prev, [idx]: !prev[idx] }))}
                  >
                    <Trans>Clinical Summary</Trans>
                  </button>
                  {showClinicalSummaries[idx] && (
                    <ClinicalSummaryText
                      reportClinicalSummary={reportClinicalSummary}
                      t={t}
                      idx={idx}
                    />
                  )}
                  {pdfOpened && <PdfViewer reportId={item.patient_report_id} />}
                  {!!item.dates_exclude?.length && (
                    <div style={{ marginTop: '-5px', marginLeft: '25px', fontSize: '12px' }}>
                      {' '}
                      <Trans>excluding</Trans>{'  '}{item.dates_exclude.map((date, idx) => (
                        <span key={idx}>
                          {moment(date).format('YYYY-MM-DD')}
                          {idx < item.dates_exclude.length - 1 ? ', ' : ''}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              );
            }
          })}
        </div>

        <br />
        <h6>
          <Trans>Sent Reports</Trans>
        </h6>
        {!sentReportsAvailable.current && (
          <div>
            <Trans>No sent reports available.</Trans>
          </div>
        )}
        {[...allReports].map((item, idx) => {
          const pdfOpened = expandedReports[item.patient_report_id];
          const hoverText = t('Report Created {{createdTime}}; Updated {{updatedTime}}', {
            createdTime: moment(item.created_time).format('YYYY-MM-DD HH:MM'),
            updatedTime: moment(item.updated_time).format('YYYY-MM-DD HH:MM'),
          });
          if (item.report_type in reportTypeDisplayText && item.status == 'sent') {
            return (
              <div key={idx} id={'' + item.patient_report_id} style={{ paddingBottom: '0.5rem', cursor: 'pointer' }}>
                <label
                  onClick={() => {
                    setPdfViewer(item.patient_report_id, !pdfOpened);
                  }}
                  title={hoverText}
                  // eslint-disable-next-line i18next/no-literal-string
                >
                  [{pdfOpened
                    ? '-'
                    // eslint-disable-next-line i18next/no-literal-string
                    : '+'}]{' '}
                  <span className="reportBtnLink">
                    {reportTypeDisplayText[item.report_type]} - {moment(item.date_since).format('YYYY-MM-DD')}{' '}
                    <Trans>to</Trans> {moment(item.date_until).format('YYYY-MM-DD')}
                  </span>
                </label>
                <button
                  style={{ marginLeft: '10px' }}
                  onClick={() =>
                    setShowClinicalSummaries((prev) => ({ ...prev, [idx]: !prev[idx] }))}
                >
                  <Trans>Clinical Summary</Trans>
                </button>
                {showClinicalSummaries[idx] && (
                  <ClinicalSummaryText
                    reportClinicalSummary={reportClinicalSummary}
                    t={t}
                    idx={idx}
                  />
                )}
                {pdfOpened && <PdfViewer reportId={item.patient_report_id} />}
                {!!item.dates_exclude?.length && (
                  <div style={{ marginTop: '-5px', marginLeft: '25px', fontSize: '12px' }}>
                    {' '}
                    <Trans>excluding</Trans>{'  '}{item.dates_exclude.map((date, idx) => (
                      <span key={idx}>
                        {moment(date).format('YYYY-MM-DD')}
                        {idx < item.dates_exclude.length - 1 ? ', ' : ''}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            );
          }
        })}
      </div>
      <GenerateReport
        show={showReportGen}
        toggle={() => {
          setReportGen(!showReportGen);
        }}
        draftPending={draftPending}
        setDraftPending={setDraftPending}
      />
      {errorFlagReport && (
        <ErrorFlagsModal
          show={showErrorFlags}
          toggle={() => setShowErrorFlags(!showErrorFlags)}
          report={errorFlagReport}
          errorMessageRegex={errorMessageRegex}
          fetchReports={fetchAllPatientReports}
        />
      )}
    </div>
  );
};

const ClinicalSummaryText = (props: {
  reportClinicalSummary: SummaryStatementResponse[],
  t: TFunc,
  idx: number,
}) => {
  const { reportClinicalSummary, t, idx } = props;
  const textRef = useRef(null);
  const copyRes = useAsyncResult();
  const copyToClipboard = () => {
    if (textRef.current) {
      const htmlContent = generateHtmlContent(textRef.current);
      const textContent = generateTextContent(textRef.current);
      copyRes.bind(navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([htmlContent], { type: 'text/html' }),
          'text/plain': new Blob([textContent], { type: 'text/plain' }),
        }),
      ]));
    }
  };
  return (
    <div style={{ backgroundColor: '#F1F1F1', padding: '20px 50px 20px 30px', maxWidth: '900px' }}>
      <div style={{ cursor: 'pointer', float: 'right' }}>
        <Tooltip placement="top" title={copyRes.isDone ? t('Copied!') : t('Copy to clipboard')}>
          <i
            className="fas fa-copy"
            style={{ width: '36px', paddingLeft: '3px', color: '#909090' }}
            onClick={copyToClipboard}
          />
        </Tooltip>
      </div>
      <div ref={textRef}>
        <div>
          <Trans>Clinical Summary</Trans>
        </div>
        {formatClinicalSummary(reportClinicalSummary[idx]?.results, t)?.map((s, idx) => (
          <div key={idx} style={{ fontSize: '14px' }}>
            <div style={{ fontWeight: 600 }}>
              {t(s.label)}
            </div>
            <ul>{s.list}</ul>
          </div>
        ))}
      </div>
    </div>
  );
};

const formatListItem = (text: string, idx: number) => {
  if (!text) {
    return null;
  }
  const [firstWord, ...restOfStr] = text.split(' ');
  const formattedRestOfStr = restOfStr.join(' ').replace(/[\[\]]/g, '');
  return (
    <li key={idx}>
      <span style={{ fontWeight: 600 }}>{firstWord}</span>
      {' ' + formattedRestOfStr}
    </li>
  );
};
const formatClinicalSummary = (results: SummaryStatementRowResponse[], t: TFunc) => {
  const energy = results
    ?.find(s => s.sub_category === 'unhealty_fat_kcal')
    ?.text?.split(/(?=was)|(?=et trans)/)
    ?.map((s, idx) => (idx === 0 ? <span key={idx} style={{ fontWeight: 600 }}>{s.toLowerCase()}</span> : s));
  const macroDA = results
    ?.find(s => s.sub_category === 'by_type')
    ?.text;
  const formattedMacroDA = macroDA
    ?.split(/[:,]/)
    ?.map((s, idx) => (idx === 0 ? null : formatListItem(s?.trim(), idx)))
    ?.concat(<li key="energy">{energy}</li>);
  const microDA = results
    ?.filter(s => s.category === 'rdi_summary')
    ?.map(s => s.text?.split(/\bAverage\b|\bApport moyen\b/)[1]?.trim());
  const formattedMicroDA = microDA?.map((s, idx) => formatListItem(s, idx));
  const totalItems = results
    ?.find(s => s.sub_category === 'protein' && s.text.startsWith(t('A total')))
    ?.text?.split(/\.\s/);
  const frequenciesTitle = totalItems?.[0].endsWith('.') ? totalItems?.[0] : totalItems?.[0].concat('. ');
  const beverages = totalItems?.slice(1);
  const frequencies = results
    ?.filter(s => (
      (s.sub_category === 'protein'
        && !s.text.startsWith(t('A total')))
      || (s.sub_category === 'fried_food_freq')
    ))
    ?.map(s => s.text?.split(/\.\s/));
  const frequenciesBeverages = beverages ? frequencies.concat([beverages]) : frequencies;

  const formattedFrequencies = frequenciesBeverages?.map((s, idx) => {
    const firstStr = s[0]?.split(/\,\s/)?.[1] || s[0];
    const firstSentence = firstStr?.endsWith('.') ? firstStr : firstStr?.concat('. ');
    const restOfSentence = s.slice(1);
    return (
      <li key={idx}>
        <span style={{ fontWeight: 600 }}>{firstSentence}</span>
        {restOfSentence && restOfSentence.map(s => s)}
      </li>
    );
  });

  return [
    { label: t('Macronutrients (daily avg.)'), list: formattedMacroDA },
    { label: t('Micronutrients (daily avg.)'), list: formattedMicroDA },
    { label: frequenciesTitle, list: formattedFrequencies },
  ];
};

const generateHtmlContent = (element) => {
  const wrapper = document.createElement('div');
  wrapper.appendChild(element.cloneNode(true));
  // eslint-disable-next-line i18next/no-literal-string
  const elementsToRemoveAttributes = wrapper.querySelectorAll('*[class], *[id], *[style]');
  elementsToRemoveAttributes.forEach((el) => {
    el.removeAttribute('class');
    el.removeAttribute('id');
    el.removeAttribute('style');
  });
  return wrapper.innerHTML;
};

const generateTextContent = (element) => {
  const formattedText = element.innerText
    .replace(/<\/?(?:div|p|span|strong|b|em|i|ul)>/g, '')
    .replace(/<li>/g, '- ')
    .replace(/<\/li>/g, '\n');
  return formattedText;
};

export default Reports;
