/* eslint-disable i18next/no-literal-string */
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { grayscale as grayscaleTheme } from 'victory-core/lib/victory-theme/grayscale';

export const cgmReadingMaxIntervalMinutes = 20;

export const glucoseChartLowerBound = 1.5;
export const glucoseChartUpperBound = 12.5;

export const glucoseTargets = {
  'lowDanger': {
    mmMolLower: 0,
    mmMolUpper: 2,
    color: '#FF9024',
    label: 'Very low',
  },
  'low': {
    mmMolLower: 2,
    mmMolUpper: 4,
    color: '#FFDC00',
    label: 'Low',
  },
  'target': {
    mmMolLower: 4,
    mmMolUpper: 10,
    color: '#36C2B4',
    label: 'In range',
  },
  'high': {
    mmMolLower: 10,
    mmMolUpper: 13.3,
    color: '#FFDC00',
    label: 'High',
  },
  'highDanger': {
    mmMolLower: 13.3,
    mmMolUpper: 100,
    color: '#FF9024',
    label: 'Very high',
  },
};

export const mmMolToTarget = (mmMolValue: number) => {
  for (const targetName in glucoseTargets) {
    const target = glucoseTargets[targetName];
    if (mmMolValue >= target.mmMolLower && mmMolValue < target.mmMolUpper) {
      return [targetName, target];
    }
  }
  return [null, null];
};

export const mmMolTargetRange = {
  lower: 4,
  upperWarn: 10,
  upperDanger: 13.3,
};

export const cgmColors = {
  bgInTarget: '#36C2B4',
  bgNearTarget: '#FFDC00',
  bgOutsideTarget: '#FF9024',
};

export const victoryTheme = {
  ...grayscaleTheme,
  axis: {
    ...grayscaleTheme.axis,
    style: {
      ...grayscaleTheme.axis.style,
      axisLabel: {
        ...grayscaleTheme.axis.style.axisLabel,
        fontFamily: 'Publico Headline Web',
        fill: '#7D7D7D' as any,
        fontSize: 12,
        padding: 25,
      },
      tickLabels: {
        ...grayscaleTheme.axis.style.tickLabels,
        fontFamily: 'geomanist',
        fontSize: 6,
        letterSpacing: 2,
        padding: 4,
      },
      ticks: {
        stroke: 'black',
        size: 3,
      },
    },
  },
};

export const titleCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const roundToInt = (roundTo: number, value: number) => {
  return Math.round((value + roundTo) / roundTo) * roundTo;
};

export const floorToInt = (floorTo: number, value: number) => {
  return Math.floor((value + floorTo) / floorTo) * floorTo;
};

export const ceilToInt = (ceilTo: number, value: number) => {
  return Math.ceil((value + ceilTo) / ceilTo) * ceilTo;
};

export const formatPct = (pct: number, digits: number = 1) => {
  return `${pct.toFixed(digits)}%`;
};

export const dateTimeToTimestampMS = (dateStr: string, timeStr: string): number => {
  if (timeStr.indexOf(' ') >= 0) {
    return new Date(timeStr).getTime();
  }
  return new Date(`${dateStr} ${timeStr}`).getTime();
};

export const timestampToTimestampMS = (timestamp: string) => {
  return new Date(timestamp.replace('Z', '')).getTime();
};

/**
 * Converts a 24h time string to a 12h time string.
 * > timeStrToAmPm('11:00:00') -> '11:00 AM'
 */
export const timeStrToAmPm = (timeStr: string) => {
  return moment(timeStr, 'HH:mm:ss').format('h:mm A');
};

/**
 * Formats a number of minutes as hours, minutes.
 * formatMinutes(65) -> '1 hour, 5 minutes'
 */
export const formatMinutes = (m: number) => {
  const hours = Math.floor(m / 60);
  const minutes = m % 60;
  return `${hours} hour${hours === 1 ? '' : 's'}, ${minutes} minute${minutes === 1 ? '' : 's'}`;
};

let _useIdCounter = 0;
export const useId = () => {
  _useIdCounter += 1;
  const [id, _] = React.useState(_useIdCounter);
  return id;
};

export const resultMealDotValue = (
  glucoseValues: any[],
  type: string,
  item: any,
  idx?: number,
  minTimeStr?: string,
  maxTimeStr?: string,
): number => {
  const timestamp = `${item[type + '_date']}T${item[type + '_time']}`;
  const sortedValues = _.sortBy(glucoseValues, 'timestamp');

  const minTime = minTimeStr && minTimeStr.replace(' ', 'T'); // Convert '2020-01-01 00:00:00' to '2020-01-01T00:00:00'
  const maxTime = maxTimeStr && maxTimeStr.replace(' ', 'T');

  const nearestPoint = sortedValues.find(point => (
    point.timestamp > timestamp
    && (!minTime || point.timestamp >= minTime)
    && (!maxTime || point.timestamp <= maxTime)
  ));

  const offset = idx ? (idx % 3) / 2 : 0;
  return offset + (nearestPoint == null ? 0 : nearestPoint.value);
};
