import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans } from '../i18n';
const Policy: React.FunctionComponent = (props) => {
  return (
    <Container>
      <Row>
        <Col />
        <Col>
          <Trans>
            Policy statement
          </Trans>
        </Col>
      </Row>
    </Container>
  );
};

export default Policy;
