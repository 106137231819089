/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserResetPasswordByTokenRequest } from '../models';
// @ts-ignore
import { UserResponse } from '../models';
// @ts-ignore
import { UserTestPushRequest } from '../models';
// @ts-ignore
import { UserUpdateFcmRegistrationTokenRequest } from '../models';
// @ts-ignore
import { UserUpdateRequest } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get currently logged in user. This is for restricted use case, do NOT use this for current patient or clinician information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserGetCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary patch user information
         * @param {number} user_id 
         * @param {UserUpdateRequest} UserUpdateRequest User information to put. Only non-null provided field will be updated in the server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserPatchUser: async (user_id: number, UserUpdateRequest: UserUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiUserPatchUser', 'user_id', user_id)
            // verify required parameter 'UserUpdateRequest' is not null or undefined
            assertParamExists('appApiUserPatchUser', 'UserUpdateRequest', UserUpdateRequest)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UserUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user device\'s FCM registration token
         * @param {number} user_id 
         * @param {UserUpdateFcmRegistrationTokenRequest} UserUpdateFcmRegistrationTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserPostFcmRegistrationToken: async (user_id: number, UserUpdateFcmRegistrationTokenRequest: UserUpdateFcmRegistrationTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiUserPostFcmRegistrationToken', 'user_id', user_id)
            // verify required parameter 'UserUpdateFcmRegistrationTokenRequest' is not null or undefined
            assertParamExists('appApiUserPostFcmRegistrationToken', 'UserUpdateFcmRegistrationTokenRequest', UserUpdateFcmRegistrationTokenRequest)
            const localVarPath = `/users/{user_id}/fcm_registration_token`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UserUpdateFcmRegistrationTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put user information.
         * @param {number} user_id 
         * @param {UserUpdateRequest} UserUpdateRequest User information to put. If a field was provided will null value, then it will be overwritten to null.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiUserPutUser: async (user_id: number, UserUpdateRequest: UserUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiUserPutUser', 'user_id', user_id)
            // verify required parameter 'UserUpdateRequest' is not null or undefined
            assertParamExists('appApiUserPutUser', 'UserUpdateRequest', UserUpdateRequest)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UserUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request password reset email link. The unique link for password reset will be sent to user\'s email. The link will expire in 7 days or after used for resetting password.
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserRequestPasswordResetEmail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('appApiUserRequestPasswordResetEmail', 'email', email)
            const localVarPath = `/users/request_password_reset_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request password reset for specific user using the unique token
         * @param {UserResetPasswordByTokenRequest} UserResetPasswordByTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserResetPasswordByToken: async (UserResetPasswordByTokenRequest: UserResetPasswordByTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'UserResetPasswordByTokenRequest' is not null or undefined
            assertParamExists('appApiUserResetPasswordByToken', 'UserResetPasswordByTokenRequest', UserResetPasswordByTokenRequest)
            const localVarPath = `/users/reset_password_by_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UserResetPasswordByTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test push notification for the user
         * @param {number} user_id 
         * @param {UserTestPushRequest} UserTestPushRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserTestPush: async (user_id: number, UserTestPushRequest: UserTestPushRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiUserTestPush', 'user_id', user_id)
            // verify required parameter 'UserTestPushRequest' is not null or undefined
            assertParamExists('appApiUserTestPush', 'UserTestPushRequest', UserTestPushRequest)
            const localVarPath = `/users/{user_id}/test_push`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UserTestPushRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get currently logged in user. This is for restricted use case, do NOT use this for current patient or clinician information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiUserGetCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserGetCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary patch user information
         * @param {number} user_id 
         * @param {UserUpdateRequest} UserUpdateRequest User information to put. Only non-null provided field will be updated in the server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiUserPatchUser(user_id: number, UserUpdateRequest: UserUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserPatchUser(user_id, UserUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user device\'s FCM registration token
         * @param {number} user_id 
         * @param {UserUpdateFcmRegistrationTokenRequest} UserUpdateFcmRegistrationTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiUserPostFcmRegistrationToken(user_id: number, UserUpdateFcmRegistrationTokenRequest: UserUpdateFcmRegistrationTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserPostFcmRegistrationToken(user_id, UserUpdateFcmRegistrationTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put user information.
         * @param {number} user_id 
         * @param {UserUpdateRequest} UserUpdateRequest User information to put. If a field was provided will null value, then it will be overwritten to null.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiUserPutUser(user_id: number, UserUpdateRequest: UserUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserPutUser(user_id, UserUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request password reset email link. The unique link for password reset will be sent to user\'s email. The link will expire in 7 days or after used for resetting password.
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiUserRequestPasswordResetEmail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserRequestPasswordResetEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request password reset for specific user using the unique token
         * @param {UserResetPasswordByTokenRequest} UserResetPasswordByTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiUserResetPasswordByToken(UserResetPasswordByTokenRequest: UserResetPasswordByTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserResetPasswordByToken(UserResetPasswordByTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test push notification for the user
         * @param {number} user_id 
         * @param {UserTestPushRequest} UserTestPushRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiUserTestPush(user_id: number, UserTestPushRequest: UserTestPushRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiUserTestPush(user_id, UserTestPushRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Get currently logged in user. This is for restricted use case, do NOT use this for current patient or clinician information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserGetCurrentUser(options?: AxiosRequestConfig): AxiosPromise<UserResponse> {
            return localVarFp.appApiUserGetCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary patch user information
         * @param {UserApiAppApiUserPatchUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserPatchUser(requestParameters: UserApiAppApiUserPatchUserRequest, options?: AxiosRequestConfig): AxiosPromise<UserResponse> {
            return localVarFp.appApiUserPatchUser(requestParameters.user_id, requestParameters.UserUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user device\'s FCM registration token
         * @param {UserApiAppApiUserPostFcmRegistrationTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserPostFcmRegistrationToken(requestParameters: UserApiAppApiUserPostFcmRegistrationTokenRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiUserPostFcmRegistrationToken(requestParameters.user_id, requestParameters.UserUpdateFcmRegistrationTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put user information.
         * @param {UserApiAppApiUserPutUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiUserPutUser(requestParameters: UserApiAppApiUserPutUserRequest, options?: AxiosRequestConfig): AxiosPromise<UserResponse> {
            return localVarFp.appApiUserPutUser(requestParameters.user_id, requestParameters.UserUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request password reset email link. The unique link for password reset will be sent to user\'s email. The link will expire in 7 days or after used for resetting password.
         * @param {UserApiAppApiUserRequestPasswordResetEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserRequestPasswordResetEmail(requestParameters: UserApiAppApiUserRequestPasswordResetEmailRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiUserRequestPasswordResetEmail(requestParameters.email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request password reset for specific user using the unique token
         * @param {UserApiAppApiUserResetPasswordByTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserResetPasswordByToken(requestParameters: UserApiAppApiUserResetPasswordByTokenRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiUserResetPasswordByToken(requestParameters.UserResetPasswordByTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test push notification for the user
         * @param {UserApiAppApiUserTestPushRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiUserTestPush(requestParameters: UserApiAppApiUserTestPushRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiUserTestPush(requestParameters.user_id, requestParameters.UserTestPushRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiUserPatchUser operation in UserApi.
 * @export
 * @interface UserApiAppApiUserPatchUserRequest
 */
export interface UserApiAppApiUserPatchUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiAppApiUserPatchUser
     */
    readonly user_id: number

    /**
     * User information to put. Only non-null provided field will be updated in the server.
     * @type {UserUpdateRequest}
     * @memberof UserApiAppApiUserPatchUser
     */
    readonly UserUpdateRequest: UserUpdateRequest
}

/**
 * Request parameters for appApiUserPostFcmRegistrationToken operation in UserApi.
 * @export
 * @interface UserApiAppApiUserPostFcmRegistrationTokenRequest
 */
export interface UserApiAppApiUserPostFcmRegistrationTokenRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiAppApiUserPostFcmRegistrationToken
     */
    readonly user_id: number

    /**
     * 
     * @type {UserUpdateFcmRegistrationTokenRequest}
     * @memberof UserApiAppApiUserPostFcmRegistrationToken
     */
    readonly UserUpdateFcmRegistrationTokenRequest: UserUpdateFcmRegistrationTokenRequest
}

/**
 * Request parameters for appApiUserPutUser operation in UserApi.
 * @export
 * @interface UserApiAppApiUserPutUserRequest
 */
export interface UserApiAppApiUserPutUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiAppApiUserPutUser
     */
    readonly user_id: number

    /**
     * User information to put. If a field was provided will null value, then it will be overwritten to null.
     * @type {UserUpdateRequest}
     * @memberof UserApiAppApiUserPutUser
     */
    readonly UserUpdateRequest: UserUpdateRequest
}

/**
 * Request parameters for appApiUserRequestPasswordResetEmail operation in UserApi.
 * @export
 * @interface UserApiAppApiUserRequestPasswordResetEmailRequest
 */
export interface UserApiAppApiUserRequestPasswordResetEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiAppApiUserRequestPasswordResetEmail
     */
    readonly email: string
}

/**
 * Request parameters for appApiUserResetPasswordByToken operation in UserApi.
 * @export
 * @interface UserApiAppApiUserResetPasswordByTokenRequest
 */
export interface UserApiAppApiUserResetPasswordByTokenRequest {
    /**
     * 
     * @type {UserResetPasswordByTokenRequest}
     * @memberof UserApiAppApiUserResetPasswordByToken
     */
    readonly UserResetPasswordByTokenRequest: UserResetPasswordByTokenRequest
}

/**
 * Request parameters for appApiUserTestPush operation in UserApi.
 * @export
 * @interface UserApiAppApiUserTestPushRequest
 */
export interface UserApiAppApiUserTestPushRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiAppApiUserTestPush
     */
    readonly user_id: number

    /**
     * 
     * @type {UserTestPushRequest}
     * @memberof UserApiAppApiUserTestPush
     */
    readonly UserTestPushRequest: UserTestPushRequest
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Get currently logged in user. This is for restricted use case, do NOT use this for current patient or clinician information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public appApiUserGetCurrentUser(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).appApiUserGetCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary patch user information
     * @param {UserApiAppApiUserPatchUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public appApiUserPatchUser(requestParameters: UserApiAppApiUserPatchUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).appApiUserPatchUser(requestParameters.user_id, requestParameters.UserUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user device\'s FCM registration token
     * @param {UserApiAppApiUserPostFcmRegistrationTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public appApiUserPostFcmRegistrationToken(requestParameters: UserApiAppApiUserPostFcmRegistrationTokenRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).appApiUserPostFcmRegistrationToken(requestParameters.user_id, requestParameters.UserUpdateFcmRegistrationTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put user information.
     * @param {UserApiAppApiUserPutUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public appApiUserPutUser(requestParameters: UserApiAppApiUserPutUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).appApiUserPutUser(requestParameters.user_id, requestParameters.UserUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request password reset email link. The unique link for password reset will be sent to user\'s email. The link will expire in 7 days or after used for resetting password.
     * @param {UserApiAppApiUserRequestPasswordResetEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public appApiUserRequestPasswordResetEmail(requestParameters: UserApiAppApiUserRequestPasswordResetEmailRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).appApiUserRequestPasswordResetEmail(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request password reset for specific user using the unique token
     * @param {UserApiAppApiUserResetPasswordByTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public appApiUserResetPasswordByToken(requestParameters: UserApiAppApiUserResetPasswordByTokenRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).appApiUserResetPasswordByToken(requestParameters.UserResetPasswordByTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test push notification for the user
     * @param {UserApiAppApiUserTestPushRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public appApiUserTestPush(requestParameters: UserApiAppApiUserTestPushRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).appApiUserTestPush(requestParameters.user_id, requestParameters.UserTestPushRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

