import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { clinicianTipApi } from '../api';
import { useStore } from '../context';
import { Trans, useTranslation } from '../i18n';
import { Input } from './input';
import { LabelA } from './label';
import { LoadingSpinner } from './loadingSpinner';

const SendTip = (props) => {
  const [tipText, setTipText] = useState('');
  const { clinician, patient } = useStore();
  const { toggle } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const clinicianTipsDates = {
    date_since: DateTime.now().minus({ year: 1 }).toISODate(),
    date_until: DateTime.now().toISODate(),
  };

  const tipsQuery = useQuery(['clinicianTips', patient.patient_id], async () => {
    const res = await clinicianTipApi.appApiClinicianTipGetClinicianTips({
      patient_id: patient.patient_id,
      date_since: clinicianTipsDates.date_since,
      date_until: clinicianTipsDates.date_until,
    });
    return res.data;
  });
  const tipData = tipsQuery.data;

  const postTipMutation = useMutation({
    mutationFn: async () => {
      return await clinicianTipApi.appApiClinicianTipPostBatchClinicianTip({
        CreateClinicianTipBatchRequest: {
          created_by_clinician_id: clinician.id,
          message: tipText,
          patient_ids: [patient.patient_id],
        },
      });
    },
    onError: (error) => {
      console.log(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('clinicianTips');
    },
  });

  const sendTip = (e) => {
    e.preventDefault();
    if (!tipText) {
      return;
    }
    postTipMutation.mutate();
    toggle();
    setTipText('');
  };

  const handleTipChange = (e) => {
    const tip = e.target.value.substring(0, 300);
    setTipText(tip);
  };

  return (
    <MDBContainer>
      <MDBModal backdrop={false} isOpen={props.show} toggle={toggle} centered size="lg">
        <MDBModalHeader toggle={toggle}>
          <i className="fas fa-link margin-right-s" />
          <Trans>Send a tip</Trans>
        </MDBModalHeader>
        <MDBModalBody>
          <Container>
            <Row>
              <Col className="verticalCenter">
                <LabelA className="font-wide">
                  <Trans>to</Trans> {patient.first_name} {patient.last_name}
                </LabelA>
              </Col>
            </Row>
            <Row>
              <Col className="verticalCenter">
                <Input
                  type="textarea"
                  label={t('Tip text (max 300 characters)')}
                  background
                  size="lg"
                  value={tipText}
                  onChange={handleTipChange}
                />
                {postTipMutation.isError && (
                  <div>
                    <Trans>Error:</Trans>
                    <span>{'' + postTipMutation.error}</span>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="verticalCenter">
                <LabelA className="font-wide">
                  <Trans>History (last 6 months)</Trans>
                </LabelA>
                {tipsQuery.isLoading
                  ? <LoadingSpinner />
                  : tipsQuery.isError
                  ? (
                    <div>
                      <Trans>Error:</Trans>
                      <span>{'' + tipsQuery.error}</span>
                    </div>
                  )
                  : (
                    <div className="mt-3 mb-4">
                      {(tipData?.tips && tipData?.tips.length > 0)
                        ? tipData.tips.map((item, key) => {
                          return (
                            <div key={key} style={{ fontSize: '14px', margin: '5px 0' }}>
                              <span style={{ fontWeight: 600 }}>
                                {moment(item.created_time).format('MMM D, yyyy')}:
                              </span>{' '}
                              {item.message}
                            </div>
                          );
                        })
                        : t('None')}
                    </div>
                  )}
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="margin-bot-l">
                  <Button block onClick={sendTip}>
                    <Trans>send tip</Trans>
                  </Button>
                </p>
              </Col>
            </Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export { SendTip };
