import { Select, styled, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

export const MuiCustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ced4da',
      borderRadius: '0px',
    },
    '&:hover fieldset': {
      borderColor: '#ced4da',
      borderRadius: '0px',
    },
    '&.Mui-focused  fieldset': {
      boxShadow: '0 0 0 0.2rem rgba(54, 194, 180, 0.25)',
      borderColor: '#96e2da',
      borderRadius: '0px',
    },
    '&.Mui-disabled fieldset': {
      borderColor: '#ced4da',
      borderRadius: '0px',
    },
  },
  '& .MuiFormLabel-root': {
    '&.Mui-focused': {
      color: '#36C2B4',
    },
  },
});

export const MuiCustomSelect = styled(Select)(() => ({
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ced4da',
      borderRadius: '0px',
    },
    '&:hover fieldset': {
      borderColor: '#ced4da',
      borderRadius: '0px',
    },
    '&.Mui-focused fieldset': {
      boxShadow: '0 0 0 0.2rem rgba(54, 194, 180, 0.25)',
      borderColor: '#96e2da',
      borderRadius: '0px',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '10px 20px',
  },
}));

// export const MuiCustomDatePicker = styled(DatePicker)(() => ({
//   '&.MuiOutlinedInput-root': {
//     '& fieldset': {
//       borderColor: '#ced4da',
//       borderRadius: '0px',
//     },
//     '&:hover fieldset': {
//       borderColor: '#ced4da',
//       borderRadius: '0px',
//     },
//     '&.Mui-focused fieldset': {
//       boxShadow: '0 0 0 0.2rem rgba(54, 194, 180, 0.25)',
//       borderColor: '#96e2da',
//       borderRadius: '0px',
//     },
//   },
//   '& .MuiOutlinedInput-input': {
//     padding: '10px 20px',
//   },
// }));

export const MuiCustomDatePicker = styled(DatePicker)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ced4da',
      borderRadius: '0px',
    },
    '&:hover fieldset': {
      borderColor: '#ced4da',
      borderRadius: '0px',
    },
    '&.Mui-focused  fieldset': {
      boxShadow: '0 0 0 0.2rem rgba(54, 194, 180, 0.25)',
      borderColor: '#96e2da',
      borderRadius: '0px',
    },
    '&.Mui-disabled fieldset': {
      borderColor: '#ced4da',
      borderRadius: '0px',
    },
  },
  '& .MuiFormLabel-root': {
    '&.Mui-focused': {
      color: '#36C2B4',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '8px 6px',
  },
});
