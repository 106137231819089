/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { PatientDietRestrictionResponse } from './patient-diet-restriction-response';
// May contain unused imports in some cases
// @ts-ignore
import { PatientMotivationResponse } from './patient-motivation-response';
// May contain unused imports in some cases
// @ts-ignore
import { PatientSubscriptionResponse } from './patient-subscription-response';
// May contain unused imports in some cases
// @ts-ignore
import { PatientUsageSummary } from './patient-usage-summary';
// May contain unused imports in some cases
// @ts-ignore
import { RxFeatureFlagsResponse } from './rx-feature-flags-response';

/**
 * 
 * @export
 * @interface PatientResponse
 */
export interface PatientResponse {
    /**
     * 
     * @type {number}
     * @memberof PatientResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'email_verified': boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'zipcode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'country_of_residence'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'is_active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'is_registered': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'use_fast_entry_photo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'use_fast_entry_text'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'use_fast_entry_audio'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'use_search_by_photo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'use_search_by_text'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'use_local_photo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'is_priority'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'sex'?: PatientResponseSexEnum;
    /**
     * calendar date in format YYYY-MM-DD
     * @type {string}
     * @memberof PatientResponse
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'first_meal_time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'meal_time_breakfast'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'meal_time_lunch'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'meal_time_dinner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'diagnosis_feeling'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'tracking_start_date': string;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'patient_type'?: PatientResponsePatientTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'is_shown_carbs'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'is_gender_enabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'use_exercise'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'use_medication'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'use_sleep'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'use_question'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientResponse
     */
    'use_data_screen'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'locale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'created_time': string;
    /**
     * 
     * @type {string}
     * @memberof PatientResponse
     */
    'updated_time': string;
    /**
     * 
     * @type {Array<PatientDietRestrictionResponse>}
     * @memberof PatientResponse
     */
    'diet_restrictions'?: Array<PatientDietRestrictionResponse>;
    /**
     * 
     * @type {Array<PatientMotivationResponse>}
     * @memberof PatientResponse
     */
    'motivations'?: Array<PatientMotivationResponse>;
    /**
     * 
     * @type {PatientUsageSummary}
     * @memberof PatientResponse
     */
    'usage_summary'?: PatientUsageSummary;
    /**
     * 
     * @type {Array<PatientSubscriptionResponse>}
     * @memberof PatientResponse
     */
    'subscriptions'?: Array<PatientSubscriptionResponse>;
    /**
     * 
     * @type {RxFeatureFlagsResponse}
     * @memberof PatientResponse
     */
    'flags': RxFeatureFlagsResponse;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PatientResponse
     */
    'flags_raw'?: { [key: string]: any; };
}

export const PatientResponseSexEnum = {
    Female: 'female',
    Male: 'male',
    Intersex: 'intersex',
    MtfFemale: 'mtf_female',
    FtmMale: 'ftm_male'
} as const;

export type PatientResponseSexEnum = typeof PatientResponseSexEnum[keyof typeof PatientResponseSexEnum];
export const PatientResponsePatientTypeEnum = {
    Research: 'research',
    Collaboration: 'collaboration',
    Wellness: 'wellness',
    Obesity: 'obesity',
    Diabetes: 'diabetes',
    MetabolicSyndrome: 'metabolic syndrome',
    WeightLoss: 'weight loss',
    Migraine: 'migraine',
    GastroesophagealReflux: 'gastroesophageal reflux',
    GiDiscomfort: 'gi discomfort',
    Vertigo: 'vertigo',
    SleepApnea: 'sleep apnea',
    Dexcom: 'dexcom',
    Rxlife: 'rxlife'
} as const;

export type PatientResponsePatientTypeEnum = typeof PatientResponsePatientTypeEnum[keyof typeof PatientResponsePatientTypeEnum];


