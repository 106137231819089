import React from 'react';
export const CircleDot = (props: {
  color: string,
  size: string,
}) => {
  return (
    <div
      style={{
        height: props.size,
        width: props.size,
        borderRadius: '100%',
        background: props.color,
        marginLeft: '5px',
      }}
    />
  );
};
