import DateFnsUtils from '@date-io/moment'; // choose your lib
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import classnames from 'classnames';
import _ from 'lodash';
import { MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { hospitalApi } from '../api';

import { useStore, useStoreDispatch } from '../context';
import { Trans, useTranslation } from '../i18n';
import { AuthService } from '../services/auth';
import { PatientService } from '../services/patient';
import { validate } from './formValidator';
import { Input } from './input';

const AddClinician = (props) => {
  const { mode = 'add', toggle, show } = props;
  const { patient, clinician } = useStore();
  const { setPatient, setClinician } = useStoreDispatch();
  const service = PatientService();
  const authService = AuthService();

  if (mode == 'add') {
    setPatient({
      startDate: moment(),
    });
  }

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const { t, i18n } = useTranslation();
  const [firstName, setFirstName] = useState(clinician.first_name || '');
  const [firstNameError, setFirstNameError] = useState('');
  const [bio, setBio] = useState(clinician.bio || '');
  const [bioError, setBioError] = useState('');
  const [lastName, setLastName] = useState(clinician.last_name || '');
  const [lastNameError, setLastNameError] = useState('');
  const [email, setEmail] = useState(clinician.email || '');
  const [emailError, setEmailError] = useState('');
  const [startDate, setStartDate] = useState<string | Moment>('');
  const [startDateError, setStartDateError] = useState('');
  const [clinic, setClinic] = useState<string | number>('');
  const [clinicError, setClinicError] = useState('');
  const [phone, setPhone] = useState(clinician.phone_number || '');
  const [phoneError, setPhoneError] = useState('');
  const [passwordText, setPasswordText] = useState('');
  /* eslint-disable i18next/no-literal-string */
  const [language, setLanguage] = useState(clinician.locale || 'en');

  const validator = {
    firstName: () => {
      setFirstNameError('');
      if (!firstName) {
        setFirstNameError(t('Please enter a first name'));
        return false;
      }
      return true;
    },
    lastName: () => {
      setLastNameError('');
      if (!lastName) {
        setLastNameError(t('Please enter a last name'));
        return false;
      }
      return true;
    },
    bio: () => {
      setBioError('');
      return true;
    },
    email: () => {
      setEmailError('');
      const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      if (!email) {
        setEmailError(t('Please enter an email'));
        return false;
      }
      if (!emailValid) {
        setEmailError(t('Please enter a valid email'));
        return false;
      }
      return true;
    },
    phone: () => {
      setPhoneError('');
      const phoneClean = phone.replace(/\D/g, '');
      if (phoneClean.length > 0 && phoneClean.length != 10) {
        setPhoneError(t('Please enter a valid phone number'));
        return false;
      }
      setPhone(phoneClean);
      return true;
    },
    startDate: () => {
      if (!startDate) {
        setStartDateError(t('Please enter a start date'));
        return false;
      }
      return true;
    },
    clinic: () => {
      if (!clinic) {
        setClinicError(t('Please select a clinic'));
        return false;
      }
      return true;
    },
  };

  const reset = () => {
    setFirstName(firstName);
    setFirstNameError('');
    setBio(bio);
    setBioError('');
    setLastName(lastName);
    setLastNameError('');
    setEmail(email);
    setEmailError('');
    setPhone(phone);
    setPhoneError('');
    setClinic('');
    setClinicError('');
    setStartDate(moment());
    setPasswordText('');
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleBio = (e) => {
    setBio(e.target.value);
  };
  const handlePhoneChange = (e) => {
    if (!e.target.value) {
      setPhone(e.target.value);
    }
    // only allows 0-9 inputs
    const currentValue = e.target.value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    if (cvLength == 0) {
      setPhone('');
    } else if (cvLength > 0 && cvLength < 4) {
      setPhone(currentValue);
    } else if (cvLength < 7) {
      setPhone('(' + currentValue.slice(0, 3) + ') ' + currentValue.slice(3));
    } else {
      setPhone(
        '(' + currentValue.slice(0, 3) + ') ' + currentValue.slice(3, 6) + ' - ' + currentValue.slice(6, cvLength + 1),
      );
    }
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const handleClinicChange = (e) => {
    setClinic(parseInt(e.target.value));
  };
  const handlePasswordChange = (e) => {
    setPasswordText(passwordText);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleButtonClick = async (e) => {
    let res = null;
    if (mode == 'add') {
      if (!validate(validator.firstName, validator.lastName, validator.email, validator.clinic, validator.startDate)) {
        return;
      }
      // res = await service.addPatient(clinic, firstName, lastName, email, phone, startDate);
      if (res) {
        await service.getPatientList(clinician.hospital_id, clinician.id, true);
      }
    } else if (mode == 'edit') {
      if (!validate(validator.firstName, validator.lastName, validator.email)) {
        return;
      }
      res = await hospitalApi.appApiHospitalPatchClinician({
        hospital_id: clinician.hospital_id,
        clinician_id: clinician.id,
        PatchClinicianRequest: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_number: phone,
          bio: bio,
          locale: language,
        },
      });
      // setPatient(res);
    }

    if (res) {
      setClinician({ ...clinician, locale: language });
      reset();
      toggle();
    }
  };

  const titleText = {
    add: t('Add new Clinician'),
    edit: t('Edit Clinician'),
  };

  const buttonText = {
    add: t('add new Clinician'),
    edit: t('save changes'),
  };

  const resetPassword = async (e) => {
    const response = await authService.resetPassword(email);
    setPasswordText(t('Password reset link sent to your email.'));
    if (!response) {
      setPasswordText(t('Reset password failed. Please contact technical support at support@inneranalytics.com'));
    }
  };

  return (
    <MDBContainer>
      <MDBModal backdrop={false} isOpen={props.show} toggle={props.toggle} centered size="lg">
        <MDBModalHeader toggle={props.toggle}>
          {titleText[mode]}
        </MDBModalHeader>
        <MDBModalBody>
          <Container>
            <Row>
              <Col className="verticalCenter">
                <Input
                  type="text"
                  label={t('First name')}
                  background
                  size="lg"
                  error={firstNameError}
                  value={firstName}
                  onChange={handleFirstNameChange}
                  onBlur={() => validate(validator.firstName)}
                />
              </Col>
              <Col className="verticalCenter">
                <Input
                  type="text"
                  label={t('Last name')}
                  background
                  size="lg"
                  error={lastNameError}
                  value={lastName}
                  onChange={handleLastNameChange}
                  onBlur={() => validate(validator.lastName)}
                />
              </Col>
            </Row>
            <Row>
              <Col className="verticalCenter">
                <Input
                  type="tel"
                  label={t('Phone number')}
                  background
                  size="lg"
                  error={phoneError}
                  value={phone}
                  onChange={handlePhoneChange}
                  onBlur={() => validate(validator.phone)}
                />
              </Col>
            </Row>
            <Row>
              <Col className="verticalCenter">
                <label htmlFor="Biography">{t('Biography')}:</label>
              </Col>
            </Row>
            <Row>
              <Col className="verticalCenter">
                <Input type="text" id="Biography" onChange={handleBio} value={bio} />
              </Col>
            </Row>
            <Row>
              <Col className="verticalCenter">
                <label htmlFor="Biography">{t('Language')}:</label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="margin-m">
                  <Form.Control
                    as="select"
                    value={language}
                    onChange={handleLanguageChange}
                    className="custom-select mb-3"
                  >
                    <option value="en">{t('English')}</option>
                    <option value="fr">{t('French')}</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="verticalCenter">
                <p />
                <button onClick={resetPassword}>
                  {t('Change Password')}
                </button>
                <p id="passwordTextMsg" className="verticalCenter">
                  {t('Please check your email for the reset link')}
                </p>
              </Col>
            </Row>
            <Row className={classnames({ 'd-none': mode !== 'add' })}>
              <Col className="margin-bot-l">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language}>
                  <DatePicker
                    inputVariant="outlined"
                    disableToolbar
                    format="L"
                    label={t('Tracking date')}
                    variant="inline"
                    value={startDate}
                    onChange={setStartDate}
                  />
                </MuiPickersUtilsProvider>
              </Col>
              <Col>
                <Form.Group className="margin-m">
                  <Form.Control
                    value={'' + clinic}
                    as="select"
                    className="custom-select mb-3"
                    onChange={handleClinicChange}
                  >
                    <option>
                      {t('Select a clinic')}
                    </option>
                    {_.sortBy(clinician.clinics, c => c.name?.toLowerCase()).map(item => (
                      <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                  </Form.Control>
                  <p className="errorMessage errorLabel">{clinicError}</p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="margin-l">
                  <Button onClick={handleButtonClick} block>{buttonText[mode]}</Button>
                </p>
              </Col>
            </Row>
            <Row className={classnames({ 'd-none': mode !== 'add' })}>
              <Col>
                <p className="margin-bot-m">
                  <Trans>
                    By registering you agree to{' '}
                    <Link className="btn-link" to="/policy">Privacy policy and terms & conditions</Link>
                  </Trans>
                </p>
              </Col>
            </Row>
            <Row className={classnames({ 'd-none': true })}>
              <Col>
                <p className="margin-bot-m text-center">
                  <span className="warning">
                    <i className="fas fa-trash-alt" />
                    <Trans>delete patient</Trans>
                  </span>
                </p>
              </Col>
            </Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export { AddClinician };
