import { UseInfiniteQueryResult } from '@tanstack/react-query';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useStore } from '../context';
import { ActivityFeedItem, ActivityFeedSection, usePatientActivityFeed } from '../pages/patient-logV2';

export type CalendarActivityIndicatorsType = {
  [date: string]: {
    date: string,
    meal_count: number,
    medication_count: number,
    exercise_count: number,
  },
};

// startDate and endDate should depend on month being displayed in calendar
export const useCalendarActivityIndicators = (opts: {
  oldestDate: DateTime,
  newestDate: DateTime,
}) => {
  const { patient } = useStore();
  // const oldestDateToFetch = DateTime.local().minus({ days: 60 });
  const oldestDateToFetch = opts.oldestDate;

  const mealTimeline = usePatientActivityFeed({
    patientId: patient.patient_id,
    activeDate: opts.newestDate,
  });

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const changeFetchingStatus = (query: UseInfiniteQueryResult) => {
    setIsFetching(query.isFetchingNextPage || query.isLoading);
  };

  useEffect(() => {
    const query = mealTimeline.query;
    changeFetchingStatus(query);

    if (
      !mealTimeline.feedEntries || query.isLoading || query.isRefetching || query.isFetchingNextPage
      || !query.hasNextPage
    ) {
      return;
    }

    const oldestDate = _.min(
      mealTimeline.feedEntries && mealTimeline.feedEntries.map((page) => {
        return _.min(page.data.map(item => item.timestamp));
      }),
    );

    if (oldestDate && oldestDate > oldestDateToFetch) {
      query.fetchNextPage();
    }
  }, [
    mealTimeline.feedEntries,
    mealTimeline.query,
    oldestDateToFetch,
  ]);

  const newestDate = _.max(
    mealTimeline.feedEntries && mealTimeline.feedEntries
      .filter(page => page.data.some((item) => item.type === 'meal-log'))
      .map(page => _.max(page.data.map(item => item.timestamp))),
  );

  const calendarActivityIndicators: CalendarActivityIndicatorsType = {};
  const feedEntries: ActivityFeedItem[] = [];

  (mealTimeline.feedEntries || []).forEach((section: ActivityFeedSection) => {
    const dateString = section.date.toISODate();

    if (!calendarActivityIndicators[dateString]) {
      calendarActivityIndicators[dateString] = {
        date: dateString,
        meal_count: 0,
        medication_count: 0,
        exercise_count: 0,
      };
    }

    const existingMeals = [];
    section.data.forEach((item) => {
      feedEntries.push(item);

      if (item.type === 'meal-log') {
        if (!existingMeals.includes(item.data.meal_name)) {
          calendarActivityIndicators[dateString].meal_count += 1;
          existingMeals.push(item.data.meal_name);
        }
      } else if (item.type === 'medication-log') {
        calendarActivityIndicators[dateString].medication_count += 1;
      } else if (item.type === 'exercise-log') {
        calendarActivityIndicators[dateString].exercise_count += 1;
      }
    });
  });

  return { calendarActivityIndicators, isFetching, newestDate, feedEntries };
};
