import _ from 'lodash';
import React, { useMemo } from 'react';
import { Trans } from '../../i18n';
import { GlucoseDataPoint, useCgmData, useProcessedCgmData } from './CGMDataService';
import {
  CgmMealAndPatient,
  cgmReadingMaxIntervalMinutes,
  getCgmPostPrandialTimeRange,
  glucoseTargets,
  mmMolToTarget,
} from './cgmUtils';

export type CGMCalculatedTimeInRange = {
  totalMinutes: number,
  minutesInRange: Record<keyof typeof glucoseTargets, number>,
};

export const cgmGlucoseMeasurementsToTimeInRange = (
  points: GlucoseDataPoint[],
): CGMCalculatedTimeInRange => {
  const pointsSorted = _.sortBy(points, 'xTimestampMS');
  const minutesInRange: Record<keyof typeof glucoseTargets, number> = Object.fromEntries(
    Object.keys(glucoseTargets)
      .map(t => [t, 0]),
  ) as any;

  let totalMinutes = 0;
  pointsSorted.forEach((point, i) => {
    const nextPoint = pointsSorted[i + 1];
    if (!nextPoint) {
      return;
    }
    if (nextPoint.xTimestampMS == point.xTimestampMS) {
      return;
    }
    const timeInRangeMS = nextPoint.xTimestampMS - point.xTimestampMS;
    const timeInRangeMin = timeInRangeMS / 1000 / 60;
    if (timeInRangeMin > cgmReadingMaxIntervalMinutes) {
      return;
    }
    const v = (point.value + nextPoint.value) / 2;
    const [targetName] = mmMolToTarget(v);
    if (!targetName) {
      return;
    }
    minutesInRange[targetName] += timeInRangeMin;

    totalMinutes += timeInRangeMin;
  });

  return {
    totalMinutes,
    minutesInRange,
  };
};

export const useGlucoseTimeInRange = (points: GlucoseDataPoint[]) => {
  return useMemo(() => {
    return cgmGlucoseMeasurementsToTimeInRange(points);
  }, [points]);
};

export const TIME_IN_RANGE_MIN_MINUTES = 60 * 3;

export const cgmShowTimeInRange = (data: CGMCalculatedTimeInRange) => {
  return data.totalMinutes > TIME_IN_RANGE_MIN_MINUTES;
};

export const CGMMealTimeInRange = (props: CgmMealAndPatient) => {
  const cgmTimeRange = getCgmPostPrandialTimeRange(props);
  const { glucoseData } = useCgmData(cgmTimeRange);
  const cgmData = useProcessedCgmData(glucoseData);
  const timeInRange = useGlucoseTimeInRange(cgmData);
  const mealTime = cgmTimeRange.mealTime;

  if (!cgmShowTimeInRange(timeInRange)) {
    return null;
  }

  return (
    <div className="mt-2">
      <div className="text-muted" style={{ fontSize: '12px' }}>
        <Trans>Glucose time in range</Trans>
      </div>
      <div className="range-graph">
        <CGMTimeInRangeBar data={timeInRange} />
      </div>
    </div>
  );
};

const CGMTimeInRangeBar = (props: {
  data: CGMCalculatedTimeInRange,
}) => {
  const { data } = props;

  const bars = useMemo(() => {
    return Object.entries(glucoseTargets)
      .map(([targetName, target]) => {
        const inRange = data.minutesInRange[targetName];
        if (!inRange) {
          return null;
        }

        return {
          targetName: targetName,
          widthPct: inRange / data.totalMinutes,
          color: target.color,
        };
      })
      .filter(Boolean);
  }, [data]);

  return (
    <div style={{ display: 'flex' }}>
      {bars.map((bar, i) => {
        return (
          <div
            key={bar.targetName}
            style={{
              flex: bar.widthPct,
            }}
          >
            <div
              style={{
                flex: 1,
                height: 10,
                backgroundColor: bar.color,
                borderTopLeftRadius: i == 0 ? 3 : 0,
                borderBottomLeftRadius: i == 0 ? 3 : 0,
                borderTopRightRadius: i == bars.length - 1 ? 3 : 0,
                borderBottomRightRadius: i == bars.length - 1 ? 3 : 0,
                marginRight: i == bars.length - 1 ? 0 : 1.5,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
