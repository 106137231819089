/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { WaterResponse } from '../models';
/**
 * WaterApi - axios parameter creator
 * @export
 */
export const WaterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get patient\'s water consumption of the date
         * @param {number} patient_id 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiWaterGetWater: async (patient_id: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiWaterGetWater', 'patient_id', patient_id)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('appApiWaterGetWater', 'date', date)
            const localVarPath = `/patients/{patient_id}/water`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query the patient\'s meal consumption for water
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiWaterGetWaters: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiWaterGetWaters', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiWaterGetWaters', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiWaterGetWaters', 'date_until', date_until)
            const localVarPath = `/patients/{patient_id}/waters`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put patient\'s water consumption of the date
         * @param {number} patient_id 
         * @param {string} date 
         * @param {number} cups 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiWaterPutWater: async (patient_id: number, date: string, cups: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiWaterPutWater', 'patient_id', patient_id)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('appApiWaterPutWater', 'date', date)
            // verify required parameter 'cups' is not null or undefined
            assertParamExists('appApiWaterPutWater', 'cups', cups)
            const localVarPath = `/patients/{patient_id}/water`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (cups !== undefined) {
                localVarQueryParameter['cups'] = cups;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WaterApi - functional programming interface
 * @export
 */
export const WaterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WaterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get patient\'s water consumption of the date
         * @param {number} patient_id 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiWaterGetWater(patient_id: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WaterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiWaterGetWater(patient_id, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Query the patient\'s meal consumption for water
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiWaterGetWaters(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WaterResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiWaterGetWaters(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put patient\'s water consumption of the date
         * @param {number} patient_id 
         * @param {string} date 
         * @param {number} cups 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiWaterPutWater(patient_id: number, date: string, cups: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WaterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiWaterPutWater(patient_id, date, cups, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WaterApi - factory interface
 * @export
 */
export const WaterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WaterApiFp(configuration)
    return {
        /**
         * 
         * @summary Get patient\'s water consumption of the date
         * @param {WaterApiAppApiWaterGetWaterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiWaterGetWater(requestParameters: WaterApiAppApiWaterGetWaterRequest, options?: AxiosRequestConfig): AxiosPromise<WaterResponse> {
            return localVarFp.appApiWaterGetWater(requestParameters.patient_id, requestParameters.date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Query the patient\'s meal consumption for water
         * @param {WaterApiAppApiWaterGetWatersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiWaterGetWaters(requestParameters: WaterApiAppApiWaterGetWatersRequest, options?: AxiosRequestConfig): AxiosPromise<Array<WaterResponse>> {
            return localVarFp.appApiWaterGetWaters(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put patient\'s water consumption of the date
         * @param {WaterApiAppApiWaterPutWaterRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiWaterPutWater(requestParameters: WaterApiAppApiWaterPutWaterRequest, options?: AxiosRequestConfig): AxiosPromise<WaterResponse> {
            return localVarFp.appApiWaterPutWater(requestParameters.patient_id, requestParameters.date, requestParameters.cups, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiWaterGetWater operation in WaterApi.
 * @export
 * @interface WaterApiAppApiWaterGetWaterRequest
 */
export interface WaterApiAppApiWaterGetWaterRequest {
    /**
     * 
     * @type {number}
     * @memberof WaterApiAppApiWaterGetWater
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof WaterApiAppApiWaterGetWater
     */
    readonly date: string
}

/**
 * Request parameters for appApiWaterGetWaters operation in WaterApi.
 * @export
 * @interface WaterApiAppApiWaterGetWatersRequest
 */
export interface WaterApiAppApiWaterGetWatersRequest {
    /**
     * 
     * @type {number}
     * @memberof WaterApiAppApiWaterGetWaters
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof WaterApiAppApiWaterGetWaters
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof WaterApiAppApiWaterGetWaters
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiWaterPutWater operation in WaterApi.
 * @export
 * @interface WaterApiAppApiWaterPutWaterRequest
 */
export interface WaterApiAppApiWaterPutWaterRequest {
    /**
     * 
     * @type {number}
     * @memberof WaterApiAppApiWaterPutWater
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof WaterApiAppApiWaterPutWater
     */
    readonly date: string

    /**
     * 
     * @type {number}
     * @memberof WaterApiAppApiWaterPutWater
     */
    readonly cups: number
}

/**
 * WaterApi - object-oriented interface
 * @export
 * @class WaterApi
 * @extends {BaseAPI}
 */
export class WaterApi extends BaseAPI {
    /**
     * 
     * @summary Get patient\'s water consumption of the date
     * @param {WaterApiAppApiWaterGetWaterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaterApi
     */
    public appApiWaterGetWater(requestParameters: WaterApiAppApiWaterGetWaterRequest, options?: AxiosRequestConfig) {
        return WaterApiFp(this.configuration).appApiWaterGetWater(requestParameters.patient_id, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Query the patient\'s meal consumption for water
     * @param {WaterApiAppApiWaterGetWatersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaterApi
     */
    public appApiWaterGetWaters(requestParameters: WaterApiAppApiWaterGetWatersRequest, options?: AxiosRequestConfig) {
        return WaterApiFp(this.configuration).appApiWaterGetWaters(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put patient\'s water consumption of the date
     * @param {WaterApiAppApiWaterPutWaterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WaterApi
     */
    public appApiWaterPutWater(requestParameters: WaterApiAppApiWaterPutWaterRequest, options?: AxiosRequestConfig) {
        return WaterApiFp(this.configuration).appApiWaterPutWater(requestParameters.patient_id, requestParameters.date, requestParameters.cups, options).then((request) => request(this.axios, this.basePath));
    }
}

