import React from 'react';

export const GlucoseMinSVG = (p: {
  value: string,
}) => {
  return (
    <g transform="scale(0.6)">
      <rect
        width="52.6628"
        height="21"
        rx="10.5"
        fill="#1D1C1C"
        id="rect2"
      />
      <circle
        cx="10.5"
        cy="10.5"
        r="10.5"
        fill="#36C2B4"
        id="circle4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9064 11.5419C14.9101 11.614 14.9119 11.6855 14.9119 11.7565C14.9119 14.208 12.9158 16.1913 10.456 16.1913C8.52248 16.1913 6.87555 14.966 6.25922 13.2512C6.65739 13.4194 7.10697 13.529 7.60266 13.529C8.67018 13.529 9.51343 12.9286 10.357 12.3279C11.2013 11.7268 12.046 11.1254 13.1161 11.1254C13.8202 11.1254 14.4194 11.3033 14.9064 11.5419Z"
        fill="white"
        id="path6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3614 4.04298C10.381 4.0232 10.406 4.00943 10.4335 4.00339C10.4825 3.99269 10.5336 4.00769 10.5685 4.04298C12.8254 6.2883 14.9299 9.21781 14.9299 11.6708C14.9299 14.1184 12.9292 16.1094 10.465 16.1094C8.00079 16.1094 6 14.1184 6 11.6708C6 9.21781 8.10452 6.2883 10.3614 4.04298ZM14.6417 11.6708C14.6417 9.41525 12.6537 6.56101 10.4648 4.35532C8.27584 6.56101 6.28787 9.41525 6.28787 11.6708C6.28787 13.9687 8.15894 15.8279 10.4648 15.8279C12.7706 15.8279 14.6417 13.9687 14.6417 11.6708Z"
        fill="white"
        id="path8"
      />
      <path
        d="M12.7605 14.4172C12.7217 14.4242 12.6876 14.4465 12.666 14.4788C12.1139 15.0573 11.3319 15.4202 10.465 15.4202C9.59817 15.4202 8.81619 15.0573 8.26406 14.4788C8.23583 14.447 8.19455 14.4293 8.15153 14.4304C8.09581 14.4341 8.04731 14.469 8.02707 14.5198C8.00683 14.5707 8.0185 14.6284 8.05701 14.668C8.6607 15.3004 9.51595 15.7017 10.465 15.7017C11.4141 15.7017 12.2694 15.3004 12.8731 14.668C12.9261 14.6286 12.9447 14.5588 12.9179 14.4992C12.8912 14.4396 12.8261 14.4057 12.7605 14.4172Z"
        fill="#36C2B4"
        id="path10"
      />
      <text
        x="24"
        y="14.5"
        fontSize={10}
        fontWeight="bold"
        textAnchor="left"
        fill="white"
      >
        {p.value}
      </text>
    </g>
  );
};
