import * as Sentry from '@sentry/browser';
import { truncate } from 'lodash';
import React, { PropsWithChildren } from 'react';

import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { config } from '../config';
import { Trans } from '../i18n';

const ErrorFallback = (props: FallbackProps) => {
  const message = '' + props.error;

  return (
    <p style={{ fontStyle: 'italic' }}>
      <Trans>Unexpected error:</Trans> {config.IS_DEV ? message : truncate(message, { length: 100 })}
    </p>
  );
};

export const RxErrorBoundary = (props: {
  children: React.ReactNode,
}) => {
  const handleError = (error: Error, info: { componentStack: string }) => {
    console.error('RxErrorBoundary:', error, info);
    Sentry.captureException(error);
  };
  if (!props.children) {
    return null;
  }
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={handleError}
    >
      {props.children}
    </ErrorBoundary>
  );
};
