import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { TFunc, Trans, useTranslation } from '../i18n';
import 'react-day-picker/lib/style.css';
import './foodFilter.scss';

import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import frCA from 'antd/es/locale/fr_CA';
import type { CellRenderInfo } from 'rc-picker/es/interface';
import { PresetDate, RangeValue } from 'rc-picker/lib/interface';
import { MomentRangePicker } from './rangePicker';
import { CalendarActivityIndicatorsType, useCalendarActivityIndicators } from './useCalendarActivityFeed';

import { t } from 'i18next';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { Button } from 'react-bootstrap';
import { FixedSizeList as List } from 'react-window';
import { useStore } from '../context';
import {
  ActivityFeedItem,
  activityFeedItemsToCgmSummaryChartPoints,
  getActivityFeedDefs,
} from '../pages/patient-logV2';
import { PatientService } from '../services/patient';
import { useCgmData, useProcessedCgmData } from './cgm/CGMDataService';
import { CGMGlucoseChart } from './cgm/CGMGlucoseChart';

const mealColor = getActivityFeedDefs(t)['meal-log'].color;
const medicationColor = getActivityFeedDefs(t)['medication-log'].color;
const exerciseColor = getActivityFeedDefs(t)['exercise-log'].color;

export const view_types = [
  /* eslint-disable-next-line i18next/no-literal-string */
  ['meal_name', 'meal types'],
  /* eslint-disable-next-line i18next/no-literal-string */
  ['weekday_weekend', 'weekday vs weekend'],
  /* eslint-disable-next-line i18next/no-literal-string */
  ['date', 'date'],
] as const;

export const getEnumeratedDays = (
  rangePickerValue: [moment.Moment, moment.Moment],
  setCheckboxes: (checkboxes: { [date: string]: boolean }) => void,
) => {
  const filterStart = rangePickerValue[0].clone().startOf('day');
  const currDate = rangePickerValue[1].clone().endOf('day');
  const days: moment.Moment[] = [];
  const checkboxes: { [date: string]: boolean } = {};

  while (currDate >= filterStart) {
    days.push(currDate.clone());
    currDate.subtract(1, 'days');
    // eslint-disable-next-line i18next/no-literal-string
    const dateString = currDate.toISOString().split('T')[0];
    checkboxes[dateString] = true;
  }
  setCheckboxes(checkboxes);
  return days;
};

export const withFilterPanel = (WrappedComponent) => {
  return function() {
    const { t } = useTranslation();
    const [errorText, setErrorText] = useState('');
    const { clinician, patient } = useStore();
    const patientService = PatientService();

    const generateMealReport = async () => {
      if (since == null || until == null) {
        return;
      }
      try {
        await patientService.downloadMealReport(
          clinician.id,
          patient.patient_id,
          moment(since).format('YYYY-MM-DD'),
          moment(until).format('YYYY-MM-DD'),
        );
      } catch (e) {
        setErrorText(t('Insufficient data in range to generate report.'));
        return;
      }
      setErrorText('');
    };

    const [filter, setFilter] = useState<FilterType>({
      rangePickerValue: [moment().subtract(7, 'd').startOf('day'), moment().endOf('day')],
      dateRanges: [],
    });
    const [checkboxes, setCheckboxes] = React.useState<{ [date: string]: boolean }>();
    const [dates, setDates] = useState({
      oldestDate: DateTime.local().minus({ days: 65 }),
      newestDate: null,
    });
    const { calendarActivityIndicators, newestDate, isFetching, feedEntries } = useCalendarActivityIndicators(dates);
    const [since, until] = filter.rangePickerValue;
    const [initialFilter, setInitialFilter] = useState(false);

    useEffect(() => {
      if (newestDate && !initialFilter) {
        const date = moment(newestDate?.toJSDate());
        const endDate = date.clone();
        const startDate = endDate.clone().add(-7, 'd');
        // onRangeChange(
        //   [startDate, endDate],
        //   [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')],
        // );
        setFilter({ rangePickerValue: [startDate, endDate], dateRanges: [] });
        setInitialFilter(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newestDate, initialFilter, isFetching]);

    const enumeratedDays = React.useMemo(() => getEnumeratedDays(filter.rangePickerValue, setCheckboxes), [
      filter.rangePickerValue,
    ]);

    const getFeedEntriesByDateRangeAndType = (startDate: moment.Moment, endDate: moment.Moment, type: string) => {
      return feedEntries.filter((item) => {
        const timestamp = moment(item.timestamp.toJSDate()).startOf('day');
        const dateRange = timestamp.isSameOrBefore(endDate.clone().endOf('day'))
          && timestamp.isSameOrAfter(startDate.clone().startOf('day'));
        return dateRange && item.type === type;
      });
    };

    return (
      <>
        <Row className="logFilterPanel">
          <Col style={{ paddingLeft: '30px' }}>
            <div>
              {enumeratedDays.length
                && (
                  <DateListSelector
                    enumeratedDays={enumeratedDays}
                    feedEntries={feedEntries}
                    checkboxes={checkboxes}
                    setCheckboxes={setCheckboxes}
                  />
                )}
            </div>
          </Col>

          <Col style={{ marginTop: -19 }}>
            <div style={{ width: '400px' }}>
              <label style={{ fontSize: '14px', paddingTop: '25px' }}>
                <Trans>filter by</Trans>: &nbsp;
              </label>
              <RangePickerWithActivityIndicators
                calendarActivityIndicators={calendarActivityIndicators}
                isFetching={isFetching}
                checkboxes={checkboxes}
                filter={filter}
                dates={dates}
                setFilter={setFilter}
                setDates={setDates}
              />
            </div>
          </Col>

          {clinician.is_meal_admin && (
            <Col>
              <div style={{ fontSize: '10px', paddingTop: 10, paddingBottom: 10 }}>
                <Button
                  style={{
                    fontSize: '9px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => generateMealReport()}
                >
                  <Trans>Download Meal History Report</Trans>
                </Button>
              </div>
            </Col>
          )}
        </Row>

        <WrappedComponent
          enumeratedDays={enumeratedDays}
          filter={filter}
          filteredFeedEntries={getFeedEntriesByDateRangeAndType(
            enumeratedDays[enumeratedDays.length - 1],
            enumeratedDays[0],
            'meal-log',
          )}
        />
      </>
    );
  };
};

export const DateListSelector = (props: {
  enumeratedDays: moment.Moment[],
  feedEntries: ActivityFeedItem[],
  checkboxes: { [date: string]: boolean },
  setCheckboxes: (checkboxes: { [date: string]: boolean }) => void,
  listWidth?: number,
  isReportsModal?: boolean,
}) => {
  const { enumeratedDays, feedEntries, checkboxes, setCheckboxes } = props;

  const handleCheckboxChange = (evt: any) => {
    const id = evt.target.id;
    setCheckboxes({ ...checkboxes, [id]: !checkboxes[id] });
  };

  const getFeedEntriesByDate = (date: moment.Moment) => {
    return feedEntries.filter((item) => {
      const timestamp = moment(item.timestamp.toJSDate());
      return item.type === 'meal-log'
        && timestamp > date.clone().startOf('day') && timestamp < date.clone().endOf('day');
    });
  };

  const getNumMealsByDate = (date: moment.Moment) => {
    const numMeals = getFeedEntriesByDate(date)?.length ?? 0;
    return { numMeals, numMealsStr: t('meals: {{numMeals}}', { numMeals }) };
  };

  const listWidth = props.listWidth ?? 470;
  const widthMultiplier = 0.7;
  const size = enumeratedDays.length * 70 >= listWidth ? 70 : (listWidth / enumeratedDays.length);
  const width = enumeratedDays.length * 70 >= listWidth
    ? 50
    : (listWidth / enumeratedDays.length) * widthMultiplier;
  const listItemWidth = { width, size };
  const height = props.isReportsModal ? 180 : 160;
  // eslint-disable-next-line i18next/no-literal-string
  const marginTop = props.isReportsModal ? '-50px' : '-40px';

  return (
    <List
      height={height}
      width={listWidth}
      itemCount={enumeratedDays.length}
      itemSize={listItemWidth.size}
      layout="horizontal"
      direction="rtl"
      style={{ overflowY: 'hidden' }}
    >
      {({ index, style }) => {
        const date = enumeratedDays[index];
        const stringDate = date.format('YYYY-MM-DD');
        return (
          <div style={style} className="react-window-div">
            <CgmDate
              date={date}
              activityData={getFeedEntriesByDate(date)}
              itemWidth={listItemWidth.width}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                marginRight: '0px',
                marginTop: marginTop,
                position: 'relative',
                zIndex: 100,
                fontSize: '10px',
                alignItems: 'center',
              }}
            >
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <span style={{ fontSize: '10px' }}>
                {date.format('MM-DD')}
              </span>
              {checkboxes && (
                <input
                  type="checkbox"
                  checked={checkboxes?.[stringDate] ?? false}
                  onChange={handleCheckboxChange}
                  id={stringDate}
                  name={stringDate}
                />
              )}
              {props.isReportsModal && (
                <span style={{ fontSize: '10px' }}>
                  {getNumMealsByDate(date).numMealsStr}
                </span>
              )}
            </div>
          </div>
        );
      }}
    </List>
  );
};

export const CgmDate = (props: {
  date: moment.Moment,
  activityData: ActivityFeedItem[],
  itemWidth: number,
}) => {
  const { date, activityData, itemWidth } = props;
  const { t } = useTranslation();
  const { patient } = useStore();
  const viewDates = {
    patientId: patient.patient_id,
    timeSinceLocal: date.clone().startOf('day').subtract(30, 'minutes').toDate(),
    timeUntilLocal: date.clone().endOf('day').toDate(),
  };
  const cgm = useCgmData(viewDates);
  const cgmData = useProcessedCgmData(cgm.glucoseData);
  const result = React.useMemo(() => {
    const markers = activityFeedItemsToCgmSummaryChartPoints(t, activityData);
    return (
      <CGMGlucoseChart
        data={cgmData}
        markers={markers}
        height={110}
        width={itemWidth}
        showValueAxis={false}
        showTimeAxis={false}
        xDomainMS={[viewDates.timeSinceLocal.getTime(), viewDates.timeUntilLocal.getTime()]}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cgmData,
    activityData,
    viewDates.timeSinceLocal,
    viewDates.timeUntilLocal,
  ]);
  return result;
};

export const getRangePresets = (
  t: TFunc,
  maxMonths?: 'one' | 'two' | 'three',
): PresetDate<RangeValue<moment.Moment>>[] => {
  const days = {
    today: moment().endOf('day'),
    dayAgo: moment().subtract(2, 'd').startOf('day'),
    weekAgo: moment().subtract(7, 'd').startOf('day'),
    monthAgo: moment().subtract(1, 'M').startOf('day'),
    twomonthAgo: moment().subtract(2, 'M').startOf('day'),
    threemonthAgo: moment().subtract(3, 'M').startOf('day'),
  };
  const presets = [
    { label: t('Last 7 days'), value: [days.weekAgo, days.today] },
    { label: t('Today'), value: [days.today, days.today] },
    { label: t('Yesterday'), value: [days.dayAgo, days.today] },
    { label: t('Last month'), value: [days.monthAgo, days.today] },
    { label: t('Last 2 months'), value: [days.twomonthAgo, days.today] },
    { label: t('Last 3 months'), value: [days.threemonthAgo, days.today] },
  ] as PresetDate<RangeValue<moment.Moment>>[];

  if (maxMonths === 'one') {
    return presets.slice(0, 4);
  } else if (maxMonths === 'two') {
    return presets.slice(0, 5);
  }
  return presets;
};

export type FilterType = {
  rangePickerValue: [moment.Moment, moment.Moment],
  dateRanges: [string, string][],
};
export type DateType = { oldestDate: DateTime, newestDate: DateTime };

export const RangePickerWithActivityIndicators = (props: {
  calendarActivityIndicators: CalendarActivityIndicatorsType,
  isFetching: boolean,
  checkboxes: { [date: string]: boolean },
  filter: FilterType,
  dates: DateType,
  setFilter: (filter: FilterType) => void,
  setDates: (dates: DateType) => void,
  maxMonths?: 'one' | 'two' | 'three',
  maxNumDays?: number,
  setNumDaysSelected?: (numDaysSelected: number) => void,
  setExcludedDates?: (excludeDates: string[]) => void,
}) => {
  const { filter, dates, checkboxes, isFetching, calendarActivityIndicators, setFilter, setDates } = props;
  const { t, i18n } = useTranslation();

  const getSortedDates = (
    checkboxes: { [date: string]: boolean },
  ): { checkedDatesMS: number[], uncheckedDatesMS: number[] } => {
    const getDates = (pred: (value: boolean) => boolean) => {
      return Object.entries(checkboxes)
        .filter(([_, value]) => pred(value))
        .map(([date, _]) => moment(date).valueOf())
        .reverse();
    };
    return {
      checkedDatesMS: getDates(v => v),
      uncheckedDatesMS: getDates(v => !v),
    };
  };

  const onRangeChange = (dates: null | (moment.Moment | null)[], dateStrings: string[]) => {
    if (dates) {
      setFilter({ rangePickerValue: [dates[0], dates[1]], dateRanges: [] });
      console.log('Filter from: ', dateStrings[0], ', to: ', dateStrings[1]);
    } else {
      console.log('Clear');
    }
  };

  // update filter state to reflect checkbox changes
  useEffect(() => {
    const dateRanges: [string, string][] = [];
    const dayMS = 24 * 60 * 60 * 1000;
    const checkedDatesMS = checkboxes && getSortedDates(checkboxes).checkedDatesMS;

    if (props.setNumDaysSelected && props.setExcludedDates) {
      const checkedDatesMS = checkboxes && getSortedDates(checkboxes).checkedDatesMS;
      props.setNumDaysSelected(checkedDatesMS.length);
      props.setExcludedDates(
        getSortedDates(checkboxes).uncheckedDatesMS.map(date => moment(date).format('YYYY-MM-DD')),
      );
    } else {
      let range: { since: number, until?: number } = { since: checkedDatesMS[0] };
      checkedDatesMS.forEach((date, i) => {
        if (date + dayMS !== checkedDatesMS[i + 1]) {
          range.until = date;

          dateRanges.push([
            moment(range.since).startOf('day').format('YYYY-MM-DD'),
            moment(range.until).startOf('day').format('YYYY-MM-DD'),
          ]);
          range = { since: checkedDatesMS[i + 1], until: undefined };
        }
      });
      setFilter({ ...filter, dateRanges });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxes]);

  const sortedDates = checkboxes && getSortedDates(checkboxes);
  const rangePickerValueMS = filter.rangePickerValue.map(m => m.clone().startOf('day').valueOf()) as [number, number];

  const crossedDates = (sortedDates?.uncheckedDatesMS ?? [])
    .map(date => {
      if (date >= rangePickerValueMS[0] && date <= rangePickerValueMS[1]) {
        return moment(date).startOf('day').format('YYYY-MM-DD');
      }
      return null;
    })
    .filter(x => !!x);

  const maxNumDaysExceeded = sortedDates?.checkedDatesMS.length > props.maxNumDays;
  if (props.maxMonths === 'one' && maxNumDaysExceeded) {
    console.log(
      'maxNumDaysExceeded: ',
      maxNumDaysExceeded,
      ', sortedDatesLength: ',
      sortedDates?.checkedDatesMS.length,
    );
    const start = moment(sortedDates?.checkedDatesMS[0]).startOf(
      'day',
    );
    const end = moment(sortedDates?.checkedDatesMS[props.maxNumDays - 1]).endOf('day');
    onRangeChange([start, end], [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')]);
  }

  const rangePresets = getRangePresets(t, props.maxMonths);

  const disabledDate = (date: moment.Moment) => {
    return date && date.isAfter(moment().endOf('day'));
  };

  const renderExtraFooter = () => {
    const logs = [
      { color: medicationColor, label: t('medication') },
      { color: mealColor, label: t('meal') },
      { color: exerciseColor, label: t('exercise') },
    ];
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {logs.map(({ color, label }) => (
            <span key={label} className="d-flex align-items-center justify-content-center mx-2">
              <div
                style={{
                  height: '10px',
                  width: '10px',
                  borderRadius: '100%',
                  background: color,
                  marginRight: '5px',
                }}
              />
              {label}
            </span>
          ))}
        </div>
        <div className="d-flex align-items-center">
          {isFetching && (
            <span>
              <span className="mx-2">
                <Trans>loading patient activity records</Trans>
              </span>
              <div className="spinner-border spinner-border-sm" role="status" />
            </span>
          )}
        </div>
      </div>
    );
  };

  const cellRender = React.useCallback(
    (current: number | moment.Moment, info: CellRenderInfo<moment.Moment>) => {
      if (info.type !== 'date') {
        return info.originNode;
      }
      if (typeof current === 'number') {
        return <div className="ant-picker-cell-inner">{current}</div>;
      }
      /* eslint-disable-next-line i18next/no-literal-string */
      const dateKey = current.clone().startOf('day').toISOString().split('T')[0];
      const activityIndicators = calendarActivityIndicators[dateKey];
      const dots = [
        { color: medicationColor, count: activityIndicators?.medication_count || 0 },
        { color: mealColor, count: activityIndicators?.meal_count || 0 },
        { color: exerciseColor, count: activityIndicators?.exercise_count || 0 },
      ].map(({ color, count }) => (
        _.range(0, Math.min(3, count)).map((_, i) => (
          { color }
        ))
      )).flat();
      const crossed = crossedDates.includes(dateKey);

      return (
        <div
          className="ant-picker-cell-inner"
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
          }}
        >
          {crossed
            ? (
              <div
                className="date-container"
                style={{
                  marginTop: '-4px',
                  color: '#ccc',
                }}
              >
                {current.date()}
                <div
                  style={{
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%) rotate(-45deg)',
                    width: '80%',
                    height: '1px',
                    backgroundColor: '#ff0000',
                  }}
                />
              </div>
            )
            : (
              <div
                className="date-container"
                style={{ marginTop: '-4px' }}
              >
                {current.date()}
              </div>
            )}

          <div
            className="dots-container"
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              gap: '1.5px',
              width: '13.5px',
              marginTop: '-5px',
              justifyContent: 'center',
            }}
          >
            {dots.map((dot, i) => (
              <div
                key={`dot-${dateKey}-${i}`}
                style={{
                  height: '3.5px',
                  width: '3.5px',
                  borderRadius: '100%',
                  background: dot.color,
                }}
              />
            ))}
          </div>
        </div>
      );
    },
    [calendarActivityIndicators, crossedDates],
  );

  const onPanelChange = (value: [moment.Moment, moment.Moment]) => {
    const oldestDate = DateTime.fromMillis(value[0].valueOf()).minus({ weeks: 10 });
    setDates({
      ...dates,
      oldestDate,
    });
  };

  return (
    <ConfigProvider
      locale={i18n.language === 'fr' ? frCA : enUS}
      theme={{
        components: {
          DatePicker: {
            cellHeight: 32,
            cellWidth: 32,
          },
        },
      }}
    >
      <MomentRangePicker
        presets={rangePresets}
        onChange={onRangeChange}
        onPanelChange={onPanelChange}
        cellRender={cellRender}
        value={filter.rangePickerValue}
        renderExtraFooter={renderExtraFooter}
        disabledDate={disabledDate}
      />
    </ConfigProvider>
  );
};
