/* eslint-disable i18next/no-literal-string */
import axios from 'axios';
import moment from 'moment';
import { hostUrl } from '../api';
import { useStore, useStoreDispatch } from '../context';

function ClinicService() {
  const store = useStore();
  const { token } = store;
  const { setClinician, setTipHistory, setDraftPatientReports } = useStoreDispatch();

  const getClinician = async () => {
    if (!token) {
      console.log('no token error');
      return false;
    }

    const r1 = await axios.get(hostUrl + `/api/clinicians/current`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (r1.status != 200) {
      return false;
    }

    const clinician = await r1.data;
    const clinician_id = clinician.id;
    const hospital_id = clinician.hospital_id;
    // console.log(clinician);

    const r2 = await axios.get(hostUrl + `/api/hospitals/${hospital_id}/clinicians/${clinician_id}/clinics`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (r2.status != 200) {
      return false;
    }

    const clinics = await r2.data;

    clinician.clinics = clinics;
    setClinician(clinician);
    return clinician;
  };

  const sendLink = async (link, clinicianId, patientIds) => {
    if (!token) {
      console.log('no token error');
      return false;
    }

    const r1 = await axios.post(hostUrl + `/api/clinician_links/batch`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        link: link,
        created_by_clinician_id: clinicianId,
        patient_ids: patientIds,
      },
    });
    if (r1.status != 200) {
      return false;
    }
  };

  const sendTip = async (message, clinicianId, patientIds) => {
    if (!token) {
      console.log('no token error');
      return false;
    }

    const r1 = await axios.post(hostUrl + `/api/clinician_tips/batch`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        message: message,
        created_by_clinician_id: clinicianId,
        patient_ids: patientIds,
      },
    });
    if (r1.status != 200) {
      return false;
    }
  };

  const getTipHistory = async (
    patient_id,
    since = moment().subtract(6, 'months').format('YYYY-MM-DD'),
    until = moment().format('YYYY-MM-DD'),
  ) => {
    // const url = new URL(`${hostUrl}/api/hospital/${hospital_id}/clinics/${clinic_id}/clinician/${clinician_id}/${patient_id}/appointments`);
    // const url = new URL(`${hostUrl}/api/hospital/${hospital_id}/clinics/${clinic_id}/appointments`);
    const url = `${hostUrl}/api/patients/${patient_id}/clinician_tips`;

    const params = {
      patient_id: patient_id,
      date_since: since,
      date_until: until,
    };
    Object.keys(params).forEach(key => new URL(url).searchParams.append(key, params[key]));
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.status == 200) {
      const json = await response.data;
      setTipHistory(json);
      return json;
    }
    return false;
  };

  const addAppointment = async (hospital_id, clinic_id, clinician_id, patient_id, app_date, titleText) => {
    const url =
      `${hostUrl}/api/hospital/${hospital_id}/clinics/${clinic_id}/clinician/${clinician_id}/patient/${patient_id}/appointment`;

    const start_time = moment(app_date).format('YYYY-MM-DD HH:MM:SS'); // moment(app_date).format('YYYY-MM-DD HH:MM:SS');
    const end_time = moment(app_date).add(1, 'hour').format('YYYY-MM-DD HH:MM:SS');
    const description = 'Clinic Appointment';
    const title = titleText; // "Appointment";
    const purpose = 'Visit';
    const response = await axios.post(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        description,
        end_time,
        purpose,
        start_time,
        title,
      },
    });

    if (response.status == 200) {
      const json = await response.data;
      return json;
    }
    return false;
  };

  const deleteAppointment = async (hospital_id, clinic_id, clinician_id, patient_id, app_id) => {
    const url =
      `${hostUrl}/api/hospital/${hospital_id}/clinics/${clinic_id}/clinician/${clinician_id}/patient/${patient_id}/appointment/${app_id}`;

    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.status == 200) {
      const json = await response.data;
      return json;
    }
    return false;
  };

  const getPatientsInClinic = async (clinic_id, hospital_id) => {
    const url = `${hostUrl}/api/hospitals/${hospital_id}/clinics/${clinic_id}/patients`;
    const params = {
      clinic_id: clinic_id,
      hospital_id: hospital_id,
    };
    Object.keys(params).forEach(key => new URL(url).searchParams.append(key, params[key]));
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.status == 200) {
      const json = await response.data;
      return json;
    }
    return false;
  };

  const getDraftPatientReportsClinician = async (clinician_id) => {
    const url = `${hostUrl}/api/reporting/clinician/${clinician_id}/food_reports/draft`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.status == 200) {
      const json = await response.data;
      setDraftPatientReports(json);
      return json;
    }
    return false;
  };
  /*   const getClinicAppointments = async (clinician_id, since=moment().format('YYYY-MM-DD'), until=moment().add(12,'months').format('YYYY-MM-DD')) => {

      const url = new URL(`${hostUrl}/api/clinician/${clinician_id}/appointments`);

      const params = {
        date_since: since,
        date_until: until
      };
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
      const response = await fetch(url, {
          headers: new Headers({
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          }),
          method: 'GET'
      });

      if (response.status == 200) {
        const json = await response.json();
        //setAppointments(json);
        return json;
      }
      return false;
  }*/

  return {
    getClinician,
    sendLink,
    sendTip,
    getTipHistory,
    addAppointment,
    getPatientsInClinic,
    deleteAppointment,
    getDraftPatientReportsClinician,
  };
}

export { ClinicService };
