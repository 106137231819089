/* eslint-disable i18next/no-literal-string */
import { CloseOutlined } from '@ant-design/icons';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Zoom,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Button as ReactButton } from 'react-bootstrap';
import { recipeApi } from '../api';
import { PatientRecipeListResponse } from '../api/generated';
import { useStore } from '../context';
import { RecipeListItem } from './RecipeListItem';

export const PatientRecipeList = (props: {
  recipeList: PatientRecipeListResponse,
  patientId: number,
}) => {
  const { recipeList, patientId } = props;
  const [emailAlert, setEmailAlert] = useState<string | null>(null);
  const [emailed, setEmailed] = useState(false);
  const [unselectDialog, setUnselectDialog] = useState(false);
  const [selectedRecipes, setSelectedRecipes] = useState<number[]>([]);
  const [recipeListSearch, setRecipeListSearch] = useState('');
  const hasRecipesSelected = selectedRecipes.length != 0;
  const { clinician } = useStore();

  const handleSubmit = () => {
    const alert = selectedRecipes.length > 10
      ? 'Only the first 10 selected recipes will be emailed to the patient'
      : !hasRecipesSelected && recipeList.recipe_items?.length > 10
      ? 'There are no selected recipes, so the first 10 recipes will be emailed to the patient'
      : !hasRecipesSelected
      ? 'There are no selected recipes, so the entire recipe list will be emailed to the patient'
      : null;

    if (alert) {
      setEmailAlert(alert);
      return;
    }
    handleEmailList();
  };

  const handleUnselectAll = () => {
    setSelectedRecipes([]);
    setUnselectDialog(false);
  };

  const handleEmailList = () => {
    recipeApi.appApiRecipePostPatientRecipeListEmail({
      patient_id: patientId,
      clinician_id: clinician.id,
      recipe_list_id_or_type: recipeList.id,
      recipe_item_ids: selectedRecipes,
    });

    setEmailAlert(null);
    setEmailed(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setEmailed(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [emailed]);

  return (
    <div
      style={{
        width: '100%',
        padding: '10px',
      }}
    >
      {!!recipeList.recipe_items?.length && (
        <Grid container direction="column" spacing={2}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            direction="row"
            spacing={2}
            style={{ paddingBottom: '15px' }}
          >
            <Grid item>
              <h2>{recipeList.label}</h2>
            </Grid>
            <Grid item>
              <ReactButton
                variant="secondary"
                block
                style={{ height: '45px', maxHeight: '45px', width: '275px' }}
                onClick={handleSubmit}
                disabled={emailed}
              >
                {emailed ? 'Email Sent' : 'Email List to Patient'}
              </ReactButton>
              <Dialog
                open={!!emailAlert}
                onClose={() => setEmailAlert(null)}
              >
                <DialogTitle>
                  Recipe List Email
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ textTransform: 'none' }}>
                    {emailAlert}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setEmailAlert(null)}>Cancel</Button>
                  <Button onClick={handleEmailList} autoFocus>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item>
              <Fade in={hasRecipesSelected}>
                <ReactButton
                  variant="warning"
                  block
                  style={{
                    height: '45px',
                    maxHeight: '45px',
                    width: '275px',
                  }}
                  onClick={() => setUnselectDialog(true)}
                  disabled={!hasRecipesSelected}
                >
                  Unselect All
                </ReactButton>
              </Fade>
              <Dialog
                open={unselectDialog}
                onClose={() => setUnselectDialog(false)}
              >
                <DialogTitle>
                  Unselect All
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ textTransform: 'none' }}>
                    Are you sure you want unselect all selected recipes?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setUnselectDialog(false)}>Cancel</Button>
                  <Button onClick={handleUnselectAll} autoFocus>
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item style={{ marginTop: '-20px', marginLeft: 'auto', paddingRight: '20px' }}>
              <TextField
                variant="outlined"
                label="Search Recipe List"
                size="small"
                value={recipeListSearch}
                onChange={(e) => setRecipeListSearch((e.target.value).toLowerCase())}
                style={{ width: '353px' }}
                InputProps={{
                  endAdornment: (
                    <Zoom in={!!recipeListSearch}>
                      <InputAdornment position="end">
                        <IconButton onClick={() => setRecipeListSearch('')}>
                          <CloseOutlined style={{ fontSize: '15px' }} translate={undefined} rev={undefined} />
                        </IconButton>
                      </InputAdornment>
                    </Zoom>
                  ),
                  style: { paddingRight: '0px' },
                }}
              />
            </Grid>
          </Grid>
          <div style={{ width: '100%', whiteSpace: 'pre-wrap' }}>
            <Grid container direction="row" spacing={2}>
              {recipeList.recipe_items?.filter(item => item.recipe.label.toLowerCase().includes(recipeListSearch))
                .map((
                  recipeItem,
                  index,
                ) => (
                  <RecipeListItem
                    key={recipeItem.id}
                    recipeItem={recipeItem}
                    recipeListId={recipeList.id}
                    selectedRecipes={selectedRecipes}
                    setSelectedRecipes={setSelectedRecipes}
                    patientId={patientId}
                    additionalNotes={recipeItem.additional_notes}
                  />
                ))}
            </Grid>
          </div>
        </Grid>
      )}
    </div>
  );
};
