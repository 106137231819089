import React, { ImgHTMLAttributes } from 'react';
import { Image } from 'react-bootstrap';
import { useAsyncResult } from 'react-use-async-result';
import { useStore } from '../../context';

export const AuthenticatedImage = (props: ImgHTMLAttributes<HTMLImageElement>) => {
  const { token } = useStore();

  const blobRes = useAsyncResult<string>(async () => {
    const response = await fetch(props.src, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.status !== 200) {
      throw new Error(`HTTP status ${response.status}`);
    }

    const data = await response.blob();
    return URL.createObjectURL(data);
  }, [props.src]);

  if (blobRes.isError) {
    return <div {...props}>{'' + blobRes.error}</div>;
  }
  if (!blobRes.isDone) {
    return null;
  }
  return <Image {...props} src={blobRes.result} />;
};
