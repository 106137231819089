import * as d3 from 'd3';
import _ from 'lodash';
import React from 'react';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryContainer, VictoryStack } from 'victory';
import { PatientGlucoseDataResponse } from '../../api/generated';
import {
  cgmReadingMaxIntervalMinutes,
  formatPct,
  glucoseTargets,
  mmMolToTarget,
  timestampToTimestampMS,
} from './common';

export const cgmGlucoseMeasurementsToTimeInRange = (
  points: PatientGlucoseDataResponse[],
): [number, Record<keyof typeof glucoseTargets, number>] => {
  const pointsSorted = _.sortBy(points, 'timestamp');
  const minutesInRange: Record<keyof typeof glucoseTargets, number> = Object.fromEntries(
    Object.keys(glucoseTargets)
      .map(t => [t, 0]),
  ) as any;

  let totalMinutes = 0;
  pointsSorted.forEach((point, i) => {
    const nextPoint = pointsSorted[i + 1];
    if (!nextPoint) {
      return;
    }
    if (nextPoint.timestamp == point.timestamp) {
      return;
    }
    const timeInRangeMS = timestampToTimestampMS(nextPoint.timestamp) - timestampToTimestampMS(point.timestamp);
    const timeInRangeMin = timeInRangeMS / 1000 / 60;
    if (timeInRangeMin > cgmReadingMaxIntervalMinutes) {
      return;
    }
    const v = (point.value + nextPoint.value) / 2;
    const [targetName] = mmMolToTarget(v);
    if (!targetName) {
      return;
    }
    minutesInRange[targetName] += timeInRangeMin;
    totalMinutes += timeInRangeMin;
  });

  return [totalMinutes, minutesInRange];
};

export const CGMGlucoseTimeInRange = (props: {
  data: PatientGlucoseDataResponse[],
}) => {
  const [totalMinutes, minutesInRange] = cgmGlucoseMeasurementsToTimeInRange(props.data);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <CGMGlucoseTimeInRangeChart data={props.data} totalMinutes={totalMinutes} minutesInRange={minutesInRange} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          marginLeft: 5,
        }}
      >
        {Object.entries(glucoseTargets).reverse().map(([targetName, target]) => (
          <div
            key={targetName}
            style={{
              fontWeight: targetName == 'target' ? 'bold' : 'normal',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                width: 8,
                height: 8,
                backgroundColor: target.color,
                marginRight: 5,
              }}
            />
            {formatPct(minutesInRange[targetName] / totalMinutes * 100, 0)} {glucoseTargets[targetName].label}
          </div>
        ))}
      </div>
    </div>
  );
};

const CGMGlucoseTimeInRangeChart = (props: {
  data: PatientGlucoseDataResponse[],
  totalMinutes: number,
  minutesInRange: Record<keyof typeof glucoseTargets, number>,
}) => {
  let { totalMinutes } = props;
  const { minutesInRange } = props;
  Object.entries(minutesInRange).forEach(([targetName, minutes]) => {
    const fraction = minutes / totalMinutes;
    if (fraction < 0.01) {
      return;
    }
    if (fraction < 0.07) {
      minutesInRange[targetName] += 0.07 * totalMinutes;
      totalMinutes += 0.07 * totalMinutes;
    }
  });

  const yDomain = d3.scaleLinear()
    .domain([0, totalMinutes])
    .range([0, 1]);

  const xDomain = [0, 1] as [number, number];

  const values = Object.entries(minutesInRange).map(([targetName, minutes]) => {
    const target = glucoseTargets[targetName];
    return {
      targetName: targetName,
      value: yDomain(minutes),
      color: target.color,
    };
  })
    .filter(x => x.value > 0.01);

  const yTicksObj = {} as Record<number, { value: number, label: number }>;
  let yTickCumSum = 0;
  values.forEach(v => {
    const target = glucoseTargets[v.targetName];
    if (target.mmMolLower > 0) {
      yTicksObj[target.mmMolLower] = {
        value: yTickCumSum,
        label: target.mmMolLower,
      };
    }
    if (target.mmMolUpper < 100) {
      yTicksObj[target.mmMolUpper] = {
        value: yTickCumSum + v.value,
        label: target.mmMolUpper,
      };
    }
    yTickCumSum += v.value;
  });
  const yTickValues = Object.values(yTicksObj).map(v => v.value).sort();
  const yTickLabels = Object.fromEntries(Object.values(yTicksObj).map(x => [x.value, x.label]));

  return (
    <VictoryChart
      domain={{
        x: xDomain,
        y: yDomain.range() as [number, number],
      }}
      padding={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
      height={113}
      width={30}
      containerComponent={<VictoryContainer responsive={false} style={{ height: 'auto' }} />}
    >
      <VictoryStack>
        {values.map(val => (
          <VictoryArea
            key={val.targetName}
            data={[
              { x: xDomain[0], y: val.value },
              { x: xDomain[1], y: val.value },
            ]}
            style={{
              data: {
                fill: val.color,
              },
            }}
          />
        ))}
      </VictoryStack>

      <VictoryAxis
        dependentAxis
        tickValues={[]}
        tickFormat={v => ''}
        style={{
          axis: { stroke: 'none' },
        }}
      />
    </VictoryChart>
  );
};
