/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OpenAIChatCompletionRequest } from '../models';
// @ts-ignore
import { OpenAIChatCompletionResponse } from '../models';
/**
 * OpenaiApi - axios parameter creator
 * @export
 */
export const OpenaiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Chat completion
         * @param {OpenAIChatCompletionRequest} OpenAIChatCompletionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOpenaiPostChatCompletion: async (OpenAIChatCompletionRequest: OpenAIChatCompletionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'OpenAIChatCompletionRequest' is not null or undefined
            assertParamExists('appApiOpenaiPostChatCompletion', 'OpenAIChatCompletionRequest', OpenAIChatCompletionRequest)
            const localVarPath = `/openai/chat-completion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(OpenAIChatCompletionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Chat completion
         * @param {OpenAIChatCompletionRequest} OpenAIChatCompletionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOpenaiPostFoodSubstitutionsChatCompletion: async (OpenAIChatCompletionRequest: OpenAIChatCompletionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'OpenAIChatCompletionRequest' is not null or undefined
            assertParamExists('appApiOpenaiPostFoodSubstitutionsChatCompletion', 'OpenAIChatCompletionRequest', OpenAIChatCompletionRequest)
            const localVarPath = `/openai/chat-completion/food-substitutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(OpenAIChatCompletionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Chat completion
         * @param {OpenAIChatCompletionRequest} OpenAIChatCompletionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOpenaiPostMealPlanChatCompletion: async (OpenAIChatCompletionRequest: OpenAIChatCompletionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'OpenAIChatCompletionRequest' is not null or undefined
            assertParamExists('appApiOpenaiPostMealPlanChatCompletion', 'OpenAIChatCompletionRequest', OpenAIChatCompletionRequest)
            const localVarPath = `/openai/chat-completion/meal-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(OpenAIChatCompletionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpenaiApi - functional programming interface
 * @export
 */
export const OpenaiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpenaiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Chat completion
         * @param {OpenAIChatCompletionRequest} OpenAIChatCompletionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiOpenaiPostChatCompletion(OpenAIChatCompletionRequest: OpenAIChatCompletionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenAIChatCompletionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiOpenaiPostChatCompletion(OpenAIChatCompletionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Chat completion
         * @param {OpenAIChatCompletionRequest} OpenAIChatCompletionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiOpenaiPostFoodSubstitutionsChatCompletion(OpenAIChatCompletionRequest: OpenAIChatCompletionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenAIChatCompletionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiOpenaiPostFoodSubstitutionsChatCompletion(OpenAIChatCompletionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Chat completion
         * @param {OpenAIChatCompletionRequest} OpenAIChatCompletionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiOpenaiPostMealPlanChatCompletion(OpenAIChatCompletionRequest: OpenAIChatCompletionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenAIChatCompletionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiOpenaiPostMealPlanChatCompletion(OpenAIChatCompletionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpenaiApi - factory interface
 * @export
 */
export const OpenaiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpenaiApiFp(configuration)
    return {
        /**
         * 
         * @summary Chat completion
         * @param {OpenaiApiAppApiOpenaiPostChatCompletionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOpenaiPostChatCompletion(requestParameters: OpenaiApiAppApiOpenaiPostChatCompletionRequest, options?: AxiosRequestConfig): AxiosPromise<OpenAIChatCompletionResponse> {
            return localVarFp.appApiOpenaiPostChatCompletion(requestParameters.OpenAIChatCompletionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Chat completion
         * @param {OpenaiApiAppApiOpenaiPostFoodSubstitutionsChatCompletionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOpenaiPostFoodSubstitutionsChatCompletion(requestParameters: OpenaiApiAppApiOpenaiPostFoodSubstitutionsChatCompletionRequest, options?: AxiosRequestConfig): AxiosPromise<OpenAIChatCompletionResponse> {
            return localVarFp.appApiOpenaiPostFoodSubstitutionsChatCompletion(requestParameters.OpenAIChatCompletionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Chat completion
         * @param {OpenaiApiAppApiOpenaiPostMealPlanChatCompletionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOpenaiPostMealPlanChatCompletion(requestParameters: OpenaiApiAppApiOpenaiPostMealPlanChatCompletionRequest, options?: AxiosRequestConfig): AxiosPromise<OpenAIChatCompletionResponse> {
            return localVarFp.appApiOpenaiPostMealPlanChatCompletion(requestParameters.OpenAIChatCompletionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiOpenaiPostChatCompletion operation in OpenaiApi.
 * @export
 * @interface OpenaiApiAppApiOpenaiPostChatCompletionRequest
 */
export interface OpenaiApiAppApiOpenaiPostChatCompletionRequest {
    /**
     * 
     * @type {OpenAIChatCompletionRequest}
     * @memberof OpenaiApiAppApiOpenaiPostChatCompletion
     */
    readonly OpenAIChatCompletionRequest: OpenAIChatCompletionRequest
}

/**
 * Request parameters for appApiOpenaiPostFoodSubstitutionsChatCompletion operation in OpenaiApi.
 * @export
 * @interface OpenaiApiAppApiOpenaiPostFoodSubstitutionsChatCompletionRequest
 */
export interface OpenaiApiAppApiOpenaiPostFoodSubstitutionsChatCompletionRequest {
    /**
     * 
     * @type {OpenAIChatCompletionRequest}
     * @memberof OpenaiApiAppApiOpenaiPostFoodSubstitutionsChatCompletion
     */
    readonly OpenAIChatCompletionRequest: OpenAIChatCompletionRequest
}

/**
 * Request parameters for appApiOpenaiPostMealPlanChatCompletion operation in OpenaiApi.
 * @export
 * @interface OpenaiApiAppApiOpenaiPostMealPlanChatCompletionRequest
 */
export interface OpenaiApiAppApiOpenaiPostMealPlanChatCompletionRequest {
    /**
     * 
     * @type {OpenAIChatCompletionRequest}
     * @memberof OpenaiApiAppApiOpenaiPostMealPlanChatCompletion
     */
    readonly OpenAIChatCompletionRequest: OpenAIChatCompletionRequest
}

/**
 * OpenaiApi - object-oriented interface
 * @export
 * @class OpenaiApi
 * @extends {BaseAPI}
 */
export class OpenaiApi extends BaseAPI {
    /**
     * 
     * @summary Chat completion
     * @param {OpenaiApiAppApiOpenaiPostChatCompletionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenaiApi
     */
    public appApiOpenaiPostChatCompletion(requestParameters: OpenaiApiAppApiOpenaiPostChatCompletionRequest, options?: AxiosRequestConfig) {
        return OpenaiApiFp(this.configuration).appApiOpenaiPostChatCompletion(requestParameters.OpenAIChatCompletionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Chat completion
     * @param {OpenaiApiAppApiOpenaiPostFoodSubstitutionsChatCompletionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenaiApi
     */
    public appApiOpenaiPostFoodSubstitutionsChatCompletion(requestParameters: OpenaiApiAppApiOpenaiPostFoodSubstitutionsChatCompletionRequest, options?: AxiosRequestConfig) {
        return OpenaiApiFp(this.configuration).appApiOpenaiPostFoodSubstitutionsChatCompletion(requestParameters.OpenAIChatCompletionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Chat completion
     * @param {OpenaiApiAppApiOpenaiPostMealPlanChatCompletionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenaiApi
     */
    public appApiOpenaiPostMealPlanChatCompletion(requestParameters: OpenaiApiAppApiOpenaiPostMealPlanChatCompletionRequest, options?: AxiosRequestConfig) {
        return OpenaiApiFp(this.configuration).appApiOpenaiPostMealPlanChatCompletion(requestParameters.OpenAIChatCompletionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

