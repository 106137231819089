/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PatientSubscriptionResponse
 */
export interface PatientSubscriptionResponse {
    /**
     * 
     * @type {number}
     * @memberof PatientSubscriptionResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PatientSubscriptionResponse
     */
    'patient_id': number;
    /**
     * 
     * @type {number}
     * @memberof PatientSubscriptionResponse
     */
    'hospital_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientSubscriptionResponse
     */
    'clinic_id'?: number;
    /**
     * name of the subscription
     * @type {string}
     * @memberof PatientSubscriptionResponse
     */
    'name': PatientSubscriptionResponseNameEnum;
    /**
     * 
     * @type {string}
     * @memberof PatientSubscriptionResponse
     */
    'type': PatientSubscriptionResponseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PatientSubscriptionResponse
     */
    'created_by_user_id'?: number | null;
    /**
     * calendar date in format YYYY-MM-DD
     * @type {string}
     * @memberof PatientSubscriptionResponse
     */
    'start_date': string;
    /**
     * calendar date in format YYYY-MM-DD
     * @type {string}
     * @memberof PatientSubscriptionResponse
     */
    'expiration_date': string;
    /**
     * 
     * @type {number}
     * @memberof PatientSubscriptionResponse
     */
    'subscription_length'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientSubscriptionResponse
     */
    'notified_of_logging_completion'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientSubscriptionResponse
     */
    'notified_of_logging_completion_time'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientSubscriptionResponse
     */
    'is_logging_complete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientSubscriptionResponse
     */
    'logging_completion_time'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientSubscriptionResponse
     */
    'is_complete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientSubscriptionResponse
     */
    'completion_time'?: string;
    /**
     * calendar date in format YYYY-MM-DD.
     * @type {string}
     * @memberof PatientSubscriptionResponse
     */
    'report_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientSubscriptionResponse
     */
    'created_time': string;
    /**
     * 
     * @type {string}
     * @memberof PatientSubscriptionResponse
     */
    'updated_time': string;
}

export const PatientSubscriptionResponseNameEnum = {
    ThreeDay: 'three-day',
    FiveDay: 'five-day',
    SevenDay: 'seven-day',
    TenDay: 'ten-day',
    OneMonth: 'one-month',
    Cgm14: 'cgm14',
    _14Day: '14-day',
    _21Day: '21-day',
    _28Day: '28-day',
    Custom: 'custom'
} as const;

export type PatientSubscriptionResponseNameEnum = typeof PatientSubscriptionResponseNameEnum[keyof typeof PatientSubscriptionResponseNameEnum];
export const PatientSubscriptionResponseTypeEnum = {
    Single: 'single',
    Weekly: 'weekly',
    Monthly: 'monthly',
    BiWeekly: 'bi-weekly',
    _4Weeks: '4-weeks',
    Custom: 'custom'
} as const;

export type PatientSubscriptionResponseTypeEnum = typeof PatientSubscriptionResponseTypeEnum[keyof typeof PatientSubscriptionResponseTypeEnum];


