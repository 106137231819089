import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { hostUrl } from '../api';
import { useStore } from '../context';
import { Trans } from '../i18n';

const Image = (props: {
  src: string,
  className?: string,
  width?: number,
}) => {
  const store = useStore();
  const { token } = store;
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    fetch(props.src, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
      method: 'GET',
    })
      .then(res => res.blob())
      .then(data => {
        setImgSrc(URL.createObjectURL(data));
      })
      .catch(err => {
        console.log(`Error fetching image ${props.src}: ${err}`);
      });
  }, [props.src, token]);

  return imgSrc && <img className={props.className} src={imgSrc} />;
};

const OriginalImage = (props: {
  mealPhotoId: number,
  className?: string,
  style?: React.CSSProperties,
  onClick?: () => void,
}) => {
  const store = useStore();
  const { token } = store;

  const blobQuery = useQuery(['meal-photo-blob', props.mealPhotoId], async () => {
    /* eslint-disable-next-line i18next/no-literal-string */
    const src = `${hostUrl}/api/meal_photos/${props.mealPhotoId}/original_photo`;

    const response = await fetch(src, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      method: 'GET',
    });

    if (response.status !== 200) {
      throw new Error(`${response.status}`);
    }
    const data = await response.blob();
    return URL.createObjectURL(data);
  }, {
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });

  if (blobQuery.isLoading) {
    return (
      <div className="fieldFull">
        <Trans>Loading...</Trans>
      </div>
    );
  }
  if (blobQuery.isError) {
    return (
      <div className="fieldFull">
        <Trans>Unexpected error loading meal image.</Trans>
      </div>
    );
  }
  return <img className={props.className} style={props.style} src={blobQuery.data} onClick={props.onClick} />;
};

export { OriginalImage };
export { Image };
