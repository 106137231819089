import classnames from 'classnames';
import React, { useContext, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import { Button, Form } from 'react-bootstrap';
import { useStore } from '../context';
import { Trans } from '../i18n';
import { ClinicService } from '../services/clinic';
import { Input } from './input';
import { LabelA, LabelL, LabelM } from './label';

const SendLink = (props) => {
  const [linkUrl, setLinkUrl] = useState();
  const { clinician, patient } = useStore();
  const clinicService = ClinicService();
  const { toggle } = props;

  const handleLinkChange = (e) => {
    setLinkUrl(e.target.value);
  };

  const sendLink = async () => {
    await clinicService.sendLink(linkUrl, clinician.id, [patient.patient_id]);
    toggle();
  };

  return (
    <MDBContainer>
      <MDBModal backdrop={false} isOpen={props.show} toggle={toggle} centered size="lg">
        <MDBModalHeader toggle={toggle}>
          <i className="fas fa-link margin-right-s" />
          <Trans>Send a link</Trans>
        </MDBModalHeader>
        <MDBModalBody>
          <Container>
            <Row>
              <Col className="verticalCenter">
                <LabelA className="font-wide">
                  <Trans>to</Trans> {patient.first_name} {patient.last_name}
                </LabelA>
              </Col>
            </Row>
            <Row>
              <Col className="verticalCenter">
                <Input
                  type="text"
                  label="Link (e.g. http://google.com)"
                  background
                  size="lg"
                  value={linkUrl}
                  onChange={handleLinkChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="margin-bot-l">
                  <Button block onClick={sendLink}>
                    <Trans>send link</Trans>
                  </Button>
                </p>
              </Col>
            </Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export { SendLink };
