import React from 'react';

export const InsulinDotSVG = (p: {
  value: string,
}) => {
  return (
    <g transform="scale(0.6) translate(0, -21)">
      <rect
        x="0.499512"
        y="1.18921"
        width="52.6628"
        height="19"
        rx="9.5"
        stroke="black"
        fill="white"
        id="rect2"
      />
      <circle
        cx="10"
        cy="10.6892"
        r="10"
        fill="#528EC3"
        id="circle6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.90561 9.31308C5.8374 9.24487 5.8374 9.13428 5.90561 9.06607L8.37775 6.59393C8.44596 6.52572 8.55654 6.52572 8.62475 6.59393L13.5689 11.5381C13.6371 11.6063 13.6371 11.7169 13.5689 11.7851L11.0968 14.2572C11.0286 14.3254 10.918 14.3254 10.8498 14.2572L5.90561 9.31308ZM6.27611 9.18958L10.9733 13.8867L13.1984 11.6616L8.50125 6.96444L6.27611 9.18958Z"
        fill="white"
        id="path8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9394 10.9208C14.0076 10.8526 14.1181 10.8526 14.1864 10.9207L14.8048 11.5383C14.8376 11.5711 14.856 11.6155 14.8561 11.6619C14.8561 11.7082 14.8377 11.7527 14.8049 11.7854L11.0964 15.4936C11.0282 15.5618 10.9176 15.5618 10.8494 15.4936L10.2311 14.8758C10.1984 14.8431 10.1799 14.7986 10.1799 14.7523C10.1799 14.706 10.1983 14.6615 10.2311 14.6288L13.9394 10.9208ZM14.063 11.2912L10.6016 14.7522L10.9728 15.1231L14.4343 11.662L14.063 11.2912Z"
        fill="white"
        id="path10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0128 13.0838C13.081 13.0156 13.1915 13.0156 13.2597 13.0838L14.9897 14.8142L15.4849 14.3193C15.5176 14.2866 15.5621 14.2682 15.6084 14.2682C15.6548 14.2682 15.6992 14.2866 15.7319 14.3194L16.3495 14.9377C16.4176 15.006 16.4176 15.1165 16.3493 15.1847L15.7409 15.7928C15.738 15.7962 15.735 15.7995 15.7318 15.8026L15.1139 16.4204L15.1139 16.4204L14.4956 17.0388C14.4628 17.0716 14.4184 17.09 14.3721 17.09C14.3257 17.09 14.2813 17.0716 14.2485 17.0388L13.6306 16.4204C13.5625 16.3521 13.5625 16.2416 13.6307 16.1734L14.1251 15.6791L12.3944 13.9486C12.3617 13.9159 12.3433 13.8714 12.3433 13.8251C12.3433 13.7787 12.3617 13.7343 12.3945 13.7016L13.0128 13.0838ZM12.765 13.8252L14.4956 15.5556C14.5283 15.5884 14.5468 15.6328 14.5468 15.6791C14.5467 15.7254 14.5283 15.7699 14.4956 15.8026L14.0011 16.297L14.3721 16.6683L14.8669 16.1734L14.8669 16.1734L15.4757 15.5647C15.4786 15.5613 15.4816 15.558 15.4848 15.5548L15.9789 15.0611L15.6082 14.6899L15.1131 15.1847C15.0449 15.2529 14.9344 15.2528 14.8662 15.1846L13.1361 13.4543L12.765 13.8252Z"
        fill="white"
        id="path12"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.26431 6.54315C7.31066 6.54316 7.35511 6.56159 7.38786 6.59438L8.00578 7.21306C8.07393 7.28129 8.07389 7.39183 8.00569 7.46001L6.76995 8.69538C6.70175 8.76356 6.5912 8.76357 6.523 8.6954L5.90507 8.07782C5.83685 8.00963 5.83681 7.89905 5.905 7.83082L7.14074 6.59434C7.17351 6.56156 7.21796 6.54314 7.26431 6.54315ZM6.27554 7.95421L6.64645 8.32491L7.63526 7.33639L7.26424 6.96493L6.27554 7.95421Z"
        fill="white"
        id="path14"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.05116 4.74037C4.11936 4.67216 4.22995 4.67216 4.29816 4.74037L6.7701 7.2123C6.83831 7.28051 6.83831 7.3911 6.7701 7.45931C6.70189 7.52752 6.5913 7.52752 6.52309 7.45931L4.05116 4.98737C3.98295 4.91916 3.98295 4.80857 4.05116 4.74037Z"
        fill="white"
        id="path16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00683 9.68432C8.07501 9.75256 8.07497 9.86315 8.00673 9.93133L7.38832 10.5493C7.32009 10.6174 7.2095 10.6174 7.14132 10.5492C7.07314 10.4809 7.07318 10.3703 7.14141 10.3021L7.75983 9.68423C7.82806 9.61604 7.93865 9.61609 8.00683 9.68432Z"
        fill="white"
        id="path18"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.24232 10.9207C9.31056 10.9888 9.31061 11.0994 9.24244 11.1677L8.62464 11.7861C8.55646 11.8543 8.44588 11.8544 8.37763 11.7862C8.30939 11.718 8.30934 11.6074 8.37751 11.5392L8.99531 10.9208C9.06349 10.8525 9.17407 10.8525 9.24232 10.9207Z"
        fill="white"
        id="path20"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.479 12.1567C10.5472 12.2249 10.5471 12.3355 10.4789 12.4037L9.86076 13.0216C9.79254 13.0898 9.68195 13.0898 9.61375 13.0216C9.54556 12.9534 9.54558 12.8428 9.6138 12.7746L10.232 12.1567C10.3002 12.0885 10.4108 12.0885 10.479 12.1567Z"
        fill="white"
        id="path22"
      />
      <text
        x="24"
        y="14.5"
        fontSize={10}
        fontWeight="bold"
        textAnchor="left"
        fill="black"
      >
        {p.value}
      </text>
    </g>
  );
};
