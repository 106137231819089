import classnames from 'classnames';
import React, { useContext, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import { Button, Form } from 'react-bootstrap';
import { useStore } from '../context';
import { ClinicService } from '../services/clinic';
import { PatientService } from '../services/patient';
import { Input } from './input';
import { LabelA, LabelL, LabelM } from './label';

import DateFnsUtils from '@date-io/moment'; // choose your lib
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { Trans, useTranslation } from '../i18n';
import { validate } from './formValidator';

const SendAppointment = (props) => {
  const [linkUrl, setLinkUrl] = useState();
  const { clinician, patient, appointments } = useStore();
  const clinicService = ClinicService();
  const patientService = PatientService();
  const { toggle, show } = props;
  const [startDate, setStartDate] = useState(null);
  const [titleText, setTitleText] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const { t } = useTranslation();

  const validator = {
    startDate: () => {
      if (!startDate) {
        setStartDateError(t('Please select an appointment date'));
        return false;
      }
      setStartDateError('');
      return true;
    },
  };

  const handleLinkChange = (e) => {
    setLinkUrl(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitleText(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e._d);
    console.log(e._d);
  };

  const reset = () => {
    setStartDate(null);
    setStartDateError('');
  };

  const updateCategory = (e) => {
    if (e.target.checked) {
      console.log(e.target.value);
      const dateValues = e.target.value.split(' ');
      // eslint-disable-next-line i18next/no-literal-string
      const DATE_RFC2822 = 'ddd, DD MMM YYYY HH:mm:ss ZZ';
      setStartDate(moment().add(dateValues[0], dateValues[1]).format(DATE_RFC2822));
      console.log(startDate);
    }
  };

  const handleDeleteAppt = async (appId, clinicId) => {
    console.log(appId, patient.patient_id, clinician.id, clinician.hospital_id, clinicId);
    const response = await clinicService.deleteAppointment(
      clinician.hospital_id,
      clinicId,
      clinician.id,
      patient.patient_id,
      appId,
    );
    const appUpdate = await patientService.getAppointments(clinician.id);
    if (response) {
      reset();
    }
  };

  const getAppointmentHistory = () => {
    return (
      <div>
        <Trans>History</Trans>
      </div>
    );
  };

  const bookAppointment = async () => {
    if (!validate(validator.startDate)) {
      console.log('failed', startDate);
      return;
    }
    let clinicToUse = '';

    clinician.clinics.map(async item => {
      if (clinicToUse == '') {
        const confirmPtinClinic = await clinicService.getPatientsInClinic(item.id, item.hospital_id);
        console.log(confirmPtinClinic);
        if (confirmPtinClinic) {
          clinicToUse = 1;
          if (titleText == '') {
            setTitleText(t('Appointment'));
          }
          const res = await clinicService.addAppointment(
            item.hospital_id,
            item.id,
            clinician.id,
            patient.patient_id,
            startDate,
            titleText,
          );
          if (res) {
            const appUpdate = await patientService.getAppointments(clinician.id);
            console.log('success');
            toggle();
          }
        }
      }
    });
  };

  return (
    <MDBContainer>
      <MDBModal backdrop={false} isOpen={props.show} toggle={toggle} centered size="lg">
        <MDBModalHeader toggle={toggle}>
          <i className="fas fa-calendar margin-right-s" />
          {t('Book Appointment')}
        </MDBModalHeader>
        <MDBModalBody>
          <Container>
            <Row>
              <Col>
                <h6>
                  {t('Appointment History for {{patientFirstName}} {{patientLastName}}', {
                    patientFirstName: patient.first_name,
                    patientLastName: patient.last_name,
                  })}
                </h6>
                {(appointments && appointments.clinician_appointments && appointments.clinician_appointments.length > 0)
                  ? appointments.clinician_appointments.filter(item => item.patient_id == patient.patient_id).map(
                    appoint => {
                      return (
                        <div key={appoint.appointment_id}>
                          {moment(appoint.start_time).format('MMM DD/YYYY')} {appoint.title}
                          <span
                            className="goalTypeButton"
                            onClick={() => {
                              if (window.confirm(t('Are you sure you wish to delete this item?'))) {
                                handleDeleteAppt(appoint.appointment_id, appoint.clinic_id);
                              }
                            }}
                          >
                            <i className="fas fa-times-circle" />
                          </span>
                        </div>
                      );
                    },
                  )
                  : t('none')}
              </Col>
            </Row>
            <Row>
              <Col className="verticalCenter">
                <Input
                  type="text"
                  label={t('Purpose e.g. follow-up (optional)')}
                  background
                  size="lg"
                  value={titleText}
                  onChange={handleTitleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="verticalCenter">
                <LabelA className="font-wide">
                  <input type="radio" id="1W" name="appointment" value="1 W" onChange={updateCategory} />{' '}
                  <label htmlFor="1week">{t('1 Week')}</label>
                </LabelA>
                <LabelA className="font-wide">
                  <input type="radio" id="2W" name="appointment" value="2 W" onChange={updateCategory} />{' '}
                  <label htmlFor="2week">{t('2 Weeks')}</label>
                </LabelA>
                <LabelA className="font-wide">
                  <input type="radio" id="1M" name="appointment" value="1 M" onChange={updateCategory} />{' '}
                  <label htmlFor="2week">{t('1 Month')}</label>
                </LabelA>
                <LabelA className="font-wide">
                  <input type="radio" id="2M" name="appointment" value="2 M" onChange={updateCategory} />{' '}
                  <label htmlFor="2week">{t('2 Months')}</label>
                </LabelA>
                <LabelA className="font-wide">
                  <input type="radio" id="3M" name="appointment" value="3 M" onChange={updateCategory} />{' '}
                  <label htmlFor="2week">{t('3 Months')}</label>
                </LabelA>
                <LabelA className="font-wide">
                  <input type="radio" id="6M" name="appointment" value="6 M" onChange={updateCategory} />{' '}
                  <label htmlFor="2week">{t('6 Months')}</label>
                </LabelA>
                <LabelA className="font-wide">
                  <input type="radio" id="12M" name="appointment" value="12 M" onChange={updateCategory} />{' '}
                  <label htmlFor="2week">{t('12 Months')}</label>
                </LabelA>
              </Col>
            </Row>
            <Row>
              <Col>
                <p />
                <LabelA className="font-wide">{t('Custom Date')}:</LabelA>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    inputVariant="outlined"
                    disableToolbar
                    format="L"
                    label={t('Appointment date')}
                    variant="inline"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </MuiPickersUtilsProvider>
                <p className="errorMessage errorLabel">{startDateError}</p>
                <LabelA />
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="margin-bot-l">
                  <Button block onClick={bookAppointment}>{t('Book Appointment')}</Button>
                </p>
              </Col>
            </Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export { SendAppointment };
