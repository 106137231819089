/* eslint-disable i18next/no-literal-string */
import { DeleteOutlined, EditOutlined, ExportOutlined, FullscreenOutlined } from '@ant-design/icons';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  CheckboxProps,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import classnames from 'classnames';
import { MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { recipeApi } from '../api';
import { PatientRecipeListItemResponse, RecipeResponse } from '../api/generated';
import './patientRecipeListItem.scss';
import { common, purple } from '@material-ui/core/colors';
import { GridSize } from '@material-ui/core/Grid';
import { useAsyncResult } from 'react-use-async-result';

const CardCheckbox = withStyles({
  root: {
    '&$checked': {
      color: purple[400],
    },
    color: common.white,
  },
  checked: {},
})((props: CheckboxProps) => (
  <Checkbox
    color="default"
    style={{ zIndex: 1000, position: 'absolute', top: '3px', right: '3px' }}
    {...props}
  />
));

export const RecipeListItem = (props: {
  recipeItem: PatientRecipeListItemResponse,
  recipeListId: string,
  selectedRecipes: number[],
  setSelectedRecipes: React.Dispatch<React.SetStateAction<number[]>>,
  patientId: number,
  additionalNotes?: string,
}) => {
  const { recipeItem, recipeListId, selectedRecipes, patientId, additionalNotes } = props;
  const [showRecipeModal, setShowRecipeModal] = useState(false);
  const [editRecipeNotes, setEditRecipeNotes] = useState(false);
  const [recipeNotes, setRecipeNotes] = useState(additionalNotes);
  const [lines, setLines] = useState(5);
  const selected = selectedRecipes.includes(recipeItem.id);

  const safeDivByZero = (a: number, b: number) => {
    try {
      const value = a / b;
      return value;
    } catch (error) {
      return NaN;
    }
  };

  const handleExpand = () => {
    setShowRecipeModal(!showRecipeModal);
    editRes.clear();
  };

  const titleRef = useRef(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    const hasTwoLines = (el) => {
      const { clientHeight } = el;
      return clientHeight > 41;
    };

    const checkTitleClamp = () => {
      if (titleRef.current) {
        if (!hasTwoLines(titleRef.current)) {
          setLines(7);
        }
      }
    };

    checkTitleClamp();
  }, [titleRef]);

  const clamp: CSSProperties = {
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
  };

  interface NutrientProp {
    name: string;
    value: number;
    size?: GridSize;
  }

  const Nutrient: React.FunctionComponent<NutrientProp> = (props) => {
    const { name, size = 6, value = 0 } = props;

    const classes = classnames('nutrientCol');

    return (
      <Grid item xs={size} className={classes}>
        <div className="value">{Number(value).toFixed(0)}</div>
        <div className="name">{name}</div>
      </Grid>
    );
  };

  const handleRemove = async () => {
    await recipeApi.appApiRecipeDeletePatientRecipeListItem({
      patient_id: patientId,
      recipe_list_id_or_type: recipeListId,
      recipe_id: recipeItem.recipe.id,
    });
    queryClient.invalidateQueries({ queryKey: ['patient-recipe-list', patientId] });
  };

  const handleSelect = () => {
    if (selected) {
      props.setSelectedRecipes(oldRecipes => oldRecipes.filter(r => r != recipeItem.id));
      return;
    }
    props.setSelectedRecipes(oldRecipes => [...oldRecipes, recipeItem.id]);
  };

  const handleEdit = (e) => {
    setRecipeNotes(e.target.value);
  };

  const editRes = useAsyncResult();
  const handleSubmit = () => {
    if (!recipeNotes) {
      return;
    }
    editRes.bind(
      recipeApi.appApiRecipePutPatientRecipeListItem({
        patient_id: patientId,
        recipe_list_id_or_type: recipeListId,
        recipe_id: recipeItem.recipe.id,
        PutPatientRecipeListItemRequest: {
          additional_notes: recipeNotes,
        },
      }),
    );

    setEditRecipeNotes(false);
    queryClient.invalidateQueries({ queryKey: ['patient-recipe-list', patientId] });
  };

  const instructionQuery = useQuery(['recipe', recipeItem.recipe.id, 'instructions'], async () => {
    const instructionRes = await recipeApi.appApiRecipeGetRecipeInstruction({
      recipe_id: recipeItem.recipe.id,
    });

    return instructionRes.data;
  }, { enabled: showRecipeModal });

  return (
    <Grid item>
      <Card className={selected ? 'cardClampSelected' : 'cardClamp'} style={{ position: 'relative' }}>
        <CardActionArea onClick={handleSelect}>
          <CardMedia
            style={{ height: '0', paddingTop: '56.25%' }}
            image={recipeItem.recipe.image_url}
            title={recipeItem.recipe.label}
          />
        </CardActionArea>
        <CardCheckbox checked={selected} onChange={handleSelect} />
        <CardContent style={{ paddingBottom: '0%' }}>
          <div ref={titleRef} className="titleClamp">
            <Typography variant="h4">
              {recipeItem.recipe.label}
            </Typography>
          </div>
          <Typography variant="subtitle1" component="p">
            Ingredient List:
          </Typography>
          <div style={clamp}>
            <Typography variant="inherit">
              {recipeItem.recipe.ingredients.join('\n')}
              <br />
            </Typography>
          </div>
        </CardContent>
        <CardActions>
          <Tooltip title="Remove from Favourites">
            <IconButton
              onClick={handleRemove}
            >
              <DeleteOutlined translate={undefined} rev={undefined} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit recipe notes">
            <IconButton
              onClick={() => {
                setShowRecipeModal(true);
                setEditRecipeNotes(true);
                editRes.clear();
              }}
              disabled={editRes.isPending}
            >
              <EditOutlined translate={undefined} rev={undefined} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Open recipe source">
            <IconButton
              component="a"
              href={recipeItem.recipe.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ExportOutlined translate={undefined} rev={undefined} />
            </IconButton>
          </Tooltip>
          <Tooltip title="View full recipe info">
            <IconButton
              style={{ marginLeft: 'auto' }}
              onClick={handleExpand}
              disabled={editRes.isPending}
            >
              <FullscreenOutlined translate={undefined} rev={undefined} />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
      {showRecipeModal && (
        <MDBModal
          centered
          isOpen
          size="lg"
          toggle={() => {
            setShowRecipeModal(false);
            setEditRecipeNotes(false);
            handleSubmit();
          }}
        >
          <MDBModalHeader
            toggle={() => {
              setShowRecipeModal(false);
              setEditRecipeNotes(false);
              handleSubmit();
            }}
            style={{ textTransform: 'capitalize' }}
          >
            {recipeItem.recipe.label}
            <Tooltip title="Open recipe source">
              <IconButton
                component="a"
                href={recipeItem.recipe.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ExportOutlined translate={undefined} rev={undefined} />
              </IconButton>
            </Tooltip>
          </MDBModalHeader>
          <MDBModalBody style={{ textTransform: 'none' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                  style={{
                    objectFit: 'cover',
                    minWidth: '100%',
                    minHeight: '90%',
                    maxWidth: '100%',
                    maxHeight: '90%',
                    boxShadow: '-1px 2px 5px 2px lightgrey',
                    borderRadius: '5px',
                  }}
                  src={recipeItem.recipe.image_url}
                  title={recipeItem.recipe.label}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container item xs={12} alignItems="center" justify="center" style={{ height: '85%' }}>
                  <Nutrient
                    name="calories (kcal) / serving"
                    value={safeDivByZero(recipeItem.recipe.nutrients.energy_kcal, recipeItem.recipe.nutrients.servings)}
                  />
                  <Nutrient
                    name="net carbs (g) / serving"
                    value={safeDivByZero(recipeItem.recipe.nutrients.netcarb_g, recipeItem.recipe.nutrients.servings)}
                  />
                  <Nutrient
                    name="fat (g) / serving"
                    value={safeDivByZero(recipeItem.recipe.nutrients.fat_g, recipeItem.recipe.nutrients.servings)}
                  />
                  <Nutrient
                    name="protein (g) / serving"
                    value={safeDivByZero(recipeItem.recipe.nutrients.protein_g, recipeItem.recipe.nutrients.servings)}
                  />
                  <Nutrient name="servings" size={12} value={recipeItem.recipe.nutrients.servings} />
                </Grid>
              </Grid>
              <Grid item xs={6} style={{ whiteSpace: 'pre-wrap' }}>
                <h5>
                  Ingredient List
                </h5>
                <Typography variant="inherit">
                  {recipeItem.recipe.ingredients.join('\n')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <h5>
                  Instructions
                </h5>
                {instructionQuery.isLoading && (
                  <Typography variant="inherit">
                    Loading instructions...
                  </Typography>
                )}
                {instructionQuery.isSuccess && (
                  <Typography variant="inherit">
                    {instructionQuery.data.instructions}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <h5>
                  Additional Notes
                  <Tooltip title="Edit recipe notes">
                    <IconButton
                      size="medium"
                      style={{ paddingBottom: '0', paddingTop: '0' }}
                      onClick={() => {
                        setEditRecipeNotes(true);
                      }}
                    >
                      <EditOutlined translate={undefined} rev={undefined} />
                    </IconButton>
                  </Tooltip>
                </h5>
                <TextField
                  multiline
                  placeholder="Add Notes Here"
                  value={recipeNotes || ''}
                  disabled={!editRecipeNotes}
                  autoFocus={editRecipeNotes}
                  onChange={(e) => handleEdit(e)}
                />
              </Grid>
            </Grid>
          </MDBModalBody>
        </MDBModal>
      )}
    </Grid>
  );
};
