import {
  ExerciseTypeInRequestEnum,
  PatientResponseSexEnum,
  PatientSubscriptionResponseTypeEnum,
} from '../api/generated';
import { TFunc } from '../i18n';

export const getUserSexOptions = (t: TFunc) => {
  return [
    { id: PatientResponseSexEnum.Female, labelTr: t('Female') },
    { id: PatientResponseSexEnum.Male, labelTr: t('Male') },
    { id: PatientResponseSexEnum.MtfFemale, labelTr: t('MtF Female') },
    { id: PatientResponseSexEnum.FtmMale, labelTr: t('FtM Male') },
    { id: PatientResponseSexEnum.Intersex, labelTr: t('Intersex') },
    { id: null, labelTr: t('Other') },
  ];
};

export const getSubscriptionTypeOptions = (t: TFunc) => {
  return [
    { id: PatientSubscriptionResponseTypeEnum.Single, labelTr: t('single') },
    { id: PatientSubscriptionResponseTypeEnum.Weekly, labelTr: t('weekly') },
    { id: PatientSubscriptionResponseTypeEnum.Monthly, labelTr: t('monthly') },
    { id: PatientSubscriptionResponseTypeEnum.BiWeekly, labelTr: t('bi-weekly') },
    { id: PatientSubscriptionResponseTypeEnum._4Weeks, labelTr: t('4 weeks') },
    { id: PatientSubscriptionResponseTypeEnum.Custom, labelTr: t('custom') },
  ];
};

export const getEmotionOptions = (t: TFunc) => {
  return [
    { id: 'tired', labelTr: t('tired') },
    { id: 'good', labelTr: t('good') },
    { id: 'stressed', labelTr: t('stressed') },
    { id: 'hungry', labelTr: t('hungry') },
    { id: 'wanting', labelTr: t('wanting') },
    { id: 'other', labelTr: t('other') },
  ];
};

export const getExerciseIntensityOptions = (t: TFunc) => {
  return [
    { id: 'low', labelTr: t('Low') },
    { id: 'medium', labelTr: t('Medium') },
    { id: 'high', labelTr: t('High') },
  ];
};

export const EXERCISE_TYPES = {
  [ExerciseTypeInRequestEnum.StrengthResistance]: {
    getLabel: (t: TFunc) => t('Strength/Resistance'),
  },
  [ExerciseTypeInRequestEnum.AerobicTraining]: {
    getLabel: (t: TFunc) => t('Cardio/Aerobic'),
  },
  [ExerciseTypeInRequestEnum.BalanceAndStability]: {
    getLabel: (t: TFunc) => t('Balance/Stability'),
  },
  [ExerciseTypeInRequestEnum.FlexibilityStretching]: {
    getLabel: (t: TFunc) => t('Flexibility/Stretching'),
  },
  [ExerciseTypeInRequestEnum.MixedCardioStrength]: {
    getLabel: (t: TFunc) => t('Mixed Cardio/Strength'),
  },
  [ExerciseTypeInRequestEnum.Other]: {
    getLabel: (t: TFunc) => t('Other'),
  },
};
