/* eslint-disable i18next/no-literal-string */
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { recipeApi } from '../api';
import { RecipeResponse } from '../api/generated';
import { AddRecipe } from './AddRecipe';

export const RecipeSearchGrid = (props: {
  patientId: number,
  focuses: { [key: string]: string[] },
  setRecipesToAdd: React.Dispatch<React.SetStateAction<RecipeResponse[]>>,
  gptRecipe: string,
}) => {
  const focuses = props.focuses || {};
  const gptRecipe = props.gptRecipe;
  const patientId = props.patientId;

  const recipeQuery = useQuery(['recipe-search', gptRecipe], async () => {
    const recipeRes = await recipeApi.appApiRecipeSearchRecipes({
      name: gptRecipe,
      filters: focuses,
    }).then(res => res.data);

    return {
      recipes: recipeRes,
    };
  });

  const edamamRecipes = recipeQuery.data?.recipes;

  return (
    <div
      style={{
        width: '100%',
        padding: '10px',
        display: (recipeQuery.isFetched && !!edamamRecipes.length) || (recipeQuery.isLoading || recipeQuery.isError)
          ? 'block'
          : 'none',
      }}
    >
      {recipeQuery.isLoading && <h5>Finding Recipes...</h5>}

      {recipeQuery.isError && <h5>Error: {'' + recipeQuery.error}</h5>}

      {recipeQuery.isSuccess && !!edamamRecipes.length && (
        <div style={{ whiteSpace: 'pre-wrap' }}>
          <h3 style={{ paddingLeft: '5px' }}>{gptRecipe}</h3>
          <div style={{ width: '100%' }}>
            {edamamRecipes.map((recipe, index) => (
              <AddRecipe key={index} setRecipesToAdd={props.setRecipesToAdd} recipe={recipe} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
