import React from 'react';
import { ReportCGM } from './ReportCGM';

import classNames from 'classnames';
import styles from './index.module.scss';

/**
 * Wrapper component around all reports.
 */
export const ReportWrapper = (props: React.PropsWithChildren<unknown>) => {
  return (
    <div className={styles.ReportWrapper}>
      {props.children}
    </div>
  );
};

export const ReportPage = (props: React.PropsWithChildren<unknown>) => {
  return (
    <div className={styles.ReportPage}>
      {props.children}
    </div>
  );
};

export const ReportSection = (
  props: React.PropsWithChildren<{
    greyBg?: boolean,
    style?: React.CSSProperties,
  }>,
) => {
  return (
    <div
      className={classNames([
        styles.ReportSection,
        props.greyBg && styles.reportLightGreyBg,
      ])}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export const ReportDot = (
  props: React.PropsWithChildren<{
    bgColor: 'green',
    fgColor?: string,
  }>,
) => {
  return (
    <div
      className={styles.ReportDot}
      style={{
        backgroundColor: {
          'green': '#36C2B415',
        }[props.bgColor],
        color: props.fgColor || '#36C2B4',
      }}
    >
      {props.children}
    </div>
  );
};
