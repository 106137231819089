import React, { useQuery, useQueryClient } from '@tanstack/react-query';
import classnames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAsyncResult } from 'react-use-async-result';
import { subscriptionApi } from '../api';
import { LoadingSpinner } from '../components/loadingSpinner';
import { ScheduleRepeatLoggingTrials, SendLoggingTrial } from '../components/sendLoggingTrial';
import { useStore, useStoreDispatch } from '../context';
import { Trans, useTranslation } from '../i18n';
import { PatientService } from '../services/patient';
import './patient-subscriptions.scss';
import { DateTime, Duration } from 'luxon';

const Subscriptions = () => {
  const { addNotification } = useStoreDispatch();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showScheduleRepeatLoggingTrials, setShowScheduleRepeatLoggingTrials] = useState(false);
  const [editID, setEditID] = useState<number>(null);
  const { patient } = useStore();
  const patientService = PatientService();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const subscriptionQuery = useQuery(['patient-subscriptions', patient.patient_id], async () => {
    const res = await subscriptionApi.appApiSubscriptionApiGetPatientSubscriptions({ patient_id: patient.patient_id });
    return res.data || [];
  });
  const patientSubscriptions = subscriptionQuery.data || [];

  const deleteRes = useAsyncResult();
  const handleDeleteSubscription = (sub_id) => {
    const subscriptionToDelete = patientSubscriptions.find(s => s.id == sub_id);
    const res = patientService.deleteSubscription(
      subscriptionToDelete.hospital_id,
      subscriptionToDelete.patient_id,
      sub_id,
    );
    deleteRes.bind(res);
    queryClient.invalidateQueries({ queryKey: ['patient-subscriptions'] });
  };

  useEffect(() => {
    if (deleteRes?.isError) {
      addNotification([t('Error deleting the subscription'), true]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRes]);

  const currentLoggingTrials = patientSubscriptions.filter(subscription => {
    return subscription.is_complete == false
      && (moment(subscription.report_date) > moment().subtract(14, 'days')
        || (moment(subscription.start_date) > moment().subtract(subscription.subscription_length, 'days')
          && subscription.report_date == null))
      && moment(subscription.start_date) <= moment();
  });

  const upcomingLoggingTrials = patientSubscriptions.filter(subscription => moment() < moment(subscription.start_date));
  const pastLoggingTrials = patientSubscriptions.filter(subscription => {
    return subscription.is_complete == true
      || moment(subscription.report_date) < moment().subtract(14, 'days')
      || (moment().subtract(subscription.subscription_length, 'days') > moment(subscription.start_date)
        && subscription.report_date == null);
  });

  const getReportDate = (subscription) => {
    if (subscription.report_date) {
      return subscription.report_date;
    }
    if (subscription.type !== 'single') {
      const diff = DateTime.fromISO(subscription.expiration_date)
        .diff(DateTime.fromISO(subscription.start_date), 'months')
        .toObject().months;

      return Array.from({ length: diff }, (_, i) => {
        return DateTime.fromISO(subscription.start_date).plus({ months: i + 1, days: 2 }).toISODate();
      }).join(', ');
    }
    return subscription.start_date;
  };

  return (
    <>
      <div className="mt-4 px-3">
        <h4>
          <Trans>Current Logging Trial</Trans>
        </h4>
      </div>
      <Row className={classnames('currentloggingTrialTable border-0 mb-5 px-3')}>
        <Col className="subscriptionCol">
          <table>
            <thead>
              <tr>
                <th style={{ width: '200px' }}>
                  <Trans>Start date</Trans>
                </th>
                <th style={{ width: '200px' }}>
                  <Trans>Type</Trans>
                </th>
                <th style={{ width: '200px' }}>
                  <Trans>Length</Trans>
                </th>
                <th style={{ width: '200px' }}>
                  <Trans>Report Date</Trans>
                </th>
                {
                  /*<th  style={{width:'200px'}}>
                  <Trans>Number of meals logged</Trans>
                  </th>*/
                }
                <th style={{ width: '200px' }}>&nbsp;</th>
              </tr>
            </thead>
            {subscriptionQuery.isLoading && (
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <LoadingSpinner />
                  </td>
                </tr>
              </tbody>
            )}
            {subscriptionQuery.isError && (
              <tbody>
                <tr>
                  <td colSpan={5} className="text-muted text-center">
                    <Trans>Unexpected error loading logging trials</Trans>
                  </td>
                </tr>
              </tbody>
            )}
            {(!subscriptionQuery.isLoading && !subscriptionQuery.isError) && (
              <tbody>
                {currentLoggingTrials.map(
                  (subscription, index) => {
                    return (
                      <tr key={index}>
                        <td>{subscription.start_date}</td>
                        <td style={{ textTransform: 'capitalize' }}>{subscription.type}</td>
                        <td>
                          {subscription.subscription_length} <Trans>days</Trans>
                        </td>
                        <td>{getReportDate(subscription)}</td>
                        {/*<td>-</td>*/}
                        <td className="text-right">
                          <span
                            onClick={() => {
                              setShowEditModal(true);
                              setEditID(subscription.id);
                            }}
                            className="subscriptionBtn mx-3"
                            title={t('Edit')}
                          >
                            <i className="fas fa-pen" />
                          </span>
                          <span
                            onClick={() => {
                              setShowScheduleRepeatLoggingTrials(true);
                              setEditID(subscription.id);
                            }}
                            className="subscriptionBtn mx-3"
                            title={t('Schedule repeat logging trials')}
                          >
                            <i className="fas fa-redo" />
                          </span>
                          <span
                            onClick={() => {
                              if (window.confirm(t('Are you sure you wish to delete this item?'))) {
                                handleDeleteSubscription(subscription.id);
                              }
                            }}
                            className={classnames('delete subscriptionBtn mx-3', {
                              'deleteDisabled': deleteRes.isPending,
                            })}
                            title={t('Delete')}
                          >
                            <i className="fas fa-trash" />
                          </span>
                        </td>
                      </tr>
                    );
                  },
                )}
                {(currentLoggingTrials.length == 0 || !subscriptionQuery.data) && (
                  <tr>
                    <td colSpan={5} className="text-muted text-center">
                      <Trans>No Trials</Trans>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </Col>
      </Row>
      <div className="mt-4 px-3">
        <h4>
          <Trans>Upcoming Logging Trial</Trans>
        </h4>
      </div>
      <Row className={classnames('currentloggingTrialTable border-0 mb-5 px-3')}>
        <Col className="subscriptionCol">
          <table>
            <thead>
              <tr>
                <th style={{ width: '200px' }}>
                  <Trans>Start date</Trans>
                </th>
                <th style={{ width: '200px' }}>
                  <Trans>Type</Trans>
                </th>
                <th style={{ width: '200px' }}>
                  <Trans>Length</Trans>
                </th>
                <th style={{ width: '200px' }}>
                  <Trans>Report Date</Trans>
                </th>
                <th style={{ width: '200px' }}>&nbsp;</th>
              </tr>
            </thead>
            {subscriptionQuery.isLoading && (
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <LoadingSpinner />
                  </td>
                </tr>
              </tbody>
            )}
            {subscriptionQuery.isError && (
              <tbody>
                <tr>
                  <td colSpan={5} className="text-muted text-center">
                    <Trans>Unexpected error loading logging trials</Trans>
                  </td>
                </tr>
              </tbody>
            )}
            {(!subscriptionQuery.isLoading && !subscriptionQuery.isError) && (
              <tbody>
                {upcomingLoggingTrials.map(
                  (subscription, index) => (
                    <tr key={index}>
                      <td>{subscription.start_date}</td>
                      <td style={{ textTransform: 'capitalize' }}>{subscription.type}</td>
                      <td>
                        {subscription.subscription_length} <Trans>days</Trans>
                      </td>
                      <td>{getReportDate(subscription)}</td>
                      <td className="text-right">
                        <span
                          className="subscriptionBtn mx-3"
                          onClick={() => {
                            setShowEditModal(true);
                            setEditID(subscription.id);
                          }}
                          title={t('Edit')}
                        >
                          <i className="fas fa-pen" />
                        </span>
                        <span
                          onClick={() => {
                            setShowScheduleRepeatLoggingTrials(true);
                            setEditID(subscription.id);
                          }}
                          className="subscriptionBtn mx-3"
                          title={t('Schedule repeat logging trials')}
                        >
                          <i className="fas fa-redo" />
                        </span>
                        <span
                          onClick={() => {
                            if (window.confirm(t('Are you sure you wish to delete this item?'))) {
                              handleDeleteSubscription(subscription.id);
                            }
                          }}
                          title={t('Delete')}
                          className={classnames('delete subscriptionBtn mx-3', {
                            'deleteDisabled': deleteRes.isPending,
                          })}
                        >
                          <i className="fas fa-trash" />
                        </span>
                      </td>
                    </tr>
                  ),
                )}
                {(upcomingLoggingTrials.length == 0 || !subscriptionQuery.data) && (
                  <tr>
                    <td colSpan={5} className="text-muted text-center">
                      <Trans>No Trials</Trans>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </Col>
      </Row>
      <div className="mt-4 px-3">
        <h4>
          <Trans>Past Logging Trial</Trans>
        </h4>
      </div>
      <Row className={classnames('currentloggingTrialTable border-0 mb-5 px-3')}>
        <Col className="subscriptionCol">
          <table>
            <thead>
              <tr>
                <th style={{ width: '200px' }}>
                  <Trans>Start date</Trans>
                </th>
                <th style={{ width: '200px' }}>
                  <Trans>Type</Trans>
                </th>
                <th style={{ width: '200px' }}>
                  <Trans>Length</Trans>
                </th>
                <th style={{ width: '200px' }}>
                  <Trans>Report Date</Trans>
                </th>
                <th style={{ width: '200px' }}>&nbsp;</th>
              </tr>
            </thead>
            {subscriptionQuery.isLoading && (
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <LoadingSpinner />
                  </td>
                </tr>
              </tbody>
            )}
            {subscriptionQuery.isError && (
              <tbody>
                <tr>
                  <td colSpan={5} className="text-muted text-center">
                    <Trans>Unexpected error loading logging trials</Trans>
                  </td>
                </tr>
              </tbody>
            )}
            {(!subscriptionQuery.isLoading && !subscriptionQuery.isError) && (
              <tbody>
                {pastLoggingTrials.map(
                  (subscription, index) => {
                    return (
                      <tr key={index}>
                        <td>{subscription.start_date}</td>
                        <td style={{ textTransform: 'capitalize' }}>{subscription.type}</td>
                        <td>
                          {subscription.subscription_length} <Trans>days</Trans>
                        </td>
                        <td>{getReportDate(subscription)}</td>
                        <td className="text-right">
                          <span
                            onClick={() => {
                              setShowScheduleRepeatLoggingTrials(true);
                              setEditID(subscription.id);
                            }}
                            className="subscriptionBtn mx-3"
                            title={t('Schedule repeat logging trials')}
                          >
                            <i className="fas fa-redo" />
                          </span>
                        </td>
                      </tr>
                    );
                  },
                )}
                {(pastLoggingTrials.length == 0 || !subscriptionQuery.data) && (
                  <tr>
                    <td colSpan={5} className="text-muted text-center">
                      <Trans>No Trials</Trans>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </Col>

        <SendLoggingTrial
          show={showEditModal}
          // customize={{ edit: showEditModal }}
          isEdit={showEditModal}
          subscriptionId={{ current: editID }}
          toggle={() => {
            setShowEditModal(!showEditModal);
            setEditID(null);
          }}
        />
        <ScheduleRepeatLoggingTrials
          show={showScheduleRepeatLoggingTrials}
          toggle={() => setShowScheduleRepeatLoggingTrials(!showScheduleRepeatLoggingTrials)}
          targetSubscriptonId={editID}
          subscriptions={patientSubscriptions}
          handleDeleteSubscription={handleDeleteSubscription}
        />
      </Row>
    </>
  );
};
export default Subscriptions;
