import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import React, { useEffect } from 'react';
import { ClinicianResponse } from './api/generated/models';
import { config } from './config';

export const sentryReplay = new Sentry.Replay();

/**
 * Triggers a Sentry Replay recording session while the component is mounted.
 */
export const useSentryReplay = () => {
  React.useEffect(() => {
    sentryReplay.start();
    return () => {
      sentryReplay.stop();
    };
  }, []);
};

export const initAnalytics = () => {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    tunnel: config.SENTRY_TUNNEL,
    environment: config.ENV,
    release: config.COMMIT_SHA,

    integrations: [
      new BrowserTracing(),
      sentryReplay,
    ],

    tracesSampleRate: 0.1,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.1,
  });
};

export const useAnalyticsUserIdentity = (user: ClinicianResponse | null) => {
  useEffect(() => {
    if (!user) {
      Sentry.setUser(null);
      return;
    }
    Sentry.setUser({
      id: '' + user.id,
      username: `User ${user.id}`,
    });
  }, [user]);
};
