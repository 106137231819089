import { useQuery } from '@tanstack/react-query';
import Slider from 'rc-slider';
import React from 'react';
import 'rc-slider/assets/index.css';
import { Trans } from 'react-i18next';
import { userSurveysApi } from '../api';
import {
  UserSurveyFormItem,
  UserSurveyFormWidgetInputMulticheck,
  UserSurveyFormWidgetInputRadio,
  UserSurveyFormWidgetInputScale,
  UserSurveyResponseItemValue,
  UserSurveyTypeEnum,
} from '../api/generated';
import { useStore } from '../context';
import mindfulEatingHeartFilledIcon from '../theme/icons/mindful-eating-heart-filled-icon.png';
import { LoadingSpinner } from './loadingSpinner';
import './patientSurvey.scss';

export const usePatientSurvey = (opts: {
  patientId: string | number,
  surveyType: UserSurveyTypeEnum,
  relatedActivityType: string,
  relatedActivityId: number,
}) => {
  const { patientId, surveyType, relatedActivityType, relatedActivityId } = opts;

  const formQuery = useQuery(['user-survey-form', surveyType], async () => {
    const res = await userSurveysApi.appApiUserSurveyGetUserSurveyForm({
      user_id: Number(patientId),
      survey_type: surveyType,
    });
    return res.data;
  });

  const responseCacheQuery = useQuery(['user-survey-responses', surveyType], async () => {
    const res = await userSurveysApi.appApiUserSurveyGetUserSurveyResponses({
      user_id: Number(patientId),
      survey_type: surveyType,
    });
    return res.data;
  });

  const response = responseCacheQuery.data?.find((r) => {
    return r.related_activity_id == relatedActivityId && r.related_activity_type == relatedActivityType;
  });

  return {
    formQuery: formQuery,
    form: formQuery.data,

    responseQuery: responseCacheQuery,
    response: response,
  };
};

export function MindfulEatingSurveyIcon(props: {
  mealId: number,
}) {
  const size = 24;
  const { patient } = useStore();
  const survey = usePatientSurvey({
    patientId: patient.patient_id,
    surveyType: 'mindful-eating',
    relatedActivityType: 'meal-log',
    relatedActivityId: props.mealId,
  });
  const isMindfulEatingSurveySubmitted = !!survey.response;
  if (!isMindfulEatingSurveySubmitted) {
    return null;
  }
  return (
    <div className="mindful-eating-survey-icon">
      <img
        src={mindfulEatingHeartFilledIcon}
        width={size}
        height={size}
      />
    </div>
  );
}

export const PatientSurvey = (props: {
  mealId: number,
}) => {
  const { patient } = useStore();
  const survey = usePatientSurvey({
    patientId: patient.patient_id,
    surveyType: 'mindful-eating',
    relatedActivityType: 'meal-log',
    relatedActivityId: props.mealId,
  });
  const isMindfulEatingSurveySubmitted = !!survey.response;
  if (!isMindfulEatingSurveySubmitted) {
    return null;
  }
  if (survey.formQuery.isLoading || survey.responseQuery.isLoading) {
    return <LoadingSpinner />;
  }
  if (survey.formQuery.isError || survey.responseQuery.isError) {
    return (
      <div>
        <Trans>Unexpected error loading survey</Trans>:{' '}
        <div>{'' + survey.formQuery.error || survey.responseQuery.error}</div>
      </div>
    );
  }
  return (
    <div className="patient-survey-container">
      {survey.form.survey_form.items.map(
        item => (
          <UserSurveyFormItemComponent
            key={item.id}
            item={item}
            response={survey.response.survey_responses?.items.find((r) => r.form_item_id === item.id)?.value}
          />
        ),
      )}
    </div>
  );
};

function UserSurveyFormItemComponent(props: {
  item: UserSurveyFormItem,
  response: UserSurveyResponseItemValue,
}) {
  const { item, response } = props;
  switch (item.widget.type) {
    case 'heading':
      return <UserSurveyHeading item={item} />;
    case 'input-radio':
      return <UserSurveyInputRadio {...{ item, response }} widget={item.widget} />;
    case 'input-multicheck':
      return <UserSurveyInputMulticheck {...{ item, response }} widget={item.widget} />;
    case 'input-scale':
      return <UserSurveyInputScale {...{ item, response }} widget={item.widget} />;
    default:
      return null;
  }
}

function UserSurveyHeading(props: { item: UserSurveyFormItem }) {
  return (
    <h5 className="pb-3">
      <Trans>
        {{ label: props.item.label }}
      </Trans>
    </h5>
  );
}

function UserSurveyInputRadio(props: {
  item: UserSurveyFormItem,
  widget: UserSurveyFormWidgetInputRadio,
  response: UserSurveyResponseItemValue,
}) {
  return (
    <div className="question-container">
      <QuestionLabel label={props.item.label} />
      <div className="user-survey-input-buttons">
        {props.widget.options.map((option) => (
          <div
            key={option.value}
            style={getButtonStyle(props.widget, props.response === option.value)}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
}

function UserSurveyInputMulticheck(props: {
  item: UserSurveyFormItem,
  widget: UserSurveyFormWidgetInputMulticheck,
  response: UserSurveyResponseItemValue,
}) {
  const responseArr = props.response as UserSurveyResponseItemValue[] ?? [];
  return (
    <div>
      <QuestionLabel label={props.item.label} />
      <div className="user-survey-input-buttons">
        {props.widget.options.map((option) => (
          <div
            key={option.value}
            style={getButtonStyle(props.widget, responseArr.includes(option.value))}
          >
            <div style={getButtonLabelStyle(props.widget)}>
              {option.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function UserSurveyInputScale(props: {
  item: UserSurveyFormItem,
  widget: UserSurveyFormWidgetInputScale,
  response: UserSurveyResponseItemValue,
}) {
  const selectedValue = props.response;
  const selectedValueLabel = props.widget.options && selectedValue != null
    && props.widget.options.find((option) => option.value === selectedValue).label;

  return (
    <div className="question-container">
      <QuestionLabel label={props.item.label} />

      <div className="slider-container">
        <Slider
          max={props.widget.range[1]}
          min={props.widget.range[0]}
          step={props.widget.range_interval || 1}
          value={selectedValue as number}
          styles={{
            track: { backgroundColor: '#FFDC00' },
            handle: selectedValue
              ? { backgroundColor: '#FFDC00', border: 'solid 3px #FFDC00', opacity: 1, cursor: 'pointer' }
              : { backgroundColor: '#E9E9E9', border: 'solid 3px #E9E9E9', opacity: 1, cursor: 'pointer' },
          }}
          dots={true}
          dotStyle={{ borderColor: '#E9E9E9' }}
          activeDotStyle={{ borderColor: '#FFDC00' }}
        />
      </div>
      <div className="slider-labels-container">
        <div className="slider-label-start">
          <Trans>
            {{ label: formatLabel(props.widget.label_min) }}
          </Trans>
        </div>
        <div className="slider-label-end">
          <Trans>
            {{ label: formatLabel(props.widget.label_max) }}
          </Trans>
        </div>
      </div>

      {!!props.widget.options && (
        <div className="slider-label-middle">
          {selectedValueLabel
            ? (
              <Trans>
                {{ label: selectedValueLabel }}
              </Trans>
            )
            : ''}
        </div>
      )}
    </div>
  );
}

function formatLabel(label: string) {
  return label.includes('(') ? label.replace('(', '\n(') : label;
}

function QuestionLabel(props: { label: string }) {
  const { label } = props;
  const labelParts = label.split(' (').map((part) => formatLabel(part));
  return (
    <div className="question-label">
      <span style={{ fontSize: '18px' }}>
        <Trans shouldUnescape>
          {{ label: labelParts[0] }}
        </Trans>
      </span>
      {labelParts[1] && (
        <span style={{ fontSize: '15px' }}>
          <Trans>
            {{ label_additional_info: ` (${labelParts[1]}` }}
          </Trans>
        </span>
      )}
    </div>
  );
}

function getButtonStyle(
  widget: UserSurveyFormWidgetInputRadio | UserSurveyFormWidgetInputMulticheck,
  isSelected: boolean,
) {
  return {
    ...(widget['selection-type'] === 'button-emoji'
      ? {
        padding: '4px 14px',
        borderRadius: '16px',
      }
      : {
        padding: '6px 8px',
        borderRadius: '14px',
      }),
    margin: '4px 0px',
    borderWidth: '1px',
    ...(isSelected
      ? {
        backgroundColor: '#FFDC00',
        border: 'solid 1px #FFDC00',
      }
      : {
        backgroundColor: 'white',
        border: 'solid 1px #9F9F9F',
      }),
  };
}

function getButtonLabelStyle(
  widget: UserSurveyFormWidgetInputRadio | UserSurveyFormWidgetInputMulticheck,
) {
  return {
    ...(widget['selection-type'] === 'button-emoji'
      && { fontSize: '24px' }),
  };
}
