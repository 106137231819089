import React from 'react';

export const GlucoseMaxSVG = (p: {
  value: string,
}) => {
  return (
    <g transform="scale(0.6) translate(0, -21)">
      <rect width="52.6628" height="21" rx="10.5" fill="#1D1C1C" />
      <ellipse cx="10.2968" cy="10.5" rx="10.2968" ry="10.5" fill="#FF9024" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4375 6.20703C13.8675 8.06485 14.9119 10.068 14.9119 11.7566C14.9119 14.208 12.9158 16.1913 10.456 16.1913C7.99607 16.1913 6 14.208 6 11.7566C6 10.742 6.377 9.61397 6.98811 8.4721C7.18413 8.5087 7.38912 8.52903 7.60268 8.52903C8.6702 8.52903 9.51345 7.92864 10.357 7.328C11.0148 6.85965 11.6728 6.39116 12.4375 6.20703Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3614 4.04298C10.381 4.0232 10.406 4.00943 10.4335 4.00339C10.4825 3.99269 10.5336 4.00769 10.5685 4.04298C12.8254 6.2883 14.9299 9.21781 14.9299 11.6708C14.9299 14.1184 12.9292 16.1094 10.465 16.1094C8.00079 16.1094 6 14.1184 6 11.6708C6 9.21781 8.10452 6.2883 10.3614 4.04298ZM14.6417 11.6708C14.6417 9.41525 12.6537 6.56101 10.4648 4.35532C8.27584 6.56101 6.28787 9.41525 6.28787 11.6708C6.28787 13.9687 8.15894 15.8279 10.4648 15.8279C12.7706 15.8279 14.6417 13.9687 14.6417 11.6708Z"
        fill="white"
      />
      <text
        x="24"
        y="14.5"
        fontSize={10}
        fontWeight="bold"
        textAnchor="left"
        fill="white"
      >
        {p.value}
      </text>
    </g>
  );
};
