/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppApiClinicReportClinicianMealLogsTable200Response } from '../models';
// @ts-ignore
import { AppApiClinicReportClinicianPatientsTable200Response } from '../models';
// @ts-ignore
import { AveragePlateDataResponse } from '../models';
// @ts-ignore
import { ClinicPatientsReportResponse } from '../models';
// @ts-ignore
import { GhgEquivalentDataResponse } from '../models';
// @ts-ignore
import { GoalExtendedWeeklyReportResponse } from '../models';
// @ts-ignore
import { GoalWeeklyReportResponse } from '../models';
// @ts-ignore
import { PatientNutrientResponse } from '../models';
// @ts-ignore
import { PatientNutrientsReportResponse } from '../models';
// @ts-ignore
import { PatientStarPlotResponse } from '../models';
// @ts-ignore
import { PatientSummaryResponse } from '../models';
// @ts-ignore
import { SummaryStatementResponse } from '../models';
// @ts-ignore
import { WeeklyPatientEngagementResponse } from '../models';
/**
 * PatientReportApi - axios parameter creator
 * @export
 */
export const PatientReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Since this query assembles all nutrients and goal information of the patients in the clinic, this query takes quite a load on the backend. If just listing the patients in the clinic, please use GET /hospitals/{hospital_id}/clinics/{clinic_id}/patients.
         * @summary Get the basic report of patients registered in the clinic.
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicReportClinicPatientsReport: async (hospital_id: number, clinic_id: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiClinicReportClinicPatientsReport', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiClinicReportClinicPatientsReport', 'clinic_id', clinic_id)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('appApiClinicReportClinicPatientsReport', 'date', date)
            const localVarPath = `/hospitals/{hospital_id}/clinics/{clinic_id}/patients_report`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a data table of all meals of a specific patient in the clinic.
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {number} patient_id 
         * @param {string} [date] 
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicReportClinicianMealLogsTable: async (hospital_id: number, clinician_id: number, patient_id: number, date?: string, filter?: string, limit?: number, offset?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiClinicReportClinicianMealLogsTable', 'hospital_id', hospital_id)
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiClinicReportClinicianMealLogsTable', 'clinician_id', clinician_id)
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiClinicReportClinicianMealLogsTable', 'patient_id', patient_id)
            const localVarPath = `/hospitals/{hospital_id}/clinicians/{clinician_id}/{patient_id}/meals`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinician_id"}}`, encodeURIComponent(String(clinician_id)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Since this query assembles all nutrients and goal information of the patients in the clinic, this query takes quite a load on the backend. If just listing the patients in the clinic, please use GET /hospitals/{hospital_id}/clinics/{clinic_id}/patients.
         * @summary Get the basic report of patients registered to all clinics this clinician belongs to.
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {string} date 
         * @param {boolean} [active_patients_only] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicReportClinicianPatientsReport: async (hospital_id: number, clinician_id: number, date: string, active_patients_only?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiClinicReportClinicianPatientsReport', 'hospital_id', hospital_id)
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiClinicReportClinicianPatientsReport', 'clinician_id', clinician_id)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('appApiClinicReportClinicianPatientsReport', 'date', date)
            const localVarPath = `/hospitals/{hospital_id}/clinicians/{clinician_id}/patients_report`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinician_id"}}`, encodeURIComponent(String(clinician_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (active_patients_only !== undefined) {
                localVarQueryParameter['active_patients_only'] = active_patients_only;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a data table of all patients in the clinic.
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {string} [date] 
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicReportClinicianPatientsTable: async (hospital_id: number, clinician_id: number, date?: string, filter?: string, limit?: number, offset?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiClinicReportClinicianPatientsTable', 'hospital_id', hospital_id)
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiClinicReportClinicianPatientsTable', 'clinician_id', clinician_id)
            const localVarPath = `/hospitals/{hospital_id}/clinicians/{clinician_id}/patients/table`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinician_id"}}`, encodeURIComponent(String(clinician_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the goal report of the patient for this week and last week.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetGoalsExtendedWeeklyReport: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiGoalGetGoalsExtendedWeeklyReport', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiGoalGetGoalsExtendedWeeklyReport', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiGoalGetGoalsExtendedWeeklyReport', 'date_until', date_until)
            const localVarPath = `/patients/{patient_id}/goals/extended_weekly_report`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the goal report of the patient for this week and last week.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetGoalsExtendedWeeklyReportCsv: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiGoalGetGoalsExtendedWeeklyReportCsv', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiGoalGetGoalsExtendedWeeklyReportCsv', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiGoalGetGoalsExtendedWeeklyReportCsv', 'date_until', date_until)
            const localVarPath = `/patients/{patient_id}/goals/extended_weekly_report/csv`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the goal report of the patient for this week and last week.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetGoalsProgressReport: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiGoalGetGoalsProgressReport', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiGoalGetGoalsProgressReport', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiGoalGetGoalsProgressReport', 'date_until', date_until)
            const localVarPath = `/patients/{patient_id}/goals/progress_report`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the goal report of the patient for this week and last week.
         * @param {number} patient_id 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetGoalsWeeklyReport: async (patient_id: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiGoalGetGoalsWeeklyReport', 'patient_id', patient_id)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('appApiGoalGetGoalsWeeklyReport', 'date', date)
            const localVarPath = `/patients/{patient_id}/goals/weekly_report`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s ghg equivalent data
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMetricsGhgEquivalentGetPatientGhgEquivalent: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMetricsGhgEquivalentGetPatientGhgEquivalent', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiMetricsGhgEquivalentGetPatientGhgEquivalent', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiMetricsGhgEquivalentGetPatientGhgEquivalent', 'date_until', date_until)
            const localVarPath = `/patients/{patient_id}/ghg_equivalent`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s ghg equivalent emission key data point in an image format
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMetricsGhgEquivalentGetPatientGhgEquivalentGraph: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMetricsGhgEquivalentGetPatientGhgEquivalentGraph', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiMetricsGhgEquivalentGetPatientGhgEquivalentGraph', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiMetricsGhgEquivalentGetPatientGhgEquivalentGraph', 'date_until', date_until)
            const localVarPath = `/patients/{patient_id}/ghg_equivalent/graph`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s nutrients report for the clinician view
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {AppApiPatientNutrientsReportPatientNutrientsReportViewByEnum} view_by 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNutrientsReportPatientNutrientsReport: async (patient_id: number, date_since: string, date_until: string, view_by: AppApiPatientNutrientsReportPatientNutrientsReportViewByEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientNutrientsReportPatientNutrientsReport', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientNutrientsReportPatientNutrientsReport', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientNutrientsReportPatientNutrientsReport', 'date_until', date_until)
            // verify required parameter 'view_by' is not null or undefined
            assertParamExists('appApiPatientNutrientsReportPatientNutrientsReport', 'view_by', view_by)
            const localVarPath = `/patients/{patient_id}/patient_nutrients_report`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (view_by !== undefined) {
                localVarQueryParameter['view_by'] = view_by;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s average plate data
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsAveragePlateGetAveragePlate: async (patient_id: number, date_since: string, date_until: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsAveragePlateGetAveragePlate', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsAveragePlateGetAveragePlate', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsAveragePlateGetAveragePlate', 'date_until', date_until)
            const localVarPath = `/patients/{patient_id}/average_plate`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s average plate in an image format
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {AppApiPatientReportsSectionsAveragePlateGetAveragePlateGraphMealNameEnum} meal_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsAveragePlateGetAveragePlateGraph: async (patient_id: number, date_since: string, date_until: string, meal_name: AppApiPatientReportsSectionsAveragePlateGetAveragePlateGraphMealNameEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsAveragePlateGetAveragePlateGraph', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsAveragePlateGetAveragePlateGraph', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsAveragePlateGetAveragePlateGraph', 'date_until', date_until)
            // verify required parameter 'meal_name' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsAveragePlateGetAveragePlateGraph', 'meal_name', meal_name)
            const localVarPath = `/patients/{patient_id}/average_plate/{meal_name}/graph`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_name"}}`, encodeURIComponent(String(meal_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s average plate data
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsAveragePlateGetAveragePlateRecent: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsAveragePlateGetAveragePlateRecent', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/average_plate/recent`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s clinical summary
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} disaggregated 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements: async (patient_id: number, date_since: string, date_until: string, disaggregated: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements', 'date_until', date_until)
            // verify required parameter 'disaggregated' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements', 'disaggregated', disaggregated)
            const localVarPath = `/patients/{patient_id}/clinical_summary`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (disaggregated !== undefined) {
                localVarQueryParameter['disaggregated'] = disaggregated;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s potassium graph
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} disaggregated 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph: async (patient_id: number, date_since: string, date_until: string, disaggregated: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph', 'date_until', date_until)
            // verify required parameter 'disaggregated' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph', 'disaggregated', disaggregated)
            const localVarPath = `/patients/{patient_id}/patient_potassium_report/graph`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (disaggregated !== undefined) {
                localVarQueryParameter['disaggregated'] = disaggregated;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s potassium details
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} disaggregated 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsPotassiumGetPatientPotassiumReport: async (patient_id: number, date_since: string, date_until: string, disaggregated: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsPotassiumGetPatientPotassiumReport', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsPotassiumGetPatientPotassiumReport', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsPotassiumGetPatientPotassiumReport', 'date_until', date_until)
            // verify required parameter 'disaggregated' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsPotassiumGetPatientPotassiumReport', 'disaggregated', disaggregated)
            const localVarPath = `/patients/{patient_id}/patient_potassium_report`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (disaggregated !== undefined) {
                localVarQueryParameter['disaggregated'] = disaggregated;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s summary metrics as a star plot graph
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} disaggregated 
         * @param {boolean} daily_avg_method 
         * @param {boolean} [include_custom_items] 
         * @param {boolean} [enforce_unique_base_food] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsStarPlotGetPatientStarPlot: async (patient_id: number, date_since: string, date_until: string, disaggregated: boolean, daily_avg_method: boolean, include_custom_items?: boolean, enforce_unique_base_food?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsStarPlotGetPatientStarPlot', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsStarPlotGetPatientStarPlot', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsStarPlotGetPatientStarPlot', 'date_until', date_until)
            // verify required parameter 'disaggregated' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsStarPlotGetPatientStarPlot', 'disaggregated', disaggregated)
            // verify required parameter 'daily_avg_method' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsStarPlotGetPatientStarPlot', 'daily_avg_method', daily_avg_method)
            const localVarPath = `/patients/{patient_id}/patient_star_plot_summary/graph`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (disaggregated !== undefined) {
                localVarQueryParameter['disaggregated'] = disaggregated;
            }

            if (daily_avg_method !== undefined) {
                localVarQueryParameter['daily_avg_method'] = daily_avg_method;
            }

            if (include_custom_items !== undefined) {
                localVarQueryParameter['include_custom_items'] = include_custom_items;
            }

            if (enforce_unique_base_food !== undefined) {
                localVarQueryParameter['enforce_unique_base_food'] = enforce_unique_base_food;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s summary metrics for the star plot graph
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} disaggregated 
         * @param {boolean} daily_avg_method 
         * @param {boolean} [include_statements] 
         * @param {number} [n_frequency_items] 
         * @param {boolean} [include_non_ranked_items] 
         * @param {boolean} [include_custom_items] 
         * @param {boolean} [enforce_unique_base_food] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary: async (patient_id: number, date_since: string, date_until: string, disaggregated: boolean, daily_avg_method: boolean, include_statements?: boolean, n_frequency_items?: number, include_non_ranked_items?: boolean, include_custom_items?: boolean, enforce_unique_base_food?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary', 'date_until', date_until)
            // verify required parameter 'disaggregated' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary', 'disaggregated', disaggregated)
            // verify required parameter 'daily_avg_method' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary', 'daily_avg_method', daily_avg_method)
            const localVarPath = `/patients/{patient_id}/patient_star_plot_summary`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (disaggregated !== undefined) {
                localVarQueryParameter['disaggregated'] = disaggregated;
            }

            if (daily_avg_method !== undefined) {
                localVarQueryParameter['daily_avg_method'] = daily_avg_method;
            }

            if (include_statements !== undefined) {
                localVarQueryParameter['include_statements'] = include_statements;
            }

            if (n_frequency_items !== undefined) {
                localVarQueryParameter['n_frequency_items'] = n_frequency_items;
            }

            if (include_non_ranked_items !== undefined) {
                localVarQueryParameter['include_non_ranked_items'] = include_non_ranked_items;
            }

            if (include_custom_items !== undefined) {
                localVarQueryParameter['include_custom_items'] = include_custom_items;
            }

            if (enforce_unique_base_food !== undefined) {
                localVarQueryParameter['enforce_unique_base_food'] = enforce_unique_base_food;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s summary statements
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} disaggregated 
         * @param {boolean} report_filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements: async (patient_id: number, date_since: string, date_until: string, disaggregated: boolean, report_filter: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements', 'date_until', date_until)
            // verify required parameter 'disaggregated' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements', 'disaggregated', disaggregated)
            // verify required parameter 'report_filter' is not null or undefined
            assertParamExists('appApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements', 'report_filter', report_filter)
            const localVarPath = `/patients/{patient_id}/summary_statements`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (disaggregated !== undefined) {
                localVarQueryParameter['disaggregated'] = disaggregated;
            }

            if (report_filter !== undefined) {
                localVarQueryParameter['report_filter'] = report_filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s summary metrics for the star plot graph
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} disaggregated 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsStatementsGetPatientFrequencyStatementItems: async (patient_id: number, date_since: string, date_until: string, disaggregated: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsStatementsGetPatientFrequencyStatementItems', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientReportsStatementsGetPatientFrequencyStatementItems', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientReportsStatementsGetPatientFrequencyStatementItems', 'date_until', date_until)
            // verify required parameter 'disaggregated' is not null or undefined
            assertParamExists('appApiPatientReportsStatementsGetPatientFrequencyStatementItems', 'disaggregated', disaggregated)
            const localVarPath = `/patients/{patient_id}/patient_frequency_statement_items`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (disaggregated !== undefined) {
                localVarQueryParameter['disaggregated'] = disaggregated;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s summary for the clinician view
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {string} [patient_ids_to_include] 
         * @param {string} [patient_ids_to_exclude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientSummaryPatientEngagementSummary: async (date_since: string, date_until: string, patient_ids_to_include?: string, patient_ids_to_exclude?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiPatientSummaryPatientEngagementSummary', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiPatientSummaryPatientEngagementSummary', 'date_until', date_until)
            const localVarPath = `/patients/patient_engagement_summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (patient_ids_to_include !== undefined) {
                localVarQueryParameter['patient_ids_to_include'] = patient_ids_to_include;
            }

            if (patient_ids_to_exclude !== undefined) {
                localVarQueryParameter['patient_ids_to_exclude'] = patient_ids_to_exclude;
            }

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the patient\'s summary for the clinician view
         * @param {number} patient_id 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientSummaryPatientSummary: async (patient_id: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientSummaryPatientSummary', 'patient_id', patient_id)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('appApiPatientSummaryPatientSummary', 'date', date)
            const localVarPath = `/patients/{patient_id}/patient_summary`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientReportApi - functional programming interface
 * @export
 */
export const PatientReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientReportApiAxiosParamCreator(configuration)
    return {
        /**
         * Since this query assembles all nutrients and goal information of the patients in the clinic, this query takes quite a load on the backend. If just listing the patients in the clinic, please use GET /hospitals/{hospital_id}/clinics/{clinic_id}/patients.
         * @summary Get the basic report of patients registered in the clinic.
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiClinicReportClinicPatientsReport(hospital_id: number, clinic_id: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicPatientsReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiClinicReportClinicPatientsReport(hospital_id, clinic_id, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a data table of all meals of a specific patient in the clinic.
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {number} patient_id 
         * @param {string} [date] 
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiClinicReportClinicianMealLogsTable(hospital_id: number, clinician_id: number, patient_id: number, date?: string, filter?: string, limit?: number, offset?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppApiClinicReportClinicianMealLogsTable200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiClinicReportClinicianMealLogsTable(hospital_id, clinician_id, patient_id, date, filter, limit, offset, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Since this query assembles all nutrients and goal information of the patients in the clinic, this query takes quite a load on the backend. If just listing the patients in the clinic, please use GET /hospitals/{hospital_id}/clinics/{clinic_id}/patients.
         * @summary Get the basic report of patients registered to all clinics this clinician belongs to.
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {string} date 
         * @param {boolean} [active_patients_only] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiClinicReportClinicianPatientsReport(hospital_id: number, clinician_id: number, date: string, active_patients_only?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicPatientsReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiClinicReportClinicianPatientsReport(hospital_id, clinician_id, date, active_patients_only, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a data table of all patients in the clinic.
         * @param {number} hospital_id 
         * @param {number} clinician_id 
         * @param {string} [date] 
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiClinicReportClinicianPatientsTable(hospital_id: number, clinician_id: number, date?: string, filter?: string, limit?: number, offset?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppApiClinicReportClinicianPatientsTable200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiClinicReportClinicianPatientsTable(hospital_id, clinician_id, date, filter, limit, offset, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the goal report of the patient for this week and last week.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiGoalGetGoalsExtendedWeeklyReport(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GoalExtendedWeeklyReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiGoalGetGoalsExtendedWeeklyReport(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the goal report of the patient for this week and last week.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiGoalGetGoalsExtendedWeeklyReportCsv(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiGoalGetGoalsExtendedWeeklyReportCsv(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the goal report of the patient for this week and last week.
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiGoalGetGoalsProgressReport(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GoalExtendedWeeklyReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiGoalGetGoalsProgressReport(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the goal report of the patient for this week and last week.
         * @param {number} patient_id 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiGoalGetGoalsWeeklyReport(patient_id: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GoalWeeklyReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiGoalGetGoalsWeeklyReport(patient_id, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s ghg equivalent data
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMetricsGhgEquivalentGetPatientGhgEquivalent(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GhgEquivalentDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMetricsGhgEquivalentGetPatientGhgEquivalent(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s ghg equivalent emission key data point in an image format
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMetricsGhgEquivalentGetPatientGhgEquivalentGraph(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMetricsGhgEquivalentGetPatientGhgEquivalentGraph(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s nutrients report for the clinician view
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {AppApiPatientNutrientsReportPatientNutrientsReportViewByEnum} view_by 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientNutrientsReportPatientNutrientsReport(patient_id: number, date_since: string, date_until: string, view_by: AppApiPatientNutrientsReportPatientNutrientsReportViewByEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientNutrientsReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientNutrientsReportPatientNutrientsReport(patient_id, date_since, date_until, view_by, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s average plate data
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsSectionsAveragePlateGetAveragePlate(patient_id: number, date_since: string, date_until: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AveragePlateDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsSectionsAveragePlateGetAveragePlate(patient_id, date_since, date_until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s average plate in an image format
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {AppApiPatientReportsSectionsAveragePlateGetAveragePlateGraphMealNameEnum} meal_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsSectionsAveragePlateGetAveragePlateGraph(patient_id: number, date_since: string, date_until: string, meal_name: AppApiPatientReportsSectionsAveragePlateGetAveragePlateGraphMealNameEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsSectionsAveragePlateGetAveragePlateGraph(patient_id, date_since, date_until, meal_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s average plate data
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsSectionsAveragePlateGetAveragePlateRecent(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AveragePlateDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsSectionsAveragePlateGetAveragePlateRecent(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s clinical summary
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} disaggregated 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements(patient_id: number, date_since: string, date_until: string, disaggregated: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SummaryStatementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements(patient_id, date_since, date_until, disaggregated, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s potassium graph
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} disaggregated 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph(patient_id: number, date_since: string, date_until: string, disaggregated: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph(patient_id, date_since, date_until, disaggregated, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s potassium details
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} disaggregated 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsSectionsPotassiumGetPatientPotassiumReport(patient_id: number, date_since: string, date_until: string, disaggregated: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientNutrientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsSectionsPotassiumGetPatientPotassiumReport(patient_id, date_since, date_until, disaggregated, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s summary metrics as a star plot graph
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} disaggregated 
         * @param {boolean} daily_avg_method 
         * @param {boolean} [include_custom_items] 
         * @param {boolean} [enforce_unique_base_food] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsSectionsStarPlotGetPatientStarPlot(patient_id: number, date_since: string, date_until: string, disaggregated: boolean, daily_avg_method: boolean, include_custom_items?: boolean, enforce_unique_base_food?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsSectionsStarPlotGetPatientStarPlot(patient_id, date_since, date_until, disaggregated, daily_avg_method, include_custom_items, enforce_unique_base_food, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s summary metrics for the star plot graph
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} disaggregated 
         * @param {boolean} daily_avg_method 
         * @param {boolean} [include_statements] 
         * @param {number} [n_frequency_items] 
         * @param {boolean} [include_non_ranked_items] 
         * @param {boolean} [include_custom_items] 
         * @param {boolean} [enforce_unique_base_food] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary(patient_id: number, date_since: string, date_until: string, disaggregated: boolean, daily_avg_method: boolean, include_statements?: boolean, n_frequency_items?: number, include_non_ranked_items?: boolean, include_custom_items?: boolean, enforce_unique_base_food?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientStarPlotResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary(patient_id, date_since, date_until, disaggregated, daily_avg_method, include_statements, n_frequency_items, include_non_ranked_items, include_custom_items, enforce_unique_base_food, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s summary statements
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} disaggregated 
         * @param {boolean} report_filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements(patient_id: number, date_since: string, date_until: string, disaggregated: boolean, report_filter: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SummaryStatementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements(patient_id, date_since, date_until, disaggregated, report_filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s summary metrics for the star plot graph
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} disaggregated 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsStatementsGetPatientFrequencyStatementItems(patient_id: number, date_since: string, date_until: string, disaggregated: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsStatementsGetPatientFrequencyStatementItems(patient_id, date_since, date_until, disaggregated, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s summary for the clinician view
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {string} [patient_ids_to_include] 
         * @param {string} [patient_ids_to_exclude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientSummaryPatientEngagementSummary(date_since: string, date_until: string, patient_ids_to_include?: string, patient_ids_to_exclude?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeeklyPatientEngagementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientSummaryPatientEngagementSummary(date_since, date_until, patient_ids_to_include, patient_ids_to_exclude, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the patient\'s summary for the clinician view
         * @param {number} patient_id 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientSummaryPatientSummary(patient_id: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientSummaryPatientSummary(patient_id, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientReportApi - factory interface
 * @export
 */
export const PatientReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientReportApiFp(configuration)
    return {
        /**
         * Since this query assembles all nutrients and goal information of the patients in the clinic, this query takes quite a load on the backend. If just listing the patients in the clinic, please use GET /hospitals/{hospital_id}/clinics/{clinic_id}/patients.
         * @summary Get the basic report of patients registered in the clinic.
         * @param {PatientReportApiAppApiClinicReportClinicPatientsReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicReportClinicPatientsReport(requestParameters: PatientReportApiAppApiClinicReportClinicPatientsReportRequest, options?: AxiosRequestConfig): AxiosPromise<ClinicPatientsReportResponse> {
            return localVarFp.appApiClinicReportClinicPatientsReport(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a data table of all meals of a specific patient in the clinic.
         * @param {PatientReportApiAppApiClinicReportClinicianMealLogsTableRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicReportClinicianMealLogsTable(requestParameters: PatientReportApiAppApiClinicReportClinicianMealLogsTableRequest, options?: AxiosRequestConfig): AxiosPromise<AppApiClinicReportClinicianMealLogsTable200Response> {
            return localVarFp.appApiClinicReportClinicianMealLogsTable(requestParameters.hospital_id, requestParameters.clinician_id, requestParameters.patient_id, requestParameters.date, requestParameters.filter, requestParameters.limit, requestParameters.offset, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * Since this query assembles all nutrients and goal information of the patients in the clinic, this query takes quite a load on the backend. If just listing the patients in the clinic, please use GET /hospitals/{hospital_id}/clinics/{clinic_id}/patients.
         * @summary Get the basic report of patients registered to all clinics this clinician belongs to.
         * @param {PatientReportApiAppApiClinicReportClinicianPatientsReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicReportClinicianPatientsReport(requestParameters: PatientReportApiAppApiClinicReportClinicianPatientsReportRequest, options?: AxiosRequestConfig): AxiosPromise<ClinicPatientsReportResponse> {
            return localVarFp.appApiClinicReportClinicianPatientsReport(requestParameters.hospital_id, requestParameters.clinician_id, requestParameters.date, requestParameters.active_patients_only, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a data table of all patients in the clinic.
         * @param {PatientReportApiAppApiClinicReportClinicianPatientsTableRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiClinicReportClinicianPatientsTable(requestParameters: PatientReportApiAppApiClinicReportClinicianPatientsTableRequest, options?: AxiosRequestConfig): AxiosPromise<AppApiClinicReportClinicianPatientsTable200Response> {
            return localVarFp.appApiClinicReportClinicianPatientsTable(requestParameters.hospital_id, requestParameters.clinician_id, requestParameters.date, requestParameters.filter, requestParameters.limit, requestParameters.offset, requestParameters.order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the goal report of the patient for this week and last week.
         * @param {PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetGoalsExtendedWeeklyReport(requestParameters: PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReportRequest, options?: AxiosRequestConfig): AxiosPromise<Array<GoalExtendedWeeklyReportResponse>> {
            return localVarFp.appApiGoalGetGoalsExtendedWeeklyReport(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the goal report of the patient for this week and last week.
         * @param {PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReportCsvRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetGoalsExtendedWeeklyReportCsv(requestParameters: PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReportCsvRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.appApiGoalGetGoalsExtendedWeeklyReportCsv(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the goal report of the patient for this week and last week.
         * @param {PatientReportApiAppApiGoalGetGoalsProgressReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetGoalsProgressReport(requestParameters: PatientReportApiAppApiGoalGetGoalsProgressReportRequest, options?: AxiosRequestConfig): AxiosPromise<Array<GoalExtendedWeeklyReportResponse>> {
            return localVarFp.appApiGoalGetGoalsProgressReport(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the goal report of the patient for this week and last week.
         * @param {PatientReportApiAppApiGoalGetGoalsWeeklyReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiGoalGetGoalsWeeklyReport(requestParameters: PatientReportApiAppApiGoalGetGoalsWeeklyReportRequest, options?: AxiosRequestConfig): AxiosPromise<Array<GoalWeeklyReportResponse>> {
            return localVarFp.appApiGoalGetGoalsWeeklyReport(requestParameters.patient_id, requestParameters.date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s ghg equivalent data
         * @param {PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMetricsGhgEquivalentGetPatientGhgEquivalent(requestParameters: PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalentRequest, options?: AxiosRequestConfig): AxiosPromise<GhgEquivalentDataResponse> {
            return localVarFp.appApiMetricsGhgEquivalentGetPatientGhgEquivalent(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s ghg equivalent emission key data point in an image format
         * @param {PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalentGraphRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMetricsGhgEquivalentGetPatientGhgEquivalentGraph(requestParameters: PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalentGraphRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.appApiMetricsGhgEquivalentGetPatientGhgEquivalentGraph(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s nutrients report for the clinician view
         * @param {PatientReportApiAppApiPatientNutrientsReportPatientNutrientsReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientNutrientsReportPatientNutrientsReport(requestParameters: PatientReportApiAppApiPatientNutrientsReportPatientNutrientsReportRequest, options?: AxiosRequestConfig): AxiosPromise<PatientNutrientsReportResponse> {
            return localVarFp.appApiPatientNutrientsReportPatientNutrientsReport(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.view_by, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s average plate data
         * @param {PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsAveragePlateGetAveragePlate(requestParameters: PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateRequest, options?: AxiosRequestConfig): AxiosPromise<AveragePlateDataResponse> {
            return localVarFp.appApiPatientReportsSectionsAveragePlateGetAveragePlate(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s average plate in an image format
         * @param {PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateGraphRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsAveragePlateGetAveragePlateGraph(requestParameters: PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateGraphRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.appApiPatientReportsSectionsAveragePlateGetAveragePlateGraph(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.meal_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s average plate data
         * @param {PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateRecentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsAveragePlateGetAveragePlateRecent(requestParameters: PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateRecentRequest, options?: AxiosRequestConfig): AxiosPromise<AveragePlateDataResponse> {
            return localVarFp.appApiPatientReportsSectionsAveragePlateGetAveragePlateRecent(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s clinical summary
         * @param {PatientReportApiAppApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatementsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements(requestParameters: PatientReportApiAppApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatementsRequest, options?: AxiosRequestConfig): AxiosPromise<SummaryStatementResponse> {
            return localVarFp.appApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.disaggregated, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s potassium graph
         * @param {PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumGraphRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph(requestParameters: PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumGraphRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.appApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.disaggregated, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s potassium details
         * @param {PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsPotassiumGetPatientPotassiumReport(requestParameters: PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumReportRequest, options?: AxiosRequestConfig): AxiosPromise<PatientNutrientResponse> {
            return localVarFp.appApiPatientReportsSectionsPotassiumGetPatientPotassiumReport(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.disaggregated, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s summary metrics as a star plot graph
         * @param {PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsStarPlotGetPatientStarPlot(requestParameters: PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.appApiPatientReportsSectionsStarPlotGetPatientStarPlot(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.disaggregated, requestParameters.daily_avg_method, requestParameters.include_custom_items, requestParameters.enforce_unique_base_food, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s summary metrics for the star plot graph
         * @param {PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary(requestParameters: PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummaryRequest, options?: AxiosRequestConfig): AxiosPromise<PatientStarPlotResponse> {
            return localVarFp.appApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.disaggregated, requestParameters.daily_avg_method, requestParameters.include_statements, requestParameters.n_frequency_items, requestParameters.include_non_ranked_items, requestParameters.include_custom_items, requestParameters.enforce_unique_base_food, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s summary statements
         * @param {PatientReportApiAppApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatementsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements(requestParameters: PatientReportApiAppApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatementsRequest, options?: AxiosRequestConfig): AxiosPromise<SummaryStatementResponse> {
            return localVarFp.appApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.disaggregated, requestParameters.report_filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s summary metrics for the star plot graph
         * @param {PatientReportApiAppApiPatientReportsStatementsGetPatientFrequencyStatementItemsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsStatementsGetPatientFrequencyStatementItems(requestParameters: PatientReportApiAppApiPatientReportsStatementsGetPatientFrequencyStatementItemsRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiPatientReportsStatementsGetPatientFrequencyStatementItems(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.disaggregated, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s summary for the clinician view
         * @param {PatientReportApiAppApiPatientSummaryPatientEngagementSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientSummaryPatientEngagementSummary(requestParameters: PatientReportApiAppApiPatientSummaryPatientEngagementSummaryRequest, options?: AxiosRequestConfig): AxiosPromise<WeeklyPatientEngagementResponse> {
            return localVarFp.appApiPatientSummaryPatientEngagementSummary(requestParameters.date_since, requestParameters.date_until, requestParameters.patient_ids_to_include, requestParameters.patient_ids_to_exclude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the patient\'s summary for the clinician view
         * @param {PatientReportApiAppApiPatientSummaryPatientSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientSummaryPatientSummary(requestParameters: PatientReportApiAppApiPatientSummaryPatientSummaryRequest, options?: AxiosRequestConfig): AxiosPromise<PatientSummaryResponse> {
            return localVarFp.appApiPatientSummaryPatientSummary(requestParameters.patient_id, requestParameters.date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiClinicReportClinicPatientsReport operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiClinicReportClinicPatientsReportRequest
 */
export interface PatientReportApiAppApiClinicReportClinicPatientsReportRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiClinicReportClinicPatientsReport
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiClinicReportClinicPatientsReport
     */
    readonly clinic_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiClinicReportClinicPatientsReport
     */
    readonly date: string
}

/**
 * Request parameters for appApiClinicReportClinicianMealLogsTable operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiClinicReportClinicianMealLogsTableRequest
 */
export interface PatientReportApiAppApiClinicReportClinicianMealLogsTableRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiClinicReportClinicianMealLogsTable
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiClinicReportClinicianMealLogsTable
     */
    readonly clinician_id: number

    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiClinicReportClinicianMealLogsTable
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiClinicReportClinicianMealLogsTable
     */
    readonly date?: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiClinicReportClinicianMealLogsTable
     */
    readonly filter?: string

    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiClinicReportClinicianMealLogsTable
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiClinicReportClinicianMealLogsTable
     */
    readonly offset?: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiClinicReportClinicianMealLogsTable
     */
    readonly order?: string
}

/**
 * Request parameters for appApiClinicReportClinicianPatientsReport operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiClinicReportClinicianPatientsReportRequest
 */
export interface PatientReportApiAppApiClinicReportClinicianPatientsReportRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiClinicReportClinicianPatientsReport
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiClinicReportClinicianPatientsReport
     */
    readonly clinician_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiClinicReportClinicianPatientsReport
     */
    readonly date: string

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiClinicReportClinicianPatientsReport
     */
    readonly active_patients_only?: boolean
}

/**
 * Request parameters for appApiClinicReportClinicianPatientsTable operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiClinicReportClinicianPatientsTableRequest
 */
export interface PatientReportApiAppApiClinicReportClinicianPatientsTableRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiClinicReportClinicianPatientsTable
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiClinicReportClinicianPatientsTable
     */
    readonly clinician_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiClinicReportClinicianPatientsTable
     */
    readonly date?: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiClinicReportClinicianPatientsTable
     */
    readonly filter?: string

    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiClinicReportClinicianPatientsTable
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiClinicReportClinicianPatientsTable
     */
    readonly offset?: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiClinicReportClinicianPatientsTable
     */
    readonly order?: string
}

/**
 * Request parameters for appApiGoalGetGoalsExtendedWeeklyReport operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReportRequest
 */
export interface PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReportRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReport
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReport
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiGoalGetGoalsExtendedWeeklyReportCsv operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReportCsvRequest
 */
export interface PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReportCsvRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReportCsv
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReportCsv
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReportCsv
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiGoalGetGoalsProgressReport operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiGoalGetGoalsProgressReportRequest
 */
export interface PatientReportApiAppApiGoalGetGoalsProgressReportRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiGoalGetGoalsProgressReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiGoalGetGoalsProgressReport
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiGoalGetGoalsProgressReport
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiGoalGetGoalsWeeklyReport operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiGoalGetGoalsWeeklyReportRequest
 */
export interface PatientReportApiAppApiGoalGetGoalsWeeklyReportRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiGoalGetGoalsWeeklyReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiGoalGetGoalsWeeklyReport
     */
    readonly date: string
}

/**
 * Request parameters for appApiMetricsGhgEquivalentGetPatientGhgEquivalent operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalentRequest
 */
export interface PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalentRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalent
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalent
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalent
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiMetricsGhgEquivalentGetPatientGhgEquivalentGraph operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalentGraphRequest
 */
export interface PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalentGraphRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalentGraph
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalentGraph
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalentGraph
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiPatientNutrientsReportPatientNutrientsReport operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiPatientNutrientsReportPatientNutrientsReportRequest
 */
export interface PatientReportApiAppApiPatientNutrientsReportPatientNutrientsReportRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiPatientNutrientsReportPatientNutrientsReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientNutrientsReportPatientNutrientsReport
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientNutrientsReportPatientNutrientsReport
     */
    readonly date_until: string

    /**
     * 
     * @type {'day' | 'weekday_weekend' | 'meal_name'}
     * @memberof PatientReportApiAppApiPatientNutrientsReportPatientNutrientsReport
     */
    readonly view_by: AppApiPatientNutrientsReportPatientNutrientsReportViewByEnum
}

/**
 * Request parameters for appApiPatientReportsSectionsAveragePlateGetAveragePlate operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateRequest
 */
export interface PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlate
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlate
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlate
     */
    readonly date_until: string
}

/**
 * Request parameters for appApiPatientReportsSectionsAveragePlateGetAveragePlateGraph operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateGraphRequest
 */
export interface PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateGraphRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateGraph
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateGraph
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateGraph
     */
    readonly date_until: string

    /**
     * 
     * @type {'breakfast' | 'lunch' | 'dinner' | 'snack' | 'overall'}
     * @memberof PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateGraph
     */
    readonly meal_name: AppApiPatientReportsSectionsAveragePlateGetAveragePlateGraphMealNameEnum
}

/**
 * Request parameters for appApiPatientReportsSectionsAveragePlateGetAveragePlateRecent operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateRecentRequest
 */
export interface PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateRecentRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateRecent
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatementsRequest
 */
export interface PatientReportApiAppApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatementsRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements
     */
    readonly date_until: string

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements
     */
    readonly disaggregated: boolean
}

/**
 * Request parameters for appApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumGraphRequest
 */
export interface PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumGraphRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph
     */
    readonly date_until: string

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph
     */
    readonly disaggregated: boolean
}

/**
 * Request parameters for appApiPatientReportsSectionsPotassiumGetPatientPotassiumReport operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumReportRequest
 */
export interface PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumReportRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumReport
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumReport
     */
    readonly date_until: string

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumReport
     */
    readonly disaggregated: boolean
}

/**
 * Request parameters for appApiPatientReportsSectionsStarPlotGetPatientStarPlot operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotRequest
 */
export interface PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlot
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlot
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlot
     */
    readonly date_until: string

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlot
     */
    readonly disaggregated: boolean

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlot
     */
    readonly daily_avg_method: boolean

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlot
     */
    readonly include_custom_items?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlot
     */
    readonly enforce_unique_base_food?: boolean
}

/**
 * Request parameters for appApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummaryRequest
 */
export interface PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummaryRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary
     */
    readonly date_until: string

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary
     */
    readonly disaggregated: boolean

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary
     */
    readonly daily_avg_method: boolean

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary
     */
    readonly include_statements?: boolean

    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary
     */
    readonly n_frequency_items?: number

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary
     */
    readonly include_non_ranked_items?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary
     */
    readonly include_custom_items?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary
     */
    readonly enforce_unique_base_food?: boolean
}

/**
 * Request parameters for appApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatementsRequest
 */
export interface PatientReportApiAppApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatementsRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements
     */
    readonly date_until: string

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements
     */
    readonly disaggregated: boolean

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements
     */
    readonly report_filter: boolean
}

/**
 * Request parameters for appApiPatientReportsStatementsGetPatientFrequencyStatementItems operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiPatientReportsStatementsGetPatientFrequencyStatementItemsRequest
 */
export interface PatientReportApiAppApiPatientReportsStatementsGetPatientFrequencyStatementItemsRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiPatientReportsStatementsGetPatientFrequencyStatementItems
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsStatementsGetPatientFrequencyStatementItems
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientReportsStatementsGetPatientFrequencyStatementItems
     */
    readonly date_until: string

    /**
     * 
     * @type {boolean}
     * @memberof PatientReportApiAppApiPatientReportsStatementsGetPatientFrequencyStatementItems
     */
    readonly disaggregated: boolean
}

/**
 * Request parameters for appApiPatientSummaryPatientEngagementSummary operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiPatientSummaryPatientEngagementSummaryRequest
 */
export interface PatientReportApiAppApiPatientSummaryPatientEngagementSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientSummaryPatientEngagementSummary
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientSummaryPatientEngagementSummary
     */
    readonly date_until: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientSummaryPatientEngagementSummary
     */
    readonly patient_ids_to_include?: string

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientSummaryPatientEngagementSummary
     */
    readonly patient_ids_to_exclude?: string
}

/**
 * Request parameters for appApiPatientSummaryPatientSummary operation in PatientReportApi.
 * @export
 * @interface PatientReportApiAppApiPatientSummaryPatientSummaryRequest
 */
export interface PatientReportApiAppApiPatientSummaryPatientSummaryRequest {
    /**
     * 
     * @type {number}
     * @memberof PatientReportApiAppApiPatientSummaryPatientSummary
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof PatientReportApiAppApiPatientSummaryPatientSummary
     */
    readonly date: string
}

/**
 * PatientReportApi - object-oriented interface
 * @export
 * @class PatientReportApi
 * @extends {BaseAPI}
 */
export class PatientReportApi extends BaseAPI {
    /**
     * Since this query assembles all nutrients and goal information of the patients in the clinic, this query takes quite a load on the backend. If just listing the patients in the clinic, please use GET /hospitals/{hospital_id}/clinics/{clinic_id}/patients.
     * @summary Get the basic report of patients registered in the clinic.
     * @param {PatientReportApiAppApiClinicReportClinicPatientsReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiClinicReportClinicPatientsReport(requestParameters: PatientReportApiAppApiClinicReportClinicPatientsReportRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiClinicReportClinicPatientsReport(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a data table of all meals of a specific patient in the clinic.
     * @param {PatientReportApiAppApiClinicReportClinicianMealLogsTableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiClinicReportClinicianMealLogsTable(requestParameters: PatientReportApiAppApiClinicReportClinicianMealLogsTableRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiClinicReportClinicianMealLogsTable(requestParameters.hospital_id, requestParameters.clinician_id, requestParameters.patient_id, requestParameters.date, requestParameters.filter, requestParameters.limit, requestParameters.offset, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Since this query assembles all nutrients and goal information of the patients in the clinic, this query takes quite a load on the backend. If just listing the patients in the clinic, please use GET /hospitals/{hospital_id}/clinics/{clinic_id}/patients.
     * @summary Get the basic report of patients registered to all clinics this clinician belongs to.
     * @param {PatientReportApiAppApiClinicReportClinicianPatientsReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiClinicReportClinicianPatientsReport(requestParameters: PatientReportApiAppApiClinicReportClinicianPatientsReportRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiClinicReportClinicianPatientsReport(requestParameters.hospital_id, requestParameters.clinician_id, requestParameters.date, requestParameters.active_patients_only, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a data table of all patients in the clinic.
     * @param {PatientReportApiAppApiClinicReportClinicianPatientsTableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiClinicReportClinicianPatientsTable(requestParameters: PatientReportApiAppApiClinicReportClinicianPatientsTableRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiClinicReportClinicianPatientsTable(requestParameters.hospital_id, requestParameters.clinician_id, requestParameters.date, requestParameters.filter, requestParameters.limit, requestParameters.offset, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the goal report of the patient for this week and last week.
     * @param {PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiGoalGetGoalsExtendedWeeklyReport(requestParameters: PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReportRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiGoalGetGoalsExtendedWeeklyReport(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the goal report of the patient for this week and last week.
     * @param {PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReportCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiGoalGetGoalsExtendedWeeklyReportCsv(requestParameters: PatientReportApiAppApiGoalGetGoalsExtendedWeeklyReportCsvRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiGoalGetGoalsExtendedWeeklyReportCsv(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the goal report of the patient for this week and last week.
     * @param {PatientReportApiAppApiGoalGetGoalsProgressReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiGoalGetGoalsProgressReport(requestParameters: PatientReportApiAppApiGoalGetGoalsProgressReportRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiGoalGetGoalsProgressReport(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the goal report of the patient for this week and last week.
     * @param {PatientReportApiAppApiGoalGetGoalsWeeklyReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiGoalGetGoalsWeeklyReport(requestParameters: PatientReportApiAppApiGoalGetGoalsWeeklyReportRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiGoalGetGoalsWeeklyReport(requestParameters.patient_id, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s ghg equivalent data
     * @param {PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiMetricsGhgEquivalentGetPatientGhgEquivalent(requestParameters: PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalentRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiMetricsGhgEquivalentGetPatientGhgEquivalent(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s ghg equivalent emission key data point in an image format
     * @param {PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalentGraphRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiMetricsGhgEquivalentGetPatientGhgEquivalentGraph(requestParameters: PatientReportApiAppApiMetricsGhgEquivalentGetPatientGhgEquivalentGraphRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiMetricsGhgEquivalentGetPatientGhgEquivalentGraph(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s nutrients report for the clinician view
     * @param {PatientReportApiAppApiPatientNutrientsReportPatientNutrientsReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiPatientNutrientsReportPatientNutrientsReport(requestParameters: PatientReportApiAppApiPatientNutrientsReportPatientNutrientsReportRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiPatientNutrientsReportPatientNutrientsReport(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.view_by, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s average plate data
     * @param {PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiPatientReportsSectionsAveragePlateGetAveragePlate(requestParameters: PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiPatientReportsSectionsAveragePlateGetAveragePlate(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s average plate in an image format
     * @param {PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateGraphRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiPatientReportsSectionsAveragePlateGetAveragePlateGraph(requestParameters: PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateGraphRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiPatientReportsSectionsAveragePlateGetAveragePlateGraph(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.meal_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s average plate data
     * @param {PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateRecentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiPatientReportsSectionsAveragePlateGetAveragePlateRecent(requestParameters: PatientReportApiAppApiPatientReportsSectionsAveragePlateGetAveragePlateRecentRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiPatientReportsSectionsAveragePlateGetAveragePlateRecent(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s clinical summary
     * @param {PatientReportApiAppApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements(requestParameters: PatientReportApiAppApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatementsRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiPatientReportsSectionsClinicalSummaryGetClinicalSummaryStatements(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.disaggregated, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s potassium graph
     * @param {PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumGraphRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph(requestParameters: PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumGraphRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiPatientReportsSectionsPotassiumGetPatientPotassiumGraph(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.disaggregated, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s potassium details
     * @param {PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiPatientReportsSectionsPotassiumGetPatientPotassiumReport(requestParameters: PatientReportApiAppApiPatientReportsSectionsPotassiumGetPatientPotassiumReportRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiPatientReportsSectionsPotassiumGetPatientPotassiumReport(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.disaggregated, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s summary metrics as a star plot graph
     * @param {PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiPatientReportsSectionsStarPlotGetPatientStarPlot(requestParameters: PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiPatientReportsSectionsStarPlotGetPatientStarPlot(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.disaggregated, requestParameters.daily_avg_method, requestParameters.include_custom_items, requestParameters.enforce_unique_base_food, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s summary metrics for the star plot graph
     * @param {PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary(requestParameters: PatientReportApiAppApiPatientReportsSectionsStarPlotGetPatientStarPlotSummaryRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiPatientReportsSectionsStarPlotGetPatientStarPlotSummary(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.disaggregated, requestParameters.daily_avg_method, requestParameters.include_statements, requestParameters.n_frequency_items, requestParameters.include_non_ranked_items, requestParameters.include_custom_items, requestParameters.enforce_unique_base_food, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s summary statements
     * @param {PatientReportApiAppApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements(requestParameters: PatientReportApiAppApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatementsRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiPatientReportsSectionsSummaryStatementGetPatientSummaryStatements(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.disaggregated, requestParameters.report_filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s summary metrics for the star plot graph
     * @param {PatientReportApiAppApiPatientReportsStatementsGetPatientFrequencyStatementItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiPatientReportsStatementsGetPatientFrequencyStatementItems(requestParameters: PatientReportApiAppApiPatientReportsStatementsGetPatientFrequencyStatementItemsRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiPatientReportsStatementsGetPatientFrequencyStatementItems(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.disaggregated, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s summary for the clinician view
     * @param {PatientReportApiAppApiPatientSummaryPatientEngagementSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiPatientSummaryPatientEngagementSummary(requestParameters: PatientReportApiAppApiPatientSummaryPatientEngagementSummaryRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiPatientSummaryPatientEngagementSummary(requestParameters.date_since, requestParameters.date_until, requestParameters.patient_ids_to_include, requestParameters.patient_ids_to_exclude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the patient\'s summary for the clinician view
     * @param {PatientReportApiAppApiPatientSummaryPatientSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientReportApi
     */
    public appApiPatientSummaryPatientSummary(requestParameters: PatientReportApiAppApiPatientSummaryPatientSummaryRequest, options?: AxiosRequestConfig) {
        return PatientReportApiFp(this.configuration).appApiPatientSummaryPatientSummary(requestParameters.patient_id, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AppApiPatientNutrientsReportPatientNutrientsReportViewByEnum = {
    Day: 'day',
    WeekdayWeekend: 'weekday_weekend',
    MealName: 'meal_name'
} as const;
export type AppApiPatientNutrientsReportPatientNutrientsReportViewByEnum = typeof AppApiPatientNutrientsReportPatientNutrientsReportViewByEnum[keyof typeof AppApiPatientNutrientsReportPatientNutrientsReportViewByEnum];
/**
 * @export
 */
export const AppApiPatientReportsSectionsAveragePlateGetAveragePlateGraphMealNameEnum = {
    Breakfast: 'breakfast',
    Lunch: 'lunch',
    Dinner: 'dinner',
    Snack: 'snack',
    Overall: 'overall'
} as const;
export type AppApiPatientReportsSectionsAveragePlateGetAveragePlateGraphMealNameEnum = typeof AppApiPatientReportsSectionsAveragePlateGetAveragePlateGraphMealNameEnum[keyof typeof AppApiPatientReportsSectionsAveragePlateGetAveragePlateGraphMealNameEnum];
