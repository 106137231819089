import React, { useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';

import { useStore, useStoreDispatch } from '../context';
import './notification.scss';

const Notification = () => {
  const { notification } = useStore();
  const { setNotification } = useStoreDispatch();

  useEffect(() => {
    setNotification([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeNotification = (index) => {
    console.log('closeNotification', notification.filter((_, i) => i !== index));
    setNotification(notification.filter((_, i) => i !== index));
  };

  return (
    <div className="notificationPanel">
      {notification?.map((msg, index) => (
        <Toast key={index} onClose={() => closeNotification(index)}>
          <Toast.Header>
            <strong className="mr-auto">RxFoodApp</strong>
          </Toast.Header>
          <Toast.Body>{msg[0]}</Toast.Body>
        </Toast>
      ))}
    </div>
  );
};

export default Notification;
