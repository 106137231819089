import { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import { hostUrl, reportingApi, subscriptionApi } from '../api';
import { ReportingApiAppApiPatientReportsApiPostFoodReportRequest } from '../api/generated/apis/reporting-api';
import {
  SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionRequest,
  SubscriptionApiAppApiSubscriptionApiPutPatientSubscriptionRequest,
} from '../api/generated/apis/subscription-api';
import {
  CreatePatientFromClinicianRequest,
  CreatePatientSubscriptionRequestNameEnum,
  CreatePatientSubscriptionRequestTypeEnum,
  PatientReportResponse,
  TaskResultResponse,
  UpdatePatientSubscriptionRequestTypeEnum,
} from '../api/generated/models';
import { view_types } from '../components/foodFilterNew';
import { useStore, useStoreDispatch } from '../context';
import { useTranslation } from '../i18n';

export const SUBSCRIPTION_NAME_MAP: Record<string, CreatePatientSubscriptionRequestNameEnum> = {
  '3': 'three-day',
  '5': 'five-day',
  '7': 'seven-day',
  '10': 'ten-day',
  '14': '14-day',
  '21': '21-day',
  '28': '28-day',
} as const;

function PatientService() {
  const store = useStore();
  const { token, patient } = store;
  const { t } = useTranslation();
  const {
    setNutrients,
    setMeals,
    setWater,
    setEmotion,
    setEmotionQuestion,
    setHEI,
    setDash,
    setGoals,
    setPatientList,
    setPatientSummary,
    setPatientClinics,
    setAppointments,
    setPatientSubscriptions,
    setAllPatientReports,
    setPatientInAClinic,
    setCurrentUser,
  } = useStoreDispatch();

  const getPatientSummary = async (patient_id) => {
    if (!token || !patient_id) {
      return false;
    }

    const url = new URL(`${hostUrl}/api/patients/${patient_id}/patient_summary`);
    const params = { date: moment().format('YYYY-MM-DD') };
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url, {
      headers: new Headers({ Authorization: `Bearer ${token}` }),
    });

    if (response.status == 200) {
      const json = await response.json();
      setPatientSummary(json);
      return json;
    }
    return false;
  };

  const getPatientReport = async (patient_id) => {
    if (!token || !patient_id) {
      return false;
    }

    const url = new URL(`${hostUrl}/api/patients/${patient_id}/goals/progress_report`);
    const params = {
      date_since: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      date_until: moment().format('YYYY-MM-DD'),
    };
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url, {
      headers: new Headers({ Authorization: `Bearer ${token}` }),
    });

    if (response.status == 200) {
      const json = await response.json();
      setGoals(json);
      return json;
    }
    return false;
  };

  const getPatientReportPdf = async (report_id) => {
    if (!token) {
      return false;
    }

    const url = new URL(`${hostUrl}/api/reporting/patient_report/${report_id}/pdf`);
    const response = await fetch(url, {
      headers: new Headers({ Authorization: `Bearer ${token}` }),
    });

    if (response.status == 200) {
      const data = await response.blob();
      return URL.createObjectURL(data);
    }

    throw new Error(`${response.status}`);
  };

  const getTaskResponse = async (response: AxiosResponse): Promise<boolean | TaskResultResponse> => {
    if (response.status >= 400) {
      return false;
    }
    if (!response.data?.link) {
      return false;
    }
    if (response.data.link) {
      const maxRetries = 50;
      const retryDelay = 1000;
      let retries = 0;
      while (retries < maxRetries) {
        try {
          const res = await fetch(response.data.link, {
            headers: new Headers({
              'Authorization': `Bearer ${token}`,
            }),
          });
          if (res.status === 200) {
            return res.json();
          }
        } catch (e) {
          console.log(`Error fetching ${response.data.link}: ${e}`);
          return false;
        }
        retries += 1;
        await new Promise(r => setTimeout(r, retryDelay));
      }
      console.log(`Exceeded maximum retries for fetching ${response.data.link}`);
      return false;
    }
  };

  const getBackgroundTaskResult = async (response: AxiosResponse) => {
    let taskRes = await getTaskResponse(response);
    if (taskRes === false) {
      return false;
    }
    if ((taskRes as TaskResultResponse).task_is_success) {
      return true;
    } else if ((taskRes as TaskResultResponse).task_is_error) {
      console.log(`Error in task: ${(taskRes as TaskResultResponse).link}`);
      return false;
    }
    while ((taskRes as TaskResultResponse).task_is_pending) {
      await new Promise(r => setTimeout(r, 2000));
      taskRes = await getTaskResponse(response);
    }
    if ((taskRes as TaskResultResponse).task_is_error) {
      console.log(`Error in task: ${(taskRes as TaskResultResponse).link}`);
      return false;
    }
    return true;
  };

  const postFoodReport = async (
    patient_id: number,
    hospital_id: number,
    clinic_id: number,
    date_since: string,
    date_until: string,
    dates_exclude: string[],
    report_status: 'draft' | 'sent',
    comparison_report_id: number,
    sub_id: number | null,
    sections: string[] = null,
  ) => {
    if (!token) {
      return false;
    }

    const params: ReportingApiAppApiPatientReportsApiPostFoodReportRequest = {
      patient_id: patient_id,
      hospital_id: hospital_id,
      clinic_id: clinic_id,
      date_since: date_since,
      date_until: date_until,
      dates_exclude: dates_exclude,
      is_comparison_report: comparison_report_id != null,
      comparison_report_id: comparison_report_id || undefined,
      status: report_status,
      patient_subscription_id: sub_id || null,
      sections: sections || null,
      as_background_task: true,
    };
    try {
      const response = await reportingApi.appApiPatientReportsApiPostFoodReport(params);
      return await getBackgroundTaskResult(response);
    } catch (e) {
      console.log(e);
      window.alert(t('{{status}} error: {{message}}', {
        status: e.response.data.status,
        message: e.response.data.message,
      }));
      return false;
    }
  };

  const registerReport = async (
    hospital_id,
    clinic_id,
    patient_id,
    sub_id,
    comparison_report_id,
    date_since,
    date_until,
    is_draft,
  ) => {
    if (!token) {
      return false;
    }

    const url = new URL(
      `${hostUrl}/api/hospital/${hospital_id}/clinic/${clinic_id}/patients/${patient_id}/subscription/${sub_id}/register_report`,
    );

    const formatDateTime = (m: Moment | string): string => {
      return m && moment(m).format().replace('T', ' ');
    };

    const request = {
      autogenerate: true,
      autosend: true,
      to_process_time: formatDateTime(moment()),
      to_send_time: formatDateTime(moment()),
      date_since: formatDateTime(date_since).split(' ')[0],
      date_until: formatDateTime(date_until).split(' ')[0],
      is_comparison_report: comparison_report_id != null,
      comparison_report_id: comparison_report_id || undefined,
      report_type: 'rxfood',
    };

    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(request),
    });

    const responseDetail = await response.json();

    if (response.status !== 200) {
      window.alert(t('Error {{status}}{{colon}} "{{statusText}}" from "{{message}}".', {
        status: response.status,
        colon: ':',
        statusText: response.statusText,
        message: responseDetail.message,
      }));
      return false;
    }

    return true;
  };

  const getPatientList = async (hospital_id, clinician_id, activePatientsFlag) => {
    if (!token || !hospital_id || !clinician_id) {
      console.log('cannot refresh patient list');
      return false;
    }

    const url = new URL(`${hostUrl}/api/hospitals/${hospital_id}/clinicians/${clinician_id}/patients_report`);
    const params = { date: moment().format('YYYY-MM-DD'), active_patients_only: activePatientsFlag };
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url, {
      headers: new Headers({ Authorization: `Bearer ${token}` }),
    });

    if (response.status == 200) {
      const json = await response.json();
      setPatientList(json.rows.reverse());
      return json;
    }
    return false;
  };

  const addPatient = async (patient: CreatePatientFromClinicianRequest) => {
    const response = await fetch(hostUrl + `/api/patients/from_clinician`, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify({
        ...patient,
      }),
    });

    const json = await response.json();
    if (response.status == 200) {
      return json;
    }

    throw new Error(json.message ?? `Internal error (${response.status})`);
  };

  const updatePatient = async (
    patient_id,
    first_name,
    last_name,
    email,
    phone_number,
    flags_raw,
    hospital_attributes,
  ) => {
    const response = await fetch(hostUrl + `/api/patients/${patient_id}`, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      body: JSON.stringify({
        first_name,
        last_name,
        phone_number,
        email,
        first_meal_time: null,
        sex: null,
        password: null,
        flags_raw,
        hospital_attributes,
      }),
    });

    if (response.status == 200) {
      const json = await response.json();
      return json;
    }
    return false;
  };

  const resendInvite = async (hospital_id, patient_id) => {
    const url = new URL(
      `${hostUrl}/api/hospitals/${hospital_id}/send_hospital_invitation_email?patient_id=${patient_id}`,
    );
    const response = await fetch(url, {
      headers: new Headers({ Authorization: `Bearer ${token}` }),
      method: 'POST',
    });

    if (response.status == 200) {
      const json = await response.json();
      return json;
    }
    return false;
  };

  const getHEI = async (
    patient_id,
    since = moment().subtract(100, 'days').format('YYYY-MM-DD'),
    until = moment().format('YYYY-MM-DD'),
  ) => {
    const url = new URL(`${hostUrl}/api/metrics/patients/${patient_id}/HEI/components`);
    const params = {
      date_since: since, // moment().subtract(30, 'days').format('YYYY-MM-DD'),
      date_until: until, // moment().format('YYYY-MM-DD')
    };
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url, {
      headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.json();
      setHEI(json);
      return json;
    }
    return false;
  };

  const getDash = async (
    patient_id,
    since = moment().subtract(60, 'days').format('YYYY-MM-DD'),
    until = moment().format('YYYY-MM-DD'),
  ) => {
    const url = new URL(`${hostUrl}/api/metrics/patients/${patient_id}/DASH`);
    const params = {
      // patient_id: patient_id,
      date_since: since, // moment().subtract(30, 'days').format('YYYY-MM-DD'),
      date_until: until, // moment().format('YYYY-MM-DD')
    };
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url, {
      headers: new Headers({ Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.json();
      setDash(json);
      return json;
    }
    return false;
  };

  const getMeals = async (p: { patient_id: number | string, include_deleted: boolean }) => {
    setMeals([]);
    const url = new URL(`${hostUrl}/api/patients/${p.patient_id}/meals`);
    const params = {
      date_since: moment().subtract(365, 'days').format('YYYY-MM-DD'),
      date_until: moment().format('YYYY-MM-DD'),
      include_deleted: p.include_deleted,
    };
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.json();
      setMeals(json);
      return json;
    }
    return false;
  };

  // TODO: Create putMeal function.
  const putMeal = async (patient_id, meal_index, meal) => {
  };

  // TODO: Create itemName lookup function

  const getWater = async (
    patient_id,
    since = moment().subtract(7, 'days').format('YYYY-MM-DD'),
    until = moment().format('YYYY-MM-DD'),
  ) => {
    const url = new URL(`${hostUrl}/api/patients/${patient_id}/waters`);
    const params = {
      // patient_id: patient_id,
      date_since: since, // moment().subtract(30, 'days').format('YYYY-MM-DD'),
      date_until: until, // moment().format('YYYY-MM-DD')
    };
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.json();
      setWater(json);
      return json;
    }
    return false;
  };

  const getEmotion = async (
    patient_id,
    since = moment().subtract(30, 'days').format('YYYY-MM-DD'),
    until = moment().format('YYYY-MM-DD'),
  ) => {
    const url = new URL(`${hostUrl}/api/patients/${patient_id}/emotion`);
    const params = {
      // patient_id: patient_id,
      date_since: since, // moment().subtract(30, 'days').format('YYYY-MM-DD'),
      date_until: until, // moment().format('YYYY-MM-DD')
    };
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.json();
      setEmotion(json);
      return json;
    }
    return false;
  };

  const getEmotionQuestion = async (patient_id) => {
    const url = new URL(`${hostUrl}/api/patients/${patient_id}/emotion_question`);
    // const params = {
    //  patient_id: patient_id
    // };
    // Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.json();
      setEmotionQuestion(json);
      return json;
    }
    return false;
  };

  const getNutrients = async (
    patient_id,
    view = view_types[0],
    since = moment().subtract(7, 'days').format('YYYY-MM-DD'),
    until = moment().format('YYYY-MM-DD'),
  ) => {
    const url = new URL(`${hostUrl}/api/patients/${patient_id}/patient_nutrients_report`);
    const params = {
      date_since: since,
      date_until: until,
      view_by: view[0],
    };
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.json();
      // json.rows.map(item => {
      // })
      setNutrients(json.rows);
      return json;
    }
    return false;
  };

  const getPatientClinics = async (patient_id) => {
    const url = new URL(`${hostUrl}/api/patients/${patient_id}/clinics`);
    // const params = {
    //   patient_id: patient_id
    // };
    // Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.json();
      setPatientClinics(json);
      return json;
    }
    return false;
  };

  const getPatientInAClinic = async (clinic_id, hospital_id) => {
    const url = new URL(`${hostUrl}/api/hospitals/${hospital_id}/clinics/${clinic_id}/patients`);
    const params = {
      clinic_id: clinic_id,
      hospital_id: hospital_id,
    };
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.json();
      setPatientInAClinic(json);
      return json;
    }
    return false;
  };

  const removePatient = async (patient_id, hospital_id, clinic_id) => {
    const url = new URL(`${hostUrl}/api/hospitals/${hospital_id}/clinics/${clinic_id}/deregister_patient`);
    const params = {
      hospital_id: hospital_id,
      clinic_id: clinic_id,
      patient_id: patient_id,
    };
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
    });

    if (response.status == 204) {
      console.log('successfully removed patient');
      return 1;
    }
    console.log('Response ' + response.status);

    return false;
  };

  const getAppointments = async (
    clinician_id,
    since = moment().format('YYYY-MM-DD'),
    until = moment().add(12, 'months').format('YYYY-MM-DD'),
  ) => {
    // const url = new URL(`${hostUrl}/api/hospital/${hospital_id}/clinics/${clinic_id}/clinician/${clinician_id}/${patient_id}/appointments`);
    // const url = new URL(`${hostUrl}/api/hospital/${hospital_id}/clinics/${clinic_id}/appointments`);
    const url = new URL(`${hostUrl}/api/clinician/${clinician_id}/appointments`);

    const params = {
      // patient_id: patient_id,
      // hospital_id: hospital_id,
      // clinic_id: clinic_id,
      clinician_id: clinician_id,
      date_since: since,
      date_until: until,
    };
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.json();
      setAppointments(json);
      return json;
    }
    return false;
  };

  const getPatientSubscriptions = async (patient_id) => {
    const url = new URL(`${hostUrl}/api/patients/${patient_id}/subscriptions?active_only=false`);
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.json();
      setPatientSubscriptions(json);
      return json;
    }
    setPatientSubscriptions([]);
    return false;
  };

  const addSubscription = async (
    hospital_id: number,
    clinic_id: number,
    patient_id: number,
    startDate: string,
    trialDays: number,
    report_date: string,
    type: string,
    multipleTrialEndDate?: string,
  ) => {
    if (trialDays != 3 && trialDays != 5 && trialDays != 7) {
      window.alert(t('Error{{colon}} Invalid Trial Days.', {
        colon: ':',
      }));
      return false;
    }
    const start_date = moment(startDate).format('YYYY-MM-DD');

    let expiration_date = null;

    if (moment().format('YYYY-MM-DD') == start_date) { // scheduled to start on current day, should expire in number of trial days plus one
      expiration_date = moment(startDate).add(trialDays + 1, 'days').format('YYYY-MM-DD');
    } else { // scheduled to start on future day, should expire the number of trial days after the start date
      expiration_date = moment(startDate).add(trialDays, 'days').format('YYYY-MM-DD');
    }

    const params: SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionRequest = {
      hospital_id: hospital_id,
      patient_id: patient_id,
      CreatePatientSubscriptionRequest: {
        name: SUBSCRIPTION_NAME_MAP[trialDays.toString()] || 'three-day',
        start_date: start_date,
        expiration_date: (type !== 'single' && multipleTrialEndDate) ? multipleTrialEndDate : expiration_date,
        type: type as CreatePatientSubscriptionRequestTypeEnum,
        report_date: (type !== 'single' && multipleTrialEndDate) ? undefined : report_date,
        clinic_id: clinic_id,
      },
    };

    try {
      const response = await subscriptionApi.appApiSubscriptionApiPostPatientSubscription(params);
      console.log('response', response);
    } catch (e) {
      console.log(e);
      window.alert(t('Error {{status}} from "{{message}}".', {
        status: e.response.data.status,
        message: e.response.data.message,
      }));
      return false;
    }
    return true;
  };

  const putSubscription = async (
    hospital_id: number,
    patient_id: number,
    subscription_id: number,
    startDate: string,
    trialDays: number,
    report_date: string,
    type: string,
    multipleTrialEndDate?: string,
  ) => {
    if (trialDays != 3 && trialDays != 5 && trialDays != 7) {
      window.alert(t('Error{{colon}} Invalid Trial Days.', { colon: ':' }));
      return false;
    }
    const start_date = moment(startDate).format('YYYY-MM-DD');

    let expiration_date = null;

    if (moment().format('YYYY-MM-DD') == start_date) { // scheduled to start on current day, should expire in number of trial days plus one
      expiration_date = moment(startDate).add(trialDays + 1, 'days').format('YYYY-MM-DD');
    } else { // scheduled to start on future day, should expire the number of trial days after the start date
      expiration_date = moment(startDate).add(trialDays, 'days').format('YYYY-MM-DD');
    }

    const params: SubscriptionApiAppApiSubscriptionApiPutPatientSubscriptionRequest = {
      patient_subscription_id: subscription_id,
      hospital_id: hospital_id,
      patient_id: patient_id,
      UpdatePatientSubscriptionRequest: {
        name: SUBSCRIPTION_NAME_MAP[trialDays.toString()] || 'three-day',
        type: type as UpdatePatientSubscriptionRequestTypeEnum,
        start_date: start_date,
        expiration_date: (type !== 'single' && multipleTrialEndDate) ? multipleTrialEndDate : expiration_date,
        report_date: (type !== 'single' && multipleTrialEndDate) ? undefined : report_date,
      },
    };
    try {
      const response = await subscriptionApi.appApiSubscriptionApiPutPatientSubscription(params);
    } catch (e) {
      console.log(e);
      window.alert(t('Error "{{status}}" from "{{message}}".', {
        status: e.response.data.status,
        message: e.response.data.message,
      }));
      return false;
    }
    return true;
  };

  const deleteSubscription = async (hospital_id, patient_id, sub_id) => {
    const url = new URL(`${hostUrl}/api/hospital/${hospital_id}/patients/${patient_id}/subscription/${sub_id}`);

    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
    });

    console.log(response);

    if (response.status == 200) {
      const json = await response.json();
      return json;
    }
    return false;
  };

  const getAllPatientReports = async (
    patient_id,
    hospital_id,
    clinic_id,
    since = moment().subtract(2, 'years').format('YYYY-MM-DD'),
    until = moment().add(2, 'years').format('YYYY-MM-DD'),
  ) => {
    try {
      const response = await reportingApi.appApiPatientReportsPatientReportGetPatientReports({
        patient_id,
        hospital_id,
        clinic_id,
        date_since: since,
        date_until: until,
      });
      const json = response.data;
      setAllPatientReports(json);
      return json;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const getPatientReportHTMLById = async (patient_report_id) => {
    const url = new URL(`${hostUrl}/api/reporting/patient_report/${patient_report_id}/report_html`);
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.text(); // was json
      return json;
    }
    return false;
  };

  const getPatientReportDataById = async (patient_id, hospital_id, clinic_id, patient_report_id) => {
    const url = new URL(
      `${hostUrl}/api/reporting/hospital/${hospital_id}/clinic/${clinic_id}/patient/${patient_id}/patient_report/${patient_report_id}/report_data`,
    );
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.json();
      return json;
    }
    return false;
  };

  const getPatientReportReportImages = async (patient_report_id) => {
    const url = new URL(`${hostUrl}/api/reporting/patient_report/${patient_report_id}/report_images`);
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.json();
      return json;
    }
    return false;
  };

  const getPatientReportReportImageById = async (patient_report_id, image_report_id) => {
    const url = new URL(
      `${hostUrl}/api/reporting/patient_report/${patient_report_id}/patient_report_image/${image_report_id}`,
    );
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'image/png',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.blob();
      return json;
    }
    return false;
  };

  const getCurrentUser = async () => {
    const response = await fetch(hostUrl + '/api/users/current', {
      method: 'GET',
    });

    if (response.status != 200) {
      const json = await response.json();
      setCurrentUser(json);
      return json;
    }
    return false;
  };

  const getMealAuditorUrl = async (patient_id, meal_id) => {
    const url = new URL(`${hostUrl}/api/patients/${patient_id}/meals/${meal_id}/auditor-url`);
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });

    if (response.status == 200) {
      const json = await response.json();
      return json;
    }
    return false;
  };

  const deleteMeal = async (patient_id, meal_id) => {
    const url = new URL(`${hostUrl}/api/patients/${patient_id}/meals/${meal_id}`);
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
    });
    if (response.status < 300) {
      return true;
    }
    return false;
  };

  const mealPhotoQueueCreateEmptyMeal = async (data_reviewer_id, patient_id, data) => {
    const url = new URL(
      `${hostUrl}/api/data_reviewer/${data_reviewer_id}/meal_photo_queue/empty_meal?patient_id=${patient_id}`,
    );
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (response.status < 300) {
      return await response.json();
    }
    return false;
  };

  const refreshMealItem = async (patient_id, meal_id, meal_item_id) => {
    const url = new URL(`${hostUrl}/api/patients/${patient_id}/meals/${meal_id}/meal_items/${meal_item_id}/refresh`);
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });

    if (response.status == 200) {
      const json = await response.json();
      return json;
    }
    return false;
  };

  const downloadMealReport = async (clinician_id, patient_id, date_since, date_until) => {
    const url = new URL(
      `${hostUrl}/api/reporting/data_reviewer/${clinician_id}/patient/${patient_id}/meal_report?date_since=${date_since}&date_until=${date_until}`,
    );
    const preResponse = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/pdf',
      }),
    });
    if (preResponse.status != 200) {
      throw new Error(`${preResponse.status}`);
    }

    const response = fetch(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/pdf',
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        const path = window.URL.createObjectURL(
          new Blob([blob]),
        );
        // eslint-disable-next-line i18next/no-literal-string
        const link = document.createElement('a');
        link.href = path;
        link.download = 'mealReport.pdf';

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const getAveragePlateData = async (patient_id, date_since, date_until) => {
    const url = new URL(
      `${hostUrl}/api/patients/${patient_id}/average_plate?date_since=${date_since}&date_until=${date_until}`,
    );
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.json();
      return json;
    }
    return false;
  };

  const getAveragePlateResults = async (patient_id) => {
    const url = new URL(`${hostUrl}/api/patients/${patient_id}/average_plate/recent`);
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const json = await response.json();
      return json;
    }
    return false;
  };

  const getDraftReportPdf = async (
    hospital_id,
    clinic_id,
    patient_id,
    comparison_report_id,
    date_since,
    date_until,
  ) => {
    let urlString =
      // eslint-disable-next-line i18next/no-literal-string
      `${hostUrl}/api/hospital/${hospital_id}/clinic/${clinic_id}/patient/${patient_id}/food_report/draft_pdf?date_since=${date_since}&date_until=${date_until}`;
    if (comparison_report_id != null) {
      urlString += `&is_comparison_report=true&comparison_report_id=${comparison_report_id}`;
    } else {
      urlString += `&is_comparison_report=false`;
    }
    const url = new URL(urlString);
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/pdf',
      }),
      method: 'GET',
    });

    if (response.status == 200) {
      const data = await response.blob();
      return URL.createObjectURL(data);
    }

    throw new Error(`${response.status}`);
  };

  // const getMealDatesSinceReport = async (patient_id) => {
  //   const url = new URL(`${hostUrl}/api/patients/${patient_id}/meals_since_last_report`);
  //   const response = await fetch(url, {
  //     headers: new Headers({
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     }),
  //     method: 'GET',
  //   });

  //   if (response.status == 200) {
  //     const json = await response.json();
  //     return json;
  //   }
  //   return false;
  // };

  const setReportSent = async (hospital_id, clinic_id, patient_id, patient_report_id) => {
    if (
      !window.confirm(
        t('Send Report')
          + ':\n'
          + t(
            'This will finalize the report and send a notification to the clinician and/or patient (if permitted). Do you wish to proceed?',
          ),
      )
    ) {
      return;
    }

    const url = new URL(
      `${hostUrl}/api/hospital/${hospital_id}/clinic/${clinic_id}/patient/${patient_id}/food_report/${patient_report_id}?status=sent`,
    );

    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
    });

    if (response.status == 200) {
      window.confirm(t('Report Sent'));
      return true;
    }
    const json = await response.json();
    window.confirm(t('Failed to Send Report') + ': ' + json.message);
    return false;
  };

  const refreshReport = async (patient_report_id) => {
    if (
      !window.confirm(
        t('Refresh Report')
          + ':\n'
          + t(
            'This will regenerate the PDF to incorporate any changes made to statement items or other report parameters. Would you like to proceed?',
          ),
      )
    ) {
      return;
    }

    const url = new URL(`${hostUrl}/api/reporting/patient_report/${patient_report_id}/refresh_pdf`);

    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });

    if (response.status == 200) {
      return true;
    }
    return false;
  };

  const deleteReport = async (hospital_id, clinic_id, patient_id, patient_report_id) => {
    if (
      !window.confirm(
        t('Delete Report') + ':\n'
          + t("This will delete the PDF from the patient's record. Would you like to proceed?"),
      )
    ) {
      return;
    }

    const url = new URL(
      `${hostUrl}/api/hospital/${hospital_id}/clinic/${clinic_id}/patient/${patient_id}/food_report/${patient_report_id}`,
    );

    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
    });

    if (response.status == 200) {
      return true;
    }
    return false;
  };

  const regenerateReport = async (patient_report_id) => {
    if (
      !window.confirm(
        t('Regenerate Report') + ':\n'
          + t('Warning, this will overwrite any changes made to the report. Do you wish to proceed?'),
      )
    ) {
      return;
    }

    const url = new URL(`${hostUrl}/api/reporting/patient_report/${patient_report_id}/regenerate`);

    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });

    if (response.status == 200) {
      window.confirm(t('Regenerate Report Complete'));
      return true;
    }
    window.confirm(t('Regenerate Report Failed'));
    return false;
  };

  return {
    getNutrients,
    getMeals,
    getWater,
    getEmotion,
    getEmotionQuestion,
    getPatientList,
    addPatient,
    getPatientReport,
    getPatientReportPdf,
    registerReport,
    postFoodReport,
    getPatientSummary,
    updatePatient,
    resendInvite,
    getHEI,
    getDash,
    getPatientClinics,
    removePatient,
    getAppointments,
    addSubscription,
    putSubscription,
    deleteSubscription,
    getAllPatientReports,
    getPatientReportHTMLById,
    getPatientReportDataById,
    getPatientReportReportImages,
    getPatientReportReportImageById,
    getPatientInAClinic,
    getCurrentUser,
    deleteMeal,
    mealPhotoQueueCreateEmptyMeal,
    refreshMealItem,
    getPatientSubscriptions,
    downloadMealReport,
    getAveragePlateData,
    getAveragePlateResults,
    getDraftReportPdf,
    getMealAuditorUrl,
    setReportSent,
    refreshReport,
    deleteReport,
    regenerateReport,
  };
}

export { PatientService };
