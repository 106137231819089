import * as d3 from 'd3';
import _ from 'lodash';
import moment from 'moment';
import React, { CSSProperties } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  MealResponse,
  PatientCgmReportMealResponse,
  PatientCgmWholeSummaryResponseGlucosePeriodStatsInner,
  PatientGlucoseDataResponse,
  UsdaNutritionResponse,
} from '../../api/generated';
import { Trans, useTranslation } from '../../i18n';
import { ReportSection } from '..';
import { AuthenticatedImage } from './AuthenticatedImage';
import { CGMGlucoseChart } from './CGMGlucoseChart';
import { CGMGlucoseTimeInRange } from './CGMGlucoseTimeInRange';
import styles from './CGMMealSummary.module.scss';
import { CGMSmallGlucoseTimeseries } from './CGMSmallGlucoseTimeseries';
import { dateTimeToTimestampMS, timeStrToAmPm, titleCase } from './common';
import { NutrientBreakdownBar } from './NutrientBreakdownBar';

function mealCalculateNutrientBreakdown(meal: MealResponse) {
  const KCAL_PER_G_FAT = 9;
  const KCAL_PER_G_FIBRE = 2;
  const KCAL_PER_G_CARB = 4;
  const KCAL_PER_G_PROTEIN = 4;

  const fatKcal = d3.sum(meal.meal_items.map(mi => (mi.nutrition_response.fat_g ?? 0) * KCAL_PER_G_FAT));
  // const fibreKcal = d3.sum(meal.meal_items.map(mi => (mi.nutrition_response.fiber_g ?? 0) * KCAL_PER_G_FIBRE))
  const carbKcal = d3.sum(meal.meal_items.map(mi => (mi.nutrition_response.carbohydrate_g ?? 0) * KCAL_PER_G_CARB));
  const proteinKcal = d3.sum(meal.meal_items.map(mi => (mi.nutrition_response.protein_g ?? 0) * KCAL_PER_G_PROTEIN));
  // const totalKcal = fatKcal + fibreKcal + carbKcal + proteinKcal
  const totalKcal = fatKcal + carbKcal + proteinKcal;

  return {
    carbohydrate_pct_of_total_kcal: carbKcal / totalKcal * 100,
    // fibre_pct_of_total_kcal: fibreKcal / totalKcal * 100,
    protein_pct_of_total_kcal: proteinKcal / totalKcal * 100,
    fat_pct_of_total_kcal: fatKcal / totalKcal * 100,
  };
}

function mealsCalculateAvgMacros(meals: MealResponse[]) {
  /* eslint-disable i18next/no-literal-string */
  const macros: Array<keyof UsdaNutritionResponse> = [
    'carbohydrate_g',
    'fiber_g',
    'protein_g',
    'fat_g',
    'energy_kcal',
  ];
  return Object.fromEntries(macros.map(m => {
    return [
      m,
      d3.mean(meals.map(meal => d3.sum(meal.meal_items.map(mi => mi.nutrition_response[m])))),
    ];
  }));
}

const mergeGlucose = (
  glucose1: PatientGlucoseDataResponse[],
  glucose2: PatientGlucoseDataResponse[],
) => {
  const res = [...glucose1, ...glucose2];
  return _(res).sortBy('timestamp').sortedUniqBy('timestamp').value();
};

const mergeMeals = (meals: PatientCgmReportMealResponse[]) => {
  const timeWindowMins = 90;
  const res = meals.reduce((acc, meal) => {
    if (acc.length == 0) {
      return [{
        ...meal,
        mealTimesMS: [dateTimeToTimestampMS(meal.meal.meal_date, meal.meal.meal_time)],
        mealItemTimesHHMMSS: Object.fromEntries(meal.meal.meal_items.map(mi => [mi.id, meal.meal.meal_time])),
        images: [{
          src: meal.meal.meal_photo_resized_url,
          carbs: d3.sum(meal.meal.meal_items.map(mi => mi.nutrition_response.carbohydrate_g)),
        }],
      }];
    }

    const lastMeal = acc[acc.length - 1];
    const lastMealTime = dateTimeToTimestampMS(lastMeal.meal.meal_date, lastMeal.meal.meal_time);
    const mealTime = dateTimeToTimestampMS(meal.meal.meal_date, meal.meal.meal_time);
    const timeDiffMins = (mealTime - lastMealTime) / 1000 / 60;
    if (timeDiffMins > timeWindowMins) {
      return [...acc, {
        ...meal,
        mealTimesMS: [dateTimeToTimestampMS(meal.meal.meal_date, meal.meal.meal_time)],
        mealItemTimesHHMMSS: Object.fromEntries(meal.meal.meal_items.map(mi => [mi.id, meal.meal.meal_time])),
        images: [{
          carbs: d3.sum(meal.meal.meal_items.map(mi => mi.nutrition_response.carbohydrate_g)),
          src: meal.meal.meal_photo_resized_url,
        }],
      }];
    }

    const mergedMeal = {
      meal: {
        ...lastMeal.meal,
        meal_items: [...lastMeal.meal.meal_items, ...meal.meal.meal_items],
      },
      mealTimesMS: [...lastMeal.mealTimesMS, dateTimeToTimestampMS(meal.meal.meal_date, meal.meal.meal_time)],
      mealItemTimesHHMMSS: {
        ...lastMeal.mealItemTimesHHMMSS,
        ...Object.fromEntries(meal.meal.meal_items.map(mi => [mi.id, meal.meal.meal_time])),
      },
      glucose: mergeGlucose(lastMeal.glucose, meal.glucose),
      images: [
        ...lastMeal.images,
        {
          carbs: d3.sum(meal.meal.meal_items.map(mi => mi.nutrition_response.carbohydrate_g)),
          src: meal.meal.meal_photo_resized_url,
        },
      ],
    };
    return [...acc.slice(0, acc.length - 1), mergedMeal];
  }, [] as Array<
    PatientCgmReportMealResponse & {
      mealTimesMS: number[],
      mealItemTimesHHMMSS: Record<number, string>,
      images: Array<{
        carbs: number,
        src: string,
      }>,
    }
  >);

  res.forEach(meal => {
    const images = _(meal.images)
      .filter(img => !!img.src)
      .sortBy('carbs')
      .reverse()
      .value();
    if (images.length > 0) {
      meal.images = images.slice(0, 4);
      return;
    }
    meal.images = _(meal.meal.meal_items)
      .map(mi => ({
        carbs: mi.nutrition_response.carbohydrate_g,
        src: mi.food_photo_url,
      }))
      .flatten()
      .filter(img => !!img.src)
      .uniqBy('image')
      .sortBy('carbs')
      .reverse()
      .slice(0, 4)
      .value();
  });

  return res;
};

const cssLimitLines = (nLines: number): CSSProperties => {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: nLines,
    WebkitBoxOrient: 'vertical',
  };
};

const timeHHMMSSToSeconds = (time: string) => {
  const [hh, mm, ss] = time.split(':').map(s => parseInt(s));
  return hh * 60 * 60 + mm * 60 + ss;
};

export const MealSummarySection = (p: {
  allMeals: PatientCgmReportMealResponse[],
  glucosePeriodStats: Array<PatientCgmWholeSummaryResponseGlucosePeriodStatsInner>,
}) => {
  const { allMeals } = p;
  const { t } = useTranslation();
  return (
    <ReportSection>
      <header>
        <h1 style={{ marginBottom: 40 }}>
          <Trans>Meal Summaries</Trans>
        </h1>
      </header>

      {[t('breakfast'), t('lunch'), t('dinner'), t('snack')].map(mealName => {
        const meals = mergeMeals(allMeals.filter(m => m.meal.meal_name === mealName));
        return (
          <div key={mealName} style={{ pageBreakInside: 'avoid' }}>
            <h2 style={{ margin: 0, marginBottom: 5 }}>{titleCase(mealName)}</h2>
            <MealSummaryOverview meals={meals} glucosePeriodStats={p.glucosePeriodStats} />
          </div>
        );
      })}
    </ReportSection>
  );
};

export const CGMMealSummary = (p: {
  mealName: 'breakfast' | 'lunch' | 'dinner' | 'snack',
  allMeals: PatientCgmReportMealResponse[],
  glucosePeriodStats: Array<PatientCgmWholeSummaryResponseGlucosePeriodStatsInner>,
}) => {
  const { mealName, allMeals } = p;
  const meals = mergeMeals(allMeals.filter(m => m.meal.meal_name === mealName));

  if (meals.length == 0) {
    return null;
  }

  return (
    <ReportSection style={{ paddingTop: 0, paddingBottom: 0 }}>
      {
        /*
    <header>
      <h1 style={{ marginBottom: 40 }}>
        {titleCase(mealName)} Summary
      </h1>
    </header>
    */
      }

      {/*<MealSummaryOverview meals={meals} glucosePeriodStats={p.glucosePeriodStats} />*/}

      {meals.map((mealGlucose, idx) => <MealSummaryRow key={idx} {...mealGlucose} />)}
    </ReportSection>
  );
};

const MealSummaryOverview = (p: {
  meals: ReturnType<typeof mergeMeals>,
  glucosePeriodStats: Array<PatientCgmWholeSummaryResponseGlucosePeriodStatsInner>,
}) => {
  const { meals } = p;
  const { t } = useTranslation();
  const mealAvgMacros = mealsCalculateAvgMacros(meals.map(m => m.meal));
  const [mealMinTime, mealMaxTime] = d3.extent(meals.map(m => timeHHMMSSToSeconds(m.meal.meal_time)));

  return (
    <Row>
      <Col>
        <CGMGlucoseChart
          height={141}
          width={500}
          responsive={false}
          unit="mmol/L"
          data={p.glucosePeriodStats
            .filter(g => {
              const pointTime = timeHHMMSSToSeconds(g.time_of_day);
              return (
                pointTime >= (mealMinTime - 60 * 30)
                && pointTime <= (mealMaxTime + 60 * 60 * 3)
              );
            })
            .map(g => ({
              ...g,
              value: g.avg,
              xTimestampMS: new Date('2000-01-01T' + g.time_of_day).getTime(),
            }))}
          xTickFormat={ms => moment(ms).format('ha')}
          xTickFrequencyHours={1}
          yAxisLabel={t('mmol/L')}
          tickFontSize={12}
          hideHorizontalGridLines
        />
      </Col>

      <Col>
        <table className={styles.MealOptionsSummaryOverviewTable}>
          <thead>
            <tr>
              <th colSpan={2}>Average Macros</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Carbs</strong>
              </td>
              <td>{mealAvgMacros.carbohydrate_g?.toFixed(1)}g</td>
            </tr>
            <tr>
              <td>
                <strong>Fiber</strong>
              </td>
              <td>{mealAvgMacros.fiber_g?.toFixed(1)}g</td>
            </tr>
            <tr>
              <td>
                <strong>Protein</strong>
              </td>
              <td>{mealAvgMacros.protein_g?.toFixed(1)}g</td>
            </tr>
            <tr>
              <td>
                <strong>Fat</strong>
              </td>
              <td>{mealAvgMacros.fat_g?.toFixed(1)}g</td>
            </tr>
          </tbody>
        </table>
      </Col>

      <Col>
        <CGMGlucoseTimeInRange data={_.flatten(meals.map(m => m.glucose))} />
      </Col>
    </Row>
  );
};

const MealSummaryRow = (p: {
  meal?: MealResponse,
  glucose?: PatientGlucoseDataResponse[],
  mealTimesMS?: number[],
  mealItemTimesHHMMSS?: Record<number, string>,
  images: Array<{
    src: string,
  }>,
}) => {
  const { meal, glucose, images } = p;
  const imgContainerElem = React.useRef<HTMLElement>(null);

  const refreshHiddenImages = () => {
    if (!imgContainerElem.current) {
      return;
    }
    const imgs = imgContainerElem.current.querySelectorAll('img');
    imgs.forEach(img => img.style.display = 'inline-block');
    imgs.forEach(img => {
      if (img.offsetTop > 0) {
        img.style.display = 'none';
      }
    });
  };

  const handleMealImageLoad = () => {
    refreshHiddenImages();
  };

  if (!meal) {
    return null;
  }

  if (!glucose?.length || glucose.length < 15) {
    return null;
  }

  const nutrientBreakdown = mealCalculateNutrientBreakdown(meal);
  const mealItemsSorted = _
    .sortBy(meal.meal_items || [], mi => mi.nutrition_response.carbohydrate_g)
    .reverse();

  const numItems = 6;
  const mealItemsToDisplay = mealItemsSorted.slice(0, numItems);
  const mealItemsOther = mealItemsSorted.slice(numItems);
  if (mealItemsOther.length == 1) {
    mealItemsToDisplay.push(mealItemsOther[0]);
    mealItemsOther.splice(0, 1);
  }

  return (
    <div key={meal.id} style={{ pageBreakInside: 'avoid', paddingTop: 16 }}>
      <h2 style={{ marginTop: 0, fontSize: 14 }}>
        {titleCase(meal.meal_name)}: {meal.meal_date} - {timeStrToAmPm(meal.meal_time)}
      </h2>
      <Row>
        <Col style={{ paddingRight: 0 }}>
          <CGMSmallGlucoseTimeseries
            data={glucose}
            mealTimesMS={p.mealTimesMS}
          />
          <NutrientBreakdownBar food={nutrientBreakdown} />
        </Col>

        <Col style={{ flex: 1.25, paddingRight: 0 }}>
          <table className={styles.MealSummaryRowTable}>
            <thead>
              <tr>
                <th>Time</th>
                <th style={{ width: '100%' }}>Food</th>
                <th>Carbs</th>
                <th>Fibre</th>
                <th>Protein</th>
                <th>Fat</th>
              </tr>
            </thead>
            <tbody>
              {mealItemsToDisplay.map((mealItem, idx) => (
                <tr key={idx}>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {moment(p.mealItemTimesHHMMSS[mealItem.id], 'HH:mm:ss').format('h:mma')}
                  </td>
                  <td>
                    <div style={cssLimitLines(1)}>{mealItem.food_name}</div>
                  </td>
                  <td>{mealItem.carbohydrate_g?.toFixed(1)}g</td>
                  <td>{mealItem.nutrition_response.fiber_g?.toFixed(1)}g</td>
                  <td>{mealItem.nutrition_response.protein_g?.toFixed(1)}g</td>
                  <td>{mealItem.nutrition_response.fat_g?.toFixed(1)}g</td>
                </tr>
              ))}
              {mealItemsOther.length > 1 && (
                <tr>
                  <td />
                  <td>+{mealItemsOther.length} more</td>
                  <td>{d3.sum(mealItemsOther.map(mi => mi.carbohydrate_g || 0)).toFixed(1)}g</td>
                  <td>{d3.sum(mealItemsOther.map(mi => mi.nutrition_response.fiber_g || 0)).toFixed(1)}g</td>
                  <td>{d3.sum(mealItemsOther.map(mi => mi.nutrition_response.protein_g || 0)).toFixed(1)}g</td>
                  <td>{d3.sum(mealItemsOther.map(mi => mi.nutrition_response.fat_g || 0)).toFixed(1)}g</td>
                </tr>
              )}
              <tr>
                <td />
                <td>
                  <strong>Total</strong>
                </td>
                <td>
                  <strong>{d3.sum(meal.meal_items.map(mi => mi.carbohydrate_g || 0)).toFixed(1)}g</strong>
                </td>
                <td>
                  <strong>{d3.sum(meal.meal_items.map(mi => mi.nutrition_response.fiber_g || 0)).toFixed(1)}g</strong>
                </td>
                <td>
                  <strong>{d3.sum(meal.meal_items.map(mi => mi.nutrition_response.protein_g || 0)).toFixed(1)}g</strong>
                </td>
                <td>
                  <strong>{d3.sum(meal.meal_items.map(mi => mi.nutrition_response.fat_g || 0)).toFixed(1)}g</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>

        <Col
          ref={imgContainerElem as any}
          style={{ maxHeight: 350, maxWidth: 272, overflow: 'hidden', paddingRight: 0 }}
        >
          {images.map((img, idx) => (
            <AuthenticatedImage
              key={idx}
              title={img && img.src}
              src={img && img.src}
              style={{
                maxWidth: 350,
                maxHeight: 112,
              }}
              onLoad={handleMealImageLoad}
            />
          ))}
        </Col>
      </Row>
    </div>
  );
};
