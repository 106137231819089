import DateFnsUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { DateTime, Duration } from 'luxon';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useAsyncResult } from 'react-use-async-result';
import { patientApi, subscriptionApi } from '../api';
import { ClinicResponse, PatientSubscriptionResponse } from '../api/generated';
import { useStore } from '../context';
import { Trans, useTranslation } from '../i18n';
import { PatientService } from '../services/patient';
import { formatDate } from '../utils/formatDate';
import { getSubscriptionTypeOptions } from '../utils/userDataTranslations';
import { validate } from './formValidator';
import { MuiCustomDatePicker, MuiCustomTextField } from './muiCustomInput';

export const disablePastDates = (date: moment.Moment) => {
  return date < moment().subtract(1, 'days');
};

export const getDiffByTrialType = (startDate: string, endDate: string, trialType: string) => {
  if (trialType === 'monthly') {
    return DateTime.fromISO(endDate)
      .diff(DateTime.fromISO(startDate), 'months')
      .toObject().months;
  }
  const diff = DateTime.fromISO(endDate).diff(DateTime.fromISO(startDate), 'weeks').toObject().weeks;
  return trialType === '4-weeks'
    ? diff / 4
    : trialType === 'bi-weekly'
    ? diff / 2
    : trialType === 'weekly'
    ? diff
    : 0;
};

export const getWeeksToAdd = (trialType: string) => {
  return trialType === '4-weeks' ? 4 : trialType === 'bi-weekly' ? 2 : trialType === 'weekly' ? 1 : 0;
};

export const disableDatesNotInMultiple = (date: moment.Moment, startDate: string, trialType: string) => {
  const dateISO = date && DateTime.fromJSDate(date.clone().toDate()).toISODate();

  const diff = getDiffByTrialType(startDate, dateISO, trialType);
  const isInvalidDiff = diff % 1 !== 0;

  const isMoreThan6Months = DateTime.fromISO(startDate).plus({ months: 6 }).toISO() < dateISO;

  return date < moment(startDate) || isInvalidDiff || isMoreThan6Months;
};

export const SendLoggingTrial = (props: {
  show: boolean,
  toggle: () => void,
  subscriptionId?: { current: number },
  customize?: React.MutableRefObject<boolean>,
  isEdit?: boolean,
  trackingStartDate?: { current: moment.Moment },
}) => {
  const { toggle, show, subscriptionId, customize, trackingStartDate } = props;
  const { clinician, patient } = useStore();
  const { t, i18n } = useTranslation();
  const patientService = PatientService();
  const [startDate, setStartDate] = useState<string>(
    (trackingStartDate && trackingStartDate.current)
      ? trackingStartDate.current.clone().format('YYYY-MM-DD')
      : moment().add(1, 'days').format('YYYY-MM-DD'),
  );
  const [startDateError, setStartDateError] = useState('');
  const [trialDays, setTrialDays] = useState(3);
  const [requestReport, setRequestReport] = useState(true);
  const [reportDate, setReportDate] = useState<string>(moment().add(3, 'months').format('YYYY-MM-DD'));
  const [reportDateError, setReportDateError] = useState('');
  // eslint-disable-next-line i18next/no-literal-string
  const [trialType, setTrialType] = useState('');
  const [multipleTrialEndDate, setMultipleTrialEndDate] = useState<string>('');

  const queryClient = useQueryClient();

  const patientClinicsQuery = useQuery(['patient-clinics', patient?.patient_id], async () => {
    const res = await patientApi.appApiPatientGetClinics({ patient_id: patient.patient_id });
    return res.data || [];
  });

  const subscriptionQuery = useQuery(['patient-subscriptions', patient.patient_id], async () => {
    const res = await subscriptionApi.appApiSubscriptionApiGetPatientSubscriptions({ patient_id: patient.patient_id });
    return res.data || [];
  });
  const patientSubscriptions = subscriptionQuery.data || [];

  const sharedClinics = clinician?.clinics?.filter(cc => patientClinicsQuery?.data?.find(pc => pc.id === cc.id));
  const [selectedClinic, setSelectedClinic] = useState(sharedClinics[0]?.name);
  useEffect(() => {
    setSelectedClinic(sharedClinics[0]?.name);
  }, [sharedClinics]);
  const selectedClinicObj = sharedClinics?.find(clinic => clinic.name === selectedClinic);

  const trialTypeOptions = selectedClinicObj?.flags?.clinic_subscription_types?.split(',').map(type => type.trim())
    || ['single'];
  useEffect(() => {
    if (trialTypeOptions) {
      setTrialType(trialTypeOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClinicObj?.flags?.clinic_subscription_types]);

  const singleTrialEndDate = React.useMemo(() => {
    if (!startDate) {
      return null;
    }
    return moment(startDate).add(trialDays - 1, 'days');
  }, [startDate, trialDays]);

  useEffect(() => {
    const flagStr = selectedClinicObj?.flags?.clinic_default_days_until_report_date;
    const date = flagStr ? singleTrialEndDate?.clone().add(Number(flagStr), 'days') : moment().add(3, 'months');

    setReportDate(date?.clone().format('YYYY-MM-DD'));
  }, [singleTrialEndDate, selectedClinicObj?.flags?.clinic_default_days_until_report_date]);

  const validator = {
    startDate: () => {
      if (!startDate) {
        setStartDateError(t('Please select a start date for the trial'));
        return false;
      }
      setStartDateError('');
      return true;
    },
    reportDate: () => {
      if (requestReport && !reportDate) {
        setReportDateError(t('Please select a date to receive the report'));
        return false;
      }
      setReportDateError('');
      return true;
    },
  };

  const handleStartDateChange = (e: moment.Moment) => {
    setStartDate(e?.clone().format('YYYY-MM-DD'));
    // eslint-disable-next-line i18next/no-literal-string
    // const DATE_RFC2822 = 'ddd, DD MMM YYYY HH:mm:ss ZZ';
  };

  React.useEffect(() => {
    if (trialType === 'single') {
      return;
    }
    if (trialType === 'monthly') {
      setMultipleTrialEndDate(moment(startDate).add(1, 'month').format('YYYY-MM-DD'));
      return;
    }
    setMultipleTrialEndDate(moment(startDate).add(getWeeksToAdd(trialType), 'weeks').format('YYYY-MM-DD'));
  }, [trialType, startDate]);

  const handleMultipleTrialEndDateChange = (e: moment.Moment) => {
    setMultipleTrialEndDate(e?.clone().format('YYYY-MM-DD'));
  };

  const futureStartDates = React.useMemo(() => {
    if (trialType === 'single') {
      return [];
    } else if (trialType === 'monthly') {
      const diff = getDiffByTrialType(startDate, multipleTrialEndDate, trialType);
      return Array.from({ length: diff }, (_, i) => {
        return DateTime.fromISO(startDate)
          .plus({ months: i })
          .toISODate();
      });
    }
    const diff = getDiffByTrialType(startDate, multipleTrialEndDate, trialType);
    return Array.from({ length: diff }, (_, i) => {
      return DateTime.fromISO(startDate)
        .plus({ weeks: i * getWeeksToAdd(trialType) })
        .toISODate();
    });
  }, [trialType, startDate, multipleTrialEndDate]);

  const handleReportDateChange = (e: moment.Moment) => {
    setReportDate(e?.clone().format('YYYY-MM-DD'));
  };

  const reset = () => {
    setStartDate(moment().add(1, 'days').format('YYYY-MM-DD'));
    setStartDateError('');
    setReportDateError('');
    setTrialType('');
    setMultipleTrialEndDate('');
  };

  const handleDeleteSub = async (subId) => {
    const subToDel = patientSubscriptions.find(s => s.id == subId);
    const response = await patientService.deleteSubscription(subToDel.hospital_id, patient.patient_id, subId);
    await patientService.getPatientSubscriptions(patient.patient_id);
    if (response) {
      reset();
    }
  };
  const bindData = (subscriptionId) => {
    const subscription = patientSubscriptions.find(s => s.id == subscriptionId.current);
    setStartDate(subscription?.start_date);
    setReportDate(subscription?.report_date);
    setTrialDays(subscription?.subscription_length);
    setRequestReport(!(subscription?.report_date === null));
  };
  useEffect(() => {
    if (props.isEdit) {
      bindData(subscriptionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId]);

  const isExistingLoggingTrial = !props.isEdit
    && (patientSubscriptions?.some(sub => sub.start_date == startDate && sub.subscription_length == trialDays));

  const handleScheduleTrial = () => {
    if (!validate(validator.startDate, validator.reportDate)) {
      return;
    }
    if (isExistingLoggingTrial) {
      return;
    }
    if (selectedClinicObj) {
      scheduleTrial(selectedClinicObj);
    } else {
      console.log('No clinic found');
    }
  };

  const scheduleTrial = async (clinic: ClinicResponse) => {
    if (customize && customize.current == true || props.isEdit) {
      // eslint-disable-next-line i18next/no-literal-string
      const report_date = requestReport ? moment(reportDate).format('YYYY-MM-DD') : null;
      const res = await patientService.putSubscription(
        clinic?.hospital_id || 0,
        patient.patient_id,
        subscriptionId.current,
        startDate,
        trialDays,
        report_date,
        trialType,
        multipleTrialEndDate,
      );
      if (res) {
        queryClient.invalidateQueries({ queryKey: ['patient-subscriptions'] });
        reset();
        toggle();
      }
      return;
    }

    const report_date = requestReport ? moment(reportDate).format('YYYY-MM-DD') : null;
    const res = await patientService.addSubscription(
      clinic?.hospital_id || 0,
      clinic?.id || 0,
      patient.patient_id,
      startDate,
      trialDays,
      report_date,
      trialType,
      multipleTrialEndDate,
    );
    if (res) {
      queryClient.invalidateQueries({ queryKey: ['patient-subscriptions'] });
      reset();
      toggle();
    }
  };

  function disableInvalidReportDates(date: moment.Moment) {
    const offset = clinician?.flags?.clinician_report_24h_turnaround ? 1 : 2;
    return date < moment(startDate).add((Number(trialDays)) + offset, 'days');
  }

  const availableTrialLengths = clinician?.flags?.clinician_available_trial_lengths || '3,5';
  const trialList = availableTrialLengths.split(',').map(bit => bit.trim());
  useEffect(() => {
    if (clinician?.flags?.clinician_available_trial_lengths) {
      setTrialDays(Number(trialList[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinician?.flags?.clinician_available_trial_lengths]);

  return (
    <MDBContainer>
      <MDBModal backdrop={false} isOpen={show} toggle={toggle} centered size="lg">
        <MDBModalHeader toggle={toggle}>
          {(customize && customize.current)
            ? t('Customize Logging Trial for New Patient')
            : props.isEdit
            ? t('Edit Logging Trial')
            : t('Invite to Logging Trial')}
        </MDBModalHeader>
        <MDBModalBody>
          <Container>
            <Row>
              <Col
                className="paddedText"
                style={{ paddingBottom: '0.5rem', width: '30%', marginBottom: '20px' }}
              >
                <div className="mb-4" style={{ fontSize: '13px' }}>
                  <Trans>
                    A logging period (also known as logging trial) indicates a period of time where the patient can log
                    meals to be captured in their nutrition report. On the selected start date, RxFood will send the
                    patient an email reminding them to start logging for the selected number of days before the report
                    gets developed. This can be used to track intake for follow-up visits, or to reactivate expired
                    logging periods.
                  </Trans>
                </div>

                {sharedClinics?.length > 1 && (
                  <>
                    <div className="mb-2">{t('Clinic:')}</div>
                    <Form.Control
                      as="select"
                      className="w-30"
                      style={{ width: '450px' }}
                      value={selectedClinic}
                      onChange={(e) => setSelectedClinic(e.currentTarget.value)}
                    >
                      {sharedClinics?.map((clinic) => (
                        <option value={clinic.name} key={clinic.id}>{clinic.name}</option>
                      ))}
                    </Form.Control>
                  </>
                )}

                {trialTypeOptions?.length > 0 && (
                  <>
                    <div className="mb-2 mt-3">{t('Trial type:')}</div>
                    <Form.Control
                      as="select"
                      className="w-30"
                      style={{ width: '200px' }}
                      value={trialType}
                      onChange={(e) => setTrialType(e.currentTarget.value)}
                    >
                      {trialTypeOptions.map((type, i) => (
                        <option value={type} key={i}>
                          {getSubscriptionTypeOptions(t).find(option => option.id === type)?.labelTr}
                        </option>
                      ))}
                    </Form.Control>
                  </>
                )}

                <div className="mb-2 mt-3">
                  {(customize && customize.current)
                    ? t('Number of days new user should track for:')
                    : t('Number of days {{first_name}} should track for', { first_name: patient.first_name }) + ':'}
                </div>
                <Form.Control
                  as="select"
                  className="w-30"
                  value={trialDays.toString()}
                  style={{ width: '200px' }}
                  onChange={(evt) => {
                    let trial_days = Number(evt.currentTarget.value);
                    if (isNaN(trial_days)) {
                      trial_days = 0;
                    }
                    setTrialDays(trial_days);
                  }}
                >
                  {trialList.map((trialOption, i) => {
                    return <option value={trialOption} key={i}>{trialOption} {t('day trial')}</option>;
                  })}
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={i18n.language}
                >
                  <DatePicker
                    inputVariant="outlined"
                    disableToolbar
                    format="YYYY-MM-DD"
                    label={t('Start Date')}
                    variant="inline"
                    shouldDisableDate={disablePastDates}
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </MuiPickersUtilsProvider>
                <p className="errorMessage errorLabel">{startDateError}</p>
              </Col>
              <Col>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language}>
                  {trialType === 'single'
                    ? (
                      <DatePicker
                        inputVariant="outlined"
                        onChange={() => {}}
                        disableToolbar
                        disabled
                        format="YYYY-MM-DD"
                        label={t('Estimated End Date')}
                        variant="inline"
                        value={singleTrialEndDate}
                      />
                    )
                    : (
                      <DatePicker
                        inputVariant="outlined"
                        onChange={handleMultipleTrialEndDateChange}
                        disableToolbar
                        format="YYYY-MM-DD"
                        label={t('End Date')}
                        variant="inline"
                        value={multipleTrialEndDate}
                        shouldDisableDate={(d) => disableDatesNotInMultiple(d, startDate, trialType)}
                      />
                    )}
                </MuiPickersUtilsProvider>
              </Col>
            </Row>
            {trialType === 'single' && (
              <Row>
                <Col className="padded-text verticalCenter mt-4">
                  <Trans>Report Delivery Date:</Trans>
                  <div style={{ fontSize: '13px', marginBottom: '15px' }}>
                    <Trans>
                      This is the date by which you need to receive the report. Adjust this so that it is on or before
                      the patient's next appointment date. If you don't know the next appointment date, we suggest a
                      date two weeks the future. Reports will be generated if at least 2 days of logging have been
                      completed (at least 2 meals per day) and it is 48 hours prior to the report date.
                    </Trans>
                  </div>
                  {requestReport && (
                    <>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language}>
                        <DatePicker
                          inputVariant="outlined"
                          disableToolbar
                          format="YYYY-MM-DD"
                          label={t('Report Date')}
                          variant="inline"
                          shouldDisableDate={disableInvalidReportDates}
                          value={reportDate}
                          onChange={handleReportDateChange}
                        />
                      </MuiPickersUtilsProvider>
                      <p className="errorMessage errorLabel">{reportDateError}</p>
                    </>
                  )}
                </Col>
                <Col>
                  <br />
                  <br />
                  <Form.Check
                    type="checkbox"
                    label={t('No Report')}
                    id="enabled-default"
                    checked={!requestReport}
                    onChange={() => {
                      setRequestReport(!requestReport);
                    }}
                  />
                </Col>
              </Row>
            )}
            {(startDate && trialType === 'single')
              ? (
                <Row>
                  <Col>
                    <div className="mt-3">
                      {(customize && customize.current) ? t('The user') : patient.first_name}{' '}
                      {t('will complete a {{TrialDays}} day food assessment from {{startDate}} to {{endDate}}.', {
                        TrialDays: trialDays,
                        startDate: moment(startDate).format('dddd, MMMM DD'),
                        endDate: moment(startDate).add(trialDays - 1, 'days').format('dddd, MMMM DD'),
                      })}
                      {requestReport
                        ? (
                          <label>
                            {t('Your report will be delivered around {{reportDate}}, before 8 AM.', {
                              reportDate: moment(reportDate).format('dddd, MMMM DD, YYYY'),
                            })}
                          </label>
                        )
                        : (
                          <label>
                            <Trans>No report will be created for this patient's trial.</Trans>
                          </label>
                        )}
                    </div>
                    <br />
                  </Col>
                </Row>
              )
              : (
                <>
                  <Row>
                    <Col>
                      <div className="mt-3 mb-1">
                        {t('All Start Dates:')}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {futureStartDates.map((date, i) => <div key={i}>{date}</div>)}
                    </Col>
                  </Row>
                </>
              )}
            <Row>
              <Col style={{ marginTop: '-20px' }}>
                <div className="margin-l">
                  {isExistingLoggingTrial && (
                    <p className="errorMessage errorLabel" style={{ fontSize: '13px' }}>
                      {t('A logging trial with the given start date and length already exists.')}
                    </p>
                  )}
                  {!props.isEdit
                    ? (
                      <Button block onClick={handleScheduleTrial}>
                        <Trans>Schedule Logging Trial</Trans>
                      </Button>
                    )
                    : (
                      <Button block onClick={handleScheduleTrial}>
                        <Trans>Save Logging Trial Changes</Trans>
                      </Button>
                    )}
                </div>
              </Col>
            </Row>
            {!(customize && customize.current || props.isEdit) && (
              <Row>
                <Col>
                  <h6 className="mb-2">
                    <Trans>Future Logging Trials</Trans>
                  </h6>
                  {(patientSubscriptions && patientSubscriptions.length > 0) && patientSubscriptions.map(sub => {
                    if (moment(sub.start_date).valueOf() > moment().valueOf()) {
                      return (
                        <div key={sub.id}>
                          {moment(sub.start_date).format('MMM DD/YYYY')}
                          <span
                            className="goalTypeButton"
                            onClick={() => {
                              if (window.confirm(t('Are you sure you wish to delete this trial?'))) {
                                handleDeleteSub(sub.id);
                              }
                            }}
                          >
                            <i className="fas fa-times-circle" />
                          </span>
                        </div>
                      );
                    }
                  })}
                </Col>
              </Row>
            )}
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

const getNextValidStartDate = (subDate: string, multiplier: number, cadence: 'weeks' | 'months'): string => {
  const subscriptionDate = moment(subDate);
  const currentDate = moment();
  if (subscriptionDate.isBefore(currentDate)) {
    const diff = currentDate.diff(subscriptionDate, cadence);
    const newDate = subscriptionDate.add(diff, cadence);
    return formatDate(newDate.add(multiplier, cadence));
  }
  return formatDate(subscriptionDate.add(multiplier, cadence));
};

const getEndDateByStartDate = (newStartDate: string, targetSubscription: PatientSubscriptionResponse): string => {
  // eslint-disable-next-line i18next/no-literal-string
  return formatDate(moment(newStartDate).add(targetSubscription?.subscription_length, 'days'));
};

const getReportDateByStartDate = (newStartDate: string, targetSubscription: PatientSubscriptionResponse): string => {
  const daysUntilReportDate =
    // eslint-disable-next-line i18next/no-literal-string
    moment(targetSubscription?.report_date).diff(moment(targetSubscription?.start_date), 'days');
  // eslint-disable-next-line i18next/no-literal-string
  return formatDate(moment(newStartDate).add(daysUntilReportDate, 'days'));
};

export const ScheduleRepeatLoggingTrials = (props: {
  show: boolean,
  toggle: () => void,
  targetSubscriptonId: number,
  subscriptions: PatientSubscriptionResponse[],
  handleDeleteSubscription: (subId: number) => void,
}) => {
  const { t, i18n } = useTranslation();
  const { clinician } = useStore();
  const patientService = PatientService();
  const queryClient = useQueryClient();
  const targetSubscription = props.subscriptions?.find(sub => sub.id === props.targetSubscriptonId);

  const repeatCadence = [t('Weekly'), t('Every other week'), t('Monthly'), t('Every other month')];
  const [repeatCadenceVal, setRepeatCadenceVal] = useState(repeatCadence[0]);
  const isWeeksCadence = repeatCadenceVal === repeatCadence[0] || repeatCadenceVal === repeatCadence[1];

  const [repeatTimes, setRepeatTimes] = useState<number | string>('');
  const [loggingTrials, setLoggingTrials] = useState<{ startDate: string, endDate: string, reportDate: string }[]>([]);

  useEffect(() => {
    if (props.show) {
      setRepeatCadenceVal(repeatCadence[0]);
      setRepeatTimes('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    const loggingTrials = _.times(Number(repeatTimes), (i) => {
      // eslint-disable-next-line i18next/no-literal-string
      const cadence = isWeeksCadence ? 'weeks' : 'months';
      const multiplier = (i + 1)
        * (repeatCadenceVal === repeatCadence[0] || repeatCadenceVal === repeatCadence[2] ? 1 : 2);
      const newStartDate = getNextValidStartDate(targetSubscription?.start_date, multiplier, cadence);
      return {
        startDate: newStartDate,
        endDate: getEndDateByStartDate(newStartDate, targetSubscription),
        reportDate: getReportDateByStartDate(newStartDate, targetSubscription),
      };
    });
    setLoggingTrials(loggingTrials);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repeatCadenceVal, targetSubscription, repeatTimes]);

  const handleTrialStartDateChange = (newStartDate: moment.Moment, index: number) => {
    const newStartDateISO = formatDate(newStartDate);
    const newTrials = [...loggingTrials];
    const updatedTrial = newTrials[index];
    updatedTrial.startDate = newStartDateISO;
    updatedTrial.endDate = getEndDateByStartDate(newStartDateISO, targetSubscription);
    updatedTrial.reportDate = getReportDateByStartDate(newStartDateISO, targetSubscription);
    setLoggingTrials(newTrials);
  };

  const handleTrialReportDateChange = (newReportDate: moment.Moment, index: number) => {
    const newReportDateISO = formatDate(newReportDate);
    const newTrials = [...loggingTrials];
    const updatedTrial = newTrials[index];
    updatedTrial.reportDate = newReportDateISO;
    setLoggingTrials(newTrials);
  };

  const disableInvalidReportDates = (date: moment.Moment, index: number) => {
    const offset = clinician?.flags?.clinician_report_24h_turnaround ? 1 : 2;
    return date < moment(loggingTrials[index].endDate).add(offset, 'days');
  };

  const isExistingLoggingTrialForRepeatTrials = props.subscriptions?.some(sub =>
    loggingTrials?.some(trial => sub.start_date == trial.startDate && sub.expiration_date == trial.endDate)
  );
  const subRes = useAsyncResult<boolean[]>();

  const handleScheduleTrial = async () => {
    if (isExistingLoggingTrialForRepeatTrials) {
      return;
    }
    const res = Promise.all(loggingTrials.map(async trial => {
      return await patientService.addSubscription(
        targetSubscription?.hospital_id,
        targetSubscription?.clinic_id,
        targetSubscription?.patient_id,
        trial.startDate,
        targetSubscription?.subscription_length,
        trial.reportDate,
        // eslint-disable-next-line i18next/no-literal-string
        'single',
      );
    }));

    subRes.bind(res);
    props.toggle();
    setRepeatCadenceVal(repeatCadence[0]);
    setRepeatTimes('');
  };

  if (subRes.isDone) {
    queryClient.invalidateQueries({ queryKey: ['patient-subscriptions'] });
    subRes.clear();
  }

  return (
    <MDBContainer>
      <MDBModal backdrop={false} isOpen={props.show} toggle={props.toggle} centered size="lg">
        <MDBModalHeader toggle={props.toggle}>
          {t('Schedule Repeat Logging Trials')}
        </MDBModalHeader>
        <MDBModalBody>
          <Container className="mb-5">
            <Row>
              <Col>
                <h6>
                  <Trans>Current Logging Trial</Trans>
                </h6>
              </Col>
            </Row>
            <Col>
              <Row
                style={{
                  marginTop: 9,
                  marginRight: 8,
                  marginBottom: 4,
                  borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                }}
              >
                <Col style={{ paddingLeft: 0 }}>
                  <Trans>Start date</Trans>
                </Col>
                <Col>
                  <Trans>End date</Trans>
                </Col>
                <Col>
                  <Trans>Report date</Trans>
                </Col>
              </Row>
              <Row
                style={{ height: '25px', marginRight: 8, marginBottom: 40 }}
              >
                <Col style={{ paddingLeft: 0 }}>
                  {targetSubscription?.start_date}
                </Col>
                <Col>
                  {targetSubscription?.expiration_date}
                </Col>
                <Col>
                  {targetSubscription?.report_date}
                </Col>
              </Row>
            </Col>
            <Row className="pb-4">
              <Col>
                <h6>
                  <Trans>Frequency of repeating trials:</Trans>
                </h6>
                <Form.Group style={{ marginTop: 8, marginBottom: 40 }}>
                  {repeatCadence.map((cadence, idx) => {
                    return (
                      <Form.Check
                        key={idx}
                        type="radio"
                        label={cadence}
                        id={`repeat-${idx}`}
                        name="repeat"
                        value={cadence}
                        checked={repeatCadenceVal === cadence}
                        onChange={() => setRepeatCadenceVal(cadence)}
                        style={{ marginRight: 25 }}
                      />
                    );
                  })}
                </Form.Group>
                <>
                  <h6>
                    {t('Number of {{cadence}} to repeat this trial:', {
                      cadence: isWeeksCadence ? t('weeks') : t('months'),
                    })}
                  </h6>
                  <MuiCustomTextField
                    value={repeatTimes}
                    onChange={e => setRepeatTimes(e.target.value)}
                    variant="outlined"
                    style={{ width: '200px', maxHeight: '56px', marginTop: '8px' }}
                    error={isNaN(+repeatTimes)}
                    helperText={isNaN(+repeatTimes) ? 'Value must be a number' : ''}
                    placeholder="0"
                    InputProps={{ endAdornment: isWeeksCadence ? t('weeks') : t('months') }}
                  />
                </>
              </Col>
              {loggingTrials && (
                <Col>
                  <Row>
                    <h6>
                      <Trans>Logging trials will be scheduled on:</Trans>
                    </h6>
                  </Row>
                  <Row
                    style={{
                      marginTop: 9,
                      marginRight: 8,
                      marginBottom: 4,
                      borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <Col style={{ paddingLeft: 0 }} sm={{ span: 4 }}>
                      <Trans>Start date</Trans>
                    </Col>
                    <Col>
                      <Trans>End date</Trans>
                    </Col>
                    <Col>
                      <Trans>Report date</Trans>
                    </Col>
                  </Row>
                  {loggingTrials.map((trial, i) => {
                    return (
                      <Row
                        key={i}
                        style={{ height: '40px', marginRight: 8, display: 'flex', alignItems: 'center' }}
                      >
                        <Col style={{ paddingLeft: 0 }}>
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={i18n.language}
                          >
                            <MuiCustomDatePicker
                              inputVariant="outlined"
                              disableToolbar
                              format="YYYY-MM-DD"
                              shouldDisableDate={disablePastDates}
                              value={trial.startDate}
                              onChange={(date) => handleTrialStartDateChange(date, i)}
                            />
                          </MuiPickersUtilsProvider>
                        </Col>
                        <Col>
                          {trial.endDate}
                        </Col>
                        <Col>
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={i18n.language}
                          >
                            <MuiCustomDatePicker
                              inputVariant="outlined"
                              disableToolbar
                              format="YYYY-MM-DD"
                              shouldDisableDate={(date) => disableInvalidReportDates(date, i)}
                              value={trial.reportDate}
                              onChange={(date) => handleTrialReportDateChange(date, i)}
                            />
                          </MuiPickersUtilsProvider>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              )}
            </Row>
            <Row>
              <Col style={{ marginTop: '-20px' }}>
                <div className="margin-l">
                  {isExistingLoggingTrialForRepeatTrials && (
                    <p className="errorMessage errorLabel" style={{ fontSize: '13px' }}>
                      {t('The start dates and length of a logging trial conflicts with an existing logging trial.')}
                    </p>
                  )}
                  <Button block onClick={handleScheduleTrial}>
                    <Trans>Schedule Logging Trials</Trans>
                  </Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <h6 className="mb-2">
                  <Trans>Future Logging Trials</Trans>
                </h6>
                {(props.subscriptions && props.subscriptions.length > 0) && props.subscriptions.map(sub => {
                  if (moment(sub.start_date).valueOf() > moment().valueOf()) {
                    return (
                      <div key={sub.id}>
                        {moment(sub.start_date).format('MMM DD/YYYY')}
                        <span
                          className="goalTypeButton"
                          onClick={() => {
                            if (window.confirm(t('Are you sure you wish to delete this trial?'))) {
                              props.handleDeleteSubscription(sub.id);
                            }
                          }}
                        >
                          <i className="fas fa-times-circle" />
                        </span>
                      </div>
                    );
                  }
                })}
              </Col>
            </Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
