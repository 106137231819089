import classnames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';

import DateFnsUtils from '@date-io/moment'; // choose your lib
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import { hostUrl } from '../api';
import { view_types, withFilterPanel } from '../components/foodFilter';
import { Image } from '../components/image';
import { LabelAA, LabelL, LabelM } from '../components/label';
import { useStore } from '../context';
import placeholder from '../placeholder.png';
import { PatientService } from '../services/patient';
import './patient-log.scss';
import { t } from 'i18next';
import { MealResponse } from '../api/generated';
import { MealTable } from '../components/MealTable';
import { ShowMeal } from '../components/showMeal';
import { Trans, useTranslation } from '../i18n';

const Log = (props: {
  filter: any,
  mealTypes: Array<string>,
  nutrientFilters: any,
}) => {
  const patientService = PatientService();
  const { clinician, patient } = useStore();
  const { t } = useTranslation();

  const [showAddMeal, setShowAddMeal] = useState(false);

  const { filter, mealTypes, nutrientFilters } = props;
  const [selectedMealId, setSelectedMealId] = useState(null as null | number);

  const [since, until] = filter[0].map(m => moment(m));

  const filterObj = {
    meal_date: {
      'ge': since.format('YYYY-MM-DD'),
      'le': until.format('YYYY-MM-DD'),
    },
    meal_name: {
      'contains_any': mealTypes.join(' '),
    },
    ...(nutrientFilters || {}),
  };

  const deleteMeal = (meal_id: number) => {
    const conf = prompt(t("Are you sure you want to permanently delete this meal? Type 'yes' to confirm") + ':');
    if (conf.toLowerCase() != 'yes') {
      alert(t('Delete cancelled'));
      return;
    }

    patientService.deleteMeal(patient?.patient_id, meal_id)
      .then(res => {
        if (!res) {
          throw new Error('unknown error');
        }
        alert(t('Meal deleted successfully.'));
      })
      .catch(err => {
        alert(`${t('Error deleting meal')}: ${err}`);
      });
  };

  return (
    <>
      {showAddMeal && <AddMeal onClose={() => setShowAddMeal(false)} defaultValue={showAddMeal} />}
      {!!selectedMealId && (
        <ShowMeal
          mealId={selectedMealId}
          onClose={() => setSelectedMealId(null)}
          onDelete={deleteMeal}
        />
      )}
      <MealTable
        filterObj={filterObj}
        onClickMeal={(mealId: number) => setSelectedMealId(mealId)}
        extraColumns={!clinician?.flags?.clinician_can_delete_meals ? undefined : [
          {
            header: t('Flags'),
            render: (meal) => <MealFlags meal={meal} deleteMeal={deleteMeal} />,
            onClick: null,
          },
        ]}
      />
      {!!clinician.is_meal_admin && (
        <div
          style={{ cursor: 'pointer', marginBottom: 40 }}
          onClick={() => setShowAddMeal(true)}
        >
          <i
            className="fas fa-plus patientMenuIcon"
            style={{ paddingLeft: 11, marginLeft: 0, marginRight: 5 }}
            title={t('Add Meal')}
          />{'  '}
          <Trans>Add meal</Trans>
        </div>
      )}
    </>
  );
};

const MealFlags = (props) => {
  const { meal, deleteMeal } = props;

  return (
    <i
      title={t('Delete Meal')}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        deleteMeal(meal.meal_id);
      }}
      className={classnames('fa fa-trash flagIcon', { active: true })}
    />
  );
};

const AddMeal = (props) => {
  const { onClose, defaultValue } = props;
  const { clinician, patient } = useStore();
  const { i18n } = useTranslation();
  const [saveState, setSaveState] = useState(null);
  const service = PatientService();

  const mealsToTimes = {
    breakfast: 8,
    lunch: 12,
    dinner: 18,
    snack: 22,
  };

  const defaultLower = (defaultValue + '').toLowerCase();
  const defaultParsed = mealsToTimes[defaultLower]
    ? moment().hour(mealsToTimes[defaultLower]).minute(0).second(0).millisecond(0)
    : moment(defaultValue + ', ' + moment().format('YYYY'), 'ddd. MMM D, YYYY');

  const [form, _setForm] = React.useState({
    mealName: defaultLower in mealsToTimes ? defaultLower : '',
    startDateTime: defaultParsed.isValid ? defaultParsed : moment(),
  });

  const setForm = x => {
    if (x.mealName) {
      x.startDateTime = form.startDateTime
        .clone()
        .hour(mealsToTimes[x.mealName] || 1)
        .minute(0)
        .second(0)
        .millisecond(0);
    }
    _setForm({ ...form, ...x });
  };

  const handleClose = () => {
    onClose();
  };

  const submit = () => {
    // eslint-disable-next-line i18next/no-literal-string
    setSaveState('pending');
    const req = service.mealPhotoQueueCreateEmptyMeal(clinician.id, patient.patient_id, {
      meal_date: form.startDateTime.format('YYYY-MM-DD'),
      meal_time: form.startDateTime.format('HH:mm:ss'),
      meal_name: form.mealName,
      meal_items: [],
    });
    req
      .then(res => {
        if (!res) {
          throw new Error('save error');
        }

        return service.getMealAuditorUrl(res.patient_id, res.created_meal_id);
      })
      .then(res => {
        // eslint-disable-next-line i18next/no-literal-string
        const q = res.url.indexOf('?') >= 0 ? '&' : '?';
        window.open(res.url + q + 'back=' + encodeURIComponent(window.location.href), '_blank');
        service.getMeals({
          patient_id: patient.patient_id,
          include_deleted: clinician.is_meal_admin,
        });
        handleClose();
      })
      .catch(err => {
        // eslint-disable-next-line i18next/no-literal-string
        setSaveState('error');
        console.error(err);
      });
  };

  return (
    <MDBContainer>
      <MDBModal isOpen={true} toggle={() => {}}>
        <MDBModalHeader toggle={handleClose}>
          <Trans>Add Meal</Trans>
        </MDBModalHeader>
        <MDBModalBody>
          <Container>
            <Row style={{ marginBottom: 20 }}>
              <Col>
                <select
                  className="browser-default custom-select"
                  value={form.mealName}
                  onChange={e => setForm({ mealName: e.target.value })}
                >
                  <option disabled value="">
                    <Trans>Meal Type</Trans>
                  </option>
                  <option value="breakfast">
                    <Trans>Breakfast</Trans>
                  </option>
                  <option value="lunch">
                    <Trans>Lunch</Trans>
                  </option>
                  <option value="dinner">
                    <Trans>Dinner</Trans>
                  </option>
                  <option value="snack">
                    <Trans>Snack</Trans>
                  </option>
                </select>
              </Col>
            </Row>

            <Row>
              <Col>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language}>
                  <DateTimePicker
                    inputVariant="outlined"
                    label={t('Meal Date and Time')}
                    variant="inline"
                    value={form.startDateTime}
                    onChange={value => setForm({ startDateTime: value })}
                  />
                </MuiPickersUtilsProvider>
              </Col>
            </Row>
          </Container>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="light" onClick={handleClose}>
            <Trans>Close</Trans>
          </MDBBtn>
          <MDBBtn color="primary" onClick={submit} disabled={saveState == 'pending' || !form.mealName}>
            <Trans>Create</Trans>
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
};

const AddMealButton = (props) => {
  const { onClick, label } = props;
  return (
    <span style={{ cursor: 'pointer' }} onClick={onClick}>
      <i
        className="fas fa-plus patientMenuIcon"
        style={{ paddingLeft: 11, marginLeft: 0, marginRight: 5 }}
        title={t('Add Meal')}
      />{' '}
      {label}
    </span>
  );
};

export default withFilterPanel(Log);
