/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreatePatientReportLogRequest } from '../models';
// @ts-ignore
import { CreatePatientSubscriptionRequest } from '../models';
// @ts-ignore
import { PatchPatientSubscriptionRequest } from '../models';
// @ts-ignore
import { PatientReportLogResponse } from '../models';
// @ts-ignore
import { PatientSubscriptionResponse } from '../models';
// @ts-ignore
import { PatientSupplementResponse } from '../models';
// @ts-ignore
import { SubscriptionResponse } from '../models';
// @ts-ignore
import { UpdatePatientSubscriptionRequest } from '../models';
/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Post a subscription to a patient
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_id 
         * @param {number} patient_subscription_id 
         * @param {CreatePatientReportLogRequest} CreatePatientReportLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPostRegisterReport: async (hospital_id: number, clinic_id: number, patient_id: number, patient_subscription_id: number, CreatePatientReportLogRequest: CreatePatientReportLogRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostRegisterReport', 'hospital_id', hospital_id)
            // verify required parameter 'clinic_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostRegisterReport', 'clinic_id', clinic_id)
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostRegisterReport', 'patient_id', patient_id)
            // verify required parameter 'patient_subscription_id' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostRegisterReport', 'patient_subscription_id', patient_subscription_id)
            // verify required parameter 'CreatePatientReportLogRequest' is not null or undefined
            assertParamExists('appApiPatientReportsApiPostRegisterReport', 'CreatePatientReportLogRequest', CreatePatientReportLogRequest)
            const localVarPath = `/hospital/{hospital_id}/clinic/{clinic_id}/patients/{patient_id}/subscription/{patient_subscription_id}/register_report`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinic_id)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"patient_subscription_id"}}`, encodeURIComponent(String(patient_subscription_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreatePatientReportLogRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the subscription for the patient
         * @param {number} patient_subscription_id 
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiDeletePatientSubscription: async (patient_subscription_id: number, hospital_id: number, patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_subscription_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiDeletePatientSubscription', 'patient_subscription_id', patient_subscription_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiDeletePatientSubscription', 'hospital_id', hospital_id)
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiDeletePatientSubscription', 'patient_id', patient_id)
            const localVarPath = `/hospital/{hospital_id}/patients/{patient_id}/subscription/{patient_subscription_id}`
                .replace(`{${"patient_subscription_id"}}`, encodeURIComponent(String(patient_subscription_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of subscriptions available to the clinician
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiGetClinicianPatientSubscriptions: async (clinician_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinician_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiGetClinicianPatientSubscriptions', 'clinician_id', clinician_id)
            const localVarPath = `/clinician/{clinician_id}/patient_subscriptions`
                .replace(`{${"clinician_id"}}`, encodeURIComponent(String(clinician_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of subscriptions available to the hospital
         * @param {number} hospital_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiGetHospitalSubscriptions: async (hospital_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiGetHospitalSubscriptions', 'hospital_id', hospital_id)
            const localVarPath = `/hospital/{hospital_id}/subscriptions`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of subscriptions for the patient
         * @param {number} patient_id 
         * @param {number} patient_subscription_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiGetNDaysLogged: async (patient_id: number, patient_subscription_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiGetNDaysLogged', 'patient_id', patient_id)
            // verify required parameter 'patient_subscription_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiGetNDaysLogged', 'patient_subscription_id', patient_subscription_id)
            const localVarPath = `/patients/{patient_id}/subscription/{patient_subscription_id}/days_logged`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"patient_subscription_id"}}`, encodeURIComponent(String(patient_subscription_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of subscriptions for the patient
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {number} patient_subscription_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiGetPatientSubscription: async (hospital_id: number, patient_id: number, patient_subscription_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiGetPatientSubscription', 'hospital_id', hospital_id)
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiGetPatientSubscription', 'patient_id', patient_id)
            // verify required parameter 'patient_subscription_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiGetPatientSubscription', 'patient_subscription_id', patient_subscription_id)
            const localVarPath = `/hospital/{hospital_id}/patients/{patient_id}/subscription/{patient_subscription_id}`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"patient_subscription_id"}}`, encodeURIComponent(String(patient_subscription_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of subscriptions for the patient
         * @param {number} patient_id 
         * @param {boolean} [active_only] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiGetPatientSubscriptions: async (patient_id: number, active_only?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiGetPatientSubscriptions', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/subscriptions`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (active_only !== undefined) {
                localVarQueryParameter['active_only'] = active_only;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify the subscription for the patient
         * @param {number} patient_subscription_id 
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {PatchPatientSubscriptionRequest} PatchPatientSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiPatchPatientSubscription: async (patient_subscription_id: number, hospital_id: number, patient_id: number, PatchPatientSubscriptionRequest: PatchPatientSubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_subscription_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiPatchPatientSubscription', 'patient_subscription_id', patient_subscription_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiPatchPatientSubscription', 'hospital_id', hospital_id)
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiPatchPatientSubscription', 'patient_id', patient_id)
            // verify required parameter 'PatchPatientSubscriptionRequest' is not null or undefined
            assertParamExists('appApiSubscriptionApiPatchPatientSubscription', 'PatchPatientSubscriptionRequest', PatchPatientSubscriptionRequest)
            const localVarPath = `/hospital/{hospital_id}/patients/{patient_id}/subscription/{patient_subscription_id}`
                .replace(`{${"patient_subscription_id"}}`, encodeURIComponent(String(patient_subscription_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(PatchPatientSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post a subscription to a patient
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {CreatePatientSubscriptionRequest} CreatePatientSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiPostPatientSubscription: async (hospital_id: number, patient_id: number, CreatePatientSubscriptionRequest: CreatePatientSubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiPostPatientSubscription', 'hospital_id', hospital_id)
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiPostPatientSubscription', 'patient_id', patient_id)
            // verify required parameter 'CreatePatientSubscriptionRequest' is not null or undefined
            assertParamExists('appApiSubscriptionApiPostPatientSubscription', 'CreatePatientSubscriptionRequest', CreatePatientSubscriptionRequest)
            const localVarPath = `/hospital/{hospital_id}/patients/{patient_id}/subscription`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreatePatientSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a reminder for upcoming subscriptions
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {number} patient_subscription_id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiSubscriptionApiPostPatientSubscriptionReminder: async (hospital_id: number, patient_id: number, patient_subscription_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiPostPatientSubscriptionReminder', 'hospital_id', hospital_id)
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiPostPatientSubscriptionReminder', 'patient_id', patient_id)
            // verify required parameter 'patient_subscription_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiPostPatientSubscriptionReminder', 'patient_subscription_id', patient_subscription_id)
            const localVarPath = `/hospital/{hospital_id}/patients/{patient_id}/subscription/{patient_subscription_id}/reminder`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"patient_subscription_id"}}`, encodeURIComponent(String(patient_subscription_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new subscription
         * @param {number} patient_id 
         * @param {string} start_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiPostPatientSubscriptions: async (patient_id: number, start_date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiPostPatientSubscriptions', 'patient_id', patient_id)
            // verify required parameter 'start_date' is not null or undefined
            assertParamExists('appApiSubscriptionApiPostPatientSubscriptions', 'start_date', start_date)
            const localVarPath = `/patients/{patient_id}/subscriptions`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify the subscription for the patient
         * @param {number} patient_subscription_id 
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {UpdatePatientSubscriptionRequest} UpdatePatientSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiPutPatientSubscription: async (patient_subscription_id: number, hospital_id: number, patient_id: number, UpdatePatientSubscriptionRequest: UpdatePatientSubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_subscription_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiPutPatientSubscription', 'patient_subscription_id', patient_subscription_id)
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiPutPatientSubscription', 'hospital_id', hospital_id)
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSubscriptionApiPutPatientSubscription', 'patient_id', patient_id)
            // verify required parameter 'UpdatePatientSubscriptionRequest' is not null or undefined
            assertParamExists('appApiSubscriptionApiPutPatientSubscription', 'UpdatePatientSubscriptionRequest', UpdatePatientSubscriptionRequest)
            const localVarPath = `/hospital/{hospital_id}/patients/{patient_id}/subscription/{patient_subscription_id}`
                .replace(`{${"patient_subscription_id"}}`, encodeURIComponent(String(patient_subscription_id)))
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdatePatientSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send end of subscription email to patient and/or clinician
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {number} patient_subscription_id 
         * @param {boolean} [send_to_patient] 
         * @param {boolean} [send_to_clinicians] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionEmailPostEndOfSubscriptionEmail: async (hospital_id: number, patient_id: number, patient_subscription_id: number, send_to_patient?: boolean, send_to_clinicians?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiSubscriptionEmailPostEndOfSubscriptionEmail', 'hospital_id', hospital_id)
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSubscriptionEmailPostEndOfSubscriptionEmail', 'patient_id', patient_id)
            // verify required parameter 'patient_subscription_id' is not null or undefined
            assertParamExists('appApiSubscriptionEmailPostEndOfSubscriptionEmail', 'patient_subscription_id', patient_subscription_id)
            const localVarPath = `/hospital/{hospital_id}/patients/{patient_id}/subscription/{patient_subscription_id}/end_of_subscription_email`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"patient_subscription_id"}}`, encodeURIComponent(String(patient_subscription_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (send_to_patient !== undefined) {
                localVarQueryParameter['send_to_patient'] = send_to_patient;
            }

            if (send_to_clinicians !== undefined) {
                localVarQueryParameter['send_to_clinicians'] = send_to_clinicians;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send end of subscription email to patient
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {number} patient_subscription_id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiSubscriptionEmailPostPatientSubscriptionReminder: async (hospital_id: number, patient_id: number, patient_subscription_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiSubscriptionEmailPostPatientSubscriptionReminder', 'hospital_id', hospital_id)
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSubscriptionEmailPostPatientSubscriptionReminder', 'patient_id', patient_id)
            // verify required parameter 'patient_subscription_id' is not null or undefined
            assertParamExists('appApiSubscriptionEmailPostPatientSubscriptionReminder', 'patient_subscription_id', patient_subscription_id)
            const localVarPath = `/hospital/{hospital_id}/patients/{patient_id}/subscription/{patient_subscription_id}/reminder_email`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"patient_subscription_id"}}`, encodeURIComponent(String(patient_subscription_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send email to the patient to indicate that they are done logging
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {number} patient_subscription_id 
         * @param {string} [date_since] 
         * @param {string} [date_until] 
         * @param {boolean} [send_to_admin_only] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionEmailPostSendPatientLoggingCompleteEmail: async (hospital_id: number, patient_id: number, patient_subscription_id: number, date_since?: string, date_until?: string, send_to_admin_only?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hospital_id' is not null or undefined
            assertParamExists('appApiSubscriptionEmailPostSendPatientLoggingCompleteEmail', 'hospital_id', hospital_id)
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSubscriptionEmailPostSendPatientLoggingCompleteEmail', 'patient_id', patient_id)
            // verify required parameter 'patient_subscription_id' is not null or undefined
            assertParamExists('appApiSubscriptionEmailPostSendPatientLoggingCompleteEmail', 'patient_subscription_id', patient_subscription_id)
            const localVarPath = `/hospital/{hospital_id}/patients/{patient_id}/subscription/{patient_subscription_id}/logging_complete_email`
                .replace(`{${"hospital_id"}}`, encodeURIComponent(String(hospital_id)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"patient_subscription_id"}}`, encodeURIComponent(String(patient_subscription_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (send_to_admin_only !== undefined) {
                localVarQueryParameter['send_to_admin_only'] = send_to_admin_only;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of subscriptions for the patient
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSupplementGetPatientSupplements: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiSupplementGetPatientSupplements', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/supplements`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Post a subscription to a patient
         * @param {number} hospital_id 
         * @param {number} clinic_id 
         * @param {number} patient_id 
         * @param {number} patient_subscription_id 
         * @param {CreatePatientReportLogRequest} CreatePatientReportLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiPatientReportsApiPostRegisterReport(hospital_id: number, clinic_id: number, patient_id: number, patient_subscription_id: number, CreatePatientReportLogRequest: CreatePatientReportLogRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientReportLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiPatientReportsApiPostRegisterReport(hospital_id, clinic_id, patient_id, patient_subscription_id, CreatePatientReportLogRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the subscription for the patient
         * @param {number} patient_subscription_id 
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionApiDeletePatientSubscription(patient_subscription_id: number, hospital_id: number, patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionApiDeletePatientSubscription(patient_subscription_id, hospital_id, patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of subscriptions available to the clinician
         * @param {number} clinician_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionApiGetClinicianPatientSubscriptions(clinician_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionApiGetClinicianPatientSubscriptions(clinician_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of subscriptions available to the hospital
         * @param {number} hospital_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionApiGetHospitalSubscriptions(hospital_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionApiGetHospitalSubscriptions(hospital_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of subscriptions for the patient
         * @param {number} patient_id 
         * @param {number} patient_subscription_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionApiGetNDaysLogged(patient_id: number, patient_subscription_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionApiGetNDaysLogged(patient_id, patient_subscription_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of subscriptions for the patient
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {number} patient_subscription_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionApiGetPatientSubscription(hospital_id: number, patient_id: number, patient_subscription_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionApiGetPatientSubscription(hospital_id, patient_id, patient_subscription_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of subscriptions for the patient
         * @param {number} patient_id 
         * @param {boolean} [active_only] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionApiGetPatientSubscriptions(patient_id: number, active_only?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientSubscriptionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionApiGetPatientSubscriptions(patient_id, active_only, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Modify the subscription for the patient
         * @param {number} patient_subscription_id 
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {PatchPatientSubscriptionRequest} PatchPatientSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionApiPatchPatientSubscription(patient_subscription_id: number, hospital_id: number, patient_id: number, PatchPatientSubscriptionRequest: PatchPatientSubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionApiPatchPatientSubscription(patient_subscription_id, hospital_id, patient_id, PatchPatientSubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post a subscription to a patient
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {CreatePatientSubscriptionRequest} CreatePatientSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionApiPostPatientSubscription(hospital_id: number, patient_id: number, CreatePatientSubscriptionRequest: CreatePatientSubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionApiPostPatientSubscription(hospital_id, patient_id, CreatePatientSubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a reminder for upcoming subscriptions
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {number} patient_subscription_id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiSubscriptionApiPostPatientSubscriptionReminder(hospital_id: number, patient_id: number, patient_subscription_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionApiPostPatientSubscriptionReminder(hospital_id, patient_id, patient_subscription_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new subscription
         * @param {number} patient_id 
         * @param {string} start_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionApiPostPatientSubscriptions(patient_id: number, start_date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientSubscriptionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionApiPostPatientSubscriptions(patient_id, start_date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Modify the subscription for the patient
         * @param {number} patient_subscription_id 
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {UpdatePatientSubscriptionRequest} UpdatePatientSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionApiPutPatientSubscription(patient_subscription_id: number, hospital_id: number, patient_id: number, UpdatePatientSubscriptionRequest: UpdatePatientSubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionApiPutPatientSubscription(patient_subscription_id, hospital_id, patient_id, UpdatePatientSubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send end of subscription email to patient and/or clinician
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {number} patient_subscription_id 
         * @param {boolean} [send_to_patient] 
         * @param {boolean} [send_to_clinicians] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionEmailPostEndOfSubscriptionEmail(hospital_id: number, patient_id: number, patient_subscription_id: number, send_to_patient?: boolean, send_to_clinicians?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionEmailPostEndOfSubscriptionEmail(hospital_id, patient_id, patient_subscription_id, send_to_patient, send_to_clinicians, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send end of subscription email to patient
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {number} patient_subscription_id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiSubscriptionEmailPostPatientSubscriptionReminder(hospital_id: number, patient_id: number, patient_subscription_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionEmailPostPatientSubscriptionReminder(hospital_id, patient_id, patient_subscription_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send email to the patient to indicate that they are done logging
         * @param {number} hospital_id 
         * @param {number} patient_id 
         * @param {number} patient_subscription_id 
         * @param {string} [date_since] 
         * @param {string} [date_until] 
         * @param {boolean} [send_to_admin_only] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSubscriptionEmailPostSendPatientLoggingCompleteEmail(hospital_id: number, patient_id: number, patient_subscription_id: number, date_since?: string, date_until?: string, send_to_admin_only?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSubscriptionEmailPostSendPatientLoggingCompleteEmail(hospital_id, patient_id, patient_subscription_id, date_since, date_until, send_to_admin_only, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of subscriptions for the patient
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiSupplementGetPatientSupplements(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientSupplementResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiSupplementGetPatientSupplements(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionApiFp(configuration)
    return {
        /**
         * 
         * @summary Post a subscription to a patient
         * @param {SubscriptionApiAppApiPatientReportsApiPostRegisterReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiPatientReportsApiPostRegisterReport(requestParameters: SubscriptionApiAppApiPatientReportsApiPostRegisterReportRequest, options?: AxiosRequestConfig): AxiosPromise<PatientReportLogResponse> {
            return localVarFp.appApiPatientReportsApiPostRegisterReport(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.patient_id, requestParameters.patient_subscription_id, requestParameters.CreatePatientReportLogRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the subscription for the patient
         * @param {SubscriptionApiAppApiSubscriptionApiDeletePatientSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiDeletePatientSubscription(requestParameters: SubscriptionApiAppApiSubscriptionApiDeletePatientSubscriptionRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiSubscriptionApiDeletePatientSubscription(requestParameters.patient_subscription_id, requestParameters.hospital_id, requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of subscriptions available to the clinician
         * @param {SubscriptionApiAppApiSubscriptionApiGetClinicianPatientSubscriptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiGetClinicianPatientSubscriptions(requestParameters: SubscriptionApiAppApiSubscriptionApiGetClinicianPatientSubscriptionsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SubscriptionResponse>> {
            return localVarFp.appApiSubscriptionApiGetClinicianPatientSubscriptions(requestParameters.clinician_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of subscriptions available to the hospital
         * @param {SubscriptionApiAppApiSubscriptionApiGetHospitalSubscriptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiGetHospitalSubscriptions(requestParameters: SubscriptionApiAppApiSubscriptionApiGetHospitalSubscriptionsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SubscriptionResponse>> {
            return localVarFp.appApiSubscriptionApiGetHospitalSubscriptions(requestParameters.hospital_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of subscriptions for the patient
         * @param {SubscriptionApiAppApiSubscriptionApiGetNDaysLoggedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiGetNDaysLogged(requestParameters: SubscriptionApiAppApiSubscriptionApiGetNDaysLoggedRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiSubscriptionApiGetNDaysLogged(requestParameters.patient_id, requestParameters.patient_subscription_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of subscriptions for the patient
         * @param {SubscriptionApiAppApiSubscriptionApiGetPatientSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiGetPatientSubscription(requestParameters: SubscriptionApiAppApiSubscriptionApiGetPatientSubscriptionRequest, options?: AxiosRequestConfig): AxiosPromise<PatientSubscriptionResponse> {
            return localVarFp.appApiSubscriptionApiGetPatientSubscription(requestParameters.hospital_id, requestParameters.patient_id, requestParameters.patient_subscription_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of subscriptions for the patient
         * @param {SubscriptionApiAppApiSubscriptionApiGetPatientSubscriptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiGetPatientSubscriptions(requestParameters: SubscriptionApiAppApiSubscriptionApiGetPatientSubscriptionsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PatientSubscriptionResponse>> {
            return localVarFp.appApiSubscriptionApiGetPatientSubscriptions(requestParameters.patient_id, requestParameters.active_only, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Modify the subscription for the patient
         * @param {SubscriptionApiAppApiSubscriptionApiPatchPatientSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiPatchPatientSubscription(requestParameters: SubscriptionApiAppApiSubscriptionApiPatchPatientSubscriptionRequest, options?: AxiosRequestConfig): AxiosPromise<PatientSubscriptionResponse> {
            return localVarFp.appApiSubscriptionApiPatchPatientSubscription(requestParameters.patient_subscription_id, requestParameters.hospital_id, requestParameters.patient_id, requestParameters.PatchPatientSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post a subscription to a patient
         * @param {SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiPostPatientSubscription(requestParameters: SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionRequest, options?: AxiosRequestConfig): AxiosPromise<PatientSubscriptionResponse> {
            return localVarFp.appApiSubscriptionApiPostPatientSubscription(requestParameters.hospital_id, requestParameters.patient_id, requestParameters.CreatePatientSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a reminder for upcoming subscriptions
         * @param {SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionReminderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiSubscriptionApiPostPatientSubscriptionReminder(requestParameters: SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionReminderRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiSubscriptionApiPostPatientSubscriptionReminder(requestParameters.hospital_id, requestParameters.patient_id, requestParameters.patient_subscription_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new subscription
         * @param {SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiPostPatientSubscriptions(requestParameters: SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PatientSubscriptionResponse>> {
            return localVarFp.appApiSubscriptionApiPostPatientSubscriptions(requestParameters.patient_id, requestParameters.start_date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Modify the subscription for the patient
         * @param {SubscriptionApiAppApiSubscriptionApiPutPatientSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionApiPutPatientSubscription(requestParameters: SubscriptionApiAppApiSubscriptionApiPutPatientSubscriptionRequest, options?: AxiosRequestConfig): AxiosPromise<PatientSubscriptionResponse> {
            return localVarFp.appApiSubscriptionApiPutPatientSubscription(requestParameters.patient_subscription_id, requestParameters.hospital_id, requestParameters.patient_id, requestParameters.UpdatePatientSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send end of subscription email to patient and/or clinician
         * @param {SubscriptionApiAppApiSubscriptionEmailPostEndOfSubscriptionEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionEmailPostEndOfSubscriptionEmail(requestParameters: SubscriptionApiAppApiSubscriptionEmailPostEndOfSubscriptionEmailRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiSubscriptionEmailPostEndOfSubscriptionEmail(requestParameters.hospital_id, requestParameters.patient_id, requestParameters.patient_subscription_id, requestParameters.send_to_patient, requestParameters.send_to_clinicians, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send end of subscription email to patient
         * @param {SubscriptionApiAppApiSubscriptionEmailPostPatientSubscriptionReminderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiSubscriptionEmailPostPatientSubscriptionReminder(requestParameters: SubscriptionApiAppApiSubscriptionEmailPostPatientSubscriptionReminderRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiSubscriptionEmailPostPatientSubscriptionReminder(requestParameters.hospital_id, requestParameters.patient_id, requestParameters.patient_subscription_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send email to the patient to indicate that they are done logging
         * @param {SubscriptionApiAppApiSubscriptionEmailPostSendPatientLoggingCompleteEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSubscriptionEmailPostSendPatientLoggingCompleteEmail(requestParameters: SubscriptionApiAppApiSubscriptionEmailPostSendPatientLoggingCompleteEmailRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiSubscriptionEmailPostSendPatientLoggingCompleteEmail(requestParameters.hospital_id, requestParameters.patient_id, requestParameters.patient_subscription_id, requestParameters.date_since, requestParameters.date_until, requestParameters.send_to_admin_only, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of subscriptions for the patient
         * @param {SubscriptionApiAppApiSupplementGetPatientSupplementsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiSupplementGetPatientSupplements(requestParameters: SubscriptionApiAppApiSupplementGetPatientSupplementsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PatientSupplementResponse>> {
            return localVarFp.appApiSupplementGetPatientSupplements(requestParameters.patient_id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiPatientReportsApiPostRegisterReport operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiPatientReportsApiPostRegisterReportRequest
 */
export interface SubscriptionApiAppApiPatientReportsApiPostRegisterReportRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiPatientReportsApiPostRegisterReport
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiPatientReportsApiPostRegisterReport
     */
    readonly clinic_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiPatientReportsApiPostRegisterReport
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiPatientReportsApiPostRegisterReport
     */
    readonly patient_subscription_id: number

    /**
     * 
     * @type {CreatePatientReportLogRequest}
     * @memberof SubscriptionApiAppApiPatientReportsApiPostRegisterReport
     */
    readonly CreatePatientReportLogRequest: CreatePatientReportLogRequest
}

/**
 * Request parameters for appApiSubscriptionApiDeletePatientSubscription operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiSubscriptionApiDeletePatientSubscriptionRequest
 */
export interface SubscriptionApiAppApiSubscriptionApiDeletePatientSubscriptionRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiDeletePatientSubscription
     */
    readonly patient_subscription_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiDeletePatientSubscription
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiDeletePatientSubscription
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiSubscriptionApiGetClinicianPatientSubscriptions operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiSubscriptionApiGetClinicianPatientSubscriptionsRequest
 */
export interface SubscriptionApiAppApiSubscriptionApiGetClinicianPatientSubscriptionsRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiGetClinicianPatientSubscriptions
     */
    readonly clinician_id: number
}

/**
 * Request parameters for appApiSubscriptionApiGetHospitalSubscriptions operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiSubscriptionApiGetHospitalSubscriptionsRequest
 */
export interface SubscriptionApiAppApiSubscriptionApiGetHospitalSubscriptionsRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiGetHospitalSubscriptions
     */
    readonly hospital_id: number
}

/**
 * Request parameters for appApiSubscriptionApiGetNDaysLogged operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiSubscriptionApiGetNDaysLoggedRequest
 */
export interface SubscriptionApiAppApiSubscriptionApiGetNDaysLoggedRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiGetNDaysLogged
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiGetNDaysLogged
     */
    readonly patient_subscription_id: number
}

/**
 * Request parameters for appApiSubscriptionApiGetPatientSubscription operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiSubscriptionApiGetPatientSubscriptionRequest
 */
export interface SubscriptionApiAppApiSubscriptionApiGetPatientSubscriptionRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiGetPatientSubscription
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiGetPatientSubscription
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiGetPatientSubscription
     */
    readonly patient_subscription_id: number
}

/**
 * Request parameters for appApiSubscriptionApiGetPatientSubscriptions operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiSubscriptionApiGetPatientSubscriptionsRequest
 */
export interface SubscriptionApiAppApiSubscriptionApiGetPatientSubscriptionsRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiGetPatientSubscriptions
     */
    readonly patient_id: number

    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionApiAppApiSubscriptionApiGetPatientSubscriptions
     */
    readonly active_only?: boolean
}

/**
 * Request parameters for appApiSubscriptionApiPatchPatientSubscription operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiSubscriptionApiPatchPatientSubscriptionRequest
 */
export interface SubscriptionApiAppApiSubscriptionApiPatchPatientSubscriptionRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiPatchPatientSubscription
     */
    readonly patient_subscription_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiPatchPatientSubscription
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiPatchPatientSubscription
     */
    readonly patient_id: number

    /**
     * 
     * @type {PatchPatientSubscriptionRequest}
     * @memberof SubscriptionApiAppApiSubscriptionApiPatchPatientSubscription
     */
    readonly PatchPatientSubscriptionRequest: PatchPatientSubscriptionRequest
}

/**
 * Request parameters for appApiSubscriptionApiPostPatientSubscription operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionRequest
 */
export interface SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiPostPatientSubscription
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiPostPatientSubscription
     */
    readonly patient_id: number

    /**
     * 
     * @type {CreatePatientSubscriptionRequest}
     * @memberof SubscriptionApiAppApiSubscriptionApiPostPatientSubscription
     */
    readonly CreatePatientSubscriptionRequest: CreatePatientSubscriptionRequest
}

/**
 * Request parameters for appApiSubscriptionApiPostPatientSubscriptionReminder operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionReminderRequest
 */
export interface SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionReminderRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionReminder
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionReminder
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionReminder
     */
    readonly patient_subscription_id: number
}

/**
 * Request parameters for appApiSubscriptionApiPostPatientSubscriptions operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionsRequest
 */
export interface SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionsRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptions
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptions
     */
    readonly start_date: string
}

/**
 * Request parameters for appApiSubscriptionApiPutPatientSubscription operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiSubscriptionApiPutPatientSubscriptionRequest
 */
export interface SubscriptionApiAppApiSubscriptionApiPutPatientSubscriptionRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiPutPatientSubscription
     */
    readonly patient_subscription_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiPutPatientSubscription
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionApiPutPatientSubscription
     */
    readonly patient_id: number

    /**
     * 
     * @type {UpdatePatientSubscriptionRequest}
     * @memberof SubscriptionApiAppApiSubscriptionApiPutPatientSubscription
     */
    readonly UpdatePatientSubscriptionRequest: UpdatePatientSubscriptionRequest
}

/**
 * Request parameters for appApiSubscriptionEmailPostEndOfSubscriptionEmail operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiSubscriptionEmailPostEndOfSubscriptionEmailRequest
 */
export interface SubscriptionApiAppApiSubscriptionEmailPostEndOfSubscriptionEmailRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionEmailPostEndOfSubscriptionEmail
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionEmailPostEndOfSubscriptionEmail
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionEmailPostEndOfSubscriptionEmail
     */
    readonly patient_subscription_id: number

    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionApiAppApiSubscriptionEmailPostEndOfSubscriptionEmail
     */
    readonly send_to_patient?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionApiAppApiSubscriptionEmailPostEndOfSubscriptionEmail
     */
    readonly send_to_clinicians?: boolean
}

/**
 * Request parameters for appApiSubscriptionEmailPostPatientSubscriptionReminder operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiSubscriptionEmailPostPatientSubscriptionReminderRequest
 */
export interface SubscriptionApiAppApiSubscriptionEmailPostPatientSubscriptionReminderRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionEmailPostPatientSubscriptionReminder
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionEmailPostPatientSubscriptionReminder
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionEmailPostPatientSubscriptionReminder
     */
    readonly patient_subscription_id: number
}

/**
 * Request parameters for appApiSubscriptionEmailPostSendPatientLoggingCompleteEmail operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiSubscriptionEmailPostSendPatientLoggingCompleteEmailRequest
 */
export interface SubscriptionApiAppApiSubscriptionEmailPostSendPatientLoggingCompleteEmailRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionEmailPostSendPatientLoggingCompleteEmail
     */
    readonly hospital_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionEmailPostSendPatientLoggingCompleteEmail
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSubscriptionEmailPostSendPatientLoggingCompleteEmail
     */
    readonly patient_subscription_id: number

    /**
     * 
     * @type {string}
     * @memberof SubscriptionApiAppApiSubscriptionEmailPostSendPatientLoggingCompleteEmail
     */
    readonly date_since?: string

    /**
     * 
     * @type {string}
     * @memberof SubscriptionApiAppApiSubscriptionEmailPostSendPatientLoggingCompleteEmail
     */
    readonly date_until?: string

    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionApiAppApiSubscriptionEmailPostSendPatientLoggingCompleteEmail
     */
    readonly send_to_admin_only?: boolean
}

/**
 * Request parameters for appApiSupplementGetPatientSupplements operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiAppApiSupplementGetPatientSupplementsRequest
 */
export interface SubscriptionApiAppApiSupplementGetPatientSupplementsRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionApiAppApiSupplementGetPatientSupplements
     */
    readonly patient_id: number
}

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * 
     * @summary Post a subscription to a patient
     * @param {SubscriptionApiAppApiPatientReportsApiPostRegisterReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiPatientReportsApiPostRegisterReport(requestParameters: SubscriptionApiAppApiPatientReportsApiPostRegisterReportRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiPatientReportsApiPostRegisterReport(requestParameters.hospital_id, requestParameters.clinic_id, requestParameters.patient_id, requestParameters.patient_subscription_id, requestParameters.CreatePatientReportLogRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the subscription for the patient
     * @param {SubscriptionApiAppApiSubscriptionApiDeletePatientSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiSubscriptionApiDeletePatientSubscription(requestParameters: SubscriptionApiAppApiSubscriptionApiDeletePatientSubscriptionRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiSubscriptionApiDeletePatientSubscription(requestParameters.patient_subscription_id, requestParameters.hospital_id, requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of subscriptions available to the clinician
     * @param {SubscriptionApiAppApiSubscriptionApiGetClinicianPatientSubscriptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiSubscriptionApiGetClinicianPatientSubscriptions(requestParameters: SubscriptionApiAppApiSubscriptionApiGetClinicianPatientSubscriptionsRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiSubscriptionApiGetClinicianPatientSubscriptions(requestParameters.clinician_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of subscriptions available to the hospital
     * @param {SubscriptionApiAppApiSubscriptionApiGetHospitalSubscriptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiSubscriptionApiGetHospitalSubscriptions(requestParameters: SubscriptionApiAppApiSubscriptionApiGetHospitalSubscriptionsRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiSubscriptionApiGetHospitalSubscriptions(requestParameters.hospital_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of subscriptions for the patient
     * @param {SubscriptionApiAppApiSubscriptionApiGetNDaysLoggedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiSubscriptionApiGetNDaysLogged(requestParameters: SubscriptionApiAppApiSubscriptionApiGetNDaysLoggedRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiSubscriptionApiGetNDaysLogged(requestParameters.patient_id, requestParameters.patient_subscription_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of subscriptions for the patient
     * @param {SubscriptionApiAppApiSubscriptionApiGetPatientSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiSubscriptionApiGetPatientSubscription(requestParameters: SubscriptionApiAppApiSubscriptionApiGetPatientSubscriptionRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiSubscriptionApiGetPatientSubscription(requestParameters.hospital_id, requestParameters.patient_id, requestParameters.patient_subscription_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of subscriptions for the patient
     * @param {SubscriptionApiAppApiSubscriptionApiGetPatientSubscriptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiSubscriptionApiGetPatientSubscriptions(requestParameters: SubscriptionApiAppApiSubscriptionApiGetPatientSubscriptionsRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiSubscriptionApiGetPatientSubscriptions(requestParameters.patient_id, requestParameters.active_only, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Modify the subscription for the patient
     * @param {SubscriptionApiAppApiSubscriptionApiPatchPatientSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiSubscriptionApiPatchPatientSubscription(requestParameters: SubscriptionApiAppApiSubscriptionApiPatchPatientSubscriptionRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiSubscriptionApiPatchPatientSubscription(requestParameters.patient_subscription_id, requestParameters.hospital_id, requestParameters.patient_id, requestParameters.PatchPatientSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post a subscription to a patient
     * @param {SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiSubscriptionApiPostPatientSubscription(requestParameters: SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiSubscriptionApiPostPatientSubscription(requestParameters.hospital_id, requestParameters.patient_id, requestParameters.CreatePatientSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a reminder for upcoming subscriptions
     * @param {SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionReminderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiSubscriptionApiPostPatientSubscriptionReminder(requestParameters: SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionReminderRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiSubscriptionApiPostPatientSubscriptionReminder(requestParameters.hospital_id, requestParameters.patient_id, requestParameters.patient_subscription_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new subscription
     * @param {SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiSubscriptionApiPostPatientSubscriptions(requestParameters: SubscriptionApiAppApiSubscriptionApiPostPatientSubscriptionsRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiSubscriptionApiPostPatientSubscriptions(requestParameters.patient_id, requestParameters.start_date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Modify the subscription for the patient
     * @param {SubscriptionApiAppApiSubscriptionApiPutPatientSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiSubscriptionApiPutPatientSubscription(requestParameters: SubscriptionApiAppApiSubscriptionApiPutPatientSubscriptionRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiSubscriptionApiPutPatientSubscription(requestParameters.patient_subscription_id, requestParameters.hospital_id, requestParameters.patient_id, requestParameters.UpdatePatientSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send end of subscription email to patient and/or clinician
     * @param {SubscriptionApiAppApiSubscriptionEmailPostEndOfSubscriptionEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiSubscriptionEmailPostEndOfSubscriptionEmail(requestParameters: SubscriptionApiAppApiSubscriptionEmailPostEndOfSubscriptionEmailRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiSubscriptionEmailPostEndOfSubscriptionEmail(requestParameters.hospital_id, requestParameters.patient_id, requestParameters.patient_subscription_id, requestParameters.send_to_patient, requestParameters.send_to_clinicians, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send end of subscription email to patient
     * @param {SubscriptionApiAppApiSubscriptionEmailPostPatientSubscriptionReminderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiSubscriptionEmailPostPatientSubscriptionReminder(requestParameters: SubscriptionApiAppApiSubscriptionEmailPostPatientSubscriptionReminderRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiSubscriptionEmailPostPatientSubscriptionReminder(requestParameters.hospital_id, requestParameters.patient_id, requestParameters.patient_subscription_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send email to the patient to indicate that they are done logging
     * @param {SubscriptionApiAppApiSubscriptionEmailPostSendPatientLoggingCompleteEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiSubscriptionEmailPostSendPatientLoggingCompleteEmail(requestParameters: SubscriptionApiAppApiSubscriptionEmailPostSendPatientLoggingCompleteEmailRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiSubscriptionEmailPostSendPatientLoggingCompleteEmail(requestParameters.hospital_id, requestParameters.patient_id, requestParameters.patient_subscription_id, requestParameters.date_since, requestParameters.date_until, requestParameters.send_to_admin_only, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of subscriptions for the patient
     * @param {SubscriptionApiAppApiSupplementGetPatientSupplementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public appApiSupplementGetPatientSupplements(requestParameters: SubscriptionApiAppApiSupplementGetPatientSupplementsRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).appApiSupplementGetPatientSupplements(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }
}

