import { useQuery } from '@tanstack/react-query';
import { MDBIcon } from 'mdbreact';
import React, { useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Link, useHistory } from 'react-router-dom';
import { patientReportApi } from '../api';
import { PatientReportApiAppApiClinicReportClinicianPatientsTableRequest } from '../api/generated/apis/patient-report-api';
import {
  AppApiClinicReportClinicianPatientsTable200Response,
  ClinicianPatientsTableResponseRow,
  ClinicResponse,
} from '../api/generated/models';
import { useStore, useStoreDispatch } from '../context';
import { Trans, useTranslation } from '../i18n';
import { patientListFiltersType } from '../pages/patientlist';
import { ClinicService } from '../services/clinic';
import { idToStr } from '../utils/idStr';
import { clinicGetHospitalPatientCustomFields, HospitalPatientCustomField } from './addUser';
import { LoadingSpinner } from './loadingSpinner';

type TableColumn<T> = {
  field?: keyof T,
  header: string,
  render?: (row: T) => React.ReactNode,
  onClick?: null | ((row: T) => void),
  hidden?: boolean,
  noSort?: boolean,
};

function useDebounce<K, T extends Promise<K>>(delay: number, callback: () => T): () => T {
  const timeout = React.useRef<NodeJS.Timeout | null>(null);
  const callbackRef = React.useRef(callback);
  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return React.useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    return new Promise((resolve, reject) => {
      timeout.current = setTimeout(async () => {
        try {
          resolve(await callbackRef.current());
        } catch (err) {
          reject(err);
        }
      }, delay);
    }) as unknown as T;
  }, [delay]);
}

export const PatientTable = (props: {
  filterObj: Record<string, object | boolean>,
  allFilterValues: patientListFiltersType,
  extraColumns?: Array<TableColumn<ClinicianPatientsTableResponseRow>>,
  q: any,
  setQ: (q: any) => void,
}) => {
  const { q, setQ } = props;
  const history = useHistory();
  const clinicService = ClinicService();
  const clinicianReq = useQuery(['clinician'], async () => {
    return clinicService.getClinician();
  });
  const { clinician } = useStore();
  const {
    setPatient,
    setPatientSummary,
    setGoals,
    setWater,
    setEmotion,
    setHEI,
    setDash,
    setNutrients,
    setPatientSubscriptions,
  } = useStoreDispatch();

  const clinics = clinician.clinics as ClinicResponse[];
  const hospitalCustomColumns = {} as Record<string, HospitalPatientCustomField>;
  clinics?.forEach(clinic => {
    const hospitalCustomFields = clinicGetHospitalPatientCustomFields(clinic);
    if (!hospitalCustomFields) {
      return;
    }
    hospitalCustomFields.forEach(field => {
      hospitalCustomColumns[field.field] = field;
    });
  });
  const { t } = useTranslation();
  const COLUMNS: Array<TableColumn<ClinicianPatientsTableResponseRow>> = [
    {
      field: 'hospital_patient_external_id' as any,
      header: t('External ID'),
      render: (row) => (
        <span>
          {row.hospital_attributes?.patient_external_id}
        </span>
      ),
      hidden: !clinics.find(x => x.flags?.hospital_patient_has_external_id),
      noSort: true,
    },

    ...Object.values(hospitalCustomColumns).map(field => ({
      field: 'hospital_patient_custom_fields:' + field.field as any,
      header: field.label,
      render: (row) => (
        <span>
          {row.hospital_attributes?.patient_custom_fields?.[field.field]}
        </span>
      ),
      noSort: true,
    })),

    {
      field: 'last_name',
      header: t('Name'),
      render: (row) => (
        <span>
          {row.last_name}, {row.first_name} <span style={{ opacity: 0.6 }}>#{row.patient_id}</span>
        </span>
      ),
    },

    {
      field: 'email',
      header: t('Email'),
    },

    {
      field: 'phone_number',
      header: t('Phone Number'),
    },

    {
      field: 'next_report_date',
      header: t('Next Report'),
    },

    {
      field: 'last_completed_report_date',
      header: t('Last Report'),
      render: (row) => (
        <span>
          <Link key="reports" to="/patient/reports">{row.last_completed_report_date}</Link>
        </span>
      ),
    },

    {
      field: 'last_meal_date',
      header: t('Last Meal'),
    },
  ];
  const columns = COLUMNS.filter(x => !x.hidden).concat(...(props.extraColumns || []));

  const queryOrder = !q.order ? null : {
    column: q.order.substring(1),
    dir: q.order.substring(0, 1) as '+' | '-',
  };
  const [sortColumn, _setSortColumn] = React.useState(queryOrder);

  const handleColumnHeaderClick = (column: TableColumn<ClinicianPatientsTableResponseRow>) => {
    if (!column.field) {
      return;
    }

    if (column.noSort) {
      return;
    }

    if (!sortColumn || sortColumn.column != column.field) {
      _setSortColumn({
        column: column.field,
        dir: '+',
      });
      return;
    }

    if (sortColumn.dir == '+') {
      _setSortColumn({
        column: column.field,
        dir: '-',
      });
    } else {
      _setSortColumn(null);
    }
  };

  const tableParams: Partial<PatientReportApiAppApiClinicReportClinicianPatientsTableRequest> = {
    order: sortColumn ? `${sortColumn.dir}${sortColumn.column}` : '',
    filter: JSON.stringify(props.filterObj),
  };

  useEffect(() => {
    const filterValues = Object.fromEntries(
      Object.keys(props.allFilterValues).map(key => {
        if (props.allFilterValues[key]?.length === 0) {
          return [key, undefined];
        }
        return [key, props.allFilterValues[key]];
      }),
    );
    setQ({
      ...filterValues,
      order: tableParams.order || undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allFilterValues, tableParams.order]);

  const params: PatientReportApiAppApiClinicReportClinicianPatientsTableRequest = {
    clinician_id: clinicianReq.data?.id || 0,
    hospital_id: clinicianReq.data?.hospital_id || 0,
    ...tableParams,
  };

  const getTableData = useDebounce(props.filterObj.name ? 250 : 0, async () => {
    if (clinicianReq.isLoading) {
      return new Promise(() => {}) as Promise<AppApiClinicReportClinicianPatientsTable200Response>;
    }
    if (clinicianReq.isError) {
      throw clinicianReq.error;
    }
    const res = await patientReportApi.appApiClinicReportClinicianPatientsTable(params);
    return res.data;
  });

  const tableQuery = useQuery(['patient-table', params, params.filter], getTableData);

  const resetPatient = () => {
    setPatient(null);
    setPatientSummary(null);
    setGoals([]);
    setNutrients([]);
    setWater([]);
    setHEI([]);
    setDash([]);
    setEmotion([]);
    setPatientSubscriptions([]);
  };

  const columnSortIcon = (column: TableColumn<ClinicianPatientsTableResponseRow>) => {
    if (!sortColumn || sortColumn.column != column.field) {
      /* eslint-disable-next-line i18next/no-literal-string */
      return 'sort';
    }
    /* eslint-disable-next-line i18next/no-literal-string */
    return sortColumn.dir == '+' ? 'sort-up' : 'sort-down';
  };

  return (
    <div>
      <Table hover style={{ width: '100%' }}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                onClick={() => handleColumnHeaderClick(column)}
                style={{ cursor: column.field ? 'pointer' : 'default' }}
              >
                {column.header}
                {column.field && !column.noSort && (
                  <>
                    {' '}
                    <MDBIcon icon={columnSortIcon(column)} />
                  </>
                )}
              </th>
            ))}
          </tr>
        </thead>
        {tableQuery.isLoading && (
          <tbody>
            <tr>
              <td colSpan={99}>
                <LoadingSpinner />
              </td>
            </tr>
          </tbody>
        )}
        {tableQuery.isError && (
          <tbody>
            <tr>
              <td colSpan={99}>
                <Trans>Unexpected error loading patient list</Trans>: {'' + tableQuery.error}
              </td>
            </tr>
          </tbody>
        )}
        {!tableQuery.isLoading && tableQuery?.data?.rows?.length == 0 && (
          <tbody>
            <tr>
              <td colSpan={99}>
                <i>
                  <Trans>No patients found</Trans>
                </i>
              </td>
            </tr>
          </tbody>
        )}
        {!tableQuery.isLoading && (
          <tbody>
            {tableQuery?.data?.rows?.map((row, index) => {
              const defaultOnClick = (e) => {
                resetPatient();
                if (e.ctrlKey || e.type === 'contextmenu') {
                  window.open(`/patient/${idToStr(row.patient_id)}`, '_blank');
                  e.preventDefault();
                } else {
                  history.push(`/patient/${idToStr(row.patient_id)}`);
                }
              };
              return (
                <tr key={index}>
                  {columns.map((column, index) => (
                    <td
                      key={index}
                      style={{ cursor: column.onClick !== null ? 'pointer' : 'default' }}
                      onClick={column.onClick === null
                        ? null
                        : column.onClick === undefined
                        ? (e) => defaultOnClick(e)
                        : () => column.onClick(row)}
                      onContextMenu={column.onClick === null
                        ? null
                        : column.onClick === undefined
                        ? (e) => defaultOnClick(e)
                        : () => column.onClick(row)}
                    >
                      {column.render ? column.render(row) : row[column.field]}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
    </div>
  );
};
